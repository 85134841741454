import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import helper from '../../services/helper';
import useTranslations from '../../i18n/useTranslations';


function CountdownTimer({ expireDateTime = null, onExpire = null  }) {
  const { t } = useTranslations();
  const targetDate = expireDateTime ? moment(expireDateTime) : moment(); // Replace with your target date and time
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining(countLoop = 0) {
    const now = moment();
    const timeDifference = moment.duration(targetDate.diff(now));

    if (timeDifference.asMilliseconds() <= 0) {
      // Countdown has ended

      if( expireDateTime != null && onExpire) {
        console.log('AA onExpire', expireDateTime, countLoop)
        onExpire()
      }

      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = timeDifference.asDays();
    const hours = timeDifference.hours();
    const minutes = timeDifference.minutes();
    const seconds = timeDifference.seconds();

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }
  useEffect(() => {
  let countLoop = 0
    const interval = setInterval(() => {
      const remaining = calculateTimeRemaining(countLoop);
      if (remaining.days === 0 && remaining.hours === 0 && remaining.minutes === 0 && remaining.seconds === 0) {
        clearInterval(interval); // Stop the interval if the countdown has expired
      } else {
        setTimeRemaining(remaining);
      }
      countLoop++
    }, 1000);

    return () => clearInterval(interval);
  }, [onExpire]);

  // return (
  //   <div>
  //     <h1>Countdown Timer</h1>
  //     <div>
  //       <p>Days: {Math.floor(timeRemaining.days)}</p>
  //       <p>Hours: {timeRemaining.hours}</p>
  //       <p>Minutes: {timeRemaining.minutes}</p>
  //       <p>Seconds: {timeRemaining.seconds}</p>
  //     </div>
  //   </div>
  // );

  return (
    <div className="flex justify-center items-baseline gap-[16px]">
      <div className="text-center flex gap-[8px] items-center">
        <h4 className="font-medium text-[24px] md:text-[32px] text-Neutral-A800">{helper.FN.numberWithZero(timeRemaining.minutes,2)}</h4>
        <p className="text-[12px] md:text-[14px] text-Neutral-A800">{t.bookingStep5.min}</p>
      </div>
      <div className="text-center flex gap-[8px] items-center">
        <h4 className="font-medium text-[24px] md:text-[32px] text-Neutral-A800">{helper.FN.numberWithZero(timeRemaining.seconds,2)}</h4>
        
        <p className="text-[12px] md:text-[14px] text-Neutral-A800">{t.bookingStep5.sec}</p>
      </div>
    </div>
  )
}

export default CountdownTimer