import * as React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OpenPass from "../../assets/images/icon-input/open-pass.svg";
import OpenNotPass from "../../assets/images/icon-input/open-not-pass.svg";

const TextFieldPassword = React.forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      ref={ref}
      {...props}
      type={!showPassword ? "password" : "text"}
      InputProps={{
        ...props?.InputProps,
        endAdornment: (
          <InputAdornment className="px-[10px]" position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {/* {!showPassword ? <img className="mr-[6px]" src="/assets/Icon/eye-off.svg" alt="eye-off"/> : <Visibility />} */}
              {!showPassword ? (
                  <img className="w-[12px] md:w-[18px]" src={OpenPass} alt="" />
                ) : (
                  <img className="w-[12px] md:w-[18px]" src={OpenNotPass} alt="" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{ maxLength: 150 }}
    />
  );
});

export default TextFieldPassword;
