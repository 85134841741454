import { langUrl } from "./i18nConfig";
import en from './lang/en.json';
import th from './lang/th.json';
import cn from './lang/cn.json';
export function fetchTranslations(lang) {
  if (lang === 'th') {
    return th
  } else if (lang === 'cn') {
    return cn
  }
  return en
}
