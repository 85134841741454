import {createSlice} from '@reduxjs/toolkit'

const defaultData = {
  data: [],
  isLoading: false,
}

export const ProgramTreamentSlice = createSlice({
  name: 'ProgramTreament',
  initialState: defaultData,
  reducers: {
    setProgramTreament: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetProgramTreament: () => defaultData
  },
})

// Action creators are generated for each case reducer function
export const {
  setProgramTreament,
  resetProgramTreament
} = ProgramTreamentSlice.actions

export default ProgramTreamentSlice.reducer