import React from 'react'
import useTranslations from '../../i18n/useTranslations';
import { useNavigate } from 'react-router-dom';
import WebContainer from '../../components/Layouts/WebContainer';
import SettingTemplate from './SettingTemplate';
import PrivacyPolicyContent from './PrivacyPolicyContent';

export default function PrivacyPolicy() {
  const { t, lang } = useTranslations();
  const navigate = useNavigate();

  return (
    <WebContainer bgGray>
      <SettingTemplate 
        isOpen={true}
        onAccept={()=> {
          navigate(-1)
        }}
        onClose={()=> {
          navigate(-1)
        }}
        title="Privacy Policy"
      >
        <>
          <PrivacyPolicyContent/>
        </>
      </SettingTemplate>
    </WebContainer>
  )
}