import React from "react";
import GridRap from "../../components/Layouts/GridRap";
import GridMain from "../../components/Layouts/GridMain";
import { useNavigate, useParams } from "react-router-dom";

import { UpdateCancelReserveSlotPayment, UpdateBookingPayment } from "../../services/Booking";

export default function BankPaymentMock({ onClose, onSave, isNext = true }) {
  const { bookingId } = useParams();
  
  const postCancelReserveSlotPayment = async (payload) => {
    try {
      const { data } = await UpdateCancelReserveSlotPayment({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body;
        window.location.href = '/booking-step1'
      } else {
        window.location.href = '/booking-step1'
    }
} catch (e) {
    console.log(`🦄: postCancelReserveSlotPayment -> e`, e);
    window.location.href = '/booking-step1'
    }
  };

  const postUpdateBookingPayment = async (payload) => {
    try {
      const { data } = await UpdateBookingPayment({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body;
        const token = resData?.token ?? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJib29raW5nSWQiOiIzOCJ9.XFQ0rIzmzVJ1SiNXguySBBX6RnpNrV_gSAM01CkNPLM'
        setTimeout(()=> {
            window.location.href = `/booking-success/${token}`
        },1000)
      }
    } catch (e) {
      console.log(`🦄: postUpdateBookingPayment -> e`, e);
    }
  };

  const handleBack = () => {
    const payload = { bookingId: bookingId }
    postCancelReserveSlotPayment(payload)
  };

  const handleNext = () => {
    const payload = { bookingId: bookingId }
    postUpdateBookingPayment(payload)
  };

  return (
    <div className="bg-[#FFF] w-full text-center min-h-[100vh]">
      <GridMain>
        <div className="mx-auto max-w-[1024px] flex flex-col min-h-[100vh] justify-center items-center gap-[20px]">
          <div>
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7QfIsAdOw0vsxpmXGN4XNR9W-gnRNtfWyzA&usqp=CAU" />
            <h1 className="mt-[16px]">BankPaymentMock</h1>
          </div>
          <button
            className="btn btn-secondary !max-w-none"
            onClick={handleBack}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary !max-w-none"
            onClick={handleNext}
          >
            Submit
          </button>
        </div>
      </GridMain>
    </div>
  );
}
