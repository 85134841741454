import React from "react";

export default function GridMain({
  className,
  subClassName,
  children,
  topSpace = "0",
  botSpace = "0",
  botFooterSpace = "0",
  isMobileFull = false,
}) {

  let topSpaceStyle = ""
  if (topSpace == "1") {
    topSpaceStyle = `pt-[24px] md:pt-[50px]`
  }

  let botSpaceStyle = ""
  if (botSpace == "1") {
    botSpaceStyle = `pb-[24px] md:pb-[50px]`
  }

  let botFooterSpaceStyle = ""
  if (botFooterSpace == "1") {
    botFooterSpaceStyle = `pb-[116px] md:pb-[152px]`
  }



  if (isMobileFull) {
    return (
      <>
        <section className={`--gird-main px-[0px] md:px-[96px] w-full ${topSpaceStyle} ${botSpaceStyle} ${botFooterSpaceStyle} ${className}`}>
          <div className={`--gird-main-sub w-full mx-auto max-w-full md:max-w-screen-xl ${subClassName}`}>
            {children}
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <section className={`--gird-main px-[20px] md:px-[96px] w-full ${topSpaceStyle} ${botSpaceStyle} ${botFooterSpaceStyle} ${className}`}>
        <div className={`--gird-main-sub w-full mx-auto max-w-screen-xl ${subClassName}`}>
          {children}
        </div>
      </section>
    </>
  );
}
