import React, { useEffect, useState } from 'react'
import useTranslations from '../../i18n/useTranslations';
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import GridRap from '../../components/Layouts/GridRap';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import { SearchPointsBurn, SearchPointsEarn } from "../../services/MemberShip";
import IconPoint from "../../assets/images/icon-point.svg";
import { Link } from 'react-router-dom';

export default function SurveySuccess({ point = 0, errCode = 20000 }) {
    const { t } = useTranslations();

    return (
        <>
        <WebContainer bgGraphics={true} contentCenter={true} contentCenterDesktop={true} noLogin>
            <GridMain className="" topSpace="1" botSpace="1">
            <GridRap className="bg-white" isSmallSize>
                <div className="flex flex-col pb-[61px] md:pb-[101px]">
                    {
                        errCode === 40301
                        ? (
                            <>
                                <h5 className="cp-title mb-[8px]">{t.survey.c40301.title0} <br className="block md:hidden"/>{t.survey.c40301.title1}</h5>
                                <p className="text-[14px] md:text-[20px] text-Neutral-A800 text-center mb-[32px]">
                                    {t.survey.c40301.subTitle0} <br/>{t.survey.c40301.subTitle1}<br/>{t.survey.c20000.subTitle2}
                                </p>
                            </>
                        )
                        : (
                            <>
                                <div className="mb-[40px]">
                                    <h5 className="cp-title mb-[8px]">{t.survey.c20000.title}</h5>
                                    <p className="text-[14px] md:text-[20px] text-Neutral-A800 text-center">
                                    {t.survey.c20000.subTitle0} <br/>{t.survey.c20000.subTitle1}<br/>{t.survey.c20000.subTitle2}
                                    </p>
                                </div>
                                <div className="flex flex-col gap-[12px] md:gap-[16px] text-center mb-[48px]">
                                    <p className="text-[16px] md:text-[20px] text-Neutral-A800 font-medium">{t.survey.c20000.received}</p>
                                    <div className="flex items-center justify-center gap-[4px]">
                                        {/* <img className="w-auto h-full max-h-[21px] md:max-h-[32px]" src={IconPoint} alt="iconpoint"/> */}
                                        <p className="text-[20px] MinervaModern text-[#DDB05B]">+ {point} {t.survey.c20000.point}</p>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    <div className="text-center">
                        <Link to={"/"}>
                            <ButtonPrimary size="mini" addClass="w-full max-w-[223px] md:max-w-[188px]">
                                {t.survey.btnBackHome}
                            </ButtonPrimary>
                        </Link>
                    </div>
                </div>
            </GridRap>
            </GridMain>
        </WebContainer>
        </>
    )
}
