import React, { useEffect, useState } from 'react';
import helper from '../../services/helper';

const UnAuthGuard = ({component}) => {
    const [ loading, setLoading ] = useState(true)
    useEffect(() => {
        console.log("UnAuth Guard"+ window.location.href);
        const isLogin = helper.Auth.isLOGIN();
        const lineId = localStorage.getItem('lineId') ?? null
        // alert("UnAuth Guard")
        if (lineId) {
            setLoading(false)
        } else if (isLogin) {
            window.location.href = '/profile'
        } else {
            setLoading(false)
        }
    }, [component]);

    if (loading) return <></>

    return <React.Fragment>{component}</React.Fragment>
}

export default UnAuthGuard;