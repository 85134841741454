import React, { useEffect, useState } from "react";
import IconShield from "../../../assets/images/icon-shield.png";
import IconExam from "../../../assets/images/icon-exclamation.png";
import Heat from "../../../assets/images/heat.png";
import Infected from "../../../assets/images/infected.png";
import Hearth from "../../../assets/images/hearth.png";
import HC1 from "../../../assets/images/hc-1.png";
import HC2 from "../../../assets/images/hc-2.png";
import HC3 from "../../../assets/images/hc-3.png";
import FooterBookingPaymentPolicy from "./FooterBookingPaymentPolicy";
// import { SearchHealthConditions } from "../../../../services/Booking";
import ImgPaymentPolicy from "../../../assets/images/booking/payment-policy.png";
import styled from 'styled-components';
import GridRap from "../../../components/Layouts/GridRap";
import GridMain from "../../../components/Layouts/GridMain";
import WebContainer from "../../../components/Layouts/WebContainer";
import useTranslations from "../../../i18n/useTranslations";

const AgeHeath = styled.div`
  width: 80px;
  height: 80px;
  background: url(${ImgPaymentPolicy}) center center no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @media (min-width: 768px) {
    height: 112px;
    width: 112px;
  }
`

export default function PopupBookingPaymentPolicy({ onClose, onSave }) {
  const [ condition, setCondition ] = useState([]);
  const { t } = useTranslations();

  useEffect(() => { window.scrollTo({ top: 0 }) }, []);

  const handleBack = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleSelect = () => {
    if (onSave) {
      onSave()
    }
  }

  return (
    <>
      {/* {JSON.stringify(condition)} */}
      <WebContainer bgGray={true} footer={
         <FooterBookingPaymentPolicy onClose={handleBack} onSave={handleSelect}/>
      }>
        <GridMain isMobileFull className="popup-booking-space ">
          <GridRap isMediumSize={true}>
            <div className="w-full bg-white  py-[36px] md:py-[36px] px-[32px] md:px-[50px]">
              <div className="flex flex-col items-center">
                <AgeHeath/>
                <p className="text-brown-0 text-[20px] md:text-[24px] font-medium mb-[16px] md:mb-[24px]">
                  {t.onlinePaymentPolicy.title}
                </p>
              </div>
              <div className="mb-[24px] md:mb-[32px]">
                <label className="text-[16px] md:text-[20px] font-medium text-brown-1">{t.onlinePaymentPolicy.list0_t}</label>
                <ul className="mt-[4px] listStyle-s5-0"  >
                  <LiNo>{t.onlinePaymentPolicy.list0_0}</LiNo>
                  <LiNo>{t.onlinePaymentPolicy.list0_1}</LiNo>
                  <LiNo>{t.onlinePaymentPolicy.list0_2}</LiNo>
                  <LiNo>{t.onlinePaymentPolicy.list0_3}</LiNo>
                </ul>
              </div>
              <div className="mb-[24px] md:mb-[32px]" >
                <label className="text-[16px] md:text-[20px] font-medium text-brown-1">{t.onlinePaymentPolicy.list1_t}</label>
                <ul className="mt-[4px] listStyle-s5-0">
                  <LiNo>{t.onlinePaymentPolicy.list1_0}</LiNo>
                </ul>
              </div>
              <div className="mb-[24px] md:mb-[32px]">
                <label className="text-[16px] md:text-[20px] font-medium text-brown-1">{t.onlinePaymentPolicy.list2_t}</label>
                <ul className="mt-[4px] listStyle-s5-0">
                  <LiNo>{t.onlinePaymentPolicy.list2_0}</LiNo>
                </ul>
              </div>
              <div className="mb-[24px] md:mb-[32px]">
                <label className="text-[16px] md:text-[20px] font-medium text-brown-1">{t.onlinePaymentPolicy.list3_t}</label>
                <ul className="mt-[4px] listStyle-s5-0">
                  <LiNo>{t.onlinePaymentPolicy.list3_0}</LiNo>
                  <LiNo>{t.onlinePaymentPolicy.list3_1}</LiNo>
                  <LiNo>{t.onlinePaymentPolicy.list3_2}</LiNo>
                  <LiNo>{t.onlinePaymentPolicy.list3_3}</LiNo>
                  <LiNo>{t.onlinePaymentPolicy.list3_4}</LiNo>
                </ul>
              </div>
              <div className="mb-[24px] md:mb-[32px]">
                <label className="text-[16px] md:text-[20px] font-medium text-brown-1">{t.onlinePaymentPolicy.list4_t}</label>
                <ul className="mt-[4px] listStyle-s5-0">
                  <LiNo>{t.onlinePaymentPolicy.list4_0}</LiNo>
                  <LiNo>{t.onlinePaymentPolicy.list4_1}</LiNo>
                  <LiNo>{t.onlinePaymentPolicy.list4_2}</LiNo>
                  <LiNo>{t.onlinePaymentPolicy.list4_3}</LiNo>
                </ul>
              </div>
              <div className="mb-[24px] md:mb-[32px]">
                <label className="text-[16px] md:text-[20px] font-medium text-brown-1">{t.onlinePaymentPolicy.list5_t}</label>
                <ul className="mt-[4px] listStyle-s5-none">
                  <LiNo>{t.onlinePaymentPolicy.list5_0}</LiNo>
                </ul>
              </div>
              <div className="mb-[24px] md:mb-[32px]">
                <label className="text-[16px] md:text-[20px] font-medium text-brown-1">{t.onlinePaymentPolicy.list6_t}</label>
                <ul className="mt-[4px] listStyle-s5-none">
                  <LiNo>{t.onlinePaymentPolicy.list6_0}</LiNo>
                </ul>
              </div>
              <div className="mb-[24px] md:mb-[32px]">
                <label className="text-[16px] md:text-[20px] font-medium text-brown-1">{t.onlinePaymentPolicy.list7_t}</label>
                <ul className="mt-[4px] listStyle-s5-none">
                <LiNo>{t.onlinePaymentPolicy.list7_0}</LiNo>
                </ul>
              </div>
              
            </div>
           
            </GridRap>
        </GridMain>
      </WebContainer>
    </>
  );
}

const LiNo = ({ children }) => {
  return <li className="text-[14px] md:text-[16px] leading-[24px] md:leading-[32px] font-light">{children}</li>
}
