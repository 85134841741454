import React, { useState } from "react";
import ModalBase from "../../components/Modal/ModalBase";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../components/button/ButtonPrimary";

export default function ModalPointNotEnough({ open = false, onClose }) {
  return (
    <>
      <ModalBase
        mini={true}
        open={open}
        onClose={() => {}}
        size="size2"
        header={<></>}
        content={
          <div className="flex flex-col justify-center mb-[8px] md:mb-[16px]">
            <h1 className="text-center text-[20px] text-Neutral-A800 font-medium">
              Your points are not enough <br/>to use discount
            </h1>
          </div>
        }
        footer={
          <>
            <div className="flex flex-col md:flex-row justify-center items-center gap-[16px] md:gap-[12px]">
              <ButtonPrimary
                addClass="w-full md:max-w-[157px]"
                style={{ minWidth: "120px" }}
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                }}
              >
                Close
              </ButtonPrimary>
            </div>
          </>
        }
      />
    </>
  );
};
