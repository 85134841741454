import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import useTranslations from '../../../i18n/useTranslations';
import IconTel from "../../../assets/images/icon-tel.png";
import moment from 'moment';
import { Link, useNavigate, useParams } from "react-router-dom";

import IconUser from "../../../assets/images/svg/guest-one.svg";
import IconUserGold from "../../../assets/images/svg/guest-gold-one.svg";
import IconCalendar from "../../../assets/images/svg/calendar-one.svg";
import IconTime from "../../../assets/images/svg/time-one.svg";
import IconMarker from "../../../assets/images/svg/marker-one.svg";
import IconTag from "../../../assets/images/svg/tag-one.svg";
import helper from '../../../services/helper';

const { toNumberWithCommas } = helper.FN
export default function BookingDetailTemplate({ 
  title = "",
  bookingDate = null,
  bookingNo = "",
  bookingDetail = {}, 
  bookingList = [],
  status = ""
}) {
  const navigate = useNavigate();
  const { t } = useTranslations();
  return (
    <div className="flex flex-col items-center">
      <h5 className="cp-title mb-[32px] md:mb-[24px]">
        {title}
      </h5>
      <div className="flex flex-col text-center gap-[8px] mb-[16px]">
        <p className="text-[24px] md:text-[20px] text-Neutral-A800 font-medium">{helper.TIME.toDateNormalEN(bookingDate)}</p>
        <p className="text-[14px] md:text-[16px] text-Neutral-A600">ID {bookingNo}</p>
        <BookingStatus status={status}/>
      </div>
      <div className="w-full">
      {
        !status 
        ? (
          <div className="flex items-center justify-between py-[16px] px-[16px] border-Neutral-A200 border-[1px] rounded-[10px] md:rounded-[10px]">
            <div className="flex gap-[12px] items-center">
              <CircleHLImage outline src={IconTel}/>
              <div className="flex flex-col gap-[4px]">
                <p className="text-[14px] md:text-[16px] text-Neutral-800 font-medium">Healthland {bookingDetail.location}</p>
                <p className="text-[14px] md:text-[16px] text-Neutral-700">{bookingDetail.branchTel}</p>
              </div>
            </div>
            <a className="rounded-[54px] bg-[#649591] py-[9px] px-[24px]" href={"tel:" + bookingDetail.branchTel}>
              <p className="text-[16px] md:text-[16px] text-white">{t.bookingDetail.callnow}</p>
            </a>
          </div>
        )
        : <></>
      }
      <p className="mt-[12px] text-[14px] md:text-[14px]" dangerouslySetInnerHTML={{ __html: t.bookingDetail.desciption}}/>
      <div className="flex flex-col gap-[24px] py-[16px]">
        <TitleSS>{t.bookingDetail.bookingDetails}</TitleSS>
      </div>
      <div className="flex flex-row justify-between gap-[12px] py-[16px] border-t-[1px]">
        <TextUnitIcon src={IconMarker} title={t.bookingDetail.location} value={bookingDetail.location}/>
        <TextUnitIcon src={IconTime} title={t.bookingDetail.startTime} value={helper.TIME.toShortTimeAmPm(bookingDetail.bookingStart)}/>
        <TextUnitIcon src={IconTag} title={t.bookingDetail.totalPrice} value={`${bookingDetail.total ? helper.FN.toNumberWithCommas(bookingDetail.total):0} THB`}/>
      </div>
      <div className="flex flex-col gap-[12px] py-[16px] border-t-[1px] md:border-t-[1px]">
        <TitleSS>{t.bookingDetail.paymentInfo}</TitleSS>
      </div>
      <div className="flex flex-col gap-[24px] md:gap-[16px] py-[16px] border-t-[1px] md:border-t-[1px]">
        <InfoTextFull title={t.bookingDetail.paymentDate} value={bookingDetail.createdOn ? moment(bookingDetail.createdOn).format('DD/MM/YYYY') : "-"} />
        <InfoTextFull title={t.bookingDetail.total} value={`${toNumberWithCommas(bookingDetail?.total ?? 0)} THB`} />
        <InfoTextFull title={t.bookingDetail.discount} value={`${bookingDetail.discount ? `- ${toNumberWithCommas(bookingDetail?.discount)}` : 0} THB`} />
        <InfoTextFull title={t.bookingDetail.grandTotal} value={`${toNumberWithCommas(bookingDetail?.netPrice ?? 0)} THB`} />
      </div>
      <div className="flex flex-col py-[24px] md:py-[16px] border-t-[1px] md:border-t-[1px]">
        <InfoTextFull title={t.bookingDetail.pointsEarnBaba} value={`${toNumberWithCommas(bookingDetail.pointEstimate ?? 0)} Points`} />
      </div>
      <div className="flex gap-[8px] border-t-[1px] md:border-t-[1px] items-center">
        <div className="flex gap-[8px] py-[16px] items-center">
          <img className="w-[16px] h-[16px]" src={IconUserGold} alt="icon"/>
          {/* <TitleSS small>
            {`${t.bookingDetail.guestNumber}`.replace('$number', bookingList.length)}
          </TitleSS> */}
          <h5 className="text-[#DDB05B] text-[16px] font-medium" dangerouslySetInnerHTML={{ __html: `${t.bookingDetail.guestNumber}`.replace('$number', `<span class="text-[#000] font-medium">${bookingList.length}</span>`)}}></h5>
        </div>
      </div>
        <div className="flex flex-col gap-[24px]">
          {
            bookingList.map((row, i) => (
              <div className="card !m-0" key={i}>
                <div className="card-header-white flex items-center">
                  <img className="w-[16px] h-[16px]" src={IconUser} alt="icon"/>
                  <p className="text-[14px] md:text-[16px] font-medium text-Neutral-A800">{`${t.bookingDetail.guest}`.replace('$number',`${i + 1}`)}  : {row.guestName}</p>
                </div>
                <div className="card-body py-[16px] md:py-[16px] px-[24px] md:px-[24px] flex flex-col gap-[24px]">
                  {
                    row.subTreatment.length > 0 && row.subTreatment.map((child, j) => (
                      <div className="flex justify-between " key={j}>
                        <div className="w-[60%] flex items-center">
                          <p className="text-[14px] md:text-[16px] font-medium text-Neutral-A800">{child.name}</p>
                        </div>
                        <div className="flex justify-between w-[40%]">
                          <TextUnit value={toNumberWithCommas(child.period)} unit={"min"}/>
                          <TextUnit value={toNumberWithCommas(child.price)} unit={"Baht"}/>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
const TextUnit = ({ value, unit }) => {
  return (
    <div className="flex flex-col items-center">
      <p className="text-[14px] md:text-[16px] font-medium text-Neutral-A900">{value}</p>
      <p className="text-[14px] md:text-[16px] text-Neutral-A600">{unit}</p>
    </div>
  )
}
const CircleHLImage = ({ src, outline }) => {

  return (
    <div className="call-icon">
      <img  src={src} alt="" className="w-[18px]" />
    </div>
  )
}

const TitleSS = ({ small = false, children }) => {
  let sizeStyle = "text-[16px] md:text-[16px]"
  if (small) {
    sizeStyle = "text-[16px] md:text-[16px]"
  }
  return (
    <h5 className={`text-[#DDB05B] ${sizeStyle} font-medium`}>{children}</h5>
  )
}
const InfoTextFull = ({ title = '', value = '', bold }) => {
  let boldStyle = ""
  if (bold) {
    boldStyle = "font-medium"
  }
  return (
    <div className="flex justify-between">
      <p className={`text-[14px] md:text-[16px] leading-[1.4em] text-Neutral-A800 ${boldStyle}`}>{title}</p>
      <p className={`text-[14px] md:text-[16px] leading-[1.4em] text-Neutral-A800 font-medium`}>{value}</p>
    </div>
  )
}
const TextUnitIcon = ({ src, title, value }) => {
  return (
    <div className="flex flex-col items-center gap-[8px]">
      <div className="flex items-center gap-[8px]">
        <img className="w-[12px] h-[12px] md:w-[20px] md:h-[20px]" src={src} alt="icon"/>
        <p className="text-[14px] md:text-[16px] font-medium">{title}</p>
      </div>
      <p className="text-[14px] md:text-[16px]">{value}</p>
    </div>
  )
}

const BookingStatus = ({ status = "" }) => {
  const { t } = useTranslations();
  let styleOp = { classStyle: "checked-in", text: t.bookingDetail.checkedIn }

  if (status === "Cancel") {
    styleOp = { classStyle: "cancelled", text: t.bookingDetail.cancelled }
  }

  if (!status) return <></>

  return (
    <div className="flex justify-center mt-[16px] md:mt-[24px]">
      <div className={`py-[4px] md:py-[8px] px-[18px] md:px-[38px] border-[1px] booking-tag ${styleOp.classStyle} rounded-[20px] w-fit`}>
        <p className={`text-[12px] md:text-[16px] font-medium`}>{styleOp.text}</p>
      </div>
    </div>
  )
}