import React, { useEffect } from 'react'
import useTranslations from '../../i18n/useTranslations';
import HistoryNotfound from '../../assets/images/my-booking/history-notfound.svg'
import ButtonPrimary from '../../components/button/ButtonPrimary';
import { useNavigate } from 'react-router';


export default function NotFoundBooking({ title = "", subTitle0 = "" , subTitle1 = "", btnText = "", onClick }) {
  const navigate = useNavigate();
  return (
    <div className="not-found-booking">
      <img className="" src={HistoryNotfound} alt='' />
      <div className="con0">
        <div className="flex flex-col text-center gap-[8px]">
          <h6 className="text-[20px] md:text-[20px] text-Neutral-A900">{title}</h6>
          <p className="text-[14px] md:text-[16px] text-Neutral-A600">{subTitle0}<br />{subTitle1}</p>
        </div>
        <ButtonPrimary
          addClass="w-full max-w-[223px] md:max-w-[180px]"
          onClick={() => {
            navigate('/booking')
          }}
        >
          {btnText}
        </ButtonPrimary>
      </div>
    </div>
  )
}