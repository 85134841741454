import React from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

function CustomSelect({ name, control, error, rules, options, t }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl fullWidth>
          <Select
            className="selection-hl"
            {...field}
            displayEmpty
            fullWidth
            error={error}
            IconComponent={ExpandMoreRoundedIcon}
            renderValue={(selected) => {
              if (!selected || selected.length === 0) {
                return (
                  <span>
                    {/* {t.register.form_nationality_placeholder} */}
                  </span>
                );
              }
              return options.find(
                (item) => item.id === selected
              )?.name;
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            {Array.isArray(options) &&
              options.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  <em className="flex items-center">
                    {item.name}
                  </em>
                </MenuItem>
              ))}
          </Select>
          <FormHelperText
            error={!!field?.value}
            className="!ml-0"
          >
            {field?.value?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default CustomSelect;