import React, { useState } from 'react'
import Label from '../../components/general/Label'
import { TextField, ToggleButton, ToggleButtonGroup, styled } from '@mui/material'
import { useForm } from 'react-hook-form';
import useTranslations from '../../i18n/useTranslations';
import IconSun from '../../assets/images/icon-sun.png'
import IconSunset from '../../assets/images/icon-sunset.png'
import IconEvening from '../../assets/images/icon-evening.png'
import DatePickerField from '../../components/general/DatePickerField';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setStep1 } from '../../features/BookingMainSlice';
import _ from 'lodash';
import { SearchEmptySlot, SearchEmptySlotAll, CreateReserveSlot } from '../../services/Booking';
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import StepBanner from './StepBanner';
import FooterSummary from "./FooterSummary";
import { useNavigate } from "react-router-dom";
import DatePickerNormal from '../../components/general/DatePickerNormal';
import helper from '../../services/helper';
import GridRap from '../../components/Layouts/GridRap';
import Label2 from '../../components/general/Label2';
import sweetAlert from '../../services/sweetAlert';
import ModalBase from '../../components/Modal/ModalBase';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import ButtonPrimaryOutline from '../../components/button/ButtonPrimaryOutline';
import ImageLabel from '../../components/general/ImageLabel';



export default function BookingStep3() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslations();

  const profile = useSelector((state) => state.profile);
  const bookingMain = useSelector((state) => state.bookingMain);
  const step1 = useSelector((state) => state.bookingMain.step1);
  const step2 = useSelector((state) => state.bookingMain.step2);
  const popupTreatment = useSelector((state) => state.bookingMain.popupTreatment);
  const booking = useSelector((state) => state.booking);
  const programTreament = useSelector((state) => state.programTreament.data);

  // SLOT MAIN
  const [ slotTimeMain, setSlotTimeMain ] = useState([])
  const [ canSelectDate, setCanSelectDate ] = useState([])

  // SLOT แยกแต่ละเวลา
  const [ slotMorning, setSlotMorning ] = useState([])
  const [ slotAfternoon, setSlotAfternoon ] = useState([])
  const [ slotEvening, setSlotEvening ] = useState([])
  
  // SELET SLOT
  const [ selectSlot, setSelectSlot ] = useState({})
  const [ isOpen, setIsOpen ] = useState(false)



  // State กลาง
  const { branch, guestCount, date } = step1
  const { guestData } = step2
  
  function setDate(value) {
    const tmpObj = { date: value }
    dispatch(setStep1(tmpObj));
  }

  function gotoStep4() {
    navigate('/booking-step4')
  }

  function gotoStep2() {
    navigate('/booking-step2')
  }


  function onSave() {
    // alert('onSave')
    const isLogin = helper.Auth.isLOGIN();
    if (isLogin) { // canGotoStep4
      console.log('onSAVE', selectSlot)
      const memberId = profile.id
      let payload = helper.Booking.toPayloadBookingStep3(step1, memberId, selectSlot)
      payload.detailUsedTime = payload.detailUsedTime.map((item, index) => {
        return {
          ...item,
          guestNumber: (index + 1),
          guestName: item.guestName ? item.guestName : "Guest " + (index + 1)
        }
      })
      postReserveSlot(payload)
    } else {
      alert('ยังไม่ได้ Login')
      window.location.href = '/login?redirect=booking-step3'
    }
  }
  
  function onClose() {
    // alert('onClose')
    gotoStep2()
  }

  const postEmptySlot = async (payload) => {
    try {
      const { data } = await SearchEmptySlot({ data: payload });
      if (data.resultCode === 20000) {
        console.log('data.body >>', data.body)
        const _availableTime = data.body?.availableTime ?? []
        setSlotTimeMain(_availableTime ?? [])

        let _slotMorning = []
        let _slotAfternoon = []
        let _slotEvening = []

        setSelectSlot({})
        _.sortBy(_availableTime, ['treatmentShow']).forEach(t => {
          /**
           * 00:00|11:00 - 11:30|11:59 = morning
           * 12:00 - 14:30|14:59 = afternoon
           * 15:00 - 19:30|23:59 = evening
           */
          const [hour, minute] = t.bookingStart.split(':')
          if (+hour >= 0 && +minute >= 0 && +hour <= 11 && +minute <= 59) {
            _slotMorning.push({ ...t, select: false })
          } else if (+hour >= 12 && +minute >= 0 && +hour <= 17 && +minute <= 59) {
            _slotAfternoon.push({ ...t, select: false })
          } else if (+hour >= 18 && +minute >= 0 && +hour <= 23 && +minute <= 59) {
            _slotEvening.push({ ...t, select: false })
          }

          setSlotMorning(_slotMorning)
          setSlotAfternoon(_slotAfternoon)
          setSlotEvening(_slotEvening)
        })

        console.log('_slotMorning >>', _slotMorning)
        console.log('_slotAfternoon >>', _slotAfternoon)
        console.log('_slotEvening >>', _slotEvening)

        
      } 
    } catch (e) {
      console.log("ERR postEmptySlot", e);
    }
  }
  const postEmptySlotAll = async (payload) => {
    try {
      const { data } = await SearchEmptySlotAll({ data: payload });
      if (data.resultCode === 20000) {
        console.log('data.body >>', data.body)
        setCanSelectDate(data.body)
      } 
    } catch (e) {
      console.log("ERR postEmptySlotAll", e);
    }
  }

  const postReserveSlot = async (payload) => {
    try {
      const { data } = await CreateReserveSlot({ data: payload });
      if (data.resultCode === 20000) {
        console.log('data.body >>', data.body)
        gotoStep4()        
      } 
    } catch (e) {
      console.log("ERR postReserveSlot", e);
    }
  }

  
  useEffect(() => {
    console.log('useEffect GET postEmptySlot!', step1)
    console.log('useEffect GET postEmptySlot!', step2)
    const _payload = helper.Booking.toPayloadStep3(step1, step2)
    console.log('_payload >>', _payload)
    postEmptySlot(_payload)
    // postEmptySlotAll(_payload)
  }, [step1.date])

  useEffect(() => {
    console.log('useEffect onload profile', profile)
    if (profile.isLoading) {
      const isLogin = helper.Auth.isLOGIN();
      if (!isLogin) {
        setIsOpen(true)
      }
    }
  }, [profile])

  function calIsNext(_selectSlot = {}) {
    if (Object.keys(_selectSlot).length === 0 ) {
      return true
    }
    return false
  }

  function callEmptySlotAll(month) {
    console.log('onMonthChange >>', month )
    console.log('useEffect GET postEmptySlot!', step1)
    console.log('useEffect GET postEmptySlot!', step2)
    let _payload = helper.Booking.toPayloadStep3(step1, step2)
    _payload.treatmentDate = null
    _payload.selectYearMonth = month
    postEmptySlotAll(_payload)
  }

  useEffect(() => { window.scrollTo({ top: 0 }) }, []); // newpage-scoll-top

  return (
    <>
      <WebContainer footer={
        <FooterSummary 
          step={3} 
          dataDetail={{ time: selectSlot.bookingStart }} 
          onClose={onClose} 
          onSave={onSave} 
          isNextStep={calIsNext(selectSlot)}
        />  
      }>
        <GridMain className="bk-container bk-step3 ribbonx">
          <GridRap isSmallSize>
          <div className="flex flex-col items-center">
            <StepBanner current={2}/>
              <div className="w-full mt-[24px] md:mt-[36px]">
                <h5 className="cp-title mb-[24px] md:mb-[16px]">Available Time</h5>
                {/* {JSON.stringify(booking.guestData)} */}
                <div className="flex flex-col gap-[24px]">
                <div className="card">
                  <div className="card-body flex flex-col px-[12px] md:px-[24px] py-[24px] md:py-[32px]">
                    <div className="flex flex-col gap-[16px] md:gap-[32px] mb-[24px] md:mb-[32px]">
                      <ImageLabel dataType="date">{t.bookingStep3.selectDate}</ImageLabel>
                      <DatePickerNormal
                        name="date"
                        customClass="hl-date-step3"
                        placeholder="Choose Date"
                        minDate={moment().add(1, "day")}
                        maxDate={moment().add(6, "month")}
                        value={step1.date}
                        shouldDisableDate={canSelectDate}
                        onChange={(value) => setDate(value)}
                        onMonthChange={(month) => callEmptySlotAll(month)}
                        onOpen={(value) => {
                          const strMonth = moment(new Date(value).toISOString()).format('YYYY-MM')
                          callEmptySlotAll(strMonth)
                        }}
                      />
                    </div>
                    <div className="mb-[16px]">
                      <ImageLabel dataType="time">{t.bookingStep3.availableTime}</ImageLabel>
                    </div>
                    <div className="flex flex-col gap-[24px] gap-[16px]">
                        {
                          slotTimeMain.length === 0
                          ? <EmptySlot/>
                          : (
                            <>
                              <AvailableTimeBox title={t.bookingStep3.morning} isShow={slotMorning.length > 0} >
                                {
                                  slotMorning.map(item => {
                                    return (
                                      <TimeBox
                                        isActive={selectSlot.bookingStart === item.bookingStart}
                                        item={item}
                                        onClick={(value) => {
                                          setSelectSlot(value)
                                        }}
                                      >
                                        {item.bookingStart}
                                      </TimeBox >
                                    )
                                  })
                                }
                              </AvailableTimeBox>
                          
                              <AvailableTimeBox title={t.bookingStep3.afternoon} isShow={slotAfternoon.length > 0} >
                                {
                                  slotAfternoon.map(item => {
                                    return (
                                      <TimeBox
                                        isActive={selectSlot.bookingStart === item.bookingStart}
                                        item={item}
                                        onClick={(value) => {
                                          setSelectSlot(value)
                                        }}
                                      >
                                        {item.bookingStart}
                                      </TimeBox >
                                    )
                                  })
                                }
                              </AvailableTimeBox>

                              <AvailableTimeBox title={t.bookingStep3.evening} isShow={slotEvening.length > 0} >
                                {
                                  slotEvening.map(item => {
                                    return (
                                      <TimeBox
                                        isActive={selectSlot.bookingStart === item.bookingStart}
                                        item={item}
                                        onClick={(value) => {
                                          setSelectSlot(value)
                                        }}
                                      >
                                        {item.bookingStart}
                                      </TimeBox >
                                    )
                                  })
                                }
                              </AvailableTimeBox>
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div >
              </div>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
      <ModalCheckLogin 
        open={isOpen} 
        onClose={()=> {
          console.log("onClose!!")
          navigate('/login?redirect=booking-step3')
        }} 
        onSave={()=> {
          console.log("onSave!!")
          navigate('/register?redirect=booking-step3')
        }} 
      />
    </>
  );

}

const EmptySlot = () => {
  return (
    <div className="p-[3rem]">
      <p className="text-[14px] md:text-[16px] text-center text-[#6D6E71]">
        No available time matches your search.
      </p>
      <p className="text-[14px] md:text-[16px] text-center text-[#6D6E71]">
        Please change the appointment date.
      </p>
    </div>
  )
}

const AvailableTimeBox = ({ isShow = false, title = '', children }) => {
  const typeOption = { 
    "Morning": IconSun,
    "Afternoon": IconSunset,
    "Evening": IconEvening
  }
  const imgType = typeOption[title] ? typeOption[title] : null

  if (!isShow) return <></>

  return (
    <div className="flex flex-col gap-[8px]">
      <div className="flex items-center gap-[8px]">
        <img src={imgType} alt='' className="w-auto h-[16px] md:h-[22px]" />
        <span className="text-Neutral-A800 text-[14px] md:text-[16px] ">{title}</span>
      </div>
      <div className="flex flex-wrap gap-[8px] md:gap-x-[24px] md:gap-y-[16px]">
          {children}
      </div>
    </div>
  )
}

const TimeBox = ({ item = {}, onClick = null, isActive = false }) => {
  let activeStyle = ""
  const handleClick = () => {
    if (onClick) {
      onClick(item)
    }
  }

  if (isActive) {
    activeStyle = " active"
  }

  const [ hour, minute ] = `${item.bookingStart}`.split(':')
  return (
    <div className={`time-box ${activeStyle}`} onClick={handleClick}>
      {hour}.{minute}
    </div>
  )
}

const ModalCheckLogin = ({ open = false, onClose, onSave }) => {
  return (
    <>
      <ModalBase
        mini={true}
        open={open}
        onClose={() => {

        }}
        size="size2"
        header={
          <>
          </>
        }
        content={
          <div className="flex flex-col justify-center mb-[24px] md:mb-[32px]">
            <h1 className="text-center text-[20px] md:text-[24px] text-[#DDB05B] font-medium">You are not logged in</h1>
            <p className="text-center text-[12px] md:text-[16px]">Please sign in or sign up to continue.</p>
          </div>
        }
        footer={
          <>
            <div className="flex flex-col justify-center items-center gap-[16px] md:gap-[24px]">
              <ButtonPrimary 
                size="mini"
                addClass="w-full md:max-w-[366px]"
                onClick={() => {
                  if (onClose) {
                    onClose()
                  }
                }}
              >
                Sign in as a member
              </ButtonPrimary>
              <ButtonPrimaryOutline 
                size="mini"
                addClass="w-full md:max-w-[366px]"
                onClick={() => {
                  if (onSave) {
                    onSave()
                  }
                }}
              >
                Sign up an account
              </ButtonPrimaryOutline>
            </div>
          </>
        }
      />
    </>
  );
};