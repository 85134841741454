import React from "react";
import GridMain from "./GridMain";
import useTranslations from "../../i18n/useTranslations";
import GridRap from "./GridRap";
import registerBanner from '../../assets/images/top-banner/top-register.jpg';
import bookingBanner from '../../assets/images/top-banner/top-register.jpg';
// import bookingBanner from '../../assets/images/Banner-booking-d.png'

export default function TopBanner({ type = "Register" }) {
  const { t } = useTranslations();
  const { lang, supportedLangs, setLang } = useTranslations();

  let bannerData = { src: "" , title: "title", subtitle: "subtitle" }
  switch(type) {
    case "Register":
      bannerData = { src: registerBanner , title: t.banner.register.title, subtitle: t.banner.register.subTitle }
      break;
    case "Booking":
      bannerData = { src: bookingBanner , title: t.banner.booking.title, subtitle: t.banner.booking.subTitle }
      break;
    default:
      // code block
  }
  
  return (
    <>
        <div className="relative ">
          <img className="h-[140px] object-cover w-full md:h-[200px] md:max-h-auto mx-auto" src={bannerData.src} alt="bg-Banner" />
          <div className="absolute top-0 left-0 mx-auto w-full h-full">
            <GridRap isMobileFull={true} className="w-full flex h-full items-center md:pb-[12px]">
              <p className="text-become-member w-full">
                {bannerData.title} <br className="md:hidden"/> {bannerData?.subtitle ?? ''}
              </p>
            </GridRap>
          </div>
        </div>
    </>
  );
}
