import React from "react";
import TopLogo from "./TopLogo";
import GridMain from "./GridMain";

function WebContainer({ bgGraphics = false, bgGray = false, contentCenter = false, contentCenterDesktop = false, wfull = false, children, noLogin = false, hfull = false, footer = null }) {
  let ClassStyle = `web-content `
  if (bgGraphics) {
    ClassStyle += ` bg-graphics`
  }
  if (bgGray) {
    ClassStyle += ` bg-gray `
  }
  if (contentCenter) {
    ClassStyle += ` content-center`
  }
  if (contentCenterDesktop) {
    ClassStyle += ` web-content-center`
  }
  if (wfull) {
    ClassStyle += ` w-full`
  }
  if (hfull) {
    ClassStyle += ` m-h-full`
  }
  return (
    <div className="flex flex-col min-h-[100vh]">
      <TopLogo noLogin={noLogin}/>
      
      <main className={ClassStyle}>
        { children }
      </main>
      { footer ? footer : <></> }
    </div>
  );
}

export default WebContainer
