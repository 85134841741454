import React, { useEffect, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Clock from '../../../assets/images/icon-clock-gray.png'
import Tag from '../../../assets/images/icon-tag-gray.png'
import arrowURL from '../../../assets/images/arrow-list.svg'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import CheckSingle from '../../../components/general/CheckSingle';
import useTranslations from "../../../i18n/useTranslations";
import helper from '../../../services/helper';

export default function SelectTreatmentsLists({ treatmentList = [] , programId = null, onChange, values = {}, indexActive = null, onActive }) {
  const { t } = useTranslations();
  function onSelectCheckBox(_programId = null, _treatmentId = null, _subTreatmentId = null) {
    console.log('onSelectCheckBox >>',_programId, _treatmentId, _subTreatmentId)
    if (onChange) {
      onChange(_programId, _treatmentId, _subTreatmentId)
    }
  }

  function onToValue(_values = {}, _programId, _treatmentId, _subTreatmentId) {
    console.log('onToValue >>', _values)
    console.log('onToValue >>', _programId, _treatmentId, _subTreatmentId)
    const keyMap = `${_programId}|${_treatmentId}|${_subTreatmentId}`
    if(_values[keyMap]) {
      return !!_values[keyMap]
    }
    return false
  }

  function onCheckActive(_i, _indexActive) {
    const isCheck = _i === indexActive
    if (isCheck === true) {
      onActive(null)
    } else {
      onActive(_i)
    }
  }

  return (
    <div className="w-full bg-[#xE85] border-t-[1px] md:border-t-[0px] md:border-l-[1px] border-[#DEE2E6] md:pl-[24px]">
      {
        treatmentList?.map((row, i) => {
          return (
            <aside className="border-b-[1px] border-Neutral-A300">
              <div 
                className="py-[16px] flex flex-row gap-[16px] relative" 
              >
                <div className="flex items-center">
                  {/* {
                    !!row.hasSubTreatment === false 
                    ? <CheckSingle value={false}/>
                    : <div className="w-[24px]"/>
                  } */}
                  {
                      !!row.treatmentHasSubTreatment === false
                      ? (
                        row?.subTreatment?.filter((item, i) => i === 0).map((subRow, j) => {
                          const isCheck = !!onToValue(values, programId, row.treatmentId, subRow.subTreatmentId)
                          return (
                            <CheckSingle 
                              value={isCheck}
                              onChange={() => onSelectCheckBox(programId, row.treatmentId, subRow.subTreatmentId)}
                              disabled={isCheck === false && Object.keys(values).length >= 2}
                            />
                          )
                        })
                      )
                      : <div className="w-[14px] md:w-[24px]"/>
                    }
                </div>
                <div className="flex flex-col cursor-pointer" onClick={() => onCheckActive(i, indexActive)}>
                  <div className="flex items-center gap-[12px]">
                    <p className="text-[12px] md:text-[16px] font-medium text-[#393939]">{row.treatmentName}</p>
                    {
                      row.treatmentIsPopular 
                      ? <p className="popular-tag text-[12px] md:text-[16px] font-medium text-[#FFF] leading-[160%]">{t.selectTreatments.popular}</p>
                      : <></>
                    }
                  </div>
                  <div>
                    {
                      !!row.treatmentHasSubTreatment === false
                      ? (
                        row?.subTreatment?.filter((item, i) => i === 0).map((subRow, j) => {
                          return (
                            <div className='flex gap-[1rem]'>
                              <InfoPriceTime src={Clock} value={subRow.subTreatmentPeriod ? `${helper.FN.toNumberWithCommas(subRow.subTreatmentPeriod)} min` : "- min"}/>
                              <InfoPriceTime src={Tag} value={subRow.subTreatmentPrice ? `${helper.FN.toNumberWithCommas(subRow.subTreatmentPrice)} THB` : "- THB"} />
                            </div>
                          )
                        })
                      )
                      : <p className="text-[12px] md:text-[16px] text-Neutral-800 font-light">{t.selectTreatments.moreDetail}</p>
                    }
                  </div>
                </div>
                <div className="cursor-pointer" onClick={() => onCheckActive(i, indexActive)} >

                  <ArrowSelect isOpen={indexActive === i}/>
                </div>
              </div>
              <Collapse in={indexActive === i} timeout="auto" unmountOnExit>
                <div className="py-[16px] flex flex-row gap-[16px] relative">
                  <div className="bg-[#F8F9FA] py-[16px] px-[12px] rounded-[4px]">
                    <div className="treament-img">
                      <img className="mb-[8px]" src={row.imagePicture} alt="0"/>
                      <p className="text-[12px] md:text-[14px] mb-[8px]">{row.treatmentDescription}</p>
                    </div>
                    {
                      !!row.treatmentHasSubTreatment === true
                      ? (
                        <div className="flex flex-col gap-[8px]">
                          {
                            row?.subTreatment.map((subRow, k) => {
                              const isCheck = !!onToValue(values, programId, row.treatmentId, subRow.subTreatmentId)
                              return (
                                <div className="flex items-center gap-[8px] md:gap-[16px]">
                                  <CheckSingle 
                                    value={isCheck}
                                    onChange={() => onSelectCheckBox(programId, row.treatmentId, subRow.subTreatmentId)}
                                    disabled={isCheck === false && Object.keys(values).length >= 2}
                                  />
                                  <div>
                                    <p className="text-[12px] md:text-[16px] font-medium text-[#393939]">{subRow.subTreatmentName}</p>
                                    <div className='flex gap-[16px]'>
                                      <InfoPriceTime src={Clock} value={`${subRow.subTreatmentPeriod ? helper.FN.toNumberWithCommas(subRow.subTreatmentPeriod) : "-"} min`} />
                                      <InfoPriceTime src={Tag} value={`${subRow.subTreatmentPrice ? helper.FN.toNumberWithCommas(subRow.subTreatmentPrice) : "-"} THB`} />
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                      : <></>
                    }
                  </div>
                </div>
              </Collapse>
            </aside>
          )
        })
      }
    </div>
  )
}


const InfoPriceTime = ({ src, value }) => {
  return (
    <div className='flex items-center gap-[.5rem]'>
      <span><img src={src} alt='' /></span>
      <span className="text-[12px] md:text-[16px]">{value}</span>
    </div>
  )
}

const ArrowSelect = ( { isOpen = false }) => {
  let activeStyle =  ""
  if (isOpen) {
    activeStyle = "active"
  }

  return <img className={`arr-right ${activeStyle}`} src={arrowURL} alt="0"/>
}