import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx-js-style';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import { CreateImportSlot } from "../../services/ImportSlot";
import _ from 'lodash'
import TemplateSlot from './TemplateSlot';

function ImportSlot() {
  const [refresh, setRefresh] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [errText, setErrText] = useState("");
  const [errCode, setErrCode] = useState("");
  const [errorData, setErrorData] = useState([]);

  
  const createInputxlsx = (e) => {
    console.log('files[0] >>', e.target.files[0])
    const f = e.target.files[0]
    var name = f.name;
    console.log("name file >>", name);
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0]; // เลือกชีส แรก
      const ws = wb.Sheets[wsname];
      let tmp = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      console.log('tmp', tmp)
      const data0 = csvToData(tmp);
      console.log('data0 >>', data0)
      setExcelData(data0)
    };
    reader.readAsBinaryString(f);
    // console.log(this.fileinput);
  }

  function csvToData(csv = '') {
    var lines = csv.split("\n");
    var result = [];
    var headers;
    headers = lines[0].split(",");

    console.log('headers >>', headers)
    let monthYearStr = headers[1]
    console.log('monthYearStr >>', monthYearStr)

    const parsedDate = moment(monthYearStr, 'MMMM YYYY');
    const maxday = parsedDate.daysInMonth();
    const monthYear = parsedDate.format('YYYY-MM');
    console.log('monthYear value >>', monthYear);
    console.log('monthYear max day >>',  maxday);

    let branchData = []
    let defBranch  = { branchNo: null, subTreatments: [] }
    let tmpBranch = _.cloneDeep(defBranch)
    let defTmpSubTreatments = { subTreatmentNo: null, detail: [] }
    let tmpSubTreatments = _.cloneDeep(defTmpSubTreatments)

    lines.shift() // เอาแถวแรกออก


    function SaveAndReset() {
      branchData.push(tmpBranch)
      tmpBranch = _.cloneDeep(defBranch)
    }
    function SaveSubTreatmentsAndReset() {
      tmpBranch.subTreatments.push(tmpSubTreatments)
      tmpSubTreatments = _.cloneDeep(defTmpSubTreatments)
    }

    lines = lines.filter(line => {
      let tmpSplit = line.split(",");
      return tmpSplit.filter(item1 => !!item1).length > 0
    })

    console.log('line CAL >> ', lines)

    for(let i = 0; i < lines.length; i++) {
      let line = lines[i]
      let tmpSplit = line.split(",");

      if (tmpSplit.length) {
        console.log('tmpSplit >>', tmpSplit)
        if (tmpSplit[0] === 'Branch No') {
          if (tmpBranch.branchNo !== null ) {
            SaveSubTreatmentsAndReset()
            SaveAndReset()
          }
          tmpBranch.branchNo = tmpSplit[1]
          console.log('tmpSplit branch >>', tmpSplit)
        }
        else if (tmpSplit[0] === 'Treatment No') {
          console.log('tmpSplit tmpSubTreatments >>', tmpSubTreatments)
          console.log('tmpSplit Treatment >>', tmpSplit)
          if (tmpSubTreatments.subTreatmentNo !== null ) {
            SaveSubTreatmentsAndReset()
          }
          tmpSubTreatments.subTreatmentNo = tmpSplit[1]

        }
        else if (tmpSplit[0] !== 'No') {
          let [no, time] = tmpSplit
          let rawData = tmpSplit.splice(2, (2 + maxday))
          // console.log('tmpSplit detail >>', tmpSplit)
          // console.log('tmpSplit0 time >>', time)
          // console.log('tmpSplit0 rawData >>', rawData)

          for(let j = 0 ;j < rawData.length; j++) {
            const day = `${(j+1)}`
            const raw = rawData[j]
            const date = `${monthYear}-${moment(day, 'D').format('DD')}`
            // console.log('rawDatax day >>', day)
            // console.log('rawDatax date >>', date)
            const tmpDetail = {
              date: date,
              startTime: time,
              amount: raw ? Number(raw) : 0
            }
            // console.log('tmpDetail >>', tmpDetail)
            tmpSubTreatments.detail.push(tmpDetail)
            // tmpBranch.subTreatments.push(tmpDetail)
          }
        }
      }

      // ตัวสุดท้าย
      if (i === lines.length -1) {
        SaveSubTreatmentsAndReset()
        SaveAndReset()
      }
    }
    console.log('branchData >>', branchData)
    return branchData
  }

  const postImportSlot = async (payload) => {
    try {
        const { data } = await CreateImportSlot({ data: payload });
        console.log('data.body >>', data)
        if (data.resultCode === 20000) {
            const resData = data.body;
            console.log('resData >>', resData)
            alert("Success")
            setRefresh(false)
            setErrText("")
            setErrCode("")
            setErrorData([])
        } else if (data.resultCode === 40401){
          setErrText("40401 Sub Treatment Not Found")
          setErrCode(data.resultCode)
          setErrorData(data.body)
        } else if (data.resultCode === 40301){
          setErrText("40301 Already Exist")
          setErrCode(data.resultCode)
          setErrorData(data.body)
        }
    } catch (e) {
        console.log(`🦄: postImportSlot -> e`, e);
    }
  };

  function onSubmit() {
    const payload = _.cloneDeep(excelData)
    if (checkPayload(payload)) {
      postImportSlot(payload)
    }
  }

  function checkPayload( arrPayload = [] ) {
    let ErrorRow = []
    let rowRunning = 1
    for (let ap of arrPayload) {
      let tmpErr = { row: null, field : null }
      rowRunning++;
      console.log('ap >>', ap)
      if (!ap.branchNo) {
        // alert('Error Branch No row : ' + rowRunning)
        tmpErr = { row: rowRunning, field : "Branch No" }
        ErrorRow.push(tmpErr)
      }
      for (let st of ap.subTreatments) {
        let startTime = st.detail.map(d => d.startTime)
        let u_startTime = [...new Set([...startTime])];
        rowRunning = rowRunning + 1
        if (!st.subTreatmentNo) {
          // alert('Error subTreatmentNo row : ' + rowRunning)
          tmpErr = { row: rowRunning, field : "Treatment No" }
          ErrorRow.push(tmpErr)
        }
        rowRunning =  rowRunning + 1 + u_startTime.length;

        for (let dt of st.detail) {
          if (!dt.startTime) {
            // alert('Error startTime row : ' + rowRunning)
            tmpErr = { row: rowRunning, field : "startTime" }
            ErrorRow.push(tmpErr)
            break;
          }
        }
      }
    }
    console.log('ErrorRow >>', ErrorRow)
    if (ErrorRow.length > 0) {
      setErrCode(100)
      setErrText("Error Format")
      setErrorData(ErrorRow)
      return false
    }
    return true
  }

  useEffect(() => {
    if (refresh === false) {
      setTimeout(() => setRefresh(true), 500)
    }
  }, [refresh])

  return (
    <div className="min-h-[100vh] px-[4px]">
      <div className="flex flex-col gap-[36px] justify-center items-start mb-[16px]">
        <TemplateSlot/>
        <div>
          { refresh ? ( <input type="file" accept=".xlsx" onChange={createInputxlsx} /> ) : <div>Clear...</div>}
        </div>

        <div className="flex w-full justify-center items-center">
          <ButtonPrimary size="mini"onClick={onSubmit} disabled={excelData.length === 0}>
            Import Slot
          </ButtonPrimary>
        </div>
      </div>
      {/* {JSON.stringify(errorData)} */}
      {
        !!errCode 
        ? (
          <div className="flex flex-col gap-[12px] ">
            <h5 className="text-[20px]">{errText}</h5>
            <table>
              {
                errCode === 100 
                ? (
                  <tr>
                    <td>No</td>
                    <td>Row</td>
                    <td>Field</td>
                  </tr>
                )
                : (
                  <tr>
                    <td>Branch No</td>
                    <td>SubTreatment No</td>
                    { errCode === 40301 ? <td>Start Time</td> : <></> }
                    { errCode === 40301 ? <td>Date</td> : <></> }
                  </tr>
                )
              }
             

            {
              errorData.map((item0, i0) => {

                if (errCode === 40301) {
                  return item0.detail.map(item1 => {
                    return (
                      <tr>
                        <td>{item0.branchNo}</td>
                        <td>{item0.subTreatmentNo}</td>
                        <td>{item1.startTime}</td>
                        <td>{item1.dateAt}</td>
                      </tr>
                    )
                  }) 
                }

                if (errCode === 100) {
                  return (
                    <tr>
                    <td>{i0 + 1}</td>
                    <td>{item0.row}</td>
                    <td>{item0.field}</td>
                  </tr>
                  )
                }

                return (
                  <tr>
                    <td>{item0.branchNo}</td>
                    <td>{item0.subTreatmentNo}</td>
                  </tr>
                )
              })
            }
            </table>
          </div>
        )
        : <></>
      }
      
    </div>
  );
}

export default ImportSlot;



