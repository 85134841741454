import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx-js-style';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import _ from 'lodash'
import { SearchBranchByTreatmentNo } from "../../services/ImportSlot";

let defRow = [
  { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
  { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
  { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: 'FFFF00' } } } },
  { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
];

let mockRes = [
  {
    branchId: "8",
    subTreatments: [
      {
        subTreatmentNo: "ST014",
      },
    ],
  },
  {
    branchId: "12",
    subTreatments: [
      {
        subTreatmentNo: "ST014",
      },
      {
        subTreatmentNo: "ST017",
      },
      {
        subTreatmentNo: "ST025",
      },
    ],
  },
];

const TemplateSlot = () => {
  const [templateData, setTemplateData] =  useState([])

  const exportToExcel = () => {
    const fileName = "template-import-slot.xlsx"
    let rows = [
      // { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
      // { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
      // { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: 'FFFF00' } } } },
      // { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
    ];

    const { header , daywidth, maxday } = genHeaderDate()
    console.log("🚀 ~ file: TemplateSlot.js:26 ~ exportToExcel ~ header ", header)
    console.log("🚀 ~ file: TemplateSlot.js:26 ~ exportToExcel ~ daywidth ", daywidth)
    rows.push(header)
    
    const { rowData, merge } = resDataToRow(templateData, maxday)
    rows = [ ...rows, ...rowData]


    const ws = XLSX.utils.aoa_to_sheet(rows)

    // const merge = [
    //   { s: { r: 2, c: 2 }, e: { r: 2, c: 3 } },
    //   { s: { r: 5, c: 2 }, e: { r: 5, c: 3 } },
    //   { s: { r: 8, c: 2 }, e: { r: 8, c: 3 } },
    // ];
    ws["!merges"] = merge;

    ws['!cols'] = daywidth;

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    
    XLSX.writeFile(wb, fileName);
  };

  function resDataToRow(items = [], maxday = 0) {
    let rowsAns = []
    let iRowMerge = []
    for (let item0 of items) {
      // console.log('item >>', item0)
      const rowBranch = genBranch(item0.branchNo, item0.branchName, maxday)
      // console.log("🚀 ~ file: TemplateSlot.js:31 ~ exportToExcel ~ rowBranch:", rowBranch)
      rowsAns.push(rowBranch)
      iRowMerge.push(rowsAns.length)
      for (let item1 of item0.subTreatments) {
        const rowTreatment = genTreatment(item1.subTreatmentNo, `${item1.subTreatmentName} ( period ${item1.subTreatmentPeriod} mins)`, maxday)
        // console.log("🚀 ~ file: TemplateSlot.js:35 ~ exportToExcel ~ rowTreatment:", rowTreatment)
        rowsAns.push(rowTreatment)
        iRowMerge.push(rowsAns.length)

        const rowHeadTable = genHeadTable(maxday)
        // console.log("🚀 ~ file: TemplateSlot.js:35 ~ exportToExcel ~ rowHeadTable:", rowHeadTable)
        rowsAns.push(rowHeadTable)

        const rowDataTable = genDataTable(1, "09:00", maxday)
        // console.log("🚀 ~ file: TemplateSlot.js:35 ~ exportToExcel ~ rowDataTable:", rowDataTable)
        rowsAns.push(rowDataTable)
      }

    }
    const configMerge = { c0: 2 }
    const { c0, c1 } = configMerge
    const merge = iRowMerge.map(item => {
      return { 
        // s: { r: item, c: c0 }, e: { r: item, c: c0 + 1 }, e: { r: item, c: c0 + 2 }, e: { r: item, c: c0 + 3 }, e: { r: item, c: c0 + 4 }, }
        s: { r: item, c: c0 }, e: { r: item, c: c0 + 15 } }
      })
      
    return  { rowData: rowsAns, merge }
  }

  function genHeaderDate() {
    const selectDate = moment().add(1, 'month')
    const monthYearStr = selectDate.format('MMMM YYYY')
    const maxday = selectDate.daysInMonth();
    const monthYear = selectDate.format('YYYY-MM');
    console.log('monthYearStr str >>', monthYearStr);
    console.log('monthYear value >>', monthYear);
    console.log('monthYear max day >>',  maxday);

    let rowStyle = { bgColor: 'B6D7A8' } // Style กลางของส่วนนี้

    let allDays = [...Array(maxday)].map((item, i) => vCell((i + 1), { ...rowStyle, alignment: 'center' } ))
    let ansRow = [ vCell("", { ...rowStyle } ), vCell(monthYearStr, { ...rowStyle } ), ...allDays ]

    let wAllDays = [...Array(maxday)].map((item, i) => ({ width: 6 }))
    let daywidth = [ { width: 16 }, { width: 20 }, ...wAllDays ]

    return { header: ansRow, daywidth, maxday: maxday }
  }

  function genBranch(value0 = "",value1, maxday = 0) {
    let rowStyle = { bgColor: 'EA9999' } // Style กลางของส่วนนี้

    let allDays = [...Array(maxday)].map((item, i) => vCell("", { ...rowStyle, alignment: 'center' } ))
    allDays.shift()
    let ansRow = [ 
      vCell("Branch No", { ...rowStyle } ), vCell(value0, { ...rowStyle, alignment: 'center' } ), 
      vCell(value1, { ...rowStyle } ), ...allDays 
    ]

    return ansRow
  }

  function genTreatment(value0 = "", value1 = "", maxday = 0) {
    let rowStyle = { bgColor: 'BDD7EE' } // Style กลางของส่วนนี้

    let allDays = [...Array(maxday)].map((item, i) => vCell("", { ...rowStyle, alignment: 'center' } ))
    allDays.shift()
    let ansRow = [ 
      vCell("Treatment No", { ...rowStyle } ), vCell(value0, { ...rowStyle, alignment: 'center' } ),
      vCell(value1, { ...rowStyle } ), ...allDays 
    ]

    return ansRow
  }

  function genHeadTable(maxday = 0) {
    let rowStyle = { bgColor: 'FFF2CC' } // Style กลางของส่วนนี้

    let allDays = [...Array(maxday)].map((item, i) => vCell("", { ...rowStyle, alignment: 'center' } ))
    let ansRow = [ vCell("No", { bgColor: 'D9D2E9' } ), vCell("start", { ...rowStyle, alignment: 'center' } ), ...allDays ]

    return ansRow
  }

  function genDataTable(no = "1", value = "", maxday = 0) {
    let rowStyle = { alignment: 'center' } // Style กลางของส่วนนี้

    let allDays = [...Array(maxday)].map((item, i) => vCell("0", { ...rowStyle } ))
    let ansRow = [ vCell(no, { ...rowStyle } ), vCell(value, { ...rowStyle, alignment: 'right' } ), ...allDays ]

    return ansRow
  }

  function vCell (value, style = {}) {
    let tmpCell = { v: value, t: "s", s: { font: { name: "Calibri", sz: 14 } } }
    if (style.bgColor) {
      tmpCell.s.fill = { fgColor: { rgb: style.bgColor } } // 'FFFF00' '000000'
    }
    if (style.alignment) {
      tmpCell.s.alignment = { horizontal: style.alignment } // 'left' 'center' 'right'
    }
    return tmpCell
  }

  const getBranchByTreatmentNo = async () => {
    try {
      const { data } = await SearchBranchByTreatmentNo({});
      if (data.resultCode === 20000) {
        setTemplateData(data.body)
      } 
    } catch (e) {
      console.log("ERR getBranchByTreatmentNo >> ", e);
    }
  };

  useEffect(() => {
    getBranchByTreatmentNo()
  }, [])

  return (
    <ButtonPrimary size="mini" onClick={exportToExcel}>
      Template
    </ButtonPrimary>
  );
};

export default TemplateSlot;




const exportToExcel = () => {
  // var aoa = [
  //   ["","October 2023", "1", "2", "3", "4", "5", "6"],
  // ];
  let row = [
    { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
    { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
    { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: 'FFFF00' } } } },
    { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
  ];
  
  // STEP 3: Create worksheet with rows; Add worksheet to workbook
  const ws = XLSX.utils.aoa_to_sheet([row])

  // var ws = XLSX.utils.aoa_to_sheet(aoa);
  // ws['A2'].v = ; // Yellow fill color
  // ws['A2'].s = { fill: { fgColor: { rgb: 'FFFF00' } } }; // Yellow fill color
  
  /* create workbook and export */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, "SheetJSExportAOA.xlsx");
  // const ws = XLSX.utils.json_to_sheet(data);

  // // Add style (fill color) to a range of cells (e.g., A1:C1)
  // XLSX.utils.sheet_add_aoa(ws, [['Header 1', 'Header 2', 'Header 3']], { origin: 'A1' });
  // ws['A1'].s = { fill: { fgColor: { rgb: 'FFFF00' } } }; // Yellow fill color
  // ws['B1'].s = { fill: { fgColor: { rgb: 'FF0000' } } }; // Red fill color
  // ws['C1'].s = { fill: { fgColor: { rgb: '00FF00' } } }; // Green fill color

  // // Set column width for columns A, B, and C (width in characters)
  // const colWidths = [{ wch: 15 }, { wch: 15 }, { wch: 15 }];
  // ws['!cols'] = colWidths;

  // // Create another sheet
  // const ws2 = XLSX.utils.json_to_sheet([
  //   { Name: 'John', Age: 30, City: 'New York' },
  //   { Name: 'Alice', Age: 25, City: 'Los Angeles' },
  //   { Name: 'Bob', Age: 35, City: 'Chicago' },
  // ]);
  // ws2['A1'].s = { fill: { fgColor: { rgb: 'FF00FF' } } }; // Purple fill color
  // ws2['B1'].s = { fill: { fgColor: { rgb: '00FFFF' } } }; // Cyan fill color
  // ws2['C1'].s = { fill: { fgColor: { rgb: 'FFA500' } } }; // Orange fill color

  // // Set column width for columns A, B, and C (width in characters)
  // ws2['!cols'] = colWidths;

  // // Create a workbook with multiple sheets
  // const wb = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
  // XLSX.utils.book_append_sheet(wb, ws2, 'Sheet 2');

  // // Add text to a cell
  // XLSX.utils.sheet_add_aoa(ws2, [['Additional Text']], { origin: 'A4' });

  // XLSX.writeFile(wb, `${filename}.xlsx`);
};