import React from "react";
import { Controller } from "react-hook-form";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { pink, yellow } from "@mui/material/colors";

const RadioGroupField = ({ name, control, options }) => {

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <RadioGroup row {...field}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={
              <Radio/>
            }
              label={<span className="text-[12px] md:text-[16px]">{option.label}</span>}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
}

export default RadioGroupField;
