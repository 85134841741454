import React, { useState } from "react";
import ModalBase from "../../components/Modal/ModalBase";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../components/button/ButtonPrimary";

export default function ModalReSlot({ open = false, onClose, onSave }) {
  return (
    <>
      <ModalBase
        mini={true}
        open={open}
        onClose={() => {}}
        size="size2"
        header={<></>}
        content={
          <div className="flex flex-col justify-center mb-[8px] md:mb-[16px]">
            <h1 className="text-center text-[20px] text-Neutral-A800 font-medium">
              Are you sure to go back?
            </h1>
            <p className="text-center text-[16px]">
              If you want to go back, You have to
              <br /> select date & time again.
            </p>
          </div>
        }
        footer={
          <>
            <div className="flex flex-col md:flex-row justify-center items-center gap-[16px] md:gap-[12px]">
              <ButtonPrimary
                addClass="w-full md:max-w-[157px]"
                style={{ minWidth: "120px" }}
                onClick={() => {
                  if (onSave) {
                    onSave();
                  }
                }}
              >
                Go Back
              </ButtonPrimary>
              <ButtonPrimaryOutline
                addClass="w-full md:max-w-[157px]"
                style={{ minWidth: "120px" }}
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                }}
              >
                Cancel
              </ButtonPrimaryOutline>
            </div>
          </>
        }
      />
    </>
  );
};
