import React, { useEffect } from 'react'
import GridMain from '../../../components/Layouts/GridMain';
import GridRap from '../../../components/Layouts/GridRap';
import FooterBase from '../../../components/Layouts/FooterBase';
import PrivacyPolicyContent from '../../Settings/PrivacyPolicyContent';

export default function PagePolicy({ isOpen = false, component = <></>, onClose, onAccept }) {


  useEffect(() => {
    console.log('useEffect !!!')
    document.documentElement.scrollTop = 0
  }, [isOpen])


  if(!isOpen) {
    return <>{component}</>
  }
  
  return (
    <>
    <div className=" bg-gray popup-setting-space">
      <GridMain className="">
        <GridRap>
          <div className="form-content-space">
            <div className="header">
              <h5 className="cp-title">Privacy Policy</h5>
            </div>
            <div className="content">
              <>
              <PrivacyPolicyContent/>
              </>
            </div>
          </div>
        </GridRap>
      </GridMain>
      </div>
      <FooterBase
        labelBack="Back"
        labelNext="Accept"
        btnNoSubmit={true}
        onBack={() => {
          console.log('onClose >>')
          onClose()
        }}
        onNext={() => {
          console.log('onAccept')
          onAccept()
        }}
      />
    </>
  )
}