import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const defaultData = {
  branch: {},
  guestCount: 0,
  alreadyGuest: false,
  date: null,
  time: null,
  guestData: [{
    id: 0,
    name: 'Guest 1',
    duration: 120,
    totalDuration: 0,
    price: 650,
    totalPrice: 0,
    program: [],
    rawProgram: [],
    totalProgramSelect: [],
    totalProgramTime: 0,
    totalProgramPrice: 0
  }],
  totalDuration: 0,
  totalPrice: 0,
}

export const BookingSlice = createSlice({
  name: 'Booking',
  initialState: defaultData,
  reducers: {
    updateBooking: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    updateBranch: (state, action) => {
      state.branch = action.payload
    },
    setGuestCount: (state, action) => {
      state.guestCount = action.payload
    },
    setDate: (state, action) => {
      state.date = action.payload
    },
    addGuest: (state, action) => {
      state.guestData.push({ ...action.payload, id: state.guestData.length })
    },
    updateGuestName: (state, action) => {
      for (const [key, value] of Object.entries(action.payload.data)) {
        state.guestData[action.payload.i][key] = value
      }
    },
    deleteGuest: (state, action) => {
      state.guestData = state.guestData
        .filter(s => s.id !== action.payload.id)
        .map((g, i) => {
          g.id = i
          g.name = `Guest ${i + 1}`
          return g
        })
    },
    addTreatments: (state, action) => {
      state.guestData[action.payload].program.push({
        id: state.guestData[action.payload].program.length,
        name: `Traditional Thai Massage ${state.guestData[action.payload].program.length}`,
        duration: Math.floor(Math.random() * 100),
        price: 650
      })
    },
    deleteTreatments: (state, action) => {
      state.guestData[action.payload.i].program = state.guestData[action.payload.i].program.filter((p, i) => i !== action.payload.j)
    },
    updateGuestTotal: (state, action) => {
      const arr = action.payload.programArr ?? state.guestData[action.payload.i].program
      let maxArrDuration = []
      let sumPrice = 0
      for (let i = 0; i < arr.length; i++) {
        const program = arr[i];
        maxArrDuration.push(program.treatmentPeriod)
        sumPrice += program.treatmentPrice ?? program.subTreatmentPrice
      }
      state.guestData[action.payload.i].totalDuration = _.max(maxArrDuration)
      state.guestData[action.payload.i].totalPrice = sumPrice
    },
    updateTotal: (state, action) => {
      let maxArrDuration = []
      let sumPrice = 0
      for (let i = 0; i < state.guestData.length; i++) {
        const guest = state.guestData[i];
        maxArrDuration.push(guest.totalDuration)
        sumPrice += guest.totalPrice
      }
      state.totalDuration = _.max(maxArrDuration)
      state.totalPrice = sumPrice
    },
    rawProgram: (state, action) => {
      state.guestData[action.payload.i].rawProgram = action?.payload?.rawProgram
    },
    program: (state, action) => {
      state.guestData[action.payload.i].program = action.payload.programArr
    },
    onTotalProgramSelect: (state, action) => {
      state.guestData[action.payload.i].totalProgramSelect = action.payload.programArr
    },
    onTotalProgramTime: (state, action) => {
      state.guestData[action.payload.i].totalProgramTime = action.payload.totalTime
    },
    onTotalProgramPrice: (state, action) => {
      state.guestData[action.payload.i].totalProgramPrice = action.payload.totalPrice
    },
    resetTime: (state, action) => {
      state.time = defaultData.time
    },
    resetBooking: () => defaultData
  },
})

// Action creators are generated for each case reducer function
export const {
  updateBooking,
  updateBranch,
  setGuestCount,
  setDate,
  addGuest,
  updateGuestName,
  deleteGuest,
  addTreatments,
  updateGuestTotal,
  updateTotal,
  deleteTreatments,
  getSumValue,
  rawProgram,
  program,
  onTotalProgramSelect,
  onTotalProgramTime,
  onTotalProgramPrice,
  resetTime,
  resetBooking
} = BookingSlice.actions

export default BookingSlice.reducer