import React, { useState, useEffect } from 'react'
import useTranslations from '../../../i18n/useTranslations';

export default function CheckinComplete() {
 
  return (
    <div className=" flex flex-col gap-[16px] justify-center items-center">
      <IconSuccess/>
      <p className="text-[30px] MinervaModern text-[#DDB05B] leading-[1.2em]">Check-in<br/>Completed</p>
    </div>
  )
} 

const IconSuccess = () => {
  return (
    <>
      <div className="hidden md:block">
        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29 58C45.0163 58 58 45.0163 58 29C58 12.9837 45.0163 0 29 0C12.9837 0 0 12.9837 0 29C0 45.0163 12.9837 58 29 58Z" fill="#DDB05B"/>
          <path d="M22.7401 44.9814C22.5914 44.9814 22.4453 44.9425 22.3163 44.8686C22.1872 44.7947 22.0798 44.6884 22.0045 44.5602C19.1704 39.7319 11.6148 29.4512 11.5389 29.3479C11.4175 29.1829 11.3596 28.9797 11.3757 28.7755C11.3918 28.5713 11.4808 28.3797 11.6265 28.2357L13.9498 25.9398C14.0917 25.7996 14.2783 25.7135 14.4771 25.6966C14.6758 25.6797 14.8742 25.733 15.0378 25.8473L22.5626 31.1014C27.5978 24.6446 32.275 20.1837 35.3521 17.5515C38.8164 14.5885 41.0217 13.2546 41.114 13.1997C41.2465 13.1201 41.3982 13.0781 41.5528 13.0781H45.3116C45.4845 13.0781 45.6533 13.1307 45.7957 13.2288C45.938 13.3269 46.0472 13.466 46.1087 13.6276C46.1703 13.7892 46.1812 13.9657 46.1401 14.1337C46.0991 14.3016 46.0079 14.4532 45.8788 14.5681C35.2048 24.0754 23.5972 44.3469 23.4812 44.5506C23.4071 44.6806 23.3002 44.7888 23.1711 44.8644C23.042 44.94 22.8952 44.9803 22.7456 44.9813L22.7401 44.9814Z" fill="white"/>
        </svg>
      </div>
      <div className="block md:hidden">
        <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M51 102C79.1665 102 102 79.1665 102 51C102 22.8335 79.1665 0 51 0C22.8335 0 0 22.8335 0 51C0 79.1665 22.8335 102 51 102Z" fill="#DDB05B"/>
          <path d="M39.9903 79.1057C39.7288 79.1057 39.4719 79.0373 39.245 78.9074C39.0181 78.7775 38.8291 78.5905 38.6967 78.365C33.7126 69.8739 20.4252 51.794 20.2917 51.6123C20.0782 51.3222 19.9763 50.9649 20.0046 50.6057C20.0329 50.2466 20.1895 49.9097 20.4457 49.6565L24.5316 45.6188C24.7811 45.3722 25.1092 45.2209 25.4588 45.1912C25.8083 45.1614 26.1572 45.2552 26.4448 45.456L39.6781 54.696C48.5332 43.3411 56.7586 35.496 62.17 30.8669C68.2624 25.6562 72.1407 23.3103 72.303 23.2137C72.5361 23.0739 72.8029 23 73.0747 23H79.6849C79.989 23 80.286 23.0924 80.5363 23.265C80.7867 23.4375 80.9787 23.6822 81.0869 23.9663C81.1951 24.2505 81.2143 24.5609 81.1421 24.8563C81.0699 25.1517 80.9096 25.4181 80.6824 25.6204C61.911 42.3399 41.4976 77.9898 41.2936 78.348C41.1634 78.5767 40.9753 78.767 40.7483 78.9C40.5212 79.0329 40.2632 79.1038 40 79.1055L39.9903 79.1057Z" fill="white"/>
        </svg>
      </div>
    </>
  )
}