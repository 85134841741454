import {createSlice} from '@reduxjs/toolkit'

const defaultData = {
  step1: {},
  isLoading: false,
}

export const RegisterSlice = createSlice({
  name: 'Register',
  initialState: defaultData,
  reducers: {
    setRegister: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    setStep1: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state.step1[key] = value;
      }
    },
    resetRegister: () => defaultData
  },
})

// Action creators are generated for each case reducer function
export const {
  setRegister,
  setStep1,
  resetRegister
} = RegisterSlice.actions

export default RegisterSlice.reducer