import React, { useEffect } from "react";
import TopLogo from "../../components/Layouts/TopLogo";
import TopBanner from "../../components/Layouts/TopBanner";
import { TextField, InputLabel } from "@mui/material";
import "../../assets/styles/register.css";
import "../../assets/styles/Login.css";
import { useForm, Controller } from "react-hook-form";
import TextFieldPassword from "../../components/general/TextFieldPassword";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { SendEmailVerify, SearchLineId } from "../../services/Member";
import { AuthLogin, AuthLoginWithSocial } from "../../services/Auth";
import helper from "../../services/helper";
import sweetAlert from "../../services/sweetAlert";
import GridMain from "../../components/Layouts/GridMain";
import useTranslations from "../../i18n/useTranslations";
import Label from "../../components/general/Label";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import GridRap from "../../components/Layouts/GridRap";
import SignUpLogo from '../../assets/images/sign-up-logo.png';
import SignUpLogoMobile from '../../assets/images/singin-logo-mobile.png';
import LoginWithGoogle from "../../components/Auth/LoginWithGoogle";
import LoginWithFacebook from "../../components/Auth/LoginWithFacebook";
import WebContainer from "../../components/Layouts/WebContainer";
import HeaderRegister from "../Register/RegisterParts/HeaderRegister";

const { gotoPage } = helper.Route

export default function Login() {
  const lineId = localStorage.getItem('lineId') ?? null
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get('redirect');


  const { register, formState: { errors }, handleSubmit, setValue, control, watch, } = useForm({
    // mode:'onTouched',
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const navigate = useNavigate();

  const { t } = useTranslations();
  const { lang, supportedLangs, setLang } = useTranslations();

  const getSearchLineId = async () => {
    try {
      const { data } = await SearchLineId({ lineId: lineId });
      // alert('AAAAA' + JSON.stringify(data))
      if (data.resultCode === 20000) {
        console.log("RE หน้าไปโปรไฟล์");
        // alert('RE หน้าไปโปรไฟล์')
        navigate("/line-profile");
      } else if (data.resultCode === 40302) {
        getSendEmailVerify(data.body.id)
      }
    } catch (e) {
      console.log("ERR ", e);
    }
  };

  const postLogin = async (payload) => {
    try {
      const { data } = await AuthLogin({ data: payload });
      if (data.resultCode === 20000) {
        const userToken = data.body.userToken;
        helper.Auth.flowLOGIN(userToken, lineId, redirect, navigate);
      } else if (data.resultCode === 40302) {
        getSendEmailVerify(data.body.id)
      } else if (data.resultCode === 40401){
        sweetAlert.AlertInfo({
          errorCode: "errorCode",
          titleClass: 'title-danger',
          title: "Incorrect Email or Password",
          subtitle: "Please try again",
          showfooter: true,
          linkCallback: () => {
            navigate('/register')
          }
        });
      } else {
        sweetAlert.AlertError({
          errorCode: data?.resultCode,
          title: data?.resultCode,
          subtitle: data?.resultDescription,
        });
      }
    } catch (error) {
      const { data } = error.response;
      // sweetAlert.AlertError({
      //   errorCode: data?.resultCode,
      //   subtitle: data?.resultDescription,
      // });
    }
  };

  const postLoginWithSocial = async (payload, profile) => {
    try {
      const { data } = await AuthLoginWithSocial({ data: payload });
      if (data.resultCode === 20000) {
        const userToken = data.body.userToken;
        helper.Auth.flowLOGIN(userToken, lineId, redirect);
      } else if (data.resultCode === 40401) {
        const qProfile = helper.encodeJWT(profile)
        console.log('qProfile >>', qProfile)
        navigate('/register?profile=' + qProfile)
      } else if (data.resultCode === 40401){
        sweetAlert.AlertInfo({
          errorCode: "errorCode",
          title: "Incorrect Email or Password",
          subtitle: "Please try again",
        });
      } else {
        sweetAlert.AlertError({
          errorCode: data?.resultCode,
          title: data?.resultCode,
          subtitle: data?.resultDescription,
        });
      }
    } catch (error) {
      console.log('error postLoginWithSocial >>', error)
      // sweetAlert.AlertError({
      //   errorCode: data?.resultCode,
      //   subtitle: data?.resultDescription,
      // });
    }
  };

  const getSendEmailVerify = async (memberId) => {
    try {
      const { data } = await SendEmailVerify({ memberId: memberId });
      if (data.resultCode === 20000) {
        // alert("ส่งเมลเเล้ว")
        navigate("/check-email", { state: { email: data.body.email } });
      } else if (data.resultCode === 40401){
        sweetAlert.AlertInfo({
          errorCode: "errorCode",
          title: "Sorry, that email address is not registered with us.",
          subtitle: "Please try a different email address",
        });
      } else if (data.resultCode === 40303){
        sweetAlert.AlertInfo({
          errorCode: "errorCode",
          title: "That Email Already Verified",
          subtitle: "Please check your email box or try a different email address",
        });
      }
      else {
        sweetAlert.AlertError({
          errorCode: data?.resultCode,
          title: data?.resultCode,
          subtitle: data?.resultDescription,
        });
      }
    } catch (error) {
      const { data } = error.response;
      // sweetAlert.AlertError({
      //   errorCode: data?.resultCode,
      //   subtitle: data?.resultDescription,
      // });
    }
  };

  const onSubmit = (data) => {
    console.log("data >>", data);
    let payload = { ...data, lineUid: lineId };
    delete payload.rePassword;
    console.log("payload >>", payload);
    postLogin(payload);
  };

  useEffect(() => {
    if (lineId) {
      getSearchLineId(lineId);
    }
  }, [lineId]);

  return <>
    <WebContainer bgGray hfull>
      <TopBanner />
      <GridMain className="form-space">
        <GridRap>
          <div className="form-header-space">
            <HeaderRegister title={t.login.banner_r0} subTitle={t.login.banner_r1}/>
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
              <div className="form-content-space">
                <div className="flex flex-col gap-[40px] md:gap-[16px] mb-[40px] md:mb-[32px]">
                  <div>
                    <Label> {t.login.Email}</Label>
                    <TextField
                      required
                      fullWidth
                      placeholder={t.login.form_Email_placeholder}
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                      {...register("email", {
                        required: t.login.Requiredemail,
                        pattern: {
                          value:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                          message: t.login.form_email_validate_1,
                        },
                      })}
                      inputProps={{ 
                        maxLength: 150,
                        onChange: helper.FNFORM.handleCharOnly,
                      }}
                    />
                  </div>
                  <div>
                    <Label>{t.login.form_password}</Label>
                    <TextFieldPassword
                      required
                      fullWidth
                      placeholder={t.login.form_password_placeholder}
                      error={!!errors?.password}
                      helperText={errors?.password?.message}
                      {...register("password", {
                        required: t.login.RequiredePassword,
                        minLength: {
                          value: 6,
                          message: t.login.form_password_validate_0,
                        },
                      })}
                      inputProps={{ 
                        maxLength: 20,
                        onChange: helper.FNFORM.handleCharOnly,
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-center mb-[16px]">
                  <ButtonPrimary type="submit" addClass="w-full md:max-w-[180px]">
                    {t.login.Sing_in}
                  </ButtonPrimary>
                </div>
                <div className="flex flex-col gap-[24px]">
                  <div className="text-center">
                    <a className="underline text-[14px] md:text-[16px] text-Neutral-A900" href="forget-password">
                      {t.login.ForgetPassword}
                    </a>
                  </div>
                  {
                    !lineId && (
                      <>
                        <div className="relative w-full flex items-center justify-between">
                          <div className="absolute divider w-full z-[1]"></div>
                          <div className="text-Neutral-A400 w-[2em] md:w-[2em] bg-white mx-auto text-center z-[2] bg-white" >
                            <p className="text-[12px] md:text-[14px]">{t.login.OR}</p>
                          </div>
                        </div>

                        <div className="flex justify-center items-center gap-[36px] md:gap-[12px]">
                          <LoginWithGoogle 
                            onLoginGoogle={(uid, profile , result) => {
                              console.log('onLoginGoogle token >>', uid)
                              console.log('onLoginGoogle profile >>', profile)
                              console.log('onLoginGoogle result >>', result)
                              const payload = { googleUid :uid, email: profile.email }
                              postLoginWithSocial(payload, profile)
                            }}
                          />
                          <LoginWithFacebook 
                            onLoginFacebook={(uid, profile , result) => {
                              console.log('onLoginWithFacebook token >>', uid)
                              console.log('onLoginWithFacebook profile >>', profile)
                              console.log('onLoginWithFacebook result >>', result)
                              const payload = { facebookUid :uid, email: profile.email  }
                              postLoginWithSocial(payload, profile)
                            }}
                          />
                        </div>
                      </> 
                    )
                  }
                  {
                    lineId && (
                      <div className="relative w-full flex items-center justify-between">
                        <div className="absolute divider w-full z-[1]"></div>
                      </div>
                    )
                  }
                  <div className="flex justify-center gap-[5px]">
                    <p className="text-[14px] md:text-[16px]">{t.login.Have_Account}</p>
                    {
                      <Link className="text-[#DDB05B] text-[14px] md:text-[16px]" to={"/register"}>{t.login.Sign_up}</Link>
                    }
                  </div>
                </div>
              </div>
            </form>
          </div>
        </GridRap>
      </GridMain>
    </WebContainer>
  </>;
}
