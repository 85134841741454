import React, { useEffect, useState } from "react";
import WebContainer from "../../components/Layouts/WebContainer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchMyBooking } from "../../services/Booking";
import { SearchLineId, SearchMemberById } from "../../services/Member";
import VConsole from "vconsole";
import TopLogo from "../../components/Layouts/TopLogo";
import { setProfile } from "../../features/ProfileSlice";
import { useDispatch, useSelector } from 'react-redux';

export default function LoginLine({ lineId = null}) {
  const dispatch = useDispatch() // ไว้อัพเดต state กลาง
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const [ loading, setLoading] = useState(true)
  const [ memberData, setMemberData ] = useState({})
  const callback = searchParams.get('callback');

  const getSearchLineId = async () => {
    try {
      localStorage.removeItem("token")
      // alert("NEW LINE 0")
      const { data } = await SearchLineId({ lineId: lineId });
      if (data.resultCode === 20000) {
        const resData = data.body
        setMemberData(resData)
        localStorage.setItem("token", resData.token)
        console.log('resData >>', resData)
        // document.title = "have User: " + lineId
        document.title = "Healthland Booking"
      } else {
        // document.title = "not have User: " + lineId
        document.title = "Healthland Booking"
      }
      // alert('callback'+ callback)
      // alert(window.location.pathname)
      if (callback) {
        navigate(callback)
        const tmpObj = { isLoading: false }
        dispatch(setProfile(tmpObj));
      } else {
        navigate("/booking-step1")
      }
    } catch (e) {
      console.log("ERR ", e);
      navigate("/booking-step1")
    }
  };

//   const getSearchMemberById = async (memberId = null) => {
//     try {
//       const { data } = await SearchMemberById({ memberId: memberId });
//       if (data.resultCode === 20000) {
//         setProfile(data.body);
//       } else if (data.resultCode === 40401) {
//         navigate("/login");
//       }
//     } catch (e) {
//       console.log("ERR getSearchMemberById", e);
//     }
//   };
  useEffect(() => {
    if (lineId) {
     
        getSearchLineId()
    }
  }, [lineId])

  return (
    <>
    {/* <TopLogo/> */}
    {JSON.stringify(memberData.token)}
    {lineId} <br/>
      Loading...
    </>
  );
}
