import React from "react";
import TopLogo from "../../components/Layouts/TopLogo";
import SuccessLogo from "../../assets/images/logo-success.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Label from "../../components/general/Label";
import GridLine from "../../components/Layouts/GridLine";
import CheckEmailLogo from "../../assets/images/logo-checkemail.png";
import { TextField } from "@mui/material";
import useTranslations from "../../i18n/useTranslations";
import { useState } from "react";
import { ForgetPassword } from "../../services/Member";
import WebContainer from "../../components/Layouts/WebContainer";
import SuccessPage from "../../components/Layouts/SuccessContent";
import GridMain from "../../components/Layouts/GridMain";
import GridRap from "../../components/Layouts/GridRap";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import sweetAlert from "../../services/sweetAlert";
import helper from "../../services/helper";

export default function Register() {
  const { t } = useTranslations();

  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data) => {
    console.log("data >>", data);
    let payload = { ...data };
    setEmail(data.email);
    postForgetPassword(payload);
    // setSuccess(true)
  };

  const postForgetPassword = async (payload) => {
    try {
      const { data } = await ForgetPassword({ data: payload });
      if (data.resultCode === 20000) {
        setSuccess(true);
      setErrorEmail(false);
      } else if (data.resultCode === 40401) {
        setErrorEmail(true);
        // sweetAlert.AlertInfo({
        //   errorCode: "errorCode",
        //   title: "The email address you entered is not exist",
        //   subtitle: "Please try again",
        // });
      } else {
        sweetAlert.AlertError({
          errorCode: data?.resultCode,
          title: data?.resultCode,
          subtitle: data?.resultDescription,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickDone = () => {
    setSuccess(false);
  };


  return (
    <WebContainer bgGraphics={true} contentCenter={true} wfull={true}>
       <SuccessPage>
        <div className="flex-1">
          <GridMain className="h-full flex flex-col text-center justify-center items-center pb-[61px] md:pb-[101px]" >
            <div className="forgetpassword-main px-[20px] md:px-[0px]">
              {success ? (
                <>
                  <div className="">
                    <div className="flex justify-center">
                      <img
                        className="max-w-[150px] mb-[12px]"
                        src={CheckEmailLogo}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col justify-center items-center text-center">
                      <p className="cp-title">
                        {t.forget_password.Checkemail}
                      </p>
                      {/* <div className="divider-glod"></div> */}
                      <div className="mt-[15px] flex flex-col gap-[0px] md:gap-[4px]">
                        <p className="text-[12px] md:text-[20px]">{t.forget_password.Anemail1}</p>
                        <p className="text-[12px] md:text-[20px] font-medium">{email ? email : 'tatchapolhealthland@gmail.com'}</p>
                        <p className="text-[12px] md:text-[20px]">{t.forget_password.Anemail2}</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="">
                      <div className="flex flex-col justify-center items-center">
                        <p className="cp-title MinervaModern">
                          {t.forget_password.ForgetPassword}
                        </p>
                        <p className="text-[12px] md:text-[20px] mt-[12px] text-center HelveticaNeue">
                          {t.forget_password.Pleaseenter1}<br/>
                          {t.forget_password.Pleaseenter2}
                        </p>
                      </div>

                      <div className="flex flex-col gap-[24px]">
                        <div className="w-full mt-[24px] reset-password-box">
                          {/* <Label>{t.forget_password.Email}</Label> */}
                          <TextField
                            required
                            fullWidth
                            placeholder={t.forget_password.Email}
                            error={
                              errorEmail
                                ? "The email address you entered does not exist"
                                : !!errors?.email
                            }
                            onChange={(e) => {
                              setErrorEmail(false);
                            }}
                            helperText={
                              errorEmail
                                ? "The email address you entered does not exist"
                                : errors?.email?.message
                            }
                            {...register("email", {
                              required: {
                                value: true,
                                message: t.forget_password.Requiredemail,
                              },
                              pattern: {
                                value:
                                  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                                message: t.forget_password.Invalidemail,
                              },
                            })}
                            inputProps={{ 
                              maxLength: 150,
                              onChange: helper.FNFORM.handleCharOnly,
                            }}
                          />
                        </div>

                        <div className="w-full mt-[12px]">
                          <div className=" text-center">
                            <ButtonPrimary
                              addClass=""
                              type="submit"
                            >
                              {t.forget_password.Send}
                            </ButtonPrimary>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </GridMain>
        </div>
        </SuccessPage>
    </WebContainer>
  );

}
