import React from "react";
import axios from "axios";
import { UpdateKbankCheckOut } from "../../services/Payment";
import { baseUrl } from "../../services/Api"
import { useNavigate } from "react-router-dom";

export default class Payment extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.first) return;
    this.first = true;
    this.getmeasurement();
  }

  getmeasurement() {
    console.log("componentDidMount");

    let props = this.props;
    try {
      console.log("onload", props, window.KPayment);
      try {
        var eletoken = document.getElementsByName("token");
        for (var i = 0; i < eletoken.length; i++) {
          eletoken[i].remove();
        }
      } catch (e) {
        console.log(e);
      }

      window.KPayment.setAmount(this.props.dataAmount);
      window.KPayment.setPaymentMethods(this.props.dataPaymentMethods);
      window.KPayment.setOrderId(this.props.dataOrderId);

      setTimeout(() => {
        window.KPayment.show();
        window.KPayment.onClose((e) => {
          console.log("onClose", e);
        });
        document.getElementById("kbankcheckout").onsubmit = (e) => {
          e.preventDefault();
          const submitter = document.querySelector(
            "#kbankcheckout input[name='token']"
          );
          setTimeout(() => {
            const payload = {
              bookingId: this.props.bookingId,
              token: submitter.value,
              amount: this.props.dataAmount,
              paymentMethods: this.props.dataPaymentMethods,
              orderId: this.props.dataOrderId,
            };

            this.postKbankCheckOut(
              payload,
              // this.props.onSuccess,
              // this.props.onError
            );
          }, 50);
          return false;
        };
      }, 50);
    } catch (e) {
      console.log(e);
    }
  }

  async postKbankCheckOut(payload) {
    try {
      const { data } = await UpdateKbankCheckOut({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body;
        // console.log('resData >>', resData)
        if (resData.redirect) {
          window.location.href = resData.redirect
        }
      } 
    } catch (e) {
        console.log(`🦄: postKbankCheckOut -> e`, e);
        console.log('err', e)
    }
  };

  // kbankcheckout(body, onSuccess, onError) {
  //   console.log("kbankcheckout body", body);
  //   axios
  //     .post(baseUrl + "/kbankcheckout", body)
  //     // .post("http://127.0.0.1:8080/kbankcheckout", body)
  //     .then(function (response) {
  //       let res = response.data || {};
  //       // handle success
  //       // alert(res.resultDescription);
  //       console.log(res);

  //       // let a = res.xxx.yyy; // ไว้เทส ERROR
  //       if (res.body.transaction_state === "Authorized") {
  //         onSuccess(res.body);
  //       } else {
  //         if (res.body && res.body.redirect_url) {
  //           window.location.href = res.body.redirect_url;
  //         }
  //       }
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //       onError(error);
  //     });
  // }

  render() {
    return <div> </div>;
  }
}
