import React, { useState } from 'react'
import Label from '../../components/general/Label'
import { useForm } from 'react-hook-form';
import useTranslations from '../../i18n/useTranslations';
import IconPoint from "../../assets/images/icon-point-gold.png";
import QuantityInput from '../../components/general/NumberInput';
import { useEffect } from 'react';
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import { SearchBookingDetails, SearchBookingExpire, SearchCurrentPoint } from "../../services/Booking";
import { UpdateCancelReserveSlotBooking, UpdateBooking } from "../../services/Booking";
import { SearchMyBooking } from "../../services/Booking";
import { SearchPointConfig } from "../../services/Point";
import helper from '../../services/helper';
import moment from 'moment';
import CountdownTimer from './CountdownTimer';
import FooterNormal from './FooterNormal';
import { useNavigate } from "react-router-dom";
import StepBanner from './StepBanner';
import GridRap from '../../components/Layouts/GridRap';
import ModalBase from '../../components/Modal/ModalBase';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import ButtonPrimaryOutline from '../../components/button/ButtonPrimaryOutline';
import PopupBookingCondition from './PopupBookingCondition/PopupBookingCondition';
import PopupBookingPaymentPolicy from './PopupBookingPaymentPolicy/PopupBookingPaymentPolicy';
import PaymentDev from '../PaymentPage/PaymentDev';
import ModalTimeOutReSlot from './ModalTimeOutReSlot';
import ModalPointNotEnough from './ModalPointNotEnough';

const { toNumberWithCommas } = helper.FN
export default function BookingStep5() {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const [ bookingDetail, setBookingDetail ] = useState({ total: 0 })
  const [ bookingList, setBookingList ] = useState([])
  const [ sumTreatmentPoint, setSumTreatmentPoint ] = useState(0)
  const [ currentPoint, setCurrentPoint ] = useState(0)

  // STATE กลาง
  const [ bookingId, setBookingId ] = useState(null)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isShowAH, setIsshowAH ] = useState(false)
  
  // STATE PAGE
  const [ usePoint, setUsePoint ] = useState(0)
  const [ bookingExpire, setBookingExpire ] = useState(null)
  const [ paymentExpire, setPaymentExpire ] = useState(null)
  const [ burn, setBurn ] = useState({ point: 1, reward: 1 })
  const [ earn, setEarn ] = useState({ point: 1, reward: 1 })
  const [ survey, setSurvey ] = useState({ point: 1, reward: 1 })

  const [ popupPointNotEnough , setPopupPointNotEnough ] = useState(false)

  // STATE payment
  const [ paymentData, setPaymentData ] = useState(null)

  const minute = 0
  const second = 0

  const getBookingDetails = async (payload) => {
    try {
      const { data } = await SearchBookingDetails({ params: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body

        setBookingDetail(resData)
        setBookingList(resData.bookingList)

        // CAL sumTreatmentPoint
        let _sumTreatmentPoint = calSumTreatmentPoint(resData.bookingList)
        setSumTreatmentPoint(_sumTreatmentPoint)
      }
    } catch (e) {
      console.log(`🦄: getBookingDetails -> e`, e);
    }
  };

  const getPointConfig = async (payload) => {
    try {
      const { data } = await SearchPointConfig({ params: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        setBurn(resData.burn)
        setEarn(resData.earn)
        setSurvey(resData.survey)
      }
    } catch (e) {
      console.log(`🦄: getCurrentPoint -> e`, e);
    }
  };

  const getCurrentPoint = async (payload) => {
    try {
      const { data } = await SearchCurrentPoint({ params: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        setCurrentPoint(resData.currentPoint)
      }
    } catch (e) {
      console.log(`🦄: getCurrentPoint -> e`, e);
    }
  };

  const getBookingExpire = async (payload) => {
    try {
      const { data } = await SearchBookingExpire({ params: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        setBookingExpire(resData.bookingExpire)
        setPaymentExpire(resData.paymentExpire)
      }
    } catch (e) {
      console.log(`🦄: getBookingExpire -> e`, e);
    }
  };

  const postCancelReserveSlotBooking = async (payload, ans) => {
    try {
      const { data } = await UpdateCancelReserveSlotBooking({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body
      }
      if (ans === "Y") {
        navigate("/booking-step3")
        // alert("YES")
      } else {
        window.localStorage.removeItem('step1')
        window.localStorage.removeItem('step2')
        window.localStorage.removeItem('otherData')
        // alert("NO")
        setTimeout(() => {
          window.location.href = "/booking"
          navigate("/booking")
        }, 800)
      }
    } catch (e) {
      console.log(`🦄: getBookingExpire -> e`, e);
    }
  };

  const postUpdateBooking = async (payload, _paymentData = {}) => {
    try {
      const { data } = await UpdateBooking({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body
        // navigate(`/bank-payment/${bookingId}`)
        // window.location.href = `/bank-payment-mock/${bookingId}`
        // alert('UpdateBooking 20000')
        navigate(`/bank-payment/${bookingId}`)
        // setPaymentData(_paymentData)
      } else {
        setIsshowAH(false)
        setPopupPointNotEnough(true)
        getCurrentPoint()
        setUsePoint(0)
      }
    } catch (e) {
      console.log(`🦄: postUpdateBooking -> e`, e);
    }
  };

  const onLoadAfterGetMyBooking = (bookingId) => {
    console.log('onLoadAfterGetMyBooking step 4 !')
    const payload = { bookingId: bookingId }
    getBookingDetails(payload)
    getBookingExpire(payload)
    getCurrentPoint()
    getPointConfig()
  }

  const getMyBooking = async (payload) => {
    try {
      const { data } = await SearchMyBooking({ params: payload });
      if (data.resultCode === 20000) {
        const resData = data.body
        const _bookingId = resData.id
        onLoadAfterGetMyBooking(_bookingId)
        setBookingId(_bookingId)
      } else {
        window.location.href = '/booking'
      }
    } catch (e) {
      console.log(`🦄: getMyBooking -> e`, e);
      window.location.href = '/booking'
    }
  };

  useEffect(() => {
    console.log('useEffect step 5 !')
    getMyBooking()
  }, [] )

  function handleOnExpire () {
    if (bookingExpire) {
      console.log("handleOnExpire!!!")
      setIsOpen(true)
    }
  }
  
  function alertPaymentTimeout(ans = "N") {
    // alert('Payment Time-out')
    const payload = { bookingId: bookingId }
    postCancelReserveSlotBooking(payload, ans)
  }

  function gotoStep4() {
    navigate('/booking-step4')
  }

  const handleBack = () => {
    // alert('handleBack !')
    gotoStep4()
  }
  const handleNext = () => {
    // alert('handleNext !')
    setIsshowAH(true)
  }

  const onSubmit = () => {
    const { discount, grandTotal, test0, test1 } = calGrandTotal(burn.point, burn.reward, usePoint, bookingDetail.total)
    const { tmpEarnPoint, earnPoint } = calPointEarn(grandTotal, earn.reward, earn.point, discount , sumTreatmentPoint)
    
    const payload = {
      // memberId: memberId,
      bookingId: bookingId,
      pointEstimate: earnPoint,
      discount: discount,
      netPrice: grandTotal,
      pointBurn: usePoint ?? 0,
    }

    const _paymentData = {
      amount: grandTotal,
      bookingId: bookingDetail.bookingId,
      bookingNo: bookingDetail.bookingNo,
    }

    console.log('payload >>', payload)    
    postUpdateBooking(payload, _paymentData)
  }

  useEffect(() => { window.scrollTo({ top: 0 }) }, []); // newpage-scoll-top

  // console.log('bookingDetail >>', bookingDetail)

  const { discount, grandTotal, test0, test1 } = calGrandTotal(burn.point, burn.reward, usePoint, bookingDetail.total)
  const { tmpEarnPoint, earnPoint } = calPointEarn(grandTotal, earn.reward, earn.point, discount , sumTreatmentPoint)

  if (paymentData) {
    return <PaymentDev bookingId={paymentData.bookingId} bookingNo={paymentData.bookingNo} amount={paymentData.amount} />
  }

  if (isShowAH) {
    return <PopupBookingPaymentPolicy onClose={() => setIsshowAH(false)} onSave={onSubmit}/>
  }

  return (
    <>
      <ModalPointNotEnough open={popupPointNotEnough} onClose={() => setPopupPointNotEnough(false)}/>
      <WebContainer footer={
      //  <FooterNormal step={4} bookingList={bookingList} onClose={handleBack} onSave={handleSave}/>
        <FooterNormal labelNext="Check Out" step={5} onClose={handleBack} onSave={handleNext}/>
      }>
        <GridMain className="bk-container ribbonx bk-step5">
          <GridRap isSmallSize>
            <div className="flex flex-col items-center">
              <StepBanner current={4}/>
              <div className="w-full mt-[24px] md:mt-[36px]">
                <h5 className="cp-title mb-[24px] md:mb-[16px]">Payment</h5>
                {/* {JSON.stringify(booking.guestData)} */}
                <div className="flex flex-col">
                  <div className="sp-box0">
                    <InfoTextFull title={t.bookingStep5.orderNumber} value={bookingDetail.bookingNo} />
                    <InfoTextFull title={t.bookingStep5.paymentDate} value={helper.TIME.toShortDateNormal(bookingDetail.createdOn)} />
                  </div>
                  <div className="sp-box1">
                    <InfoTextFull title={t.bookingStep5.total} value={toNumberWithCommas(bookingDetail.total) + " THB"} />
                    <div className="b0">
                      <div className="flex flex-col gap-[8px]">
                        <div className="flex items-center gap-[4px]">
                          <img src={IconPoint} alt="" className="w-[16px] h-[16px]" />
                          <p className="text-[14px] md:text-[16px] font-medium text-[#DDB05B]">{t.bookingStep5.yourPoints} : { currentPoint > 0 ? toNumberWithCommas(currentPoint) : 0 }</p>
                        </div>
                        <p className="text-[12px] md:text-[14px]">
                          {
                            `${t.bookingStep5.covertRate}`
                            .replace('$number0',toNumberWithCommas(burn.point))
                            .replace('$number1',toNumberWithCommas(burn.reward))
                          } 
                          {/* {toNumberWithCommas(burn.point)}  */}
                          {/* {t.bookingStep5.points} = {toNumberWithCommas(burn.reward)} {t.bookingStep5.thb} */}
                        </p>
                      </div>
                      <div className='flex items-center'>
                        <QuantityInput value={usePoint} onChange={(v) => setUsePoint(v)} step={100} min={0} max={ currentPoint > 0 ? currentPoint : 0 }/>
                      </div>
                    </div>
                  </div>
                  <div className="sp-box2">
                    <InfoTextFull bold title={t.bookingStep5.grandTotal} value={toNumberWithCommas(grandTotal) + " THB"} />
                  </div>
                  <div className="sp-box3">
                    <InfoTextFull title={t.bookingStep5.pointsEarn} value={`${toNumberWithCommas(earnPoint)} ${t.bookingStep5.points}`} />
                    <p className="text-[12px] md:text-[14px] text-[#914C2A] ">
                      {t.bookingStep5.youWillEarn}
                    </p>
                  </div>
                  <div className="sp-box4">
                    {/* {bookingExpire}<br/> */}
                    {/* {moment(bookingExpire).add('minute', -4).toISOString()} */}
                    <CountdownTimer expireDateTime={bookingExpire} onExpire={handleOnExpire}/>
                    <p 
                      className="text-[12px] md:text-[14px] mt-[16px] md:mt-[16px]" 
                    >
                      <span dangerouslySetInnerHTML={{ __html: t.bookingStep5.paymentIsNon0 }}/>
                      <span dangerouslySetInnerHTML={{ __html: t.bookingStep5.paymentIsNon1 }}/>
                    </p>
                      {/* <p className="text-[12px] md:text-[14px] mt-[16px] md:mt-[16px]"/> */}
                      {/* {t.bookingStep5.paymentIsNon0} */}
                      {/* <br className="hidden md:block"/> */}
                      {/* {t.bookingStep5.paymentIsNon1} */}
                      {/* Health Land ขอสงวนสิทธิ์การคืนเงินและยกเลิกการจองทุกกรณี  */}
                      {/* หากแสดงตัวช้ากว่าเวลาจองเกิน 10 นาที <nobr>ทางบริษัทฯ</nobr> ขอสงวนสิทธิ์ยกเลิกการจองโดยไม่ต้องแจ้งให้ทราบล่วงหน้า */}
                  </div>
                </div>
              </div>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
      <ModalTimeOutReSlot 
        open={isOpen} 
        onClose={()=> {
          console.log("onClose!!")
          alertPaymentTimeout("Y")
        }} 
        onSave={()=> {
          console.log("onSave!!")
          alertPaymentTimeout("N")
          // setIsShowPopupBack(false)
        }} 
      />
    </>
  );

}

const InfoTextFull = ({ title = '', value = '', bold }) => {
  let boldStyle = ""
  if (bold) {
    boldStyle = "font-medium"
  }
  return (
    <div className="flex justify-between w-full">
      <p className={`text-[14px] md:text-[16px] leading-[1em] text-Neutral-A800 ${boldStyle}`}>{title}</p>
      <p className={`text-[14px] md:text-[16px] leading-[1em] text-Neutral-A800 font-medium`}>{value}</p>
    </div>
  )
}

function calSumTreatmentPoint(_bookingList = []) {
  let _sumTreatmentPoint = 0
  for (let bl of _bookingList) {
    for( let st of bl.subTreatment) {
      const subTreatmentPoint  = st?.point ?? 0
      _sumTreatmentPoint += subTreatmentPoint
    }
  }
  return _sumTreatmentPoint
}

function calGrandTotal(_burnPoint = 0, _burnReward = 0, _usePoint = 0, _total = 0) {
  // console.log("🚀 ~ file: BookingStep5.js:110 ~ calGrandTotal ~ _burnPoint, _burnReward, _usePoint, _total:", _burnPoint , _burnReward , _usePoint , _total )
  let discount = 0
  if (_usePoint > 0) {
    discount = (_usePoint * _burnReward) / _burnPoint 
  }

  discount = Math.floor(discount)

  let grandTotal = _total - discount
  const test0 = `(${_usePoint} *  ${_burnReward}) / ${_burnPoint}`
  const test1 = `${_total} - ${discount} `
  grandTotal = Math.floor(grandTotal)
  return { discount, grandTotal, test0,  test1}
}

function calPointEarn(_grandTotal = 0, _earnReward = 0 , _earnPoint = 0, _discount = 0, sumTreatmentPoint = 0) {
  console.log("🚀 calPointEarn ---------------------------------")
  console.log("🚀 calPointEarn ~ _grandTotal:", _grandTotal)
  console.log("🚀 calPointEarn ~ _earnReward:", _earnReward)
  console.log("🚀 calPointEarn ~ _earnPoint:", _earnPoint)
  console.log("🚀 calPointEarn ~ _discount:", _discount)
  console.log("🚀 calPointEarn ~ sumTreatmentPoint:", sumTreatmentPoint)
  console.log("🚀 calPointEarn ---------------------------------")
  // console.log("🚀 ~ file: BookingStep5.js:122 ~ calPointEarn ~ _grandTotal:", _grandTotal/ _earnReward)
  let tmpEarnPoint = 0
  if (_earnReward) {
    tmpEarnPoint = (_grandTotal / _earnReward) * _earnPoint
  }
  let earnPoint = tmpEarnPoint + sumTreatmentPoint
  earnPoint = Math.floor(earnPoint)
  return { sumTreatmentPoint, tmpEarnPoint, earnPoint }
} 