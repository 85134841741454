import * as React from 'react';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const CustomNumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  return (
    <NumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      step={100}
      slotProps={{
        incrementButton: {
          children: '+',
          className: 'increment',
        },
        decrementButton: {
          children: '-',
        },
      }}
      disabled={props.max === 0 || props.max <= 99}
      readOnly={true}
      {...props}
      ref={ref}
    />
  );
});

export default function QuantityInput({ step = 100, min = 0, max = 0, value = 0, onChange }) {
  const calMax = max - (max % step)

  function handleOnChange (event, newValue) {
    console.log('newValue >>', newValue)
    if(onChange) {
      onChange(newValue)
    }
  }

  return (
    <>
      {/* max : {max}<br/> */}
      {/* calMax : {calMax} */}
      <CustomNumberInput  value={value} step={step} min={min} max={calMax} onChange={handleOnChange}/>
    </>
  );
}

const blue = {
  100: '#daecff',
  200: '#b6daff',
  300: '#66b2ff',
  400: '#3399ff',
  500: '#007fff',
  600: '#0072e5',
  800: '#004c99',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledInputRoot = styled('div')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
  
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`,
);

const StyledInput = styled('input')(
  ({ theme }) => `
  font-size: 16px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.375;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid #DEE2E6;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin: 0;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;
  height: 40px;

  &:hover {
  }

  &:focus {
    box-shadow: 0;
  }

  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 18px;
  color: #DDB05B !important;
  font-weight: bold;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid #DEE2E6;
  border-radius: 0;
  color: #DEE2E6;
  background: #ffffff;

  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? blue[800] : blue[100]};
    cursor: pointer;
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`,
);