import React, { useEffect, useState } from "react";
import {
  SearchSubTreatmentByBranch,
  CreateImportSlot,
} from "../../services/ImportSlot";
import { SearchDdlBranch } from "../../services/Dropdown";
import moment from "moment";
import _, { values } from "lodash";

import IconSun from "../../assets/images/icon-sun.png";
import IconSunset from "../../assets/images/icon-sunset.png";
import IconEvening from "../../assets/images/icon-evening.png";
import ImageLabel from "../../components/general/ImageLabel";
import DatePickerNormal from "../../components/general/DatePickerNormal";
import useTranslations from '../../i18n/useTranslations';

import { SearchEmptySlot, SearchEmptySlotAll, UpdateRescheduleBooking } from '../../services/Booking';
import { GetPayloadRescheduleBooking } from '../../services/Booking';
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import helper from "../../services/helper";


let mockPayload = {
  branchId: 8,
  treatmentDate: "2024-04-02",
  guest: [
    {
      guestNumber: 1,
      guestName: "",
      treatment: [
        { treatmentId: "42", subTreatment: { subTreatmentId: "27" } },
      ],
    },
    {
      guestNumber: 2,
      guestName: "",
      treatment: [
        { treatmentId: "42", subTreatment: { subTreatmentId: "27" } },
      ],
    },
  ],
};

const RescheduleBooking = () => {
  const { bookingId } = useParams()
  const { t, setLang } = useTranslations();
  const step1 = {}

  const [ sDate, setSDate ] = useState(null)
  const [ payloadBooking, setPayloadBooking ] = useState({})
  // SLOT MAIN
  const [slotTimeMain, setSlotTimeMain] = useState([]);
  const [canSelectDate, setCanSelectDate] = useState([]);

  // SLOT แยกแต่ละเวลา
  const [slotMorning, setSlotMorning] = useState([]);
  const [slotAfternoon, setSlotAfternoon] = useState([]);
  const [slotEvening, setSlotEvening] = useState([]);

  // SELET SLOT
  const [selectSlot, setSelectSlot] = useState({});
  const [isOpen, setIsOpen] = useState(false);


  const postEmptySlot = async (payload) => {
    try {
      const { data } = await SearchEmptySlot({ data: payload });
      if (data.resultCode === 20000) {
        console.log('data.body >>', data.body)
        const _availableTime = data.body?.availableTime ?? []
        setSlotTimeMain(_availableTime ?? [])

        let _slotMorning = []
        let _slotAfternoon = []
        let _slotEvening = []

        setSelectSlot({})
        _.sortBy(_availableTime, ['treatmentShow']).forEach(t => {
          /**
           * 00:00|11:00 - 11:30|11:59 = morning
           * 12:00 - 14:30|14:59 = afternoon
           * 15:00 - 19:30|23:59 = evening
           */
          const [hour, minute] = t.bookingStart.split(':')
          if (+hour >= 0 && +minute >= 0 && +hour <= 11 && +minute <= 59) {
            _slotMorning.push({ ...t, select: false })
          } else if (+hour >= 12 && +minute >= 0 && +hour <= 17 && +minute <= 59) {
            _slotAfternoon.push({ ...t, select: false })
          } else if (+hour >= 18 && +minute >= 0 && +hour <= 23 && +minute <= 59) {
            _slotEvening.push({ ...t, select: false })
          }

          setSlotMorning(_slotMorning)
          setSlotAfternoon(_slotAfternoon)
          setSlotEvening(_slotEvening)
        })

        console.log('_slotMorning >>', _slotMorning)
        console.log('_slotAfternoon >>', _slotAfternoon)
        console.log('_slotEvening >>', _slotEvening)

        
      } 
    } catch (e) {
      console.log("ERR postEmptySlot", e);
    }
  }

  const postEmptySlotAll = async (payload) => {
    try {
      const { data } = await SearchEmptySlotAll({ data: payload });
      if (data.resultCode === 20000) {
        console.log('data.body >>', data.body)
        setCanSelectDate(data.body)
      } 
    } catch (e) {
      console.log("ERR postEmptySlotAll", e);
    }
  }

  const postReserveSlot = async (payload) => {
    try {
      const { data } = await UpdateRescheduleBooking({ data: payload });
      if (data.resultCode === 20000) {
        console.log('data.body >>', data.body)
        alert("ย้ายวันสำเร็จ")
      } 
    } catch (e) {
      console.log("ERR postReserveSlot", e);
    }
  }

  const getPayloadRescheduleBooking = async (bookingId) => {
    try {
      const { data } = await GetPayloadRescheduleBooking(bookingId);
      if (data.resultCode === 20000) {
        console.log('data.body >>', data.body)
        setPayloadBooking(data.body)
        setSDate(data.body.treatmentDate)
      } 
    } catch (e) {
      console.log("ERR getPayloadRescheduleBooking", e);
    }
  }

  function onSave() {
    // alert('onSave')
    console.log('onSAVE selectSlot', selectSlot)
    console.log('onSAVE payloadBooking', payloadBooking)
    const memberId = payloadBooking.memberId
    console.log('onSAVE memberId', memberId)
    payloadBooking.date = sDate
    let payload = helper.Booking.toPayloadBookingStep3(payloadBooking, memberId, selectSlot)
    payload.detailUsedTime = payload.detailUsedTime.map((item, index) => {
      return {
        ...item,
        guestName : item.guestName ? item.guestName : "Guest " + (index + 1)
      }
    })
    console.log('onSAVE payload >>', payload)
    postReserveSlot(payload)

  }

  function toCleanPayload(oldPayload = {}) {
    console.log("🚀 ~ file: index.js:169 ~ toCleanPayload ~ oldPayload:", oldPayload)
    let newPayload = _.cloneDeep(oldPayload)
    newPayload.guest = newPayload.guest.map(item0 => {
      return {
        ...item0,
        treatment: item0.treatment.map(item1 => {
          delete item1.bookingListId
          delete item1.slotDate
          delete item1.slotId
          delete item1.slotStartTime
          delete item1.treatmentByBranchId
          delete item1.subTreatmentId
          return item1
        })
      }
    })

    console.log('toCleanPayload newPayload >>', newPayload)
    return newPayload
  }

  function callEmptySlotAll(month) {
    console.log('onMonthChange >>', month )
    let _payload = toCleanPayload(payloadBooking);
    _payload.treatmentDate = null
    _payload.selectYearMonth = month
    postEmptySlotAll(_payload)
    console.log('callEmptySlotAll !')
  }

  useEffect(() => {
    if (payloadBooking.bookingId) {
      let _payload = toCleanPayload(payloadBooking);
      console.log('_payload >> 0', _payload)
      if (sDate) {
        _payload.treatmentDate = new Date(sDate).toISOString().split("T")[0]
        _payload.selectYearMonth = moment(_payload.treatmentDate,"YYYY-MM-DD").format("YYYY-MM")
        console.log('_payload >> 1', _payload)
      }
      postEmptySlot(_payload)
      postEmptySlotAll(_payload)
    }

  }, [sDate, payloadBooking])

  useEffect(() => {
    getPayloadRescheduleBooking(bookingId)
    setLang('en')
  }, [])

  function calIsNext(_selectSlot = {}) {
    if (Object.keys(_selectSlot).length === 0 ) {
      return true
    }
    return false
  }

  return (
    <>
      <div className="w-full">
        <div className="card">
          <div className="card-body flex flex-col px-[12px] md:px-[24px] py-[24px] md:py-[32px]">
            <div className="flex flex-col gap-[16px] md:gap-[32px] mb-[24px] md:mb-[32px]">
              <ImageLabel dataType="date">
                {t.bookingStep3.selectDate}
              </ImageLabel>
              <DatePickerNormal
                name="date"
                customClass="hl-date-step3"
                placeholder="Choose Date"
                minDate={moment().add(1, "day")}
                maxDate={moment().add(6, "month")}
                value={sDate}
                shouldDisableDate={canSelectDate}
                onChange={(value) => setSDate(value)}
                onMonthChange={(month) => callEmptySlotAll(month)}
                onOpen={(value) => {
                  const strMonth = moment(new Date(value).toISOString()).format('YYYY-MM')
                  callEmptySlotAll(strMonth)
                }}
              />
              {/* {JSON.stringify(canSelectDate)} */}
            </div>
            <div className="mb-[16px]">
              <ImageLabel dataType="time">
                {t.bookingStep3.availableTime}
              </ImageLabel>
            </div>
            <div className="flex flex-col gap-[24px] gap-[16px]">
              {slotTimeMain.length === 0 ? (
                <EmptySlot />
              ) : (
                <>
                  <AvailableTimeBox
                    title={t.bookingStep3.morning}
                    isShow={slotMorning.length > 0}
                  >
                    {slotMorning.map((item) => {
                      return (
                        <TimeBox
                          isActive={
                            selectSlot.bookingStart === item.bookingStart
                          }
                          item={item}
                          onClick={(value) => {
                            setSelectSlot(value);
                          }}
                        >
                          {item.bookingStart}
                        </TimeBox>
                      );
                    })}
                  </AvailableTimeBox>

                  <AvailableTimeBox
                    title={t.bookingStep3.afternoon}
                    isShow={slotAfternoon.length > 0}
                  >
                    {slotAfternoon.map((item) => {
                      return (
                        <TimeBox
                          isActive={
                            selectSlot.bookingStart === item.bookingStart
                          }
                          item={item}
                          onClick={(value) => {
                            setSelectSlot(value);
                          }}
                        >
                          {item.bookingStart}
                        </TimeBox>
                      );
                    })}
                  </AvailableTimeBox>

                  <AvailableTimeBox
                    title={t.bookingStep3.evening}
                    isShow={slotEvening.length > 0}
                  >
                    {slotEvening.map((item) => {
                      return (
                        <TimeBox
                          isActive={
                            selectSlot.bookingStart === item.bookingStart
                          }
                          item={item}
                          onClick={(value) => {
                            setSelectSlot(value);
                          }}
                        >
                          {item.bookingStart}
                        </TimeBox>
                      );
                    })}
                  </AvailableTimeBox>
                </>
              )}
            </div>
            <div>
              {/* select Data : {JSON.stringify(selectSlot)} */}
            </div>
            <div className="mt-[32px] flex justify-center gap-[16px]">
              <ButtonPrimary 
                addClass=""
                onClick={() => onSave()} 
                disabled={calIsNext(selectSlot)}
              >
                Save
              </ButtonPrimary>
              <ButtonPrimaryOutline 
                addClass=""
                // onClick={handleBack}
              >
                Reset
              </ButtonPrimaryOutline>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RescheduleBooking;

const AvailableTimeBox = ({ isShow = false, title = "", children }) => {
  const typeOption = {
    Morning: IconSun,
    Afternoon: IconSunset,
    Evening: IconEvening,
  };
  const imgType = typeOption[title] ? typeOption[title] : null;

  if (!isShow) return <></>;

  return (
    <div className="flex flex-col gap-[8px]">
      <div className="flex items-center gap-[8px]">
        <img src={imgType} alt="" className="w-auto h-[16px] md:h-[22px]" />
        <span className="text-Neutral-A800 text-[14px] md:text-[16px] ">
          {title}
        </span>
      </div>
      <div className="flex flex-wrap gap-[8px] md:gap-x-[24px] md:gap-y-[16px]">
        {children}
      </div>
    </div>
  );
};

const TimeBox = ({ item = {}, onClick = null, isActive = false }) => {
  let activeStyle = "";
  const handleClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  if (isActive) {
    activeStyle = " active";
  }

  const [hour, minute] = `${item.bookingStart}`.split(":");
  return (
    <div className={`time-box ${activeStyle}`} onClick={handleClick}>
      {hour}.{minute}
    </div>
  );
};

const EmptySlot = () => {
  return (
    <div className="p-[3rem]">
      <p className="text-[14px] md:text-[16px] text-center text-[#6D6E71]">
        No available time matches your search.
      </p>
      <p className="text-[14px] md:text-[16px] text-center text-[#6D6E71]">
        Please change the appointment date.
      </p>
    </div>
  );
};
