import React from "react";
import { Controller } from "react-hook-form";
// import { MobileDatePicker } from "@mui/lab";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import OpenPass from "../../assets/images/icon-input/calendar-mini.svg";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import moment from "moment/moment";


function DatePickerField({ name, control, rules, error, helperText, placeholder, maxDate, minDate, value = null, onChange, shouldDisableDate }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            {...field}
            // components={{
            //   ActionBar: () => <div></div>
            // }}
            className="w-full py-[10px] hl-datepicker"
            format="DD/MM/YYYY"
            minDate={minDate}
            maxDate={maxDate}
            value={field.value ?? value}
            onChange={(newValue) => onChange ? onChange(newValue) : field.onChange(newValue)}
            shouldDisableDate={d => {
              if (shouldDisableDate?.length > 0) {
                const dates = shouldDisableDate.map(s => { return moment(new Date(s)).format('DDMMYYYY') })
                if (!dates.includes(moment(new Date(d)).format('DDMMYYYY')))
                  return true
                return false
              }
            }}
            renderInput={(params) => (
              <TextField
                className="py-[10px]"
                name={name}
                {...params}
                error={!!error}
                helperText={helperText}
                placeholder="DD/MM/YYYY"
                InputProps={{
                  disableunderline: true,
                  endAdornment: (
                    <InputAdornment className="px-[10px]" position="end">
                      <IconButton
                        edge="end"
                      >
                        <img className="w-[12px] md:w-[18px]" src={OpenPass} alt="" />

                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      )
      }
    />
  );
}

export default DatePickerField;
