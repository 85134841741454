import React, { useState, useEffect } from 'react'
import useTranslations from '../../i18n/useTranslations';
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import GridRap from '../../components/Layouts/GridRap';
import IconUser from "../../assets/images/svg/guest-one.svg";
import IconCalendar from "../../assets/images/svg/calendar-one.svg";
import IconTime from "../../assets/images/svg/time-one.svg";
import IconMarker from "../../assets/images/svg/marker-one.svg";
import QRCode from "react-qr-code";
import CheckinComplete from './CheckInParts/CheckinComplete';
import { SearchBookingDetails } from "../../services/Booking";
import { useNavigate, useParams } from "react-router-dom";
import helper from '../../services/helper';
import moment from 'moment';
// import QRCode from 'qrcode.react';


export default function CheckIn() {
  const { bookingId } = useParams();
  const { t } = useTranslations();
  const [isComplete, setIsComplete] = useState(false);
  const [qrCodeValue, setQRCodeValue] = useState('');

  const [ bookingDetail, setBookingDetail ] = useState({})
  const [ bookingList, setBookingList ] = useState([])


  const getBookingDetails = async (payload) => {
    try {
      const { data } = await SearchBookingDetails({ params: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        setBookingDetail(resData)
        setBookingList(resData.bookingList)
        setQRCodeValue(resData.bookingNo)
        // setValue('branch', Branch.id)
      }
    } catch (e) {
      console.log(`🦄: getBookingDetails -> e`, e);
    }
  };

  useEffect(() => {
    console.log('useEffect Booking Detail !')
    const payload = { bookingId: bookingId }
    getBookingDetails(payload)
  }, [])


  useEffect(() => {
    setQRCodeValue('text');
  }, [])
  
  return (
    <>
      <WebContainer wfull={true} bgGraphics={true}>
        <GridMain className="">
          <GridRap className="max-w-[400px]">
            <div className="content-full box-content-space">
              <h5 className="cp-title mb-[32px] md:mb-[24px]">
                CheckIn
              </h5>
              <div className="border-[1px] md:border-[2px] rounded-[8px] md:rounded-[8px] border-Neutral-A300">
                <div className="border-b-[1px] md:border-b-[2px] border-Neutral-A300 py-[16px] md:py-[16px] flex">
                  <div className="flex gap-[36px] items-center mx-auto">
                    <div className="text-center">
                      <h5 className="text-[34px] md:text-[24px] font-medium text-Neutral-A800 leading-[1.2em]">{moment(bookingDetail.bookingDate).format('D')}</h5>
                      <h6 className="text-[16px] md:text-[16px] text-Neutral-A800">{moment(bookingDetail.bookingDate).format('MMM YYYY')}</h6>
                    </div>
                    <div className="flex flex-col gap-[4px] md:gap-[4px]">
                      <InfoText2 img={IconTime} title="" value={`Start at ${helper.TIME.toShortTime(bookingDetail.bookingStart)}`}/>
                      <InfoText2 img={IconMarker} title="" value={bookingDetail.location}/>
                      <P className="text-Neutral-A600">Order {bookingDetail.bookingNo}</P>
                    </div>
                  </div>
                </div>
                <div className="border-b-[1px] md:border-b-[2px]  border-Neutral-A300 py-[32px] md:py-[32px] flex">
                  <div className="flex gap-[36px] mx-auto ">
                    {
                      isComplete 
                      ? (
                        <CheckinComplete/>
                      ) 
                      : <QRCodeGen value={qrCodeValue} />
                    }
                  </div>
                </div>
                <div className="border-b-[1px] md:border-b-[2px]  border-Neutral-A300 flex">
                  <BoxContent className="flex flex-row justify-between w-full">
                    <BoxItem title={t.bookingCheckin.guest} value={`${bookingList.length} ${t.bookingCheckin.guestUnit}`} />
                    <BoxItem title={t.bookingCheckin.name} value={bookingDetail.contactName} />
                    <BoxItem title={t.bookingCheckin.grandTotal} value={`${helper.FN.toNumberWithCommas(bookingDetail.total)} THB`} />
                  </BoxContent>
                </div>
                <BoxContent>
                  <BoxItem title={t.bookingCheckin.email} value={bookingDetail.contactEmail} />
                </BoxContent>
              </div>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
    </>
  )
}

const BoxContent = ({ className = "" , children }) => {
  return (
    <div className={` py-[16px] md:py-[16px] px-[16px] md:px-[16px] ${className} `}>
      {children}
    </div>
  )
}

const BoxItem = ({ title, value }) => {
  return (
    <div className="flex flex-col justify-start">
      <P className="text-[14px] md:text-[16px] font-medium text-Gray-A700 text-left">{title}</P>
      <P className="text-[14px] md:text-[16px] text-Neutral-A800 text-left">{value}</P>
    </div>
  )
}

const InfoText2 = ({ img , title, value = "-" , className = "", styleList = null}) => {
  let pClassName = ""
  let spClassName = ""
  if (styleList == 0) {
    pClassName = "md:text-right md:w-[200px]"
    spClassName = "md:ml-[40px]"
  }
  return (
      <div className={`flex gap-[8px] md:gap-[12px] items-center ${className}`}>
        { img ? <img className="w-auto h-[12px] md:h-[16px]" src={img} alt="img-info" /> : <></> }
        <P className={`text-[14px] md:text-[16px] text-Neutral-A800 `}>{value}</P>
      </div>
  )
}

const P = ({ className = "" , children }) => {
  return (
    <p className={`text-[14px] md:text-[16px] ${className}`}>{children}</p>
  )
}

const QRCodeGen = ({ value }) => {
  return (
    <>
      <QRCode
        className="hidden md:block"
        size={134}
        value={value}
        viewBox={`0 0 134 134`}
      />
      <QRCode
        className="block md:hidden"
        size={194}
        value={value}
        viewBox={`0 0 194 194`}
      />
    </>
  )
} 