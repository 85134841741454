import React, { useState, useEffect } from "react";
import AvatarProfile from "../../assets/images/Avatar-Profiles.svg";
import ArrowRight from "../../assets/images/arrow-right.svg";
import "../../assets/styles/Profile.css";
import { Link, useNavigate } from "react-router-dom";
import { SearchLineId, SearchMemberById } from "../../services/Member";
import helper from "../../services/helper";
import ListTileProfile from "../../components/ListItme/ListTileProfile";
import WebContainer from "../../components/Layouts/WebContainer";
import CardPoint from "./CardPoint";
import GridMain from "../../components/Layouts/GridMain";
import PopupPointBenefit from "../../components/Popup/PopupPointBenefit";
import GridRap from "../../components/Layouts/GridRap";
import BookingItem from "../../components/general/BookingItem";
import { SearchMyBookingList } from "../../services/Booking";
import SuccessPage from "../../components/Layouts/SuccessContent";
import moment from "moment";
import ProfileBox from "./ProfileBox";
import useTranslations from "../../i18n/useTranslations";

export default function Profile() {
  const lineId = localStorage.getItem('lineId') ?? null
  const { t } = useTranslations();
  const [profile, setProfile] = useState({});
  const [booking0, setBooking0] = React.useState([])
  const [modalPoints, setModalPoint] = useState(false);
  const navigate = useNavigate();


  const getMyBookingList= async () => {
    try {
      const { data } = await SearchMyBookingList({ params: {} });
      if (data.resultCode === 20000) {
        const resData = data.body
        const _booking0 = resData.filter((item, i) => i === 0)
        setBooking0(_booking0)
      }
    } catch (e) {
      console.log(`🦄: getMyBookingList -> e`, e);
    }
  };

  useEffect(() => {
    const data = helper.Auth.getTokenData();
    getMyBookingList()
  }, []);

  return (
    <>
      <WebContainer wfull={true} bgGraphics={true}>
      <GridMain className="">
        <GridRap>
          <div className="content-full box-content-space">
            <div className="profile-container ">
              <ProfileBox/>
              <div className="profile-control">
                <div className="pc-container">
                  {
                    booking0.length ?
                    <>
                      <p className="text-[14px] md:text-[20px] font-medium text-Neutral-A900">
                        {t.profileMenu.upcomingBooking}
                      </p>
                      {
                        booking0.map(item => {
                          return (
                            <BookingItem
                              bookingId={item.bookingId}
                              styleList="on"
                              size="sm"
                              date={moment(item.bookingDate).format("YYYY-MM-DD")}
                              startTime={moment(item.startTime,"HH:mm:ss").format("HH.mm")}
                              location={item.branchName}
                              guest={item.guestCount}
                              treatment={item.treatmentCount}
                            />
                          )
                        })
                      }
                    </>
                  : <></>
                  }
                  
                  <div className="w-full flex flex-col gap-[24px] px-[12px] md:px-[0px]">
                    <Link to={"/my-booking"}>
                      <ListTileProfile
                        type="booking"
                        title={t.profileMenu.myBooking}
                        isSelected={false}
                      />
                    </Link>
                    <Link to={"/history"}>
                      <ListTileProfile
                        type="history"
                        title={t.profileMenu.history}
                        isSelected={false}
                      />
                    </Link>
                    <Link to={"/membership"}>
                      <ListTileProfile
                        type="membership"
                        title={t.profileMenu.membership}
                        isSelected={false}
                      />
                    </Link>
                    <Link to={"/settings"}>
                      <ListTileProfile
                        showMobile
                        type="setting"
                        title={t.profileMenu.setting}
                        isSelected={false}
                      />
                    </Link>
                    {
                      lineId? <></>
                      : (
                        <a className="cursor-pointer" onClick={() => helper.Auth.Logout()}>
                          <ListTileProfile
                            showMobile
                            type="logout"
                            title={t.profileMenu.logout}
                            isSelected={false}
                          />
                        </a>
                      )
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridRap>
        </GridMain>
      </WebContainer>
    </>
  )
}

