import React from "react";
function Layouts({children}) {
  return (
    <>
        {children}
    </>
  );
}

export default Layouts;
