import React from 'react'
import { Stepper } from '@mui/material'

export default function Steppers(props) {

  function getStepClass(step) {
    let cls = "";
    if (step < props.activeStep) {
      cls += 'pass';
    }
    if (props.activeStep === step) {
      cls += 'active';
    }
    
    return cls;
  }

  return (
    <>
      <div className="stepper">
        {props.items.map((label, index) => {
          return (
            <div  className={getStepClass(index)}>
              <p><span>{index + 1}</span></p>
              <span>{label}</span>
            </div>
            // <div className={getStepClass(index)} key={index}>
            //   <div className="relative flex justify-center">
            //     <div className="circle text-[16px] md:text-[24px] md:font-medium">
            //       <span>{label}</span>
            //     </div>
            //     {index < props.items.length - 1 && <div className="line"></div>}
            //   </div>
            //   <div className="mt-[8px] md:mt-[12px] label text-[10px] md:text-[16px]">{label}</div>
            // </div>
          )
        })}
      </div>
    </>
  )
}