import React, { useState, useEffect } from 'react'
import Label from '../../components/general/Label'
import { useForm } from 'react-hook-form';
import useTranslations from '../../i18n/useTranslations';
import IconTel from "../../assets/images/icon-tel.png";
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import { SearchBookingDetails } from "../../services/Booking";
import { AuthVerifyToken } from "../../services/Auth";
import helper from '../../services/helper';
import moment from 'moment';
import { useNavigate, useParams } from "react-router-dom";
import GridRap from '../../components/Layouts/GridRap';
import BookingDetaillFooter from './BookingDetailParts/BookingDetaillFooter';
import BookingDetailTemplate from './BookingDetailParts/BookingDetailTemplate';

const { toNumberWithCommas } = helper.FN
export default function BookingSuccess() {
  const navigate = useNavigate();
  const { bookingId } = useParams();

  const [ loading, setLoading ] = useState(true)
  const [ bookingDetail, setBookingDetail ] = useState({})
  const [ bookingList, setBookingList ] = useState([])

  const { t } = useTranslations();

  const getBookingDetails = async (payload) => {
    try {
      const { data } = await SearchBookingDetails({ params: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        setBookingDetail(resData)
        setBookingList(resData.bookingList)
        // setValue('branch', Branch.id)
      }
    } catch (e) {
      console.log(`🦄: getBookingDetails -> e`, e);
    }
  };


  useEffect(() => {
    console.log('useEffect Booking Detail !')
    const payload = { bookingId: bookingId }
    getBookingDetails(payload)
  }, [])

  function gotoMybooking() {
    navigate('/my-booking')
  }
  function gotoCheckIn() {
    navigate(`/check-in/${bookingId}`)
  }

  return (
    <>
      <WebContainer bgGraphics={true}  
      footer={
        <BookingDetaillFooter onClose={() => gotoMybooking()} onSave={() => gotoCheckIn()}/>
      }>
        <GridMain className="">
          <GridRap isSmallSize>
            <div className="content-full box-content-space has-footer">
              <BookingDetailTemplate
                title="Booking Details"
                bookingDate={bookingDetail.bookingDate}
                bookingNo={bookingDetail.bookingNo}
                bookingDetail={bookingDetail}
                bookingList={bookingList}
                status={null}
              />
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
      {/* <BookingDetaillFooter onClose={() => gotoMybooking()} onSave={() => gotoCheckIn()}/> */}
    </>
  )
}