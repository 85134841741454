import React from "react";
import GridRap from "../../../components/Layouts/GridRap";
import { useDispatch, useSelector } from "react-redux";
import { getTotalDuration } from "../../../features/BookingSlice";
import GridMain from "../../../components/Layouts/GridMain";
import moment from "moment";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import ButtonPrimaryOutline from "../../../components/button/ButtonPrimaryOutline";
import useTranslations from "../../../i18n/useTranslations";

export default function FooterBookingCondition({ onClose , onSave, isNext = true }) {
  const { t } = useTranslations();
  const dispatch = useDispatch()
  const guestData = useSelector(state => state.booking.guestData)

  const handleBack = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleSelect = () => {
    if (onSave) {
      onSave()
    }
  }

  return (
    <footer className="summary">
      <div className="box-2">
        <GridMain>
          <GridRap isSmallSize>
            <div className="flex justify-center gap-[16px] md:gap-[24px]">
              <ButtonPrimaryOutline 
                size="mini"
                addClass="w-full md:max-w-[366px]"
                onClick={handleBack}
              >
                {t.ageHealthConditions.btnBack}
              </ButtonPrimaryOutline>
              <ButtonPrimary 
                size="mini"
                addClass="w-full md:max-w-[366px]"
                onClick={handleSelect} 
              >
                {t.ageHealthConditions.btnAccept}
              </ButtonPrimary>
            </div>
          </GridRap>
        </GridMain>
      </div>
    </footer>
  )

  return (
    <footer style={{
      background: '#ffffff',
      position: 'fixed',
      width: '100%',
      bottom: 0,
      left: 0,
      color: '#6C757D',
      padding: 0
    }}>
       <div className="bg-red w-full text-center">
        <GridMain className='border-t-[1px] border-[#E9ECEF]'>
          <div className='flex justify-center gap-[1rem] py-[1.5rem]'>
            <button
              className='btn btn-secondary !max-w-none'
              onClick={handleBack}
            >
              Back
            </button>
            <button
              className='btn btn-primary !max-w-none'
              onClick={handleSelect}
              disabled={!isNext}
            >
              Select
            </button>
          </div>
        </GridMain>
       </div>
    </footer>
  )
}