import * as React from "react";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import ModalBase from "../Modal/ModalBase";
import useTranslations from "../../i18n/useTranslations";
import closeB from "../../assets/images/booking/payment/close-b.svg"
import { useDispatch, useSelector } from "react-redux";
import helper from "../../services/helper";

export default function PopupTreament({ open = false, onChange }) {
  const step2 = useSelector((state) => state.bookingMain.step2);
  const programTreament = useSelector((state) => state.programTreament.data);
  const { guestData } = step2
  const { t } = useTranslations();
  return (
    <>
      <ModalBase
        open={open}
        onClose={() => onChange(false)}
        size="size4"
        mini
        header={
          <></>
        }
        content={
          <>
            <div className="pp-view-treaments">
              <div className="header">
                <h5>Selected Treatments</h5>
                <img className="exit" src={closeB} onClick={() => onChange(false)} alt="close" />
              </div>
              <div className="content">
                {
                  guestData.map((item, i) => {
                    const { maxPeriod, price } = helper.Booking.getMaxPeriodAndPrice(programTreament, item.values)
                    return (
                      <div className="item">
                        <div className="box">
                          <b>Guest {i + 1} :</b>
                          <p>{item.name ? item.name : '-'}</p>
                        </div>
                        {
                          Object.keys(item.values).map((value, j) => {
                            const [programId, treatmentId, subTreatmentId] = `${value}`.split('|')
                            // const p = programTreament[programId][treatmentId][subTreatmentId]
                            const _treatmentList = programTreament.find(item => item.programId == programId)?.treatment ?? []
                            const _subTreatmentList = _treatmentList.find(item => item.treatmentId == treatmentId)?.subTreatment ?? []
                            const subTreatment = _subTreatmentList.find(item => item.subTreatmentId == subTreatmentId) ?? {}
                            const p = subTreatment

                            return (
                              <div className="box">
                                <p>{p.subTreatmentName ? p.subTreatmentName : "-"}</p>
                                <p>{p.subTreatmentPrice ? helper.FN.toNumberWithCommas(p.subTreatmentPrice) : 0} THB</p>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>

          </>
        }
        footer={
          <></>
        }
      />
    </>
  );
};