import React from "react";
import TopLogo from "../../components/Layouts/TopLogo";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Label from "../../components/general/Label";
import GridLine from "../../components/Layouts/GridLine";
import SuccessLogo from "../../assets/images/logo-success.png";
import TextFieldPassword from "../../components/general/TextFieldPassword";
import useTranslations from "../../i18n/useTranslations";
import { useState } from "react";
import { UpdatePassword } from "../../services/Member";
import WebContainer from "../../components/Layouts/WebContainer";
import SuccessPage from "../../components/Layouts/SuccessContent";
import GridMain from "../../components/Layouts/GridMain";
import GridRap from "../../components/Layouts/GridRap";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import { AuthVerifyToken } from "../../services/Auth";
import helper from "../../services/helper";
import { useEffect } from "react";
import sweetAlert from "../../services/sweetAlert";

export default function ResetPasswords() {
  const { t } = useTranslations();
  const [success, setSuccess] = useState(false);
  const [ loading, setLoading ] = useState(true)
  const navigate = useNavigate();
  const { token } = useParams();
  const { register, formState: { errors }, handleSubmit, watch } = useForm({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {},
  });

  const onSubmit = (data) => {
    console.log("data >>", data);
    let payload = { ...data };
    postUpdatePassword(payload);
    // setSuccess(true)
  };

  const postAuthVerifyToken = async (payload) => {
    try {
      const { data } = await AuthVerifyToken({ data: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        const { bookingId } = helper.decodeJWT(token)
      }
      setTimeout(() => setLoading(false), 100)
    } catch (e) {
      console.log(`🦄: postAuthVerifyToken -> e`, e);
      setTimeout(() => setLoading(false), 100)

    }
  };

  const postUpdatePassword = async (payload) => {
    delete payload.rePassword;
    try {
      const { data } = await UpdatePassword({
        data: { ...payload, token: token },
      });
      if (data.resultCode === 20000) {
        setSuccess(true);
      } else if (data.resultCode === 40401){
        sweetAlert.AlertInfo({
          title: "Your Member ID does not exist.",
          subtitle: `Please Re-Sign up.`,
          btnText: "Sign up",
          callBackOk: () => { navigate('/register') },
        });
      } else {
        sweetAlert.AlertInfo({
          title: "Error",
          subtitle: data.resultCode,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickDone = () => {
    setSuccess(false);
    navigate("/login");
  };

  useEffect(() => {
    console.log('useEffect Reset Password !')
    // const { bookingId } = helper.decodeJWT(token)
    const payload = { token: token }
    postAuthVerifyToken(payload)
    
  }, [])
  return (
    <WebContainer bgGraphics={true} contentCenter={true} wfull={true}>
      <SuccessPage>
        <div className="flex-1">
          <GridMain className="h-full flex flex-col text-center justify-center items-center pb-[61px] md:pb-[101px]" >
            <div className="">
              { success ? (
                <>
                  <div className="">
                    <div className="flex justify-center">
                      <img
                        className="max-w-[150px] mb-[12px]"
                        src={SuccessLogo}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col justify-center items-center text-center">
                      <p className="cp-title">
                        {t.reset_password.PasswordResetComplete}
                      </p>
                      {/* <div className="divider-glod"></div> */}
                      <p className="text-[12px] md:text-[16px] mt-[15px] text-center mb-[32px]">
                        {t.reset_password.PasswordResetCompleteSub1}<br/>
                        {t.reset_password.PasswordResetCompleteSub2}
                      </p>
                    </div>
                    <div className="w-full  text-white text-center">
                      <ButtonPrimary
                        addClass=""
                        // className="btn-next btn-submit"
                        onClick={() => {
                          onClickDone();
                        }}
                      >
                        {t.reset_password.Signin}
                      </ButtonPrimary>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="reset-password-box">
                      <div className="flex flex-col justify-center items-center">
                        <p className="cp-title">
                          {t.reset_password.ResetPassword}
                        </p>
                        <p className="text-[12px] md:text-[16px] text-center mt-[4px] md:mt-[8px]">
                          {t.reset_password.Pleaseenter}
                        </p>
                      </div>

                      <div className="flex flex-col gap-[24px] mt-[24px] md:mt-[15px] text-center">
                        <div className="w-full" style={{ textAlign: "left" }}>
                          <Label bold={true}>{t.reset_password.NewPassword}</Label>
                          <TextFieldPassword
                            required
                            fullWidth
                            placeholder={t.register.form_password_placeholder}
                            error={!!errors?.password}
                            helperText={errors?.password?.message}
                            {...register("password", {
                              required: t.register.form_password_validate_0,
                              minLength: {
                                value: 6,
                                message: t.register.form_password_validate_1,
                              },
                            })}
                            inputProps={{ 
                              maxLength: 20,
                              onChange: helper.FNFORM.handleCharOnly,
                            }}
                          />
                          {!errors?.password ? (
                            <p className="text-[12px] md:text-[16px] mt-[0px] md:mt-[6px] text-Neutral-A600">
                              {t.register.form_password_info}
                            </p>
                          ) : (
                            <></>
                          )}
                          {/* <p className="text-[12px] md:text-[16px] mt-[0px] md:mt-[6px] text-Neutral-A600">
                        {t.register.form_password_info}
                      </p> */}
                        </div>
                        <div className="w-full" style={{ textAlign: "left" }}>
                          <Label bold={true}>{t.reset_password.ConfirmPassword}</Label>
                          <TextFieldPassword
                            required
                            fullWidth
                            placeholder={t.register.form_re_password}
                            error={!!errors?.rePassword}
                            helperText={errors?.rePassword?.message}
                            // {...register("rePassword", {required: "กรุณากรอกยืนยันรหัสผ่าน"})}
                            {...register("rePassword", {
                              required: t.register.form_password_validate_0,
                              validate: (value) =>
                                value === watch("password") ||
                                t.register.form_re_password_validate_2,
                              minLength: {
                                value: 6,
                                message: t.register.form_password_validate_1,
                              },
                            })}
                            inputProps={{ 
                              maxLength: 20,
                              onChange: helper.FNFORM.handleCharOnly,
                            }}
                          />
                          {!errors?.rePassword ? (
                            <p className="text-[12px] md:text-[16px] mt-[0px] md:mt-[6px] text-Neutral-A600">
                              {t.register.form_re_password_info}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="w-full mt-[12px]">
                          <div className="text-center">
                            <ButtonPrimary
                              addClass=""
                              type="submit"
                            >
                              {t.reset_password.ResetPasswordBtn}
                            </ButtonPrimary>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </GridMain>
        </div>
      </SuccessPage>    
    </WebContainer>
  );
}
