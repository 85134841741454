import React from "react";
import { Controller } from "react-hook-form";
// import { MobileDatePicker } from "@mui/lab";
import { LocalizationProvider, MobileDatePicker, DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import OpenPass from "../../assets/images/icon-input/calendar-mini.svg";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import moment from "moment/moment";

function DatePickerMonthYear({
  error,
  helperText,
  maxDate,
  minDate,
  value,
  onChange,
  inputFormat="DD/MM/YYYY",
  placeholder="DD/MM/YYYY",
  shouldDisableDate,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        showDaysOutsideCurrentMonth={false}
          className="w-full py-[10px] hl-datepicker"
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={(newValue) => {
            console.log('newValue >>', newValue)
            onChange(newValue)
          }}
          shouldDisableDate={(d) => {
            if (shouldDisableDate?.length > 0) {
              const dates = shouldDisableDate.map((s) => {
                return moment(new Date(s)).format("DDMMYYYY");
              });
              if (!dates.includes(moment(new Date(d)).format("DDMMYYYY")))
                return true;
              return false;
            }
          }}
          format="MMM yyyy"
          views={['month', 'year']}
          renderInput={(params) => (
            <TextField
              className="py-[10px]"
              {...params}
              error={!!error}
              helperText={helperText}
              placeholder={placeholder}
              InputProps={{
                disableunderline: true,
                endAdornment: (
                  <InputAdornment className="px-[10px]" position="end">
                    <IconButton edge="end">
                      <img
                        className="w-[12px] md:w-[18px]"
                        src={OpenPass}
                        alt=""
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
        )}
      />
    </LocalizationProvider>
  );
}

export default DatePickerMonthYear;
