import React from "react";
import GridRap from "../../components/Layouts/GridRap";
import { useDispatch, useSelector } from "react-redux";
import IconUser from "../../assets/images/svg/guest-one.svg";
import IconCalendar from "../../assets/images/svg/calendar-one.svg";
import IconTime from "../../assets/images/svg/time-one.svg";
import IconMarker from "../../assets/images/svg/marker-one.svg";
import { useEffect } from "react";
import { getTotalDuration } from "../../features/BookingSlice";
import GridMain from "../../components/Layouts/GridMain";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { SearchProgramTreatment } from "../../services/Booking";
import { setProgramTreament } from "../../features/ProgramTreamentSlice";
import helper from "../../services/helper";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import useTranslations from "../../i18n/useTranslations";
import PopupTreament from "../../components/Popup/PopupTreament";
import { useState } from "react";

const { zeroToDash } = helper.FN
const programTreamentList = helper.Booking.mockProgramTreament

export default function FooterSummary({ step = 2, values = {} , onClose, onSave, isNextStep = false, dataDetail = {}}) {
  const { t } = useTranslations();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const booking = useSelector(state => state.booking)
  const otherData = useSelector((state) => state.bookingMain.otherData)
  const step1 = useSelector((state) => state.bookingMain.step1)
  const step2 = useSelector((state) => state.bookingMain.step2)
  const popupTreatment = useSelector((state) => state.bookingMain.popupTreatment)

  const programTreament = useSelector((state) => state.programTreament.data);
  const [modalPoints, setModalPoint] = useState(false);
  // const programTreament = programTreamentList

  // State กลาง
  const { ddBranch = [] } = otherData
  const { branch, guestCount, date } = step1
  const { guestData } = step2

  const getProgramTreatment = async (branchId = 0) => {
    try {
      const { data } = await SearchProgramTreatment({
        params: { branchId: branchId },
      });
      if (data.resultCode === 20000) {
        console.log("data.body >>", data.body);
        setProgramTreament(data.body);
        const tmpObj = {
          data: data.body,
          isLoading: true,
        };
        dispatch(setProgramTreament(tmpObj)); // set เข้า state กลาง
      }
    } catch (e) {
      console.log("ERR getProgramTreatment", e);
    }
  };

  // useEffect(() => {
    
  //   if (programTreament.length === 0) {
  //     getProgramTreatment(branch)
  //   }
    
  // }, [])

  function handleBack() {
    onClose()
    // navigate(-1)
  }

  function handleSave() {
    onSave()
  }
  const branchName = ddBranch?.find(item => item.id == branch)?.name

  let allMaxPeriod = 0
  let allPrice = 0
  for(const gd of guestData) {
    const { maxPeriod, price } = helper.Booking.getMaxPeriodAndPrice(programTreament, gd.values)

    if (maxPeriod >= allMaxPeriod) {
      allMaxPeriod = maxPeriod
    }
    allPrice += price
  }
  // console.log('summary date >>', JSON.stringify(date))
  // console.log('summary date 0 >>', moment(date).format('DD MMM YYYY'))
  const _date = JSON.stringify(date)
  return (
    <>
      <footer className="summary">
        <div className="box-1">
          <GridMain >
          {/* {JSON.stringify(step1)} */}
          {/* {JSON.stringify(step2)} */}
          
            <GridRap isSmallSize>
              <div className="flex flex-col justify-between">
                  <div className="flex gap-[8px] mb-[8px] justify-between">
                    <InfoText img={IconUser} title={`${t.bookingSummaryFooter0.guest}`.replace('$number', Object.keys(guestData).length)} value={""} noColon={true}/>
                    <div className="flex gap-[8px]">

                    <InfoText img={IconCalendar} title={t.bookingSummaryFooter0.date} value={moment(_date, 'YYYY-MM-DDTHH:mm:ss.sssZ' ).format('DD MMM YYYY')}/>
                    { step === 3 && (<InfoText img={IconTime} title={t.bookingSummaryFooter0.time} value={dataDetail.time ? helper.TIME.toShortTime(dataDetail.time) : dataDetail.time}/>)}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <InfoText img={IconMarker} title={t.bookingSummaryFooter0.location} value={branchName}/>
                    <InfoText title={t.bookingSummaryFooter0.selectTreatments} componentsValue={<a className="ml-[8px] underline cursor-pointer" onClick={() => setModalPoint(true)}>{t.bookingSummaryFooter0.checkHere}</a>}/>
                  </div>

                <div>
                  <div className="line my-[8px]"></div>
                  <div className='flex  justify-between'>
                    <InfoText styleList="00"  title={t.bookingSummaryFooter0.totalDuration} value={`${ allPrice ? zeroToDash(helper.FN.toNumberWithCommas(allMaxPeriod)) : "-" } min`}/>
                    <InfoText title={t.bookingSummaryFooter0.totalPrice} styleList="0" value={`${ allPrice ? zeroToDash(helper.FN.toNumberWithCommas(allPrice)) : "-" } THB`}/>
                  </div>
                </div>
              </div>
            </GridRap>
          </GridMain>
        </div>
        <div className="box-2">
          <GridMain>
            <GridRap isSmallSize>
              <div className="flex justify-center gap-[16px] md:gap-[36px]">
                <ButtonPrimaryOutline 
                  addClass=""
                  onClick={handleBack}
                >
                 {t.bookingSummaryFooter0.btnBack}
                </ButtonPrimaryOutline>
                <ButtonPrimary 
                  addClass=""
                  onClick={handleSave} disabled={isNextStep}
                >
                  {t.bookingSummaryFooter0.btnNext}
                </ButtonPrimary>
              </div>
            </GridRap>
          </GridMain>
        </div>
      </footer>
      <PopupTreament open={modalPoints} onChange={(e)=> setModalPoint(e) } />
    </>
  );
}

const InfoText = ({ img , title, value = "-" , className = "", styleList = null, componentsValue, noColon = false}) => {
  let pClassName = ""
  let spClassName = ""
  if (styleList == 0) {
    pClassName = "md:text-right md:w-[200px]"
    spClassName = "md:ml-[40px]"
  }
  if (styleList == "00") {
    pClassName = "md:text-left md:w-[200px]"
    spClassName = "md:ml-[40px]"
  }
  return (
      <div className={`flex gap-[4px] items-center ${className}`}>
        { img ? <img className="w-auto h-[16px]" src={img} alt="img-info" /> : <></> }
        <p className={`text-[12px] md:text-[14px] ${pClassName}`}>{title}{ noColon ? '' : ':' } 
        {
          componentsValue
          ? componentsValue
          : <span className={`ml-[2px] text-Neutral-A800 font-medium ${spClassName}`}>{value}</span>
        }
          
        </p>
        
      </div>
  )
}