import React, { useState } from "react";
import ModalBase from "../../components/Modal/ModalBase";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import alipay from "../../assets/images/booking/payment/alipay.svg"
import close from "../../assets/images/booking/payment/close.svg"
import helper from "../../services/helper";

export default function ModalPay({ open = false, onClose, onSave, amount = 0 }) {
  return (
    <>
      <ModalBase
        mini={true}
        open={open}
        onClose={() => {}}
        onSave={() => {}}
        size="size4"
        header={
          <></>
        }
        content={
          <div className="flex flex-col justify-center">
            <div className="modal-pay">
              <div className="hp-header">
                <h1 className="mp-title">HEALTHLAND</h1>
                <img className="mp-close" src={close} alt={"close"} onClick={() => onClose()} />
              </div>
              <div className="mp-content">
                <div className="one">
                  <img src={alipay} alt={"alipay"}/>
                  <p>Alipay</p>
                </div>
                <div className="two">
                  <p className="text-center text-[16px] md:text-[16px]">Grand Total</p>
                  <span>{helper.FN.toNumberWithCommas(amount)} THB</span>
                </div>
                <div className="three">
                  <ButtonPrimary addClass="max-w-[224px]"
                    onClick={() => {
                      if (onSave) {
                        onSave()
                      }
                    }}
                  >
                    Pay {helper.FN.toNumberWithCommas(amount)} THB
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        }
        footer={
          <>
            
          </>
        }
      />
    </>
  );
};