import React, { useEffect, useState } from 'react'
import useTranslations from '../../i18n/useTranslations';
import { useNavigate } from 'react-router';
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import GridRap from '../../components/Layouts/GridRap';
import BookingItem from '../../components/general/BookingItem';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import { SearchBookingHistory } from "../../services/Booking";
import moment from 'moment';
import ButtonFilter from './HistoryParts/ButtonFilter';
import ModalFilter from './HistoryParts/ModalFilter';
import _ from 'lodash';
import ProfileBox from '../Profile/ProfileBox';
import ButtonBack from '../../components/button/ButtonBack';
import TitleBack from '../../components/general/TitleBack';
import NotFoundBooking from '../MyBooking/NotFoundBooking';

const defFilter = { time: moment(), status: null, location: null }
export default function HistoryPage() {
  const { t } = useTranslations();
  const [isOpen, setIsOpen] = React.useState(false)
  const [bookingList, setBookingList] = React.useState([])

  const [filter, setFilter] = useState(defFilter);


  const getBookingHistory= async (payload) => {
    try {
      const { data } = await SearchBookingHistory({ params: payload });
      if (data.resultCode === 20000) {
        const resData = data.body
        console.log('resData >>', resData)
        setBookingList(resData);
      }
    } catch (e) {
      console.log(`🦄: getBookingHistory -> e`, e);
    }
  };

  useEffect(() => {
    let _YearMonth = null
    if (filter.time) {
      _YearMonth = _.cloneDeep(filter.time.format('YYYY-MM'))
    }
    const payload = {
      yearMonth: _YearMonth,
      bookingStatus: filter.status,
      branchId: filter.location,
    }
    console.log('useEffect payload >>', payload)
    getBookingHistory(payload)

  }, [filter])

  return (
    <>
      <WebContainer wfull={true} bgGraphics={true}>
        <GridMain className="">
          <GridRap>
            <div className="content-full box-content-space">
              <div className="profile-container ">
                <ProfileBox showDesktop/>
                <div className="booking-control">
                  <div className="bgk-container bg-white h-[100%]">
                    <TitleBack title={t.history.History}/>
                    <div className="flex flex-col gap-[24px] md:gap-[32px]">
                    {
                      bookingList.length
                        ? (
                          <>
                            <div className="mb-[24px] md:mb-[48px]">
                              <div className="flex justify-between md:pb-[16px] mb-[20px] md:mb-[24px] md:border-b-[1px] border-Neutral-A300">
                                <h5 className="text-[14px] md:text-[20px] font-medium ">{t.history.pastBookings}</h5>
                                <ButtonFilter onClick={() => setIsOpen(true)}/>
                              </div>
                              <div className="flex flex-col gap-[16px] md:gap-[16px]">
                                {
                                  bookingList.map(item => {
                                    return (
                                      <>
                                      {/* {item.bookingStatus} */}
                                      <BookingItem
                                        bookingId={item.bookingId}
                                        styleList="off"
                                        date={moment(item.bookingDate).format("YYYY-MM-DD")}
                                        startTime={moment(item.startTime,"HH:mm:ss").format("HH.mm")}
                                        location={item.branchName}
                                        guest={item.guestCount}
                                        treatment={item.treatmentCount}
                                        type={item.bookingStatus}
                                        link="/history-detail"
                                      />
                                      </>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </>
                        )
                        : (
                          <div className="w-full">
                            <ButtonFilter onClick={() => setIsOpen(true)}/>
                            <NotFoundBooking 
                              title={t.history.BookingDesc1}
                              subTitle0={t.history.BookingDesc2}
                              subTitle1={t.history.BookingDesc3}
                              btnText={t.history.Booking}
                            />
                          </div>
                        )
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
      <ModalFilter 
        value={filter} 
        open={isOpen} 
        onClose={() => setIsOpen(false)} 
        onChange={(v)=> {
          setFilter(v)
          setIsOpen(false)
        }}
      />
    </>
  )
}