import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import helper from '../../services/helper';
import { SearchLineId, SearchMemberById } from "../../services/Member";

const AuthGuard = ({ component }) => {
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(true)
    useEffect(() => {
        console.log("Auth Guard");
        // alert("Auth Guard")
        const isLogin = helper.Auth.isLOGIN();

        if (!isLogin) {
            window.location.href = `/login?=redirect=${window.location.pathname}`
        } else {
            setLoading(false)
        }
    }, [component]);

    if (loading) return <></>

    return <React.Fragment>{component}</React.Fragment>
}

export default AuthGuard;