import React, { useState } from "react";
import AvatarProfile from "../../assets/images/Avatar-Profiles.svg";
import Arrowdown from "../../assets/images/arrowdown.svg";
import "../../assets/styles/Profile.css";
import helper from "../../services/helper";
import { Link } from "react-router-dom";
import useTranslations from "../../i18n/useTranslations";

import arrow from '../../assets/images/arrow-list.svg'
import booking from '../../assets/images/svg/booking.svg'
import history from '../../assets/images/svg/history.svg'
import memberShip from '../../assets/images/svg/membership.svg'
import setting from '../../assets/images/svg/setting.svg'
import logout from '../../assets/images/svg/logout.svg'



const MyProfiles = ({ options = [], onChange, data }) => {
  const { t } = useTranslations()
  const [isOpen, setIsOpen] = useState(false);

  const active = isOpen ? " active" : "";

  const menuList = [
    // { name: "Edit Profile", link: "" },
    { imgLink: booking, name: t.header.menu.profile, link: "/profile" },
    { imgLink: booking, name: t.header.menu.booking, link: "/my-booking" },
    { imgLink: booking, name: t.header.menu.privacyPolicy, link: "/privacy-policy" },
    { imgLink: memberShip, name: t.header.menu.termsAndConditions, link: "/term-condition" }
  ]

  return (
    <div className="flex items-center md:mr-[0px] md:ml-[20px]">
      <a className=" z-80" href="/profile">
        <img
          className="imgProfile-bar mr-[22px] md:mr-[0px]"
          src={data.imagePicture ? data.imagePicture : AvatarProfile}
          alt=""
        />
      </a>
      <div
        className={` hidden md:block c-multi-select-dropdown cursor-pointer ${active}`}
        onClick={() => setIsOpen((pre) => !pre)}
      >
        <div className="c-multi-select-dropdown__selected">
          <p className="text-[16px] HelveticaNeue pl-[10px]">{t.header.myProfile}</p>
          <img
            className="arrow"
            src={Arrowdown}
            alt=""
            style={{ height: 8, color: "red" }}
          />
        </div>
        <ul className="c-multi-select-dropdown__options">
          {
            menuList.map(item => {
              return (
                <li className="c-multi-select-dropdown__option logoutHover">
                  <div className="flex justify-start items-center gap-[8px]">
                    <Link to={item.link}  className="hidden md:block w-full max-w-auto md:max-w-[128px] text-[!#000] text-[14px] text-left">
                      <div className="spece-list">
                        {/* <img className="max-w-[10px]" src={item.imgLink}/> */}
                        <p className="text-[13px]">{item.name}</p>
                      </div>
                    </Link>
                    {/* <div size="mini" addClass="hidden md:block ml-[24px] w-full max-w-auto md:max-w-[128px]">
                      {item.name}
                    </div> */}
                  </div>
                </li>
              )
            })
          }
          <li
            className="c-multi-select-dropdown__option logoutHover"
            onClick={() => {
              helper.Auth.Logout();
            }}
          >
            <div className="flex justify-start items-center gap-[8px]">
              <div
                size="mini"
                addClass="hidden md:flex ml-[24px] w-full max-w-auto md:max-w-[128px] gap-[8px]"
              > 
                <div className="spece-list">
                  {/* <img className="max-w-[12px]" src={logout}/> */}
                  <p className="text-[13px]">{t.header.menu.logout}</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default MyProfiles;
