import React, { useState } from "react";
import Steppers from "../../components/Stepper/Steppers";
import useTranslations from "../../i18n/useTranslations";

export default function StepBanner({ current = 1 }) {
  const { t } = useTranslations();
  const stepItems = [
    t.bookingStepper.step1,
    t.bookingStepper.step2,
    t.bookingStepper.step3,
    t.bookingStepper.step4,
    t.bookingStepper.step5
  ];

  return (
    <>
      <Steppers activeStep={current} items={stepItems} alternativeLabel />
    </>
  );
}
