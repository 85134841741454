import React, { useEffect } from 'react'
import useTranslations from '../../i18n/useTranslations';
import { useNavigate } from 'react-router';
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import GridRap from '../../components/Layouts/GridRap';
import BookingItem from '../../components/general/BookingItem';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import { SearchMyBookingList } from "../../services/Booking";
import moment from 'moment';
import ProfileBox from '../Profile/ProfileBox';
import ButtonBack from '../../components/button/ButtonBack';
import TitleBack from '../../components/general/TitleBack';
import NotFoundBooking from './NotFoundBooking';


export default function MyBooking() {
  const { t } = useTranslations();
  const [bookingList, setBookingList] = React.useState([])
  const [booking0, setBooking0] = React.useState([])

  const getMyBookingList= async (memberId) => {
    try {
      const { data } = await SearchMyBookingList({ params: {} });
      if (data.resultCode === 20000) {
        const resData = data.body
        const _booking0 = resData.filter((item, i) => i === 0)
        const _bookingList = resData.filter((item, i) => i > 0)
        setBooking0(_booking0)
        setBookingList(_bookingList);
      }
    } catch (e) {
      console.log(`🦄: getMyBookingList -> e`, e);
    }
  };

  useEffect(() => {
    getMyBookingList()
  }, [])

  return (
    <>
      <WebContainer wfull={true} bgGraphics={true}>
        <GridMain className="">
          <GridRap>
            <div className="content-full box-content-space">
              <div className="profile-container ">
                <ProfileBox showDesktop/>
                <div className="booking-control">
                  <div className="bgk-container bg-white h-[100%]">
                    <TitleBack title={t.history.MyBooking}/>
                    <div className="flex flex-col gap-[24px] md:gap-[32px]">
                    {
                      booking0.length
                        ? (
                          <>
                            <div className="">
                              <h5 className="bk-title-box mb-[16px] md:mb-[16px]">{t.myBooking.upcomingBookings}</h5>
                              {
                                booking0.map(item => {
                                  return (
                                    <BookingItem
                                      bookingId={item.bookingId}
                                      styleList="on"
                                      date={moment(item.bookingDate).format("YYYY-MM-DD")}
                                      startTime={moment(item.startTime,"HH:mm:ss").format("HH.mm")}
                                      location={item.branchName}
                                      guest={item.guestCount}
                                      treatment={item.treatmentCount}
                                    />
                                  )
                                })
                              }
                              
                            </div>
                            <div className="">
                              <h5 className="bk-title-box mb-[16px] md:mb-[16px]">{t.myBooking.bookings}</h5>
                              <div className="flex flex-col gap-[16px]">
                                {
                                  bookingList.map(item => {
                                    return (
                                      <BookingItem
                                        bookingId={item.bookingId}
                                        styleList="off"
                                        date={moment(item.bookingDate).format("YYYY-MM-DD")}
                                        startTime={moment(item.startTime,"HH:mm:ss").format("HH.mm")}
                                        location={item.branchName}
                                        guest={item.guestCount}
                                        treatment={item.treatmentCount}
                                        type="more"
                                      />
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </>
                        )
                        : (
                          <NotFoundBooking 
                            title={t.history.BookingDesc1}
                            subTitle0={t.history.BookingDesc2}
                            subTitle1={t.history.BookingDesc3}
                            btnText={t.history.Booking}
                          />
                        )
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
    </>
  )
}

