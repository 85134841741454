import React from 'react';
import styled from 'styled-components';

import arrow from '../../assets/images/arrow-list.svg'
import booking from '../../assets/images/svg/booking.svg'
import history from '../../assets/images/svg/history.svg'
import memberShip from '../../assets/images/svg/membership.svg'
import setting from '../../assets/images/svg/setting.svg'
import logout from '../../assets/images/svg/logout.svg'

const StyledListTileProfile2 = styled.div`
  position: relative;
  padding: 10px 32px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #343A40;
  @media (min-width: 768px) {
    font-size: 16px;
  }

  &:hover {
    /* background-color: #f0f0f0; */
  }

  &:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 20px;
    height: 20px;
    background: palevioletred;
    /* background: url("../../assets/images/arrow-list.svg") center center no-repeat; */
    background-size: auto 100%;
    transition: all 0.3s ease;

    top: 50%;
    transform: translateY(-50%);
  }
  &:after {
    position: absolute;
    content: "";
    right: 0px;
    top: 50%;
    width: 6px;
    height: 12px;
    background: palevioletred;
    background: url(${arrow}) center center no-repeat;
    background-size: auto 100%;
    transition: all 0.3s ease;
    transform: ${(props) => (props.isSelected ? 'translateY(-50%) rotate(90deg)' : ' translateY(-50%) rotate(0deg)')};
  }

  &[type="booking"]&:before {
    background: url(${booking}) center center no-repeat;
  }
  &[type="history"]&:before {
    background: url(${history}) center center no-repeat;
  }
  &[type="memberShip"]&:before {
    background: url(${memberShip}) center center no-repeat;
  }
  &[type="setting"]&:before {
    background: url(${setting}) center center no-repeat;
  }
  &[type="logout"]&:before {
    background: url(${logout}) center center no-repeat;
  }
  
  &[active="true"]&:before {
    transform: rotate(90deg);
  }

  /* &.active::after {
    transform: rotate(90deg);
  } */

  @media (min-width: 768px) {
    /* Adjust styles for screens wider than 768px */
    flex-direction: row;
  }
`;

const StyledListTileProfile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media (min-width: 1024px) {
    gap: 16px;
  }
  > .head {
    width: 15px;
    height: 15px;
    /* background: palevioletred; */
    transition: all 0.3s ease;

    background-size: 15px !important;
    
    @media (min-width: 1024px) {
      width: 24px;
      height: 24px;
      background-size: 24px !important;
    }

    &[type="booking"] {
      background: url(${booking}) center center no-repeat;
    }
    &[type="history"] {
      background: url(${history}) center center no-repeat;
    }
    &[type="memberShip"] {
      background: url(${memberShip}) center center no-repeat;
    }
    &[type="setting"] {
      background: url(${setting}) center center no-repeat;
    }
    &[type="logout"] {
      background: url(${logout}) center center no-repeat;
    }
  }

  > .content {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    color: #343A40;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  > .tail {
    width: 6px;
    height: 12px;
    background: palevioletred;
    background: url(${arrow}) center center no-repeat;
    background-size: auto 100%;
    transition: all 0.3s ease;
    transform: ${(props) => (props.isSelected ? 'rotate(90deg)' : ' rotate(0deg)')};
  } 
`;



const ListTileProfile = ({ type, title, isSelected = false, onClick, showMobile }) => {
  let twStyle = `` 
  if (showMobile) {
    twStyle = `flex md:!hidden` 
  }
  return (
    <StyledListTileProfile className={twStyle} isSelected={isSelected} type={type}  onClick={onClick}>
      <div className="head" type={type}/>
      <div className="content">
        {title}
      </div>
      <div className="tail"/>
    </StyledListTileProfile>
  );
};

export default ListTileProfile;