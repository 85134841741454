import React from "react";
import { useLiff } from "react-liff";
import { useNavigate } from "react-router-dom";
import WebContainer from "../components/Layouts/WebContainer";
import GridMain from "../components/Layouts/GridMain";
import GridRap from "../components/Layouts/GridRap";
import SuccessPage from "../components/Layouts/SuccessContent";
import PaymentFailedSrc from "../assets/images/svg/error-page/payment-fail.svg";
import ButtonPrimary from "../components/button/ButtonPrimary";
import useTranslations from '../i18n/useTranslations';

export default function PaymentFailed() {
  const navigate = useNavigate()
  const { t } = useTranslations();
  return (
    <>
      <WebContainer bgGraphics={false} >
        <SuccessPage>
          <div className="flex-1">
            <GridMain className="h-full flex flex-col text-center justify-center items-center" >
              <div className="flex flex-col text-center justify-center items-center h-full">
                <img className="mb-[8px] md:mb-[16px]" src={PaymentFailedSrc} alt="" />
                <h5 className="text-[20px] md:text-[20px] text-[#212529]">{t.paymentFailed.pagePaymentFailed}</h5>
                <div className="flex flex-col gap-[4px] mt-[5px] mb-[32px]">
                  <p className="text-[14px] md:text-[16px] text-[#6C757D]">{t.paymentFailed.subTitle0}<br className=""/>{t.paymentFailed.subTitle1}</p>
                </div>
                <ButtonPrimary onClick={() => navigate('/booking-step1')}>{t.paymentFailed.goBack}</ButtonPrimary>
              </div>
            </GridMain>
          </div>
        </SuccessPage>
      </WebContainer>
    </>
  );
}
