import Swal from 'sweetalert2';

export default {
  AlertInfo: ({
    title = 'title',
    titleClass = '',
    subtitle = 'subtitle',
    showfooter = false,
    bottomTxt = 'Don’t have an account yet?',
    bottomLink = 'Sign up',
    btnText = 'Back',
    callBackOk = () => { },
    linkCallback = () => { }
  }) => {
    Swal.fire({ 
      allowOutsideClick:false,
      title: title,
      customClass: ` ${titleClass} `,
      html: `
        <div class="sw-subtitle">
          ${subtitle}
         
        </div>
      `, 
      icon: '',
      confirmButtonText: btnText,
      footer: showfooter ? `
        <div class="under-detail">
          ${bottomTxt}
          <span id="_LKBTN" class="sw-link">${bottomLink}</span>
        </div>
      `: ``
    }).then((result) => {
      if (result.isConfirmed) {
        callBackOk()
      }
    })

    if (showfooter) {
      document.getElementById('_LKBTN').addEventListener('click', () => {
        linkCallback()
        Swal.clickConfirm();
      });
    }
  
  },
  AlertError: async ({
    errorCode = '50000',
    title = 'title',
    subtitle = 'subtitle',
    callback = () => { }
  }) => {
    Swal.fire({ 
      allowOutsideClick: false,
      title: title, 
      html: `
        <div class="sw-subtitle mb-[2px]">
          ${subtitle}
        </div>
      `, 
      icon: '',
      confirmButtonText: 'Close'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        callback()
      } 
    })
  }
 
};
