  const config = () => {
    return {
      BASE_URL: process.env.REACT_APP_BASE_URL,
      LIFF_ID: process.env.REACT_APP_LIFF_ID,
      GOOGLE_TAG: process.env.REACT_APP_GOOGLE_TAG,
      SOCIAL: {
        API_KEY: process.env.REACT_APP_API_KEY,
        AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
        PROJECT_ID: process.env.REACT_APP_PROJECT_ID,
        STORAGE_BUCKET: process.env.REACT_APP_STORAGE_BUCKET,
        MESSAGING_SENDER_ID: process.env.REACT_APP_MESSAGING_SENDER_ID,
        APP_ID: process.env.REACT_APP_APP_ID,
        MEASUREMENT_ID: process.env.REACT_APP_MEASUREMENT_ID
      },
      PAYMENT_GETEWAY: {
        SRC: process.env.REACT_APP_PAYMENT_GETEWAY_SRC,
        DATA_APIKEY: process.env.REACT_APP_PAYMENT_GETEWAY_DATA_APIKEY,
        DATA_APIMID_CARD: process.env.REACT_APP_PAYMENT_GETEWAY_DATA_APIMID_CARD,
        DATA_APIMID_QR: process.env.REACT_APP_PAYMENT_GETEWAY_DATA_APIMID_QR,
        DATA_APIMID_ARIPAY: process.env.REACT_APP_PAYMENT_GETEWAY_DATA_APIMID_ARIPAY,
        DATA_APIMID_WE_CHAT: process.env.REACT_APP_PAYMENT_GETEWAY_DATA_APIMID_WE_CHAT,
        DATA_NAME: process.env.REACT_APP_PAYMENT_GETEWAY_DATA_NAME,
      }
    };
  }
  export default {
    config
  }
  
  