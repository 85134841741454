import {createSlice} from '@reduxjs/toolkit'

const defaultData = {
  loading: false,
}

export const LoadingSlice = createSlice({
  name: 'Loading',
  initialState: defaultData,
  reducers: {
    setLoading: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetLoading: () => defaultData
  },
})

// Action creators are generated for each case reducer function
export const {
  setLoading,
  resetLoading
} = LoadingSlice.actions

export default LoadingSlice.reducer