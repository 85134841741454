import React, { useState, useEffect } from "react";
import LogoHeader from "../../assets/images/health-land-logo.png";
import BackIcon from "../../assets/images/back-icon.svg";
import Translate from "../translate/Translate";
import GridMain from "./GridMain";
import helper from "../../services/helper";
import { useNavigate } from "react-router-dom";
import MyProfiles from "../general/MyProfile";
import VConsole from "vconsole";
import { useDispatch, useSelector } from 'react-redux';
import { setProfile } from "../../features/ProfileSlice"
import { SearchMemberById } from "../../services/Member";
import GridRap from "./GridRap";
import ButtonPrimary from "../button/ButtonPrimary";
import ButtonPrimaryOutline from "../button/ButtonPrimaryOutline";
import useTranslations from "../../i18n/useTranslations";
import LoadTreatments from "./LoadTreatments";

export default function TopLogo({ noLogin = false }) {
  const { t } = useTranslations();
  // const [vCon, setVCon] = useState(new VConsole());
  const isLogin = helper.Auth.isLOGIN();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile) // ไว้ดึง state
  const dispatch = useDispatch() // ไว้อัพเดต state กลาง

  useEffect(() => {
    const isLogin = helper.Auth.getToken();
    const data = helper.Auth.getTokenData();
    console.log('profile >> 0 ', profile)
    if (profile.isLoading === false) {
      if (isLogin) {
        getSearchMemberById(data.memberId);
      } else {
        const tmpObj = { id: 0, isLoading: true }
        dispatch(setProfile(tmpObj)); // set เข้า state กลาง
      }
    }
  }, []);

  const getSearchMemberById = async (memberId = null) => {
    try {
      const { data } = await SearchMemberById({ memberId: memberId });
      if (data.resultCode === 20000) {
        // setProfile(data.body);
        const tmpObj = {
          ...data.body, isLoading: true
        }
        dispatch(setProfile(tmpObj)); // set เข้า state กลาง
      } else if (data.resultCode === 40401) {
        helper.Auth.Logout('/login')
      }
    } catch (e) {
      console.log("ERR getSearchMemberById", e);
    }
  };

    // แก้ NAV ขยับไปมา FIX CARD #188
    useEffect(()=> {
      function handleBodyStyleChange() {
        // Your code here
        var bodyElement = document.body;
        bodyElement.style.paddingRight = "0px";
       
      }
      
      // Create a new MutationObserver instance
      const observer = new MutationObserver(function (mutationsList) {
        for (let mutation of mutationsList) {
          if (mutation.target === document.body && mutation.type === "attributes" && mutation.attributeName === "style") {
            // Call the function when body.style changes
            handleBodyStyleChange();
          }
        }
      });
      
      // Start observing the body for attribute changes
      observer.observe(document.body, { attributes: true });
    })


  if (noLogin) {
    return (
      <>
        <div id="header-nav" className="header-nav">
          <GridMain className="bg-white border-b-[1px] border-Neutral-300">
            <GridRap isSmallSize>
              <nav className="w-full flex  text-center top-logo bg-white p-[0px]">
                <div>
                  <a href="/">
                    <img
                      className="max-w-[33px] md:max-w-[60px]"
                      src={LogoHeader}
                      alt="logo"
                    />
                  </a>
                </div>
                <div className="flex-1 flex justify-end items-center">
                  <Translate />
                </div>
              </nav>
            </GridRap>
          </GridMain> 
        </div>
      </>
    )
  }

  
  return (
    <>
      <LoadTreatments/>
      <div id="header-nav" className="header-nav">
        <GridMain className="bg-white border-b-[1px] border-Neutral-300">
          <nav className="w-full flex  text-center top-logo bg-white p-[0px]">
            {
              [ '/', '/booking-step1' ].includes(window.location.pathname)
              ?  <div className="pl-[14px] flex-1 flex justify-start md:hidden"></div>
              : (
                <div className="pl-[14px] flex-1 flex justify-start md:hidden">
                  <button onClick={() => navigate(-1)}>
                    <img src={BackIcon} alt="" />
                  </button>
                </div>
              )
            }
            <div>
              <a href="/">
                <img
                  className="max-w-[33px] md:max-w-[60px]"
                  src={LogoHeader}
                  alt="logo"
                />
              </a>
            </div>
            <div className="flex-1 flex justify-end items-center">
              {isLogin ? (
                <>
                  <ButtonPrimary
                    size="mini"
                    addClass="hidden md:block ml-[16px]"
                    onClick={() => {
                      navigate("/booking");
                    }}
                  >
                    <p className="text-[16px]">{t.header.bookingNow}</p>
                  </ButtonPrimary>
                  <MyProfiles data={profile} />
                </>
              ) : (
                <>
                  <ButtonPrimaryOutline
                    size="mini"
                    addClass="hidden md:block ml-[16px] mr-[24px]"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {t.header.signIn}
                  </ButtonPrimaryOutline>
                  <ButtonPrimary
                    size="mini"
                    addClass="hidden md:block mr-[36px]"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    {t.header.signUp}
                  </ButtonPrimary>
                </>
              )}
              <Translate />
            </div>
          </nav>
        </GridMain>
      </div>
    </>
  );
}
