import moment from "moment";
import helper from './helper'
import _ from "lodash";
import sign from 'jwt-encode';
import jwt_decode from "jwt-decode";
 
export default {
  CONFIG: {
    //  CONFIG API
    MAX_SIZE_FILE: 1024000,
    IMG_TYPE_FILE: ['image/png', 'image/jpeg', 'image/jpg'],
  
  },
  encodeJWT: (data = {}, secret = 'secret') => {
    const encode = sign(data, secret);
    return encode
  },
  decodeJWT: (token = '') => {
    const decode = jwt_decode(token);
    return decode
  },
  GEN: {
   YEAR_OPTION: () => {
    const { arr1DToDownDown, range } = helper.FN
    return arr1DToDownDown(range(2015, +moment().format('YYYY')))
   }
  },
  OPTIONS: {
    GENDER: [
      { value: 1, label: "Male" },
      { value: 2, label: "Female" },
      { value: 3, label: "Non-Specified" },
    ],
    GENDERLANG:(lang = "") =>{
      let option = [
        { value: 1, label: "Male" },
        { value: 2, label: "Female" },
        { value: 3, label: "Non-Specified" },
      ]
      if (lang === "th") {
        option = [
          { value: 1, label: "ชาย" },
          { value: 2, label: "หญิง" },
          { value: 3, label: "ไม่ระบุ" },
        ]
      } else if (lang === "cn") {
        option = [
          { value: 1, label: "男性" },
          { value: 2, label: "女性" },
          { value: 3, label: "非指定" },
        ]
      }
      return option
    }
  },

  FN: {
    numberToABCTH: (number = 0) => {
      let letters = ['ก', 'ข', 'ค', 'ง', 'จ', 'ฉ'];
      if (letters[number - 1]) {
        return letters[number - 1]
      } 
      return ''
    },
    removeUndefined: (obj)=> {
      Object.keys(obj).forEach(key => obj[key] === undefined || obj[key] === '' || obj[key] === '[]' ? delete obj[key] : {});
      return obj
    },
    range: (start, end) => {
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    arr1DToDownDown: (arr = []) => {
      let dd = [];
      for (let a of arr) {
        dd.push({ id: a, name: a + 543 });
      }
      return dd;
    },
    objectToQueryString: (obj)=> {
      
      var queryString = "";
      let i = 0
      const maxLen = Object.keys(obj).length
      // 
      for (var key in obj) {
        // 
        // 
       

        if (obj[key].length) {
          // queryString += encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
          queryString += encodeURIComponent(key) + "=" + obj[key].join(',');
        }

        if (obj.hasOwnProperty(key)) {
          if (obj[key].length > 0) {
            queryString += "&";
          }
        }

        i++;
      }
      queryString = queryString.substring(0, queryString.length - 1)
      
      return queryString ? "?" + queryString: '';
    },
   
    toNumberWithCommas: (x = '') => {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return '0'
    },
    toArrayNumber: (arr = []) => {
      return arr.map(item => Number(item)).filter(item => item)
    },
    numberWithZero: (num = '', pad = 4) => {
      var str = "" + num
      var padStr = ""
      for(let i = 0; i < pad; i++) {
        padStr += "0"
      }
      return padStr.substring(0, padStr.length - str.length) + str
    },
    gotoPage: (url) => {
      if (url) window.open(url, "_blank");
    },
    splitText: (str = '', len = 255,) => {
      // if (url) window.open(url, "_blank");
      if (str.length <= len) {
        return str
      }
      return str.substring( 0, len ) + '...'
    },
    formatIdcard: (number, useStar = false) =>{
      const asteriskIndices = [10, 11, 12, 13, 14]; // Indices to replace with asterisks
      const indexSpace = ['1','5','10','12']; // Indices to replace with asterisks
      const digits = number.split('');
      // Replace the specified indices with asterisks
      let formattedNumber = ''
      for (let i in number) {
        const n = number[i]
        // formattedNumber += i + ' '
        if(indexSpace.includes(i)) {
          formattedNumber += ' '
        }
        if(useStar && i >= 8) {
          formattedNumber += '*'
        } else {
          formattedNumber += n 
        }
      }
      return formattedNumber;
    },
    
    getQueryStringParams:(url)=> {
      const queryString = url.split('?')[1];
      if (!queryString) return {};
    
      const paramsArray = queryString.split('&');
      const params = {};
    
      paramsArray.forEach(param => {
        const [key, value] = param.split('=');
        params[key] = decodeURIComponent(value);
      });
    
      return params;
    },
    detectBoxContent: (selectorCSS, callBack, realTime = false) => {
      if (!!window.IntersectionObserver) {
        let observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                
                callBack()
                if (realTime === false) {
                  observer.unobserve(entry.target);
                }
              }
            });
          },
          { rootMargin: "0px 0px -200px 0px" }
        );
        // Add the observer to you swiper
        if (document.querySelector(selectorCSS)) {
          observer.observe(document.querySelector(selectorCSS));
        }
      } else {
        // You can use the polyfill or just start the autoplay
      }
    },
    generateRandomNumber:(n = 10)=> {
      return Math.floor(Math.random() * n);
    },
    zeroToDash(value) {
      if (value == 0 || !value) {
        return "-"
      } 
      return value
    },
    encodeBase62: (num) => {
      const BASE62 = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";
      while (num > 0) {
        result = BASE62[num % 62] + result;
        num = Math.floor(num / 62);
      }
      return result || "0"; // กรณี num เป็น 0
    },
    decodeBase62: (str) => {
      const BASE62 = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = 0;
      for (let i = 0; i < str.length; i++) {
        result = result * 62 + BASE62.indexOf(str[i]);
      }
      return result;
    }
  },
  TIME: {
    toShortAllDateTH: (dateshow) => {
      if (dateshow) {
        const YearTHShort = `${(Number(moment(dateshow).format('YYYY')) + 543)}`.slice(2,4)
        return moment(dateshow).format('DD MMM ') + YearTHShort
      }
      return '' 
    },
    toDateNormalEN: (dateshow) => {
      if (dateshow) {
        return moment(dateshow).format('DD MMM YYYY')
      }
      return '' 
    },
    toShortDateNormal: (dateshow) => {
      if (dateshow) {
        return moment(dateshow).format('DD/MM/YYYY')
      }
      return '' 
    },
    toShortTime: (dateshow) => {
      if (dateshow) {
        return moment(dateshow, 'HH:mm:ss').format('HH.mm')
      }
      return '' 
    },
    toShortTimeAmPm: (dateshow) => {
      if (dateshow) {
        return moment(dateshow, 'HH:mm:ss').format('h.mm A')
      }
      return '' 
    }
  },
  FORMAT: {
    TEL: (txt = '') => {
      return txt.replace(/^(\d{3})(\d{3})(\d{4})$/, "$1-$2-$3");
    }
  },
  SORT: {
    compare: (a, b, key) => {
      if ( _.get(a,key) < _.get(b,key) ){
        return -1;
      }
      if ( _.get(a,key) > _.get(b,key) ){
        return 1;
      }
      return 0;
    },
  },

  FNFORM: {
    handleNumberOnly: (e) => {
      const numericValue = e.target.value.replace(/[^0-9]/g, '');
      e.target.value = numericValue;
    },
    handleCharOnly: (e) => {
      const charValue = e.target.value.replace(/[^@_.\s0-9a-zA-Zก-์]/g, '');
      e.target.value = charValue;
    },
    idCardCheck: (idCardNumber) => {
      let sum = 0;
      for (let i = 0; i < idCardNumber.length - 1; i++) {
        sum += parseInt(idCardNumber[i]) * (13 - i);
      }
      
      let checkDigit = (11 - (sum % 11)) % 10;
      
      if (checkDigit == idCardNumber[12]) {
        return true;
      } else {
        return false;
      }
    }
  },
  QUIZ: {
    calPercent: (type, score, fullscore) => {
      let ans = 0
      if (type === 2) {
          ans = (score * 100) / fullscore
      }
      else if (type === 3) {
          // ans = (score * 100) / 100
          ans = (score * 100) / fullscore
      }
      return ans
    },
    calText:(type, percent, range = []) => {
      let ans = 0
      if (type === 2) {
          if (percent > 66) {
              ans = 'ยอดเยี่ยม'
          } else if (percent > 33) {
              ans = 'ปานกลาง'
          } else if (percent <= 33) {
              ans = 'ควรปรับปรุง'
          }
      }
      if (type === 3) {
        const score = percent
        
        
        const _find = range.find(item => score >= item.min && score < item.max)
        return _find?.name ?? 'xxx'
      }
      return ans
    },
    getValueTagP: (html = '') => {
      // const html = '<p>สายเข้าสังคม</p>\r\n\r\n<p>เรื่องออกงานขอให้บอก ชอบเฮฮาปาร์ตี้เป็นที่สุด รักเพื่อน ใส่ใจคนรอบข้าง แถมยังมีจิตอาสา ช่วยเหลือคนอื่นอีก</p>\r\n\r\n<p>เหมาะกับอาชีพ</p>\r\n\r\n<p>คุณครู อาจารย์มหาวิทยาลัย พยาบาล นักสังคมสงเคราะห์ ดีเจ เจ้าหน้าที่ฝ่ายบุคคล</p>\r\n';
      const startTag = '<p>';
      const endTag = '</p>';
      let startIndex = html.indexOf(startTag);
      let endIndex = html.indexOf(endTag);
      let values = []
      while (startIndex !== -1 && endIndex !== -1) {
        const value = html.substring(startIndex + startTag.length, endIndex);
        
        startIndex = html.indexOf(startTag, endIndex);
        endIndex = html.indexOf(endTag, startIndex);
        values.push(value)
      }
      return values
    },
    getCoverImage: (url = '') => {
      let coverImage = ''
      if (url) {
        coverImage = 'https://sahapatadmission.com/quiz/public/uploads/quiz/images/' + url
      }
      return coverImage
    }
  },
  COLOR: {
    quizColor: (type = 2, percent, range = []) => {
      
      let COLOR = {
        PRIMARY: "#D71919",
        SECONDARY: "#D71919"
      }
      
      if (range) {
        const findColor = range.find(item => percent >= item.min && percent <= item.max)
        
        if (findColor?.color) {
          COLOR = { PRIMARY: findColor.color, SECONDARY: findColor.color }
        }
      }
      return COLOR
    }
  },
  FN2: {
    getBannerList: (bannerList = [], modtype = "thumbnail" ) => {
      return bannerList.map(item => {
        const { articleFile = [] } = item
        const desktop = articleFile.find(item2 => item2.modtype === modtype)
        return {
          name: item?.name ?? null,
          urladdress: item?.urladdress ?? null,
          // imglink: desktop?.fullurl ?? null,
          imglink: desktop?.fullurl ? desktop?.fullurl : "/assets/404-not-found.jpg",
          width: desktop?.filewidth ?? null,
          height: desktop?.fileheight ?? null
        }
      })
    },
    scrollToTop: () => {
      window.scrollTo({ top: 0, left: 0 });
    }
  },
  IMAGE: {
    async toImageSmall(fileBase64) {
      async function reduce_image_file_size(base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) {
        console.log('base64Str >>', base64Str)
        console.log('DDD')
        let resized_base64 = await new Promise((resolve) => {
            let img = new Image()
            img.src = base64Str
            img.onload = () => {
                let canvas = document.createElement('canvas')
                let width = img.width
                let height = img.height
    
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                }
                canvas.width = width
                canvas.height = height
                let ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, width, height)
                resolve(canvas.toDataURL()) // this will return base64 image results after resize
            }
        });
        return resized_base64;
      }
    
      
      async function image_to_base64(file) {
        console.log('CCC')
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.onerror = (error) => {
                console.log(error)
                alert('An Error occurred please try again, File might be corrupt');
            };
            fileReader.readAsDataURL(file);
        });
        return result_base64;
      }
      
      async function process_image(file, min_image_size = 300) {
        console.log('BBB')
        // const res = await image_to_base64(file);
        const res = file;
        if (res) {
            const old_size = calc_image_size(res);
            if (old_size > min_image_size) {
                const resized = await reduce_image_file_size(res);
                const new_size = calc_image_size(resized)
                console.log('new_size=> ', new_size, 'KB');
                console.log('old_size=> ', old_size, 'KB');
                return resized;
            } else {
                console.log('image already small enough')
                return res;
            }
    
        } else {
            console.log('return err')
            return null;
        }
      }
      
      /*- NOTE: USE THIS JUST TO GET PROCESSED RESULTS -*/
      // async function preview_image() {
      //     console.log('00')
      //     const file = document.getElementById('file');
      //     const image = await process_image(file.files[0]);
      //     console.log('image >>', image)
      // }
  
      function calc_image_size(image) {
        console.log('AAA')
        let y = 1;
        if (image.endsWith('==')) {
            y = 2
        }
        const x_size = (image.length * (3 / 4)) - y
        return Math.round(x_size / 1024)
      }
  
      return new Promise(async (resolve) => {
          // const file = document.getElementById('file');
          // const image = await process_image(file.files[0]);
          const tmpBase64 = `data:image/jpeg;base64,${fileBase64}`
          let imageResize = await process_image(tmpBase64);
          console.log('imageResize >>', imageResize)
          // imageResize = `${imageResize}`.replace('data:image/png;base64,','')
          resolve(imageResize)
      })
    }
  },
  Route: {
    gotoPage: (link, lineId = null) => {
      // alert('aaa')
      if (lineId) {
        return "line-" + link
      }
      return link
    }
  },
  Auth: {
    getToken: () => {
      let mytoken = localStorage.getItem("token");
      if (mytoken) {
        return `Bearer ${mytoken}`
      }
      return null
    },
    getTokenLine: () => {
      let lineToken = localStorage.getItem("sub");
      if (lineToken) {
        return `${lineToken}`
      }
      return null
    },
    getLang: () => {
      let lang = localStorage.getItem("lang");
      if (lang) {
        return `${lang}`
      }
      return null
    },
    getTokenData: () => {
      try {
        let mytoken = localStorage.getItem("token");
        const decode = jwt_decode(mytoken);
        return decode
      }
      catch(e) {
        return {}
      }
    },
    flowLOGIN: (userToken, lineId, redirect = null, navigate) => {
      console.log("🚀 ~ file: helper.js:467 ~ userToken, lineId, redirect = null:", userToken, lineId, redirect)
      if (userToken) {
        window.localStorage.setItem("token", userToken);

        setTimeout(() => {
          if (lineId) {
            // navigate(-1)
            navigate('/line-login?callback=/booking-step3', { replace: true });
          } else {
            if (redirect) {
              window.location.href = `/${redirect}`
            } else {
              window.location.href = '/profile'
            }
          }
        }, 1000)
      }
    },
    isLOGIN: () => {
      const token = window.localStorage.getItem("token");
      // console.log('token >>', token)
      return !!token
    },
    Logout: (link = '/') => {
      window.localStorage.removeItem("token");
      // console.log('logout >>', token)
      setTimeout(() => { 
        window.location.href = link
      }, 100)
      
    }
  },
  Booking: {
    toPayloadStep3(_step1 = {}, _step2 = {}) {
      _step1 = JSON.parse(JSON.stringify((_step1)))
      _step2 = JSON.parse(JSON.stringify((_step2)))
      let ansPayload = {
        branchId: null,
        treatmentDate: null,
        guest: [],
      };
    
      ansPayload.branchId = _step1.branch
      ansPayload.treatmentDate = moment(_step1.date).add(7,'hour').format('YYYY-MM-DD') // +7 ไปก่อนไม่รู้แก้ยัง

      const guestData = _step2.guestData
      ansPayload.guest =  guestData.map(item => {
        let tmp =  {
            guestNumber: item.number,
            guestName: item.name,
            treatment: []
        }
        
        for (let key of Object.keys(item.values)) {
            const [ programId, treatment, subTreatment ] = `${key}`.split('|')
            const tmp2 = { treatmentId: treatment, subTreatment: { subTreatmentId: subTreatment } }
            tmp.treatment.push(tmp2)
        }
    
        return tmp
      })
      return ansPayload
    },

    toPayloadBookingStep3(_step1 = {}, memberId = null, _value = {}) {
      _step1 = JSON.parse(JSON.stringify(_step1))
      _value = JSON.parse(JSON.stringify(_value))
      let ansPayload = {
        bookingId: null,
        memberId: null,
        branchId: null,
        branchNo: null,
        treatmentDate: null,
        bookingStart: null,
        bookingEnd: null,
        detailUsedTime: []
      };
      
      ansPayload.bookingId = _step1.bookingId ? _step1.bookingId : undefined
      ansPayload.memberId = memberId
      ansPayload.branchId = _step1.branch ??_step1.branchId
      ansPayload.treatmentDate = moment(_step1.date).add(7,'hour').format('YYYY-MM-DD') // +7 ไปก่อนไม่รู้แก้ยัง
    
      ansPayload.bookingStart = _value.bookingStart
      ansPayload.bookingEnd = _value.bookingEnd
      ansPayload.detailUsedTime = _value.detailUsedTime
    
      console.log("ansPayload >>", ansPayload);
      return ansPayload
    },
    getMaxPeriodAndPrice(_programTreament = [], values = {}) {
      console.log("🚀 ~ file: helper.js:476 ~ getMaxPeriodAndPrice ~ _programTreament:", _programTreament)
      console.log("🚀 ~ file: helper.js:476 ~ getMaxPeriodAndPrice ~ values:", values)
      let maxPeriod = 0
      let price = 0
      for (const value of Object.keys(values)) {
        console.log("🚀 ~ file: helper.js:476 ~ value ~ values:", value)
        const [programId, treatmentId, subTreatmentId] = `${value}`.split('|')
        const _treatmentList = _programTreament.find(item => item.programId == programId)?.treatment ?? []
        const _subTreatmentList = _treatmentList.find(item => item.treatmentId == treatmentId)?.subTreatment ?? []
        const subTreatment = _subTreatmentList.find(item => item.subTreatmentId == subTreatmentId) ?? {}
        console.log('file: helper.js:476 subTreatment >>', subTreatment)

        // if (subTreatment.subTreatmentPeriod >= maxPeriod) {
        //   maxPeriod = subTreatment.subTreatmentPeriod
        // }
        maxPeriod += subTreatment.subTreatmentPeriod
        price += subTreatment.subTreatmentPrice

      }
      maxPeriod = isNaN(maxPeriod) ? 0 : maxPeriod
      price = isNaN(price) ? 0 : price
      console.log('test 0', { maxPeriod: maxPeriod , price: price })
      return { maxPeriod: maxPeriod , price: price }
    },
    mockProgramTreament: [
      {
        "programId": 1,
        "programNo": "P001",
        "programName": "Spa & Massage Treatments",
        "treatment": [
          {
            "treatmentId": 1,
            "treatmentNo": "T001",
            "treatmentName": "Aromatherapy Body Massage 1",
            "treatmentPeriod": null,
            "treatmentPicture": 195904,
            "treatmentPoint": null,
            "treatmentPrice": null,
            "treatmentDescription": "Aromatherapy Body Massage combines the power to touch with the sense of smell. An effective aid to relaxation, the massage helps to smooth away stress, unknotting tense and aching muscles. The skin, blood and lymphatic systems are stimulated, which boost circulation,  aid cellular removal and remove toxic wastes. As tense muscles relax, stiff joints loosen,  and nerves are soothed, an all-over feeling of relaxation comes about for both mind and body.",
            "treatmentIsPopular": true,
            "hasSubTreatment": true,
            "subTreatment": [{
              "subTreatmentId": 1,
              "subTreatmentName": "Aromatherapy Body Massage with Herbal Compress 1",
              "subTreatmentPeriod": 120,
              "subTreatmentPoint": 50,
              "subTreatmentPrice": 1750,
            },
            {
              "subTreatmentId": 2,
              "subTreatmentName": "Aromatherapy Body Massage B 1",
              "subTreatmentPeriod": 120,
              "subTreatmentPoint": 50,
              "subTreatmentPrice": 1400
            }]
          },
          {
            "treatmentId": 2,
            "treatmentNo": "T001",
            "treatmentName": "Aromatherapy Body Massage 2",
            "treatmentPeriod": 150,
            "treatmentPicture": 195904,
            "treatmentPoint": 100,
            "treatmentPrice": 2100,
            "treatmentDescription": "Aromatherapy Body Massage combines the power to touch with the sense of smell. An effective aid to relaxation, the massage helps to smooth away stress, unknotting tense and aching muscles. The skin, blood and lymphatic systems are stimulated, which boost circulation,  aid cellular removal and remove toxic wastes. As tense muscles relax, stiff joints loosen,  and nerves are soothed, an all-over feeling of relaxation comes about for both mind and body.",
            "treatmentIsPopular": false,
          },
          {
            "treatmentId": 3,
            "treatmentNo": "T001",
            "treatmentName": "Aromatherapy Body Massage 3",
            "treatmentPeriod": null,
            "treatmentPicture": 195904,
            "treatmentPoint": null,
            "treatmentPrice": null,
            "treatmentDescription": "Aromatherapy Body Massage combines the power to touch with the sense of smell. An effective aid to relaxation, the massage helps to smooth away stress, unknotting tense and aching muscles. The skin, blood and lymphatic systems are stimulated, which boost circulation,  aid cellular removal and remove toxic wastes. As tense muscles relax, stiff joints loosen,  and nerves are soothed, an all-over feeling of relaxation comes about for both mind and body.",
            "treatmentIsPopular": true,
            "subTreatment": [{
              "subTreatmentId": 1,
              "subTreatmentName": "Aromatherapy Body Massage with Herbal Compress 3",
              "subTreatmentPeriod": 120,
              "subTreatmentPoint": 50,
              "subTreatmentPrice": 1750,
            },
            {
              "subTreatmentId": 2,
              "subTreatmentName": "Aromatherapy Body Massage B 3",
              "subTreatmentPeriod": 120,
              "subTreatmentPoint": 50,
              "subTreatmentPrice": 1400
            }]
          },
        ],
      },
      {
        "programId": 2,
        "programNo": "P001",
        "programName": "Spa",
        "treatment": [
          {
            "treatmentId": 1,
            "treatmentNo": "T001",
            "treatmentName": "Aromatherapy Body Massage 1",
            "treatmentPeriod": 150,
            "treatmentPicture": 195904,
            "treatmentPoint": 100,
            "treatmentPrice": 2100,
            "treatmentDescription": "Aromatherapy Body Massage combines the power to touch with the sense of smell. An effective aid to relaxation, the massage helps to smooth away stress, unknotting tense and aching muscles. The skin, blood and lymphatic systems are stimulated, which boost circulation,  aid cellular removal and remove toxic wastes. As tense muscles relax, stiff joints loosen,  and nerves are soothed, an all-over feeling of relaxation comes about for both mind and body.",
            "treatmentIsPopular": false,
            "subTreatment": [{
              "subTreatmentId": 1,
              "subTreatmentName": "AAA with Herbal Compress 3",
              "subTreatmentPeriod": 60,
              "subTreatmentPoint": 50,
              "subTreatmentPrice": 1750,
            },
            {
              "subTreatmentId": 2,
              "subTreatmentName": "BBB B 3",
              "subTreatmentPeriod": 120,
              "subTreatmentPoint": 50,
              "subTreatmentPrice": 1400
            }]
          },
          {
            "treatmentId": 2,
            "treatmentNo": "T002",
            "treatmentName": "Aromatherapy Body Massage 2",
            "treatmentPeriod": 150,
            "treatmentPicture": 195904,
            "treatmentPoint": 100,
            "treatmentPrice": 2100,
            "treatmentDescription": "Aromatherapy Body Massage combines the power to touch with the sense of smell. An effective aid to relaxation, the massage helps to smooth away stress, unknotting tense and aching muscles. The skin, blood and lymphatic systems are stimulated, which boost circulation,  aid cellular removal and remove toxic wastes. As tense muscles relax, stiff joints loosen,  and nerves are soothed, an all-over feeling of relaxation comes about for both mind and body.",
            "treatmentIsPopular": false,
          },
          {
            "treatmentId": 3,
            "treatmentNo": "T003",
            "treatmentName": "Aromatherapy Body Massage 3",
            "treatmentPeriod": 150,
            "treatmentPicture": 195904,
            "treatmentPoint": 100,
            "treatmentPrice": 2100,
            "treatmentDescription": "Aromatherapy Body Massage combines the power to touch with the sense of smell. An effective aid to relaxation, the massage helps to smooth away stress, unknotting tense and aching muscles. The skin, blood and lymphatic systems are stimulated, which boost circulation,  aid cellular removal and remove toxic wastes. As tense muscles relax, stiff joints loosen,  and nerves are soothed, an all-over feeling of relaxation comes about for both mind and body.",
            "treatmentIsPopular": false,
          }
        ]
      }
    ],
  }
 
};
