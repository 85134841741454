import React from "react";
import GridRap from "../../../components/Layouts/GridRap";
import { useDispatch, useSelector } from "react-redux";
import IconUser from "../../../assets/images/icon-user.svg";
import IconCalendar from "../../../assets/images/icon-calendar.png";
import IconMarker from "../../../assets/images/icon-marker.png";
import { useEffect } from "react";
import { getTotalDuration } from "../../../features/BookingSlice";
import GridMain from "../../../components/Layouts/GridMain";
import moment from "moment";
import helper from "../../../services/helper";
import ButtonPrimaryOutline from "../../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import useTranslations from "../../../i18n/useTranslations";

const programTreamentList = helper.Booking.mockProgramTreament

const { zeroToDash } = helper.FN

export default function BookingFooter({ values = {} , onClose, onSave, isNext = false }) {
  const { t } = useTranslations();
  const dispatch = useDispatch()
  const step1 = useSelector((state) => state.bookingMain.step1)
  const step2 = useSelector((state) => state.bookingMain.step2)
  const popupTreatment = useSelector((state) => state.bookingMain.popupTreatment)
  const programTreament = useSelector((state) => state.programTreament.data);
  // const programTreament = programTreamentList

  function handleBack() {
    onClose()
  }

  function handleSave() {
    onSave()
  }

  const { maxPeriod, price } = helper.Booking.getMaxPeriodAndPrice(programTreament, values)

  return (
    <footer className="treatment">
      <div className="box-1">
        <GridMain className="">
            <GridRap isMediumSize>
              <div className="flex justify-between">
                <p className="px-[1rem] text-[12px] md:text-[16px] flex-1 border-r-[1px] border-[#DEE2E6]">
                  {Object.keys(values).length}/2 {t.selectTreatments.programSelected}
                </p>
                <div className="flex">
                  <p className="px-[1rem] text-[12px] md:text-[16px] font-medium border-r-[1px] border-[#DEE2E6]" >{zeroToDash(maxPeriod)} min</p>
                  <p className="px-[1rem] text-[12px] md:text-[16px] font-medium "> {zeroToDash(price)} THB</p>
                </div>
              </div>
            </GridRap>
        </GridMain>
      </div>
      <div className="box-2">
        <GridMain>
          <GridRap isSmallSize>
            <div className="flex justify-center gap-[16px] md:gap-[24px]">
              <ButtonPrimaryOutline 
                size="mini"
                addClass="w-full md:max-w-[366px]"
                onClick={handleBack}
              >
                {t.selectTreatments.btnBack}
              </ButtonPrimaryOutline>
              <ButtonPrimary 
                size="mini"
                addClass="w-full md:max-w-[366px]"
                onClick={handleSave} 
                // disabled={Object.keys(values).length <= 0}
                disabled={!Object.keys(values).length > 0}
              >
                {t.selectTreatments.btnSelect}
              </ButtonPrimary>
            </div>
          </GridRap>
        </GridMain>
      </div>
    </footer>
  )
}