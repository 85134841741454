import React, { useEffect, useState } from "react";
import Label from "../../components/general/Label";
import { Controller, useForm } from "react-hook-form";
import useTranslations from "../../i18n/useTranslations";
import SignUpLogo from "../../assets/images/sign-up-logo.png";
import SignUpLogoMobile from "../../assets/images/singin-logo-mobile.png";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import moment from "moment";
import { SearchDdlBranch } from "../../services/Dropdown";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import { setBookingMain, setOtherData, setStep1, setStep2 } from "../../features/BookingMainSlice";
import { useDispatch, useSelector } from "react-redux";
import TopLogo from "../../components/Layouts/TopLogo";
import GridMain from "../../components/Layouts/GridMain";
import GridRap from "../../components/Layouts/GridRap";
import DatePickerNormal from "../../components/general/DatePickerNormal";
import { useNavigate } from "react-router-dom";

import styled from 'styled-components';
import TopBanner from "../../components/Layouts/TopBanner";
import WebContainer from "../../components/Layouts/WebContainer";

const defaultDropdownGuest = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
];

export default function BookingStep1(props) {
  const step1 = useSelector((state) => state.bookingMain.step1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropdownBranch, setDropdownBranch] = useState([]);
  const [dropdownGuest, setDropdownGuest] = useState(defaultDropdownGuest);

  const { branch, guestCount, date } = step1
  const { t } = useTranslations();


  const getDdlBranch = async () => {
    try {
      const { data } = await SearchDdlBranch();
      if (data.resultCode === 20000) {
        setDropdownBranch(data.body);
        setBranchDD(data.body)
        // setValue('branch', Branch.id)
      }
    } catch (e) {
      console.log(`🦄: getDdlNationality -> e`, e);
    }
  };

  useEffect(() => {
    getDdlBranch();
  }, []);

  useEffect(() => { window.scrollTo({ top: 0 }) }, []); // newpage-scoll-top

  function gotoStep2() {
    navigate('/booking-step2')
  }

  function setBranchDD(value) {
    const tmpObj = { ddBranch: value }
    dispatch(setOtherData(tmpObj));
  }

  function setBranch(value) {
    const tmpObj = { branch: value }
    dispatch(setStep1(tmpObj));
  }

  function setGuest(value) {
    const tmpObj = { guestCount: value }
    dispatch(setStep1(tmpObj));
  }

  function setDate(value) {
    const tmpObj = { date: value }
    dispatch(setStep1(tmpObj));
  }

  function loadOldState() {
    console.log('useEffect loadOldState !!!')
  }

  function defaultGuest(count = 0) {
    console.log("count >>", count);
    let ans = [];
    for (let n = 0; n < count; n++) {
      const running = Number(n) + 1;
      const tmp = genGuestRaw(running)
      ans.push(tmp);
    }
    return ans;
  }

  function genGuestRaw(running = 1) {
    const tmp = {
      // name: "Guest " + running,
      name: "",
      number: running,
      program: [],
      values: {},
      totalDuration: 0,
      totalPrice: 0,
    };
    return tmp
  }


  function createDefaultGuest() {
    console.log('useEffect ค่าเริ่มต้น !')
    const _guestData = defaultGuest(step1.guestCount || 1);
    console.log("🚀 ~ _guestData:", _guestData);
    setGuestData(_guestData)
    gotoStep2()
  }

  function setGuestData(value) {
    const tmpObj = { guestData: value };
    dispatch(setStep2(tmpObj));
  }

  useEffect(() => {
    window.localStorage.removeItem('step1')
    window.localStorage.removeItem('step2')

  }, [])
  

  return (
    <>
      <WebContainer bgGray hfull>
        <TopBanner type={"Booking"}/>
        <GridMain>
          <GridRap>
            <div className="mt-[-15px] md:mt-[-47px]">
              <form
                // onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
              >
                <div className="title-sp1">
                  <p className="relative text-center text-[12px] md:text-[20px] text-white ">
                    {t.bookingStep1.title0} <br className="block md:hidden"/>
                    {t.bookingStep1.title1} 
                  </p>
                </div>
                <div className="bg-white rounded-b-[4px] pt-[18px] pb-[34px] md:py-[24px] px-[20px]">
                  <div className=" max-w-[583px] mx-auto">
                    <div className="flex flex-col gap-[16px] md:gap-[24px]">
                      <div>
                        <Label color2>{t.bookingStep1.branch}</Label>
                        <div className="w-full">
                          <Select
                            className="selection-hl"
                            displayEmpty
                            fullWidth
                            IconComponent={ExpandMoreRoundedIcon}
                            renderValue={(selected) => {
                              if (!selected || selected.length === 0) {
                                return <span className="txt-gray">{t.bookingStep1.branchPlaceholder}</span>;
                              }
                              return dropdownBranch.find((item) => item.id === selected)?.name;
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            value={branch}
                            onChange={(e)=> setBranch(e.target.value)}
                            // MenuProps={MenuSelectProps}
                          >
                            {
                              Array.isArray(dropdownBranch) &&
                              dropdownBranch.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                  <em className="flex items-center">
                                    {item.name}
                                  </em>
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </div>
                      </div>
                      <div>
                        <Label color2>{t.bookingStep1.guestNumber5}</Label>
                        <div className="w-full">
                          <Select
                            className="selection-hl"
                            displayEmpty
                            fullWidth
                            IconComponent={ExpandMoreRoundedIcon}
                            renderValue={(selected) => {
                              if (!selected || selected.length === 0) {
                                return <span className="txt-gray">{t.bookingStep1.guestNumber5Placeholder}</span>;
                              }
                              return dropdownGuest.find((item) => item.id === selected)?.name;
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            value={guestCount}
                            onChange={(e)=> setGuest(e.target.value)}
                            // MenuProps={MenuSelectProps}
                          >
                            {
                              Array.isArray(dropdownGuest) &&
                                dropdownGuest.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    <em className="flex items-center">{item.name}</em>
                                  </MenuItem>
                                ))
                            }
                          </Select>
                        </div>
                      </div>
                      <div>
                        <Label color2>{t.bookingStep1.date}</Label>
                        <div className="w-full">
                          <DatePickerNormal
                            name="date"
                            placeholder={t.bookingStep1.datePlaceholder}
                            minDate={moment().add(1, "day")}
                            maxDate={moment().add(6, "month")}
                            value={date}
                            onChange={(value) => setDate(value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center mt-[16px] md:mt-[24px]">
                      <ButtonPrimary
                        // className="btn btn-primary"
                        addClass="w-full md:max-w-[180px]"
                        type="submit"
                        disabled={
                          !branch || !guestCount || !date
                        }
                        onClick={() => createDefaultGuest()}
                      >
                        {t.bookingStep1.btnNext}
                      </ButtonPrimary>
                    </div>
                    <p className="text-center text-[12px] md:text-[14px] text-[#602E20] mt-[16px] md:mt-[24px]">
                    {t.bookingStep1.note}
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
    </>
  );
}