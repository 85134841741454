import React, { useState } from "react";

const LangDropdown = ({ options = [], value = null, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const GenValueText = ({ value = null, txt = "", optionv = [] }) => {
    if (optionv) {
      const find = optionv.find((item) => item.id === value);
      if(find) {
        return (
          <div className="flex w-full justify-end gap-[10px] items-center">
            {/* <img className="w-[14px] h-[14px] md:w-[28px] md:h-[28px]" src={find.image} alt="flag"/> */}
            <div className="text-[16px] md:text-[16px] font-medium md:font-normal text-Primary-A200">
              {`${find.id}`.toUpperCase()}
            </div>
          </div>
        );
      }
      return <>xx</>
     
    }
    return <></>;
  };
  const active = isOpen ? " active" : "";
  return (
    <div
      className={`c-multi-select-dropdown cursor-pointer ${active}`}
      onClick={() => setIsOpen((pre) => !pre)}
    >
      <div className="c-multi-select-dropdown__selected">
        <GenValueText value={value} optionv={options} />
      </div>
      <ul className="c-multi-select-dropdown__options">
        {options.map((option, index) => {
          return (
            <li
              key={index}
              className="c-multi-select-dropdown__option"
              onClick={() => {
                onChange(option.id);
              }}
            >
              <div className="flex justify-evenly items-center gap-[8px]">
                {/* <img className="w-[14px] md:w-[28px]" src={option.image} alt="" /> */}
                <div className="pt-[2px] text-[12px] md:text-[16px]">{option.name}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default LangDropdown;
