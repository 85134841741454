import React, { useState, useEffect } from "react";
import helper from "../../services/helper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setProfile } from "../../features/ProfileSlice"
import useTranslations from "../../i18n/useTranslations";
import { SearchProgramTreatment } from "../../services/Booking";
import { setProgramTreament } from "../../features/ProgramTreamentSlice";

export default function LoadTreatments({ noLogin = false }) {
  const { t, lang } = useTranslations();
  // const [vCon, setVCon] = useState(new VConsole());
  const step1 = useSelector((state) => state.bookingMain.step1);
  const navigate = useNavigate();
  const dispatch = useDispatch() // ไว้อัพเดต state กลาง


  const getProgramTreatment = async (branchId = 0) => {
    try {
      const { data } = await SearchProgramTreatment({
        params: { branchId: branchId },
      });
      if (data.resultCode === 20000) {
        console.log("data.body >>", data.body);
        setProgramTreament(data.body);
        const tmpObj = {
          data: data.body,
          isLoading: true,
        };
        dispatch(setProgramTreament(tmpObj)); // set เข้า state กลาง
      }
    } catch (e) {
      console.log("ERR getProgramTreatment", e);
    }
  };

  useEffect(() => {
    let pathname = window.location.pathname;
    let result = pathname.includes("booking"); // ทำงานเฉพาะหน้า booking เท่านั้น
    if (result && step1.branch) {
      getProgramTreatment(step1.branch)
    }
  }, [lang, step1.branch])
  console.log('step1 >>', step1)
  return (
    <>
      {/* LoadTrement .... {lang} */}
    </>
  );
}
