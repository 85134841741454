import React, { useEffect } from "react";
import GridMain from "../../components/Layouts/GridMain";
import GridRap from "../../components/Layouts/GridRap";
import FooterBase from "../../components/Layouts/FooterBase";

export default function SettingTemplate({ title = "", children , onClose, onAccept }) {


  useEffect(() => {
    console.log('useEffect !!!')
    document.documentElement.scrollTop = 0
  }, [])

  return (
    <>
    <div className="bg-gray popup-setting-space">
      <GridMain className="">
        <GridRap>
          <div className="form-content-space">
            <div className="header">
              <h5 className="cp-title">{title}</h5>
            </div>
            <div className="content">
                {children}
            </div>
          </div>
        </GridRap>
      </GridMain>
      </div>
      <FooterBase
        labelBack="Back"
        labelNext="Accept"
        onBack={() => {
          console.log('onClose >>')
          onClose()
        }}
        notYes={true}
        isFixed={true}
      />
    </>
  )
}