import React, { useEffect, useState } from "react";
import TopLogo from "../../components/Layouts/TopLogo";
import Edit1 from "../../assets/images/edit1.svg";
import AvatarProfile from "../../assets/images/Avatar-Profiles.svg";
import Label from "../../components/general/Label";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import useTranslations from "../../i18n/useTranslations";
import ARdown from "../../assets/images/icon-input/ARdown";
import { SearchDdlNationality } from "../../services/Dropdown";
import helper from "../../services/helper";
import DatePickerField from "../../components/general/DatePickerField";
import RadioGroupField from "../../components/general/RadioGroupField";
import TextFieldPassword from "../../components/general/TextFieldPassword";
import {
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-international-phone/style.css";
import { InputAdornment, Typography } from "@mui/material";
import moment from "moment";
import { UpdateMember, SearchMemberById } from "../../services/Member";
import { useNavigate } from "react-router-dom";
import sweetAlert from "../../services/sweetAlert";
import GridMain from "../../components/Layouts/GridMain";
import GridRap from "../../components/Layouts/GridRap";
import WebContainer from "../../components/Layouts/WebContainer";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import { useDispatch, useSelector } from 'react-redux';
import { setProfile } from "../../features/ProfileSlice"
import heic2any from 'heic2any';
import CustomSelect from "../../components/general/CustomSelect";
import ExampleComponent from "./ExampleComponent";
const fnsetProfile = setProfile 
export default function ProfileEdit({ value }) {
  // const profile = useSelector((state) => state.profile) // ไว้ดึง state
  const dispatch = useDispatch() // ไว้อัพเดต state กลาง
  const { register, formState: { errors }, handleSubmit, setValue, control, watch,} = useForm({
    mode:'onTouched',
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      firstname: "test",
      lastname: "",
      mobileNumber: "",
      email: "",
      mobileCodeId: 1,
      nationalityId: null,
      birthdate: null,
      genderId: 1,
      password: "",
      rePassword: "",
    },
  });
  const { t, lang } = useTranslations();
  const [dropdownMemberCode, setDropdownMemberCode] = useState([]);
  const [dropdownNationality, setDropdownNationality] = useState([]);
  const [popupCrop, setPopupCrop] = useState(false);

  const navigate = useNavigate();

  const OPTION_GENDER = helper.OPTIONS.GENDERLANG(lang);

  const [profile, setProfile] = useState({});
  const getSearchMemberById = async (memberId = null) => {
    try {
      const { data } = await SearchMemberById({ memberId: memberId });
      if (data.resultCode === 20000) {
        setProfile(data.body);
        setValue("firstname", data.body.firstname);
        setValue("lastname", data.body.lastname);
        setValue("email", data.body.email);
        setValue("mobileNumber", data.body.mobileNumber);
        setValue("nationalityId", parseInt(data.body.nationality.id));
        setValue("birthdate", moment(data.body.birthDate));
        setValue("genderId", data.body.genderId);

        setFileContent(data.body.imagePicture)
        // setValue("password", data.body.password);
        // setValue("rePassword", data.body.password);
      } else if (data.resultCode === 40401) {
        helper.Auth.Logout('/login')
      }
    } catch (e) {
      console.log("ERR getSearchMemberById", e);
    }
  };

  useEffect(() => {
    const data = helper.Auth.getTokenData();
    getSearchMemberById(data.memberId);
  }, []);

  const getDdlNationality = async () => {
    try {
      const { data } = await SearchDdlNationality({});
      if (data.resultCode === 20000) {
        setDropdownNationality(data.body);
      }
    } catch (e) {
      console.log("ERR getDdlNationality", e);
    }
  };

  useEffect(() => {
    // getDdlMobileCode();
    getDdlNationality();
  }, []);

  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone) => {
    try {
      if (phone.substring(4, 6) == "") {
        return true;
      } else {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
      }
    } catch (error) {
      return false;
    }
  };



  const onSubmit = (data) => {
    let payload = {
      ...data,
      id: profile.id,
      imagePicture:
        fileContentEditImage && fileContentEditImage !== ""
          ? fileContentEditImage
          : profile.imagePicture,
    };
    delete payload.rePassword;
    payload.birthdate = new Date(payload.birthdate).toISOString().split("T")[0];
    postUnpdateMember(payload);
  };

  const postUnpdateMember = async (payload) => {
    const { data } = await UpdateMember({ data: payload });
    if (data.resultCode == 20000) {
      navigate("/profile-view");

      const tmpObj = { isLoading: false }
      dispatch(fnsetProfile(tmpObj));
    }
  };

  const [fileContentEditImage, setFileContent] = useState("");
  const editProfileImage = async (event) => {
    const selectedFile = event.target.files[0];
    console.log('selectedFile >>', selectedFile)
    const MAX_SIZE_FILE = helper.CONFIG.MAX_SIZE_FILE
    const IMG_TYPE_FILE = helper.CONFIG.IMG_TYPE_FILE
    if (IMG_TYPE_FILE.includes(selectedFile.type)) {
      const reader = new FileReader();
      reader.onload = async (val) => {
        const base64String = btoa(val.target.result);
        const base64Small = await helper.IMAGE.toImageSmall(base64String)
        console.log('0base64 Small >>', base64String)
        console.log('1base64 Small >>', base64Small)
        // "data:image/gif;base64,"
        setFileContent(base64Small);
        setTimeout(()=> setPopupCrop(true), 100)
      };
      reader.readAsBinaryString(selectedFile);
    } 
    else if (`${selectedFile.name}`.includes('.HEIC','.heic')) {
      const blob = await heic2any({ blob: selectedFile });

      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = reader.result.split(',')[1];
        const base64Small = await helper.IMAGE.toImageSmall(base64String)
        setFileContent(base64Small);
      };
      reader.readAsDataURL(blob);
    }
    else {
      // setFileContent("No file selected.");
      sweetAlert.AlertInfo({
        errorCode: 0,
        titleClass: 'title-danger',
        title: "Error uploading",
        subtitle: "Your image file type is not supported. We accept <b>JPG, JPEG and PNG</b> only.",
      });
    }
  };

  useEffect(() => {
    if (fileContentEditImage) {
      // setPopupCrop(true)
    }
  }, [fileContentEditImage])

  // if (popupCrop) {
  //   return <>
  //     <ExampleComponent srcImg={fileContentEditImage} onChange={(base64)=> {
  //       setFileContent(base64)
  //       setPopupCrop(false)
  //     }}/>
  //   </>
  // }
 

  return <>
    {
      popupCrop
      ? (
        <ExampleComponent srcImg={fileContentEditImage} onChange={(base64)=> {
          setFileContent(base64)
          setPopupCrop(false)
        }}/>
      )
      : <></>
    }
    <WebContainer bgGraphics={true}>
      <GridMain className="">
        <GridRap>
          <div className="content-full box-content-space">
            <h5 className="cp-title mb-[32px]">
              Edit Profile
            </h5>
            <div className="bg-info-profile w-full mb-[32px]">
              <div className="flex justify-center image-ediable">
                <div className="image-editable">
                  <label for="fileField">
                    <div style={{ position: "relative" }}>
                      <img
                        src={
                          fileContentEditImage && fileContentEditImage !== ""
                            ? fileContentEditImage
                            : AvatarProfile
                        }
                        className="imgProfile"
                        style={
                          fileContentEditImage || profile.imagePicture
                            ? {
                                borderRadius: "100%",
                                borderColor: "#DDB05B",
                                borderWidth: 5,
                              }
                            : {}
                        }
                      />
                      <div className="ctEditImage">
                      
                          <img className="p-[8px]" src={Edit1}/>
                      
                      </div>
                    </div>
                  </label>
                  <input
                    type="file"
                    id="fileField"
                    name="file"
                    accept="image/*, .heic"
                    hidden="true"
                    onChange={editProfileImage}
                  />
                </div>
              </div>
            </div>
            <form
              className="flex flex-col gap-[24px] md:gap-[24px]"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
            >
              <div className="box-form-content-space">
                <h5 className="title-form mb-[16px]">{t.register.edTitle0}</h5>
                <div className="flex flex-col gap-[16px]">
                  {/* name */}
                  <div className="grid grid-cols-2 gap-[24px] md:gap-[24px]">
                    <div className="">
                      <Label>{t.register.form_firstname}</Label>
                      <TextField
                        required
                        fullWidth
                        placeholder={t.register.form_firstname_placeholder}
                        error={!!errors?.firstname}
                        helperText={errors?.firstname?.message}
                        {...register("firstname", {
                          required: t.register.form_firstname_validate_0,
                        })}
                        inputProps={{ 
                          maxLength: 150,
                          onChange: helper.FNFORM.handleCharOnly,
                        }}
                      />
                    </div>
                    <div className="">
                      <Label>{t.register.form_lastname}</Label>
                      <TextField
                        required
                        fullWidth
                        placeholder={t.register.form_lastname_placeholder}
                        error={!!errors?.lastname}
                        helperText={errors?.lastname?.message}
                        {...register("lastname", {
                          required: t.register.form_lastname_validate_0,
                        })}
                        inputProps={{ 
                          maxLength: 150,
                          onChange: helper.FNFORM.handleCharOnly,
                        }}
                      />
                    </div>
                  </div>
                  {/* phone */}
                  <div className="flex">
                    <div className="w-full">
                      <Label>{t.register.form_mobile_number}</Label>
                      <div className="flex gap-[12px] md:gap-[12px]">
                        <TextField
                          required
                          variant="outlined"
                          color="primary"
                          placeholder="Mobile number"
                          type="text"
                          id="mobileNumber"
                          name="mobileNumber"
                          fullWidth
                          error={!!errors?.mobileNumber}
                          helperText={errors?.mobileNumber?.message}
                          {...register("mobileNumber", {
                            required: t.register.form_mobile_number_validate_0,
                            minLength: {
                              value: 8,
                              message: t.register.form_mobile_number_validate_1,
                            },
                            maxLength: {
                              value: 15,
                              message: t.register.form_mobile_number_validate_1,
                            },
                          })}
                          inputProps={{ 
                            maxLength: 15,
                            onChange: helper.FNFORM.handleNumberOnly,
                          }}
                        /> 
                      </div>
                    </div>
                  </div>
                  {/* email */}
                  <div className="flex">
                    <div className="w-full">
                      <Label>{t.register.form_email}</Label>
                      <TextField
                        required
                        fullWidth
                        placeholder={t.register.form_email_placeholder}
                        error={!!errors?.email}
                        helperText={errors?.email?.message}
                        {...register("email", {
                          required: {
                            value: true,
                            message: t.register.form_email_validate_0,
                          },
                          pattern: {
                            value:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                            message: t.register.form_email_validate_1,
                          },
                        })}
                        inputProps={{ 
                          maxLength: 150,
                          onChange: helper.FNFORM.handleCharOnly,
                        }}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 gap-[16px] md:gap-[24px]">
                    {/* nationality */}
                    <div className="">
                      <div className="w-full">
                        <Label>{t.register.form_nationality}</Label>
                        <div className="w-full">
                          <CustomSelect
                            name="nationalityId"
                            control={control}
                            rules={{
                              required: t.register.form_nationality_validate_0,
                            }}
                            options={dropdownNationality}
                            error={false}
                          />
                        </div>
                      </div>
                    </div>
                    {/* birthdate */}
                    <div className="">
                      <div className="w-full">
                        <Label>{t.register.form_birthdate}</Label>
                        <DatePickerField
                            name="birthdate"
                            placeholder={t.register.form_birthdate_placeholder}
                            maxDate={moment()}
                            control={control}
                            rules={{ required: true }}
                            error={!!errors.birthdate}
                            helperText={
                              errors.birthdate
                                ? t.register.form_birthdate_validate_0
                                : ""
                            }
                          />
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-full">
                      <Label>{t.register.form_gender}</Label>
                      <RadioGroupField
                        name="genderId"
                        control={control}
                        options={OPTION_GENDER}
                        {...register("genderId", { required: true })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-form-content-space">
                <h5 className="title-form mb-[16px]">{t.register.edTitle1}</h5>
                <div className="flex flex-col gap-[16px]">
                  <div className="w-full">
                    <Label>{t.register.form_password}</Label>
                    <TextFieldPassword
                      fullWidth
                      placeholder={t.register.form_password_placeholder}
                      error={!!errors?.password}
                      helperText={errors?.password?.message}
                      {...register("password", {
                        required: {
                          value: watch('password'),
                          message: t.register.form_password_validate_0
                        },
                        minLength: {
                          value: 6,
                          message: t.register.form_password_validate_1,
                        },
                      })}
                      inputProps={{ 
                        maxLength: 20,
                        onChange: helper.FNFORM.handleCharOnly,
                      }}
                    />
                  </div>
                  
                  <div className="w-full">
                    <Label>{t.register.form_re_password}</Label>
                    <TextFieldPassword
                      required
                      fullWidth
                      placeholder={t.register.form_re_password}
                      error={!!errors?.rePassword}
                      helperText={errors?.rePassword?.message}
                      // {...register("rePassword", {required: "กรุณากรอกยืนยันรหัสผ่าน"})}
                      {...register("rePassword", {
                        required: {
                          value: watch('password'),
                          message: t.register.form_password_validate_0
                        },
                        validate: (value) =>
                          value === watch("password") ||
                          t.register.form_re_password_validate_2,
                        minLength: {
                          value: 6,
                          message: t.register.form_password_validate_1,
                        },
                      })}
                      i inputProps={{ 
                        maxLength: 20,
                        onChange: helper.FNFORM.handleCharOnly,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <ButtonPrimary type="submit" addClass="">
                  {t.register.btnSave}
                </ButtonPrimary>
              </div>
            </form>
          </div>
        </GridRap>
      </GridMain>
    </WebContainer>
  </>;
}
