import { get, post } from "./Api";

export function AuthLogin({ data }) {
  return post({ url: `/api/v1/healthland/member/login`, data });
}
export function AuthLoginWithSocial({ data }) {
  return post({ url: `/api/v1/healthland/member/loginWithSocial`, data });
}
export function AuthVerifyToken({ data }) {
  return post({ url: `/api/v1/healthland/auth/verifyToken`, data });
}