import React from "react";
import GridRap from "../../../components/Layouts/GridRap";
import GridMain from "../../../components/Layouts/GridMain";
import ButtonPrimaryOutline from "../../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import useTranslations from '../../../i18n/useTranslations';
import MyBooking from './../MyBooking';

export default function BookingDetaillFooter({ 
  onClose , onSave, 
}) {
  const { t } = useTranslations();

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleSave = () => {
    if (onSave) {
      onSave()
    }
  }

  return (
    <>
      <footer className="normal">
        <div className="box-2">
          <GridMain>
            <GridRap isSmallSize>
              <div className="flex justify-center gap-[16px] md:gap-[24px]">
                <ButtonPrimaryOutline 
                  size="mini"
                  onClick={handleClose}
                >
                  {t.myBooking.myBooking}
                </ButtonPrimaryOutline>
                <ButtonPrimary 
                  size="mini"
                  onClick={handleSave}
                >
                  {t.myBooking.checkIn}
                </ButtonPrimary>
              </div>
            </GridRap>
          </GridMain>
        </div>
      </footer>
    </>
  );
}