import React from "react";
import GridRap from "../../components/Layouts/GridRap";
import GridMain from "../../components/Layouts/GridMain";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import helper from "../../services/helper";
import useTranslations from "../../i18n/useTranslations";

const { toNumberWithCommas } = helper.FN
export default function FooterNormal({ 
  onClose , onSave, 
  isNext = true,
  labelBack = "Back",
  labelNext = "Next",
  step = 0,
  bookingList = []
}) {
  const { t } = useTranslations();

  const handleBack = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleSelect = () => {
    if (onSave) {
      onSave()
    }
  }

  const { totalAll, durationAll } = calTotal(bookingList)
  const pS0 = "text-[14px] md:text-[16px] text-Neutral-A800 font-medium md:border-l-[1px] border-Neutral-A300 pl-[16px]"
  return (
    <>
      <footer className="normal">
        {
          step === 4 
          ? (
            <div className="box-1">
              <GridMain >
                <GridRap isSmallSize>
                  <div className="flex w-full justify-between">
                    <p className="text-[14px] md:text-[16px] text-Neutral-A900">{t.bookingNormalFooter.total}</p>
                    <div className="flex gap-[12px]">
                      <p className={pS0}>{durationAll} {t.bookingNormalFooter.min}</p>
                      <p className={pS0}>{toNumberWithCommas(totalAll)} {t.bookingNormalFooter.thb}</p>
                    </div>
                  </div>
                </GridRap>
              </GridMain>
            </div>
          )
          :<></>
        }
        <div className="box-2">
          <GridMain>
            <GridRap isSmallSize>
              <div className="flex justify-center gap-[16px] md:gap-[24px]">
                <ButtonPrimaryOutline 
                  size="mini"
                  addClass="w-full md:max-w-[366px]"
                  onClick={handleBack}
                >
                  {t.bookingNormalFooter.btnBack}
                </ButtonPrimaryOutline>
                <ButtonPrimary 
                  size="mini"
                  addClass="w-full md:max-w-[366px]"
                  onClick={handleSelect}
                  disabled={!isNext}
                >
                  
                  { step === 5 ? t.bookingNormalFooter.btnPayment : t.bookingNormalFooter.btnNext}
                </ButtonPrimary>
              </div>
            </GridRap>
          </GridMain>
        </div>
      </footer>
    </>
  );
}

function calTotal (_bookingList = []) {
  let totalAll = 0
  let durationAll = 0
  for (let bl of _bookingList) {
    let durationSum = 0
    for (let st of bl.subTreatment) {
      totalAll += st.price
      console.log('calTotal bl >>', bl)
      console.log('calTotal st.period >>', st.period)
      durationSum = durationSum + st.period
    }
    if (durationSum >= durationAll) {
      durationAll = durationSum
    }
  }
  return { totalAll, durationAll }
}