import Payment from "./Payment";
import { useState } from "react";

function PaymentPage() {
  const [type, setType] = useState("");
  let listCheckout = [
    { id: "1", dataPaymentMethods: "card", dataAmount: "1.0" },
    { id: "2", dataPaymentMethods: "card", dataAmount: "20.0" },
    { id: "3", dataPaymentMethods: "card", dataAmount: "50.0" },
    {
      id: "4",
      dataPaymentMethods: "qr",
      dataAmount: "30.0",
      dataOrderId: "order_test_24243a099dcb387d64d14a6acbbb679a",
    },
  ];
  
  return (
    <div className="App">
      {
        listCheckout.map((r) => {
          return (
            <div>
              <button
                onClick={() => {
                  setType("");
                  setTimeout(() => {
                    setType(r.id);
                  }, 150);
                }}
              >
                {r.dataPaymentMethods} {r.dataAmount}
              </button>
            </div>
          );
        })
      }

      {
        listCheckout.map((r) => {
          return (
            <div>
              {type === r.id ? (
                <>
                  <Payment
                    dataApikey={"pkey_test_202VgLZQaHr34eNyixMq6iqtR1S3pCKqv7n"}
                    dataAmount={r.dataAmount}
                    dataPaymentMethods={r.dataPaymentMethods}
                    dataOrderId={r.dataOrderId || null}
                    dataMid={"401923102716001"}
                    dataName={"Awesome Shop"}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          );
        })
      }
    </div>
  );
}

export default PaymentPage;
