import React from "react";
import styled from "styled-components";
import iconBack from "../../assets/images/svg/icon-back-btn.svg";
import useTranslations from "../../i18n/useTranslations";

const StyledButtonBack = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;

  > h5 {
    line-height: 16px;
    font-weight: 500;
  }
  > .icon {
    width: 8px;
    height: 13px;
    background: url(${iconBack}) center center no-repeat;
    /* background-size: 13px 7px; */
  }
`;

const ButtonBack = (props) => {
  const { t } = useTranslations();
  return (
    <StyledButtonBack {...props}>
      <div className="icon"/>
      <h5>{t.pageBack.Back}</h5>
    </StyledButtonBack>
  );
};

export default ButtonBack;
