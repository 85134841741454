import React from "react";
import TopLogo from "../../components/Layouts/TopLogo";
import TextCheckMail from "../../assets/images/text-CheckMail.png";
import CheckMail from "../../assets/images/Check-Emil.svg";
import BGInfo from "../../assets/images/bg-info.png";
import GridMain from "../../components/Layouts/GridMain";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslations from "../../i18n/useTranslations";
import WebContainer from "../../components/Layouts/WebContainer";
import SuccessPage from "../../components/Layouts/SuccessContent";

export default function CheckEmail() {
  const { state } = useLocation();
  const { t } = useTranslations();
  const navigate = useNavigate();

  // if (!state?.email) {
  //   console.log('not state email')
  //   window.location.href = '/register'
  //   return <></>
  // }

  return (
    <WebContainer bgGraphics={true} contentCenter={true}>
      <SuccessPage>
        <div className="flex-1">
          <GridMain className="h-full flex flex-col text-center justify-center items-center" >
            <div className="flex flex-col text-center justify-center items-center h-full">
              <img src={CheckMail} alt="" />
              <h5 className="cp-title">Check Your Email</h5>
              <div className="flex flex-col gap-[4px] mt-[5px]">
                <p className="text-[14px] md:text-[20px]">{t.register.checkEmail1}</p>
                <p className="text-[14px] md:text-[20px] font-medium">{state?.email ?? 'tatchapolhealthland@gmail.com'}</p>
                <p className="text-[14px] md:text-[20px]">{t.register.checkEmail2}</p>
              </div>
            </div>
          </GridMain>
        </div>
      </SuccessPage>
    </WebContainer>
  )
}
