import React, { useEffect, useState } from 'react'
import useTranslations from '../../i18n/useTranslations';
import { useNavigate, useParams } from 'react-router';
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import GridRap from '../../components/Layouts/GridRap';
import BookingItem from '../../components/general/BookingItem';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import moment from 'moment';
import { SearchSurvey, CreateAnsSurvey } from "../../services/Survey";
import _ from "lodash"
import Label from '../../components/general/Label';
import IconTag from "../../assets/images/svg/tag-one.svg";
import IconCalendar from "../../assets/images/svg/calendar-one.svg";
import IconTime from "../../assets/images/svg/time-one.svg";
import IconMarker from "../../assets/images/svg/marker-one.svg";
import RatingStar from './RatingStar';
import SurveySuccess from './SurveySuccess';
import helper from '../../services/helper';

export default function HistoryPage() {
    const navigate = useNavigate()

    const { t, lang, supportedLangs, setLang } = useTranslations();
    const { bookingId } = useParams();
    const [isLoading, setIsLoading] = React.useState(true)
    const [bookingDetail, setBookingDetail] = React.useState({})
    const [survey, setSurvey] = React.useState([])
    const [surveyList, setSurveyList] = React.useState([])


    const [isSuccess, setIsSuccess] = React.useState(false)
    const [errCode, setErrCode] = React.useState(20000)

    const [countSyList, setCountSyList] = React.useState(0)
    const [sValue, setSValue] = React.useState({})
    const [surveyComment, setSurveyComment] = React.useState({})
    const [text, setText] = useState('');
    const [sPoint, setSPoint] = useState(0);

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const getSurvey = async (bookingId = null) => {
        try {
            const { data } = await SearchSurvey({ params: { bookingId: bookingId } });
            if (data.resultCode === 20000) {
                const resData = data.body
                const _bookingDetail = resData?.bookingDetail ?? {}
                const surveyList = resData?.survey ?? []
                const _list = surveyList.filter(item => item.partId)
                const _commentList = surveyList.find(item => item.partId === null)
                const _count = _list.reduce((pre, cur) => pre + cur.questionList.length, 0)

                setBookingDetail(_bookingDetail)
                setSurvey(resData)
                setSurveyList(_list)
                setCountSyList(_count)
                if (_commentList) {
                    let _comment = _commentList?.questionList[0] ?? {}
                    setSurveyComment(_comment)
                }
            } else if (data.resultCode === 40301) {
                const resData = data.body
                setIsSuccess(true)
                setErrCode(40301)
                setSPoint(resData.point)
            } else if (data.resultCode === 40401) {
                navigate('/not-found')
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log("ERR getSurvey", e);
        }
    };

    const postSurvey = async (payload) => {
        setIsLoading(true)
        try {
            const { data } = await CreateAnsSurvey({ data: payload });
            if (data.resultCode === 20000) {
                const resData = data.body;
                setIsSuccess(true)
                setSPoint(resData.point)
            } 
            else if (data.resultCode === 40301) {
                navigate('/not-found')
            } else if (data.resultCode === 40401) {
                navigate('/not-found')
            }
            setTimeout(() => { setIsLoading(false) }, 500)
        } catch (e) {
            console.log(`🦄: postSurvey -> e`, e);
            setTimeout(() => { setIsLoading(false) }, 500)
        }
    };

    function onSubmit() {
        let payload = {
            bookingId: bookingId,
            answerList: []
        }

        // ส่วนดาว
        const _answerList = convertAnswerList(surveyList, sValue)
        payload.answerList = _answerList
        
        // ส่วนคอมเม้นต์
        const tmp = { questionId: null, comment: text };
        payload.answerList.push(tmp)
        console.log('onSubmit payload >>', payload)

        postSurvey(payload)
    }
    
    function onLoadWeb() {
        getSurvey(bookingId)
    }
    
    useEffect(()=> {  
        onLoadWeb()
    }, [lang])

    if (isLoading) {
        return <></>
    }


    if (isSuccess) {
        return <SurveySuccess point={sPoint} errCode={errCode}/>
    }

    return (
        <>
        <WebContainer bgGraphics={true} noLogin bgGray>
            <GridMain className="" topSpace="1" botSpace="1">
                <GridRap className="bg-white" isSmallSize>
                    <div className="py-[24px] px-[20px] md:py-[32px] md:px-[24px]">
                        <div className="mb-[24px] md:mb-[32px]">
                            <div className="text-center mb-[24px]">
                                <h5 className="cp-title mb-[16px]">Help Us Improve</h5>
                                <h5 className="cp-sub-title">Complete our survey</h5>
                            </div>
                            <div>
                                {/* <Label size2 bold color2>Booking Details</Label> */}
                                <div className="gird-auto mb-[8px] border-Neutral-A300 border-t-[1px] border-b-[1px] py-[4px] md:py-[8px]">
                                    <InfoText2 img={IconMarker} title="" value={bookingDetail.location}/>
                                    <InfoText2 img={IconCalendar} title="" value={moment(bookingDetail?.bookingDate).format('DD MMM YYYY')}/>
                                    <InfoText2 img={IconTime} title="" value={bookingDetail.startTime ? helper.TIME.toShortTimeAmPm(bookingDetail.startTime) : bookingDetail.startTime}/>
                                    <InfoText2 img={IconTag} title="" value={`${helper.FN.toNumberWithCommas(bookingDetail.totalPrice)} THB`}/>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[24px]">
                        {
                            surveyList.map((item0, i) => {
                                return (
                                    <div key={i} className="">
                                        <h5 className="cp-title-2">{item0.partName}</h5>
                                        <div className="flex flex-col gap-[24px] md:gap-[16px] py-[24px]">
                                            {
                                                item0.questionList.map((item1, j) => {
                                                    return (
                                                        <div key={j} className="flex  items-center justify-between">
                                                            <p className="text-[16px] md:text-[16px] text-Neutral-A800 font-medium">{item1.questionName}</p>
                                                            <RatingStar 
                                                                value={objToValue(sValue, item1.questionId)} 
                                                                onChange={(v) => {
                                                                    console.log('v star questionId >>', item1.questionId)
                                                                    console.log('v star >>', v)
                                                                    let tmpObj = _.cloneDeep(sValue)
                                                                    tmpObj[item1.questionId] = v
                                                                    setSValue(tmpObj)
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                        <div className="flex flex-col gap-[8px] mb-[24px]">
                            <p className="text-[16px] md:text-[16px] text-Neutral-A800 font-medium">{surveyComment.questionName}</p>
                            <textarea
                                className="textarea"
                                rows="2"  // Specify the number of visible rows
                                value={text} // Bind the value to the state
                                placeholder="Comment"
                                onChange={handleTextChange} // Attach the event handler
                            />
                        </div>
                    <div className="text-center">
                        <ButtonPrimary
                            size="mini"
                            addClass="w-full max-w-[188px]"
                            onClick={onSubmit}
                            disabled={Object.keys(sValue).length < countSyList || isLoading}
                        >
                            Submit
                        </ButtonPrimary>
                    </div>
                    </div>
                </GridRap>
            </GridMain>
        </WebContainer>
        </>
    )
}

const InfoText2 = ({ img , title, value = "-" , className = "", styleList = null }) => {
    let pClassName = ""
    let spClassName = ""
    if (styleList == 0) {
      pClassName = "md:text-right md:w-[200px]"
      spClassName = "md:ml-[40px]"
    }
    return (
        <div className={`flex gap-[8px] items-center ${className}`}>
          { img ? <img className="w-auto h-[16px]" src={img} alt="img-info" /> : <></> }
          <p className={`text-[14px] md:text-[16px] font-medium ${pClassName}`}>{ title ? `${title}:` : '' } <span className={`ml-[2px] text-Neutral-A800 font-normal ${spClassName}`}>{value}</span></p>
        </div>
    )
}

function objToValue(objValue = {}, id) {
    if (objValue[id]) {
        return objValue[id]
    }
    return 0
}

function convertAnswerList(list = [], objValue = {}) {
    let ans = []
    for (let l1 of list) {
        for (let l2 of l1.questionList) {
            const tmp = { questionId: l2.questionId, answerRate: objToValue(objValue, l2.questionId)  }
            ans.push(tmp)
        }
    }
    return ans
}