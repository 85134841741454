import React, { useEffect, useState } from "react";
import { CreateOrder, CancelOrder } from "../../services/Payment";
import moment from "moment";
import AppConfig from "../../utils/config";
const CONFIG = AppConfig.config()


function PaymentQR({ 
    bookingId = null, 
    amount = 1,
    sourceType = "qr",
    description = "", 
    referenceOrder = "",
    onSuccess = () => { console.log('onSuccess!') }
  }) {
  const [order, setOrder] = useState({});
  const [ intervalId, setIntervalId ] = useState(null)

  const handleClosePopup = (e, orderData, intervalId) => {
    console.log('handleClosePopup e >>', e)
    console.log('handleClosePopup orderData >>', orderData)
    console.log('handleClosePopup intervalId >>', intervalId)
    clearInterval(intervalId)
    // const payload = {
    //   orderId: orderData.id
    // }
    // postCancelOrder(payload)
  }

  const handleSubmitPopup = (e, orderData) => {
    e.preventDefault();
    console.log('handleSubmitPopup e >>', e)
    console.log('handleSubmitPopup orderData >>', orderData)
    
    const submitter = document.querySelector("#kbankcheckout input[name='token']");
    console.log("handleSubmitPopup submitter >>", submitter)
    
    onSuccess()
    // postInqueryOrder({ bookingId: bookingId, orderId: order.id })
  }

  const getMeasurement = async ( orderData = {}, intervalId = null ) => {
    console.log("getMeasurement orderData >>", orderData);

    try {
      console.log("getMeasurement onload >> ", window.KPayment);

      try {
        var eletoken = document.getElementsByName("token");
        for (var i = 0; i < eletoken.length; i++) {
          eletoken[i].remove();
        }
      } catch (e) {
        console.log(e);
      }

      // setData เข้า Popup
      if (sourceType === 'qr') {
        window.KPayment.setMid(CONFIG.PAYMENT_GETEWAY.DATA_APIMID_QR);
        window.KPayment.setPaymentMethods("qr");
      } else if (sourceType === 'wechat') {
        window.KPayment.setMid(CONFIG.PAYMENT_GETEWAY.DATA_APIMID_WE_CHAT);
        window.KPayment.setPaymentMethods("wechat");
      } else {
        window.KPayment.setMid('');
      }
      window.KPayment.setAmount(orderData.amount);
      window.KPayment.setOrderId(orderData.id);
      console.log('window.KPayment >>', window.KPayment)
      setTimeout(() => {
        window.KPayment.show(); // สั่งเปิด Popup ขึ้นมา
        window.KPayment.onClose((e) => handleClosePopup(e, orderData, intervalId)); // เรียกตอนออก Popup
        document.getElementById("kbankcheckout").onsubmit = (e) => handleSubmitPopup(e, orderData, intervalId); // เรียกตอนSubmit Popup

      }, 100)

    } catch (e) {
      console.log(e);
    }
  };

  async function postCreateOrder(payload) {
    try {
      const { data } = await CreateOrder({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body;
        // console.log('resData >>', resData)
        setOrder(resData);
        if (resData.redirect) {
          // alert(resData.redirect)
          // navigate(resData.redirect)
          window.location.href = resData.redirect
        }
      }
    } catch (e) {
      console.log(`🦄: postCreateOrder -> e`, e);
      console.log("err", e);
    }
  }

  async function postCancelOrder(payload) {
    try {
      const { data } = await CancelOrder({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body;
        // console.log('resData >>', resData)
        setOrder(resData);
      }
    } catch (e) {
      console.log(`🦄: postCreateOrder -> e`, e);
      console.log("err", e);
    }
  }

  function genOrder() {
    const payload = {
      bookingId: bookingId,
      amount: amount,
      currency: "THB",
      description: description,
      sourceType: sourceType,
      referenceOrder: referenceOrder,
    };
    console.log("genOrder !!!");
    postCreateOrder(payload)
  }


  useEffect(() => {
    console.log('useEffect order >>', order)
    if (order.id) {
      // const tmpIntervalId = setInterval(() => postInqueryOrder({ orderId: order.id }), 4000)
      const tmpIntervalId = null
      getMeasurement(order, tmpIntervalId)
      
      console.log('intervalId >>', tmpIntervalId)
      setIntervalId(tmpIntervalId);

      return () => {
        clearInterval(tmpIntervalId);
      };
    }
    
  }, [order]);

  useEffect(() => {
    genOrder();
  }, []);
  
  return <div></div>
  // return <div>PaymentQR {intervalId}</div>;
}

export default PaymentQR;