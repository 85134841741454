import React from "react";
import GridRap from "../../components/Layouts/GridRap";
import GridMain from "../../components/Layouts/GridMain";
import moment from "moment";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../components/button/ButtonPrimary";

export default function FooterSuccess({ 
  onClose , onSave, 
  isNext = true,
  labelNext = "Next",
}) {

  const handleSelect = () => {
    if (onSave) {
      onSave()
    }
  }

  return (
    <>
      <footer className="normal">
        <div className="box-2">
          <GridMain>
            <GridRap isSmallSize>
              <div className="flex justify-center gap-[16px] md:gap-[24px]">
                <ButtonPrimary 
                  size="mini"
                  addClass="w-full md:max-w-[366px]"
                  onClick={handleSelect}
                >
                  My Booking
                </ButtonPrimary>
              </div>
            </GridRap>
          </GridMain>
        </div>
      </footer>
    </>
  );
}