import React from "react";
import Label2 from "./Label2";
import Label from "./Label";


import IconCalendar from '../../assets/images/booking/step3/calendar.svg'
import IconClock from '../../assets/images/booking/step3/clock.svg'
import IconContact from '../../assets/images/booking/step3/contact.png'
import IconUser from '../../assets/images/booking/step3/user.svg'

export default function ImageLabel({ src = null, dataType = "", children, size = "" }) {
  switch(dataType) {
    case "date":
      src = IconCalendar
      break;
    case "time":
      src = IconClock
      break;
    case "contact":
      src = IconContact
      break;
    case "user":
      src = IconUser
      break;
    default:
      src = src
  }
  let styleSize = {
    img: "w-auto h-[16px] md:h-[20px]",
    // label: "leading-[20px]"
  }
  if (size == "mini") {
    styleSize.img = "w-auto h-[12px] md:h-[16px]"
    // styleSize.label = "leading-[16px]"
  }
  return (
    <>
      <div className="flex items-center gap-[8px]">
        <img src={src} alt="" className={styleSize.img} />
        {
          size == "mini" ?(
            <Label bold color2 size2 className="leading-[20px]" style={{ marginBottom: "0px" }}>{children}</Label>
          )
          : <Label bold color2 size2 className="leading-[20px]" style={{ marginBottom: "0px", fontSize: "16px" }}>{children}</Label>
          
        }
      </div>
    </>
  );
}
