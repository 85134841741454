import React from "react";
import Layouts from "./layout/Layout";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLiff } from "react-liff";
import { CircularProgress } from "@mui/joy";

import LoadingMain from "./components/LoadingMain";


import Register from "./pages/Register/Register";
import RegisterStep2 from "./pages/Register/RegisterStep2";
import Test from "./pages/Register/Test";
import Completed from "./pages/Register/Completed";
import CheckEmail from "./pages/Register/CheckEmail";
import Login from "./pages/Login/login";
import Profile from "./pages/Profile/Profile";
import ProfileView from "./pages/Profile/ProfileView";
import helper from "./services/helper";
import ForgetPassword from "./pages/Register/ForgetPassword";
import ResetPassword from "./pages/Register/ResetPassword";
import AuthGuard from "./components/Auth/AuthGuard";
import UnAuthGuard from "./components/Auth/UnAuthGuard";
import ProfileEdit from "./pages/Profile/ProfileEdit";
import Settings from "./pages/Settings/Settings";
import PrivacyPolicy from "./pages/Settings/PrivacyPolicy";
import TermsCondition from "./pages/Settings/TermsCondition";
import PhoneInputField from "./components/Other/PhoneInputField";
import { useDispatch, useSelector } from 'react-redux';
import { SearchMemberById } from "./services/Member";
import { setProfile } from "./features/ProfileSlice"
import BookingMain from "./pages/BookingNew/BookingMain";
import BookingStep1 from "./pages/BookingNew/BookingStep1";
import BookingStep2 from "./pages/BookingNew/BookingStep2";
import BookingStep3 from "./pages/BookingNew/BookingStep3";
import BookingStep4 from "./pages/BookingNew/BookingStep4";
import BookingStep5 from "./pages/BookingNew/BookingStep5";
import BookingSuccess from "./pages/BookingNew/BookingSuccess";
import BankPaymentMock from "./pages/BookingNew/BankPaymentMock";
import Membership from "./pages/MemberShip/Membership";
import Print from "./pages/BookingPrint/Print";

import LoginLine from "./pages/Login/LoginLine";

// MyBooking
import MyBooking from "./pages/MyBooking/MyBooking";
import BookingDetail from "./pages/MyBooking/BookingDetail";
import CheckIn from "./pages/MyBooking/Checkin";

// history
import History from "./pages/History2/HistoryPage";
import HistoryDetail from "./pages/History2/HistoryDetail";

// survey
import Survey from "./pages/Survey/Survey";

// ImportSlot
import ImportSlot from "./pages/ImportSlot/ImportSlot";
import NotFound404 from "./pages/NotFound404";
import PaymentPage from "./pages/PaymentPage/PaymentPage";
import PaymentDev from "./pages/PaymentPage/PaymentDev";

const theme = createTheme({
  typography: {
    fontFamily: `'Helvetica Neue', 'Kanit'`,
    // fontSize: 16,
    // fontWeightLight: 300,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
  },
});

function App() {
  const { isLoggedIn, liff } = useLiff();

  let sub = null
  window.localStorage.removeItem("sub");
  // window.localStorage.removeItem("token"); // mocktoken ลงไป
  if (isLoggedIn) {
    sub = liff.getDecodedIDToken().sub;
    window.localStorage.setItem("lineId", sub);
  }
  
  return (
    <>
      {isLoggedIn && (
        <ThemeProvider theme={theme}>
          <Layouts>
            <LoadingMain />
            <Routes>
              <Route path="/line-login" element={<LoginLine lineId={sub}/>} />

              {/* เข้าได้หมด */}
              {/* <Route path="/test" element={<PhoneInputField />} /> */}
              <Route path="/completed" element={<Completed />} />


              {/* ยังไม่ LOGIN  */}
              <Route path="/" element={<BookingStep1 />} />
              <Route path="/login" element={<UnAuthGuard component={<Login />} />} />
              <Route path="/register" element={<UnAuthGuard component={<Register />} />} />
              <Route path="/register-step2" element={<UnAuthGuard component={<RegisterStep2 />} />} />
              <Route path="/check-email" element={<UnAuthGuard component={<CheckEmail />} />} />
              <Route path="/forget-password" element={<UnAuthGuard component={<ForgetPassword />} />} />
              <Route path="/setpassword/:token" element={<UnAuthGuard component={<ResetPassword />} />} />

              {/* ต้อง LOGIN ก่อน */}
              <Route path="/profile" element={<AuthGuard component={<Profile />} />} />
              <Route path="/profile-view" element={<AuthGuard component={<ProfileView />} />} />
              <Route path="/profile-edit" element={<AuthGuard component={<ProfileEdit />} />} />

              <Route path="/settings" element={<AuthGuard component={<Settings />} />} />
              <Route path="/privacy-policy" element={<AuthGuard component={<PrivacyPolicy />} />} />
              <Route path="/term-condition" element={<AuthGuard component={<TermsCondition />} />} />

              <Route path="/booking" element={<BookingMain />} />

              {/* booking step 1 - 5 */}
              <Route path="/booking-step1" element={<BookingStep1 />} />
              <Route path="/booking-step2" element={<BookingStep2 />} />
              <Route path="/booking-step3" element={<BookingStep3 />} />
              <Route path="/booking-step4" element={<AuthGuard component={<BookingStep4 />} />} />
              <Route path="/booking-step5" element={<AuthGuard component={<BookingStep5 />} />} />
              <Route path="/booking-success/:token" element={<BookingSuccess />} />

              {/* my booking */}
              <Route path="/my-booking" element={<AuthGuard component={<MyBooking />} />} />
              <Route path="/booking-detail/:bookingId" element={<AuthGuard component={<BookingDetail />} />} />
              <Route path="/check-in/:bookingId" element={<AuthGuard component={<CheckIn/>} />} />

              {/* history */}
              <Route path="/history" element={<AuthGuard component={<History />} />} />
              <Route path="/history-detail/:bookingId" element={<AuthGuard component={<HistoryDetail />} />} />

              {/* member ship */}
              <Route path="/membership" element={<AuthGuard component={<Membership />} />} />

              {/* survey */}
              <Route path="/survey/:bookingId" element={<Survey />} />


              <Route path="/import-slot" element={<ImportSlot />} />


              <Route path="/print/:bookingId" element={<Print />} />



              <Route path="/bank-payment-mock/:bookingId" element={<BankPaymentMock/>} />
              <Route path="/not-found" element={<NotFound404/>} />
              
              <Route path="/bank-payment/:bookingId" element={<PaymentDev/>} />
              <Route path="*" element={<NotFound404/>} />
              </Routes>
          </Layouts>
        </ThemeProvider>
      )}
      {!isLoggedIn && (
        <div>
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default App;
