import React from "react";

export default function Label({ children, thin, color2, size2, className , style = {}}) {
  let fWeight = 'font-medium'
  let fColor = 'text-Neutral-A600'
  let fSize = 'text-[12px] md:text-[16px]'
  if (thin) {
    fWeight = 'font-normal'
  }
  if (color2) {
    fColor = 'text-Neutral-A800'
  }
  if (size2) {
    fSize = 'text-[14px] md:text-[16px]'
  }
  return (
    <>
      <p className={`${fSize} ${fWeight} md:${fWeight} ${fColor} mb-[4px] md:mb-[4px] ${className}`} style={style}>{children}</p >
    </>
  );
}
