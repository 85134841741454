import React from "react";
import GridMain from "../../components/Layouts/GridMain";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import useTranslations from "../../i18n/useTranslations";
import GridRap from "../../components/Layouts/GridRap";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import { Link } from "react-router-dom";

export default function FooterBase({ currentStep = 0, onBack, onNext, labelBack = "Back", labelNext = "Next", notYes = false, isFixed = false, btnNoSubmit = false }) {
  const { t } = useTranslations();
  let class0 = ""
  if (isFixed) {
    class0 = "fixed-footer"
  }
  return (
    <div class={class0}>
      <div className="footer-normal-container fixed-mb">
        <ButtonPrimaryOutline
            type="button"
            addClass=""
            onClick={() => {
              onBack();
            }}
          >
            {labelBack}
          </ButtonPrimaryOutline>
          {
            notYes
            ? <></>
            : (
              <ButtonPrimary
                type={btnNoSubmit ? "button" : "submit" }
                onClick={() => {
                  onNext();
                }}
                addClass=""
              >
                {labelNext}
              </ButtonPrimary>
            )
          }
        
      </div>
      <div className="tail-box"/>
    </div>
    
  );
}
