import { get, post } from "./Api";


// STEP 2
export function SearchProgramTreatment({ params }) {
  return get({ url: `/api/v1/healthland/booking/searchProgramTreatment`, params });
}


// STEP 3
export function SearchEmptySlot({ params, data }) {
  return post({ url: `/api/v1/healthland/booking/searchEmptySlot`, params, data });
}
export function SearchEmptySlotAll({ params, data }) {
  return post({ url: `/api/v1/healthland/booking/searchEmptySlotAll`, params, data });
}
export function CreateReserveSlot({ params, data }) {
  return post({ url: `/api/v1/healthland/booking/reserveSlot`, params, data });
}

// > STEP4 ต้อง GET ตลอด
export function SearchMyBooking({ params }) {
  return get({ url: `/api/v1/healthland/booking/searchMyBooking`, params });
}


// STEP 4
export function SearchBookingDetails({ params }) {
  return get({ url: `/api/v1/healthland/booking/searchBookingDetails`, params });
}
export function UpdateContactInfo({ params, data }) {
  return post({ url: `/api/v1/healthland/booking/updateContactInfo`, params, data });
}
export function UpdateCancelReserveSlotBooking({ params, data }) {
  return post({ url: `/api/v1/healthland/booking/cancelReserveSlotBooking`, params, data });
}


// STEP 5
export function SearchCurrentPoint({ params }) {
  return get({ url: `/api/v1/healthland/booking/searchCurrentPoint`, params });
}
export function SearchBookingExpire({ params }) {
  return get({ url: `/api/v1/healthland/booking/searchBookingExpire`, params });
}
export function UpdateBooking({ params, data }) {
  return post({ url: `/api/v1/healthland/booking/updateBooking`, params, data });
}

// STEP 6 PAYMENT
export function UpdateCancelReserveSlotPayment({ params, data }) {
  return post({ url: `/api/v1/healthland/booking/cancelReserveSlotPayment`, params, data });
}
export function UpdateBookingPayment({ params, data }) {
  return post({ url: `/api/v1/healthland/booking/updateBookingPayment`, params, data });
}

// STEP Other
export function SearchMyBookingList({ params }) {
  return get({ url: `/api/v1/healthland/booking/searchMyBookingList`, params });
}
export function SearchBookingHistory({ params }) {
  return get({ url: `/api/v1/healthland/booking/searchBookingHistory`, params });
}
export function GetPayloadRescheduleBooking(bookingId) {
  return get({ url: `/api/v1/healthland/booking/getPayloadRescheduleBooking/${bookingId}` });
}
export function UpdateRescheduleBooking({ params, data }) {
  return post({ url: `/api/v1/healthland/booking/rescheduleBooking`, params, data });
}