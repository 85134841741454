import React, { useState, useEffect } from 'react'
import Label from '../../components/general/Label'
import { useForm } from 'react-hook-form';
import useTranslations from '../../i18n/useTranslations';
import IconTel from "../../assets/images/icon-tel.png";
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import { SearchBookingDetails } from "../../services/Booking";
import { AuthVerifyToken } from "../../services/Auth";
import helper from '../../services/helper';
import moment from 'moment';
import { useNavigate, useParams } from "react-router-dom";
import GridRap from '../../components/Layouts/GridRap';

import IconUser from "../../assets/images/svg/guest-one.svg";
import IconUserGold from "../../assets/images/svg/guest-gold-one.svg";
import IconCalendar from "../../assets/images/svg/calendar-one.svg";
import IconTime from "../../assets/images/svg/time-one.svg";
import IconMarker from "../../assets/images/svg/marker-one.svg";
import IconTag from "../../assets/images/svg/tag-one.svg";
import FooterSuccess from './FooterSuccess';
import BookingDetailTemplate from '../MyBooking/BookingDetailParts/BookingDetailTemplate';

const { toNumberWithCommas } = helper.FN
export default function BookingSuccess() {
  const navigate = useNavigate();
  const { token } = useParams();
  

  const [ loading, setLoading ] = useState(true)
  const [ bookingDetail, setBookingDetail ] = useState({})
  const [ bookingList, setBookingList ] = useState([])

  const { register, formState: { errors }, handleSubmit, setValue, control, watch } = useForm({
  });
  const { t } = useTranslations();

  const getBookingDetails = async (payload) => {
    try {
      const { data } = await SearchBookingDetails({ params: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        setBookingDetail(resData)
        setBookingList(resData.bookingList)
        // setValue('branch', Branch.id)
      }
    } catch (e) {
      console.log(`🦄: getBookingDetails -> e`, e);
    }
  };

  const postAuthVerifyToken = async (payload) => {
    try {
      const { data } = await AuthVerifyToken({ data: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body

        const { bookingId } = helper.decodeJWT(token)
        const payload = { bookingId: bookingId }
        getBookingDetails(payload)
      }
      setTimeout(() => setLoading(false), 100)
    } catch (e) {
      console.log(`🦄: postAuthVerifyToken -> e`, e);
      setTimeout(() => setLoading(false), 100)

    }
  };

  useEffect(() => {
    console.log('useEffect Booking Success !')
    window.localStorage.removeItem('step1')
    window.localStorage.removeItem('step2')
    // const { bookingId } = helper.decodeJWT(token)
    const payload = { token: token }
    postAuthVerifyToken(payload)
  }, [])


  function gotoMyBooking() {
    navigate('/my-booking')
  }

  if (loading) {
    return <></>
  }

  return (
    <>
      <WebContainer>
        <GridMain className="bk-container bk-step5">
          <GridRap isSmallSize>
            <BookingDetailTemplate
              title="Booking Success"
              bookingDate={bookingDetail.bookingDate}
              bookingNo={bookingDetail.bookingNo}
              bookingDetail={bookingDetail}
              bookingList={bookingList}
              // status={convertStatus(bookingDetail.bookingStatus, bookingDetail.paymentStatus)}
            />
          </GridRap>
        </GridMain>
      </WebContainer>
      <FooterSuccess onSave={() => gotoMyBooking()}/>

    </>
  )
}
const TextUnit = ({ value, unit }) => {
  return (
    <div className="flex flex-col items-center">
      <p className="text-[16px] md:text-[16px]">{value}</p>
      <p className="text-[14px] md:text-[16px]">{unit}</p>
    </div>
  )
}
const CircleHLImage = ({ src, outline }) => {
  let style = "bg-[#649591]"
  if (outline) {
    style = "border-[#649591] border-[1px]"
  }
  return (
    <div className={`flex justify-center items-center ${style} w-[40px] md:w-[72px] h-[40px] md:h-[72px] rounded-[50%]`}>
      <img src={src} alt="" className="" />
    </div>
  )
}

const TitleSS = ({ small = false, children }) => {
  let sizeStyle = "text-[16px] md:text-[24px]"
  if (small) {
    sizeStyle = "text-[16px] md:text-[20px]"
  }
  return (
    <h5 className={`text-[#DDB05B] ${sizeStyle} font-medium`}>{children}</h5>
  )
}
const InfoTextFull = ({ title = '', value = '', bold }) => {
  let boldStyle = ""
  if (bold) {
    boldStyle = "font-medium"
  }
  return (
    <div className="flex justify-between">
      <p className={`text-[14px] md:text-[16px] leading-[1em] text-Neutral-A800 ${boldStyle}`}>{title}</p>
      <p className={`text-[14px] md:text-[16px] leading-[1em] text-Neutral-A800 font-medium`}>{value}</p>
    </div>
  )
}
const TextUnitIcon = ({ src, title, value }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center gap-[8px]">
        <img className="w-[12px] h-[12px] md:w-[20px] md:h-[20px]" src={src} alt="icon"/>
        <p className="text-[16px] md:text-[20px] font-medium">{title}</p>
      </div>
      <p className="text-[14px] md:text-[16px]">{value}</p>
    </div>
  )
}