import React, { useEffect, useState } from "react";
import IconShield from "../../../assets/images/icon-shield.png";
import IconExam from "../../../assets/images/icon-exclamation.png";
import Heat from "../../../assets/images/heat.png";
import Infected from "../../../assets/images/infected.png";
import Hearth from "../../../assets/images/hearth.png";
import HC1 from "../../../assets/images/hc-1.png";
import HC2 from "../../../assets/images/hc-2.png";
import HC3 from "../../../assets/images/hc-3.png";
import FooterBookingCondition from "./FooterBookingCondition";
// import { SearchHealthConditions } from "../../../../services/Booking";
import ImgAgeHeath from "../../../assets/images/booking/age-heath.png";
import styled from 'styled-components';
import GridRap from "../../../components/Layouts/GridRap";
import GridMain from "../../../components/Layouts/GridMain";
import WebContainer from "../../../components/Layouts/WebContainer";
import useTranslations from "../../../i18n/useTranslations";

const AgeHeath = styled.div`
  width: 80px;
  height: 80px;
  background: url(${ImgAgeHeath}) center center no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @media (min-width: 768px) {
    height: 112px;
    width: 112px;
  }
`

export default function PopupBookingCondition({ onClose, onSave }) {
  const { t } = useTranslations();
  const [condition, setCondition] = useState([]);

  useEffect(() => { window.scrollTo({ top: 0 }) }, []);

  const handleBack = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleSelect = () => {
    if (onSave) {
      onSave()
    }
  }

  return (
    <>
      {/* {JSON.stringify(condition)} */}
      <WebContainer bgGray={true}>
        <GridMain className="popup-booking-space px-full-mobile">
          <GridRap isMediumSize={true}>
            <div className="w-full bg-white  py-[36px] md:py-[36px] px-[38px] md:px-[24px]">
              <div className="flex flex-col items-center">
                <AgeHeath/>
                <p className="text-brown-0 text-[20px] md:text-[24px] font-medium mb-[16px] md:mb-[24px]">
                  {t.ageHealthConditions.title}
                </p>
                <p className="text-[14px] md:text-[16px] leading-[28px] font-light">
                  {t.ageHealthConditions.sub_title}
                </p>
              </div>
              <div className="dt-column">
                <aside>
                  <div className="flex flex-col items-center gap-[16px]">
                    <label className="text-[16px] md:text-[20px] font-medium text-brown-1 text-center">{t.ageHealthConditions.list_t}</label>
                    <div className="flex justify-center gap-[28px] w-full">
                      <CircleHLImage src={Heat}/>
                      <CircleHLImage src={Infected}/>
                      <CircleHLImage src={Hearth}/>
                    </div>
                  </div>
                  <div className="my-5">
                    <ul className="listStyle-s5-0">
                      <LiNo>{t.ageHealthConditions.list0_0}</LiNo>
                      <LiNo>{t.ageHealthConditions.list0_1}</LiNo>
                      <LiNo html={t.ageHealthConditions.list0_2}>{t.ageHealthConditions.list0_2}</LiNo>
                      <LiNo>{t.ageHealthConditions.list0_3}</LiNo>
                      <LiNo>{t.ageHealthConditions.list0_4}</LiNo>
                      <LiNo>{t.ageHealthConditions.list0_5}</LiNo>
                      <LiNo>{t.ageHealthConditions.list0_6}</LiNo>
                      <LiNo>{t.ageHealthConditions.list0_7}</LiNo>
                      <LiNo>{t.ageHealthConditions.list0_8}</LiNo>
                      <LiNo>{t.ageHealthConditions.list0_9}</LiNo>
                      {/* <LiNo>Inflamed or infected wound</LiNo>
                      <LiNo>Broken or fractured bone</LiNo>
                      <LiNo>
                        Uncontrolled heart conditions, hypertension,diabetes especially
                        with symptoms of fainting,palpitations, headache or nausea
                      </LiNo>
                      <LiNo>Skin condition, open wound or chronic ulcer</LiNo>
                      <LiNo>Contagious diseases</LiNo>
                      <LiNo>Cancer</LiNo>
                      <LiNo>Recent surgical wound</LiNo>
                      <LiNo>Deep Vein Thombosis</LiNo>
                      <LiNo>Severe Osteoporosis</LiNo> */}
                    </ul>
                  </div>
                </aside>
                <aside>
                  <div className="flex flex-col items-center gap-[16px]">
                    <label className="text-[16px] md:text-[20px] font-medium text-brown-1 text-center">{t.ageHealthConditions.list1_t}</label>
                    <div className="flex justify-center gap-[28px] w-full">
                      <CircleHLImage outline src={HC1}/>
                      <CircleHLImage outline src={HC2}/>
                      <CircleHLImage outline src={HC3}/>
                    </div>
                  </div>
                  <div className="my-5">
                    <ul className="listStyle-s5-0">
                      
                      <LiNo>{t.ageHealthConditions.list1_0}</LiNo>
                      <LiNo>{t.ageHealthConditions.list1_1}</LiNo>
                      <LiNo>{t.ageHealthConditions.list1_2}</LiNo>
                      <LiNo>{t.ageHealthConditions.list1_3}</LiNo>
                      <LiNo>{t.ageHealthConditions.list1_4}</LiNo>
                      <LiNo>{t.ageHealthConditions.list1_5}</LiNo>
                      <LiNo>{t.ageHealthConditions.list1_6}</LiNo>
                      <LiNo>{t.ageHealthConditions.list1_7}</LiNo>
                    </ul>
                  </div>
                </aside>
              </div>
              <aside className="mb-[20px] md:mb-[24px]">
                <label className="text-[16px] md:text-[20px] font-medium text-brown-1 text-center">{t.ageHealthConditions.list2_t}</label>
                <P>{t.ageHealthConditions.list2_0}</P>
              </aside>
              <aside>
                <label className="text-[16px] md:text-[20px] font-medium text-brown-1 text-center">{t.ageHealthConditions.list3_t}</label>
                <P>{t.ageHealthConditions.list3_0}</P>
              </aside>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
            <FooterBookingCondition onClose={handleBack} onSave={handleSelect}/>
    </>
  );
}

const CircleHLImage = ({ src, outline }) => {
  let style = "bg-[#9B4923]"
  if (outline) {
    style = "border-[#9B4923]"
  }
  return (
    <div className={`flex justify-center items-center ${style} w-[65px] h-[65px] rounded-[50%]`}>
      <img src={src} alt="" className="" />
    </div>
  )
}
const LiNo = ({ children, html = "" }) => {
  if (html) {
    return (
      <li 
        className="text-[14px] md:text-[16px] leading-[20px] md:leading-[28px] font-light" 
        dangerouslySetInnerHTML={{ __html:html }}
      />
    ) 
  }
  return <li className="text-[14px] md:text-[16px] leading-[20px] md:leading-[28px] font-light">{children}</li>
}
const P = ({ children }) => {
  return <p className="text-[14px] md:text-[16px] leading-[20px] md:leading-[28px] font-light">{children}</p>
}