import React, { useState } from "react";
import ModalBase from "../../components/Modal/ModalBase";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../components/button/ButtonPrimary";

export default function ModalTimeOutReSlot({ open = false, onClose, onSave }) {
  return (
    <>
      <ModalBase
        mini={true}
        open={open}
        onClose={() => {

        }}
        size="size3"
        header={
          <>
          </>
        }
        content={
          <div className="flex flex-col justify-center mb-[8px]">
            <h1 className="text-center text-[20px] md:text-[20px] text-[#914C2A] font-bold">Payment Time-out</h1>
            <p className="text-center text-[16px] md:text-[16px]">Your payment is about to time-out.<br/>Do you need check out again?</p>
          </div>
        }
        footer={
          <>
            <div className="flex w-full flex-col justify-center gap-[12px] mt-[16px]">
              <ButtonPrimary 
                // size="mini"
                addClass="w-full"
                onClick={() => {
                  if (onClose) {
                    onClose()
                  }
                }}
              >
                Yes
              </ButtonPrimary>
              <ButtonPrimaryOutline 
                // size="mini"
                addClass="w-full"
                onClick={() => {
                  if (onSave) {
                    onSave()
                  }
                }}
              >
                No
              </ButtonPrimaryOutline>
            </div>
          </>
        }
      />
    </>
  );
};