import React from "react";
import useTranslations from "../../../i18n/useTranslations";
import MyProfiles from "../../../components/general/MyProfile";

export default function EmptyPoint({ text = "No points earned yet." }) {
  const { t } = useTranslations();
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-[24px] md:gap-[16px] py-[82px]">
        <IconPoint />
        <p className="text-[16px] md:text-[16px] text-Neutral-A600">
          {text}
        </p>
      </div>
    </>
  );
}

const IconPoint = () => {
  return (
    <>
        <div className="hidden md:block">
          <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.0001 52.0835L40.3301 40.3985L52.0834 35.0002L40.3301 29.6702L35.0001 17.9168L29.6359 29.6702L17.9167 35.0002L29.6359 40.3985L35.0001 52.0835ZM21.9142 3.43016C26.0657 1.72165 30.5107 0.839617 35.0001 0.833496C39.4759 0.833496 43.9176 1.72183 48.0859 3.43016C52.2201 5.1385 55.9784 7.66683 59.1559 10.8443C62.3334 14.0218 64.8617 17.7802 66.5701 21.9143C68.2784 26.0827 69.1667 30.5243 69.1667 35.0002C69.1667 44.0543 65.5792 52.7668 59.1559 59.156C55.9869 62.3328 52.2216 64.8525 48.0763 66.5704C43.931 68.2884 39.4872 69.1707 35.0001 69.1668C30.5107 69.1607 26.0657 68.2787 21.9142 66.5702C17.7724 64.8511 14.0105 62.3316 10.8442 59.156C7.66739 55.987 5.1477 52.2218 3.42977 48.0764C1.71184 43.9311 0.829499 39.4874 0.833387 35.0002C0.833387 25.946 4.42089 17.2335 10.8442 10.8443C14.0217 7.66683 17.7801 5.1385 21.9142 3.43016ZM15.6617 54.3385C20.7867 59.4635 27.7567 62.3335 35.0001 62.3335C42.2434 62.3335 49.2134 59.4635 54.3384 54.3385C59.4634 49.2135 62.3334 42.2435 62.3334 35.0002C62.3334 27.7568 59.4634 20.7868 54.3384 15.6618C49.2063 10.5397 42.2509 7.66413 35.0001 7.66683C27.7567 7.66683 20.7867 10.5368 15.6617 15.6618C10.5396 20.7939 7.66402 27.7493 7.66672 35.0002C7.66672 42.2435 10.5367 49.2135 15.6617 54.3385Z" fill="#DEE2E6"/>
          </svg>
        </div>
        <div className="block md:hidden">
          <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.5 45.7085L35.245 35.306L45.7083 30.5002L35.245 25.7552L30.5 15.2918L25.7246 25.7552L15.2917 30.5002L25.7246 35.306L30.5 45.7085ZM18.8504 2.39516C22.5463 0.874172 26.5034 0.0889453 30.5 0.0834961C34.4846 0.0834961 38.4387 0.874329 42.1496 2.39516C45.83 3.916 49.1758 6.16683 52.0046 8.99558C54.8333 11.8243 57.0842 15.1702 58.605 18.8506C60.1258 22.5614 60.9167 26.5156 60.9167 30.5002C60.9167 38.5606 57.7229 46.3168 52.0046 52.0047C49.1834 54.8329 45.8314 57.076 42.1411 58.6054C38.4507 60.1348 34.4947 60.9203 30.5 60.9168C26.5034 60.9114 22.5463 60.1261 18.8504 58.6052C15.1632 57.0748 11.8142 54.8318 8.99541 52.0047C6.16726 49.1835 3.92412 45.8316 2.39475 42.1412C0.865372 38.4509 0.0798712 34.4949 0.083332 30.5002C0.083332 22.4397 3.27708 14.6835 8.99541 8.99558C11.8242 6.16683 15.17 3.916 18.8504 2.39516ZM13.2842 47.716C17.8467 52.2785 24.0517 54.8335 30.5 54.8335C36.9483 54.8335 43.1533 52.2785 47.7158 47.716C52.2783 43.1535 54.8333 36.9485 54.8333 30.5002C54.8333 24.0518 52.2783 17.8468 47.7158 13.2843C43.147 8.72437 36.955 6.16442 30.5 6.16683C24.0517 6.16683 17.8467 8.72183 13.2842 13.2843C8.72421 17.8531 6.16426 24.0451 6.16667 30.5002C6.16667 36.9485 8.72167 43.1535 13.2842 47.716Z" fill="#E9ECEF"/>
          </svg>
        </div>
    </>
  );
};
