import React from 'react'
import { useState } from 'react';
import BasicTabs from '../../../components/general/Tab';
import { useEffect } from 'react';
import SelectTreatmentsLists from './SelectTreatmentsLists';
import GridMain from '../../../components/Layouts/GridMain';
import { useDispatch, useSelector } from 'react-redux';
import { onTotalProgramPrice, onTotalProgramSelect, onTotalProgramTime, program, rawProgram, updateProgram } from '../../../features/BookingSlice';
import { setStep1, setStep2, setPopupTreatment } from "../../../features/BookingMainSlice";
import _ from 'lodash';
import WebContainer from '../../../components/Layouts/WebContainer';
import ButtonPrimary from '../../../components/button/ButtonPrimary';
import SelectTreatmentsFooter from './SelectTreatmentsFooter';
import helper from '../../../services/helper';
import useTranslations from "../../../i18n/useTranslations";
import IconTreament from '../../../assets/images/booking/step2/empty-treament.svg'


const programTreamentList = helper.Booking.mockProgramTreament

export default function SelectTreatments({ selectIndex = 0, component = <></> }) {
  const { t } = useTranslations();
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState(0);
  const [programId, setProgramId] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);


  // values  หน้า Popup
  const [values, setValues] = useState({});


  const popupTreatment = useSelector((state) => state.bookingMain.popupTreatment)
  const step1 = useSelector((state) => state.bookingMain.step1)
  const step2 = useSelector((state) => state.bookingMain.step2)
  const programTreament = useSelector((state) => state.programTreament.data);
  
  // State กลาง
  const { guestData } = step2
  const { isOpen, guestIndex } = popupTreatment

  // const programTreament = programTreament

  const handleChange = (event, newValue = 0) => {
    setTabValue(newValue);
    const _programId = programTreament[newValue]?.programId ?? null
    setProgramId(_programId)
    setActiveIndex(null)
  };


  function onOpenPopup(value) {
    const tmpObj = { isOpen: value, guestIndex: null };
    dispatch(setPopupTreatment(tmpObj));
  }

  useEffect(() => {
    setTabValue(0) // เปิดหน้าใหม่ให้ กลับมาอันแรก
    handleChange(null, 0)

    // โหลด Data เข้า
    const _guestData = _.cloneDeep(guestData)
    const _values = _guestData[guestIndex]?.values ?? {}
    // console.log('_values >>', _values)
    setValues(_values)
  }, [isOpen])

  
  function onSelectCheckBox(programId, treatmentId, subTreatmentId){
    console.log('p,t,sb', programId, treatmentId, subTreatmentId)
    let keyMap = `${programId}|${treatmentId}|${subTreatmentId}`

    let tmp = { ...values } 

    if (tmp[keyMap]) {
      tmp[keyMap] = false
      delete tmp[keyMap]
    } else {
      tmp[keyMap] = true 
    }

    console.log('values >>', values)
    setValues(tmp)
  }

  

  function setGuestDataValues(_guestIndex, values = {}) {
    const _guestData = _.cloneDeep(guestData)
    _guestData[guestIndex].values = values

    console.log('_guestData >>', _guestData) // values เข้า guest แต่ละคน
    const tmpObj = { guestData: _guestData };
    console.log('tmpObj >>', tmpObj)
    dispatch(setStep2(tmpObj));
  }



  function onSave() {
    onOpenPopup(false)
    setGuestDataValues(guestIndex, values)
    setValues({}) // Clear State
  }

  function onClose() {
    onOpenPopup(false)
    setValues({}) // Clear State
  }



  function onSelectActive(index){
    setActiveIndex(index)
  }
  

  const programTreamentView = programTreament[tabValue] ?? { treatment: [] }
  const treatmentList = programTreamentView.treatment ?? []

  console.log('treatmentList >>', treatmentList)
  if(!isOpen) {
    return <>{component}</>
  }
  
  return (
    <>
      {/* <br/> */}
      {/* <button onClick={() => onOpenPopup(false)}>CLOSE</button> */}
      {/* <br/> */}
      {/* values STATE : {JSON.stringify(values)} */}
      <div>
        <div className="flex flex-col items-center justify-center mb-[24px] md:mb-[32px]">
          <h5 className="cp-title mb-[8px] md:mb-[16px]">Select Treatments</h5>
          <p className="text-[12px] md:text-[16px]">{t.selectTreatments.title0}</p>
        </div>
        {
          programTreament.length === 0
          ? <EmptyTreament/>
          : (
            <div className="flex flex-col md:flex-row">
              <BasicTabs
                tabs={programTreament}
                value={tabValue}
                handleChange={handleChange}
                keyName="programName"
              />
              
              <SelectTreatmentsLists
                selectIndex={selectIndex}
                programId={programId}
                treatmentList={treatmentList}
                onChange={onSelectCheckBox}
                values={values}
                onActive={onSelectActive}
                indexActive={activeIndex}
              />
            </div>
          )
        }
        
      </div>
      {/* <div className="w-full">
        <ButtonPrimary  addClass="w-[415px]" onClick={onSave}>SAVE</ButtonPrimary>
      </div> */}
      <SelectTreatmentsFooter 
        values={values}
        onClose={onClose} 
        onSave={onSave}
        isNext={true}
      />
    </>
  )
}

const EmptyTreament = () => {
 
  return (
    <div className="flex flex-col gap-[16px] justify-center items-center min-h-empty-treament">
      <img className="h-auto w-full max-w-[62px] md:max-w-[80px] mx-auto" src={IconTreament} alt=""/>
      <div className="flex flex-col gap-[4px] md:gap-[8px]">
        <p className="text-[20px] text-center text-Neutral-A900">
          All Services Currently Unavailable
        </p>
        <p className="text-[14px] md:text-[16px] text-center text-Neutral-A600">
          We apologize for any inconvenience <br className="block md:hidden" /> this may cause, but all <br className="hidden md:block" />
          of our services are <br className="md:hidden"/> currently unavailable for booking.
        </p>
      </div>
    </div>
  )
}

