import React, { useEffect, useState } from "react";
import SinginLogo from "../../assets/images/logo-lotus.png";
import { useNavigate, useLocation } from "react-router-dom";
import helper from "../../services/helper";
import useTranslations from "../../i18n/useTranslations";
import GridMain from "../../components/Layouts/GridMain";
import GridRap from "../../components/Layouts/GridRap";
import "react-international-phone/style.css";
import { Link } from "react-router-dom";
import CheckSingle from "../../components/general/CheckSingle";
import { SearchPersonalize, CreateAnsPersonalize } from "../../services/Personalize";
import _ from "lodash"
import FooterRegister from "./RegisterParts/FooterRegister";
import WebContainer from "../../components/Layouts/WebContainer";
import TopBanner from './../../components/Layouts/TopBanner';
import HeaderRegister from "./RegisterParts/HeaderRegister";
import { useDispatch, useSelector } from "react-redux";
import { SearchLineId, CreateMember, SearchEmail, SearchMemberById } from "../../services/Member";
import sweetAlert from "../../services/sweetAlert";
import { AuthLoginWithSocial } from "../../services/Auth";
import { setProfile } from "../../features/ProfileSlice"
import { SearchDdlNationality } from "../../services/Dropdown";
const fnsetProfile = setProfile 

const { gotoPage } = helper.Route;
const { removeUndefined, objectToQueryString } = helper.FN;

export default function RegisterStep2() {
  const lineId = localStorage.getItem('lineId') ?? null
  const step1 = useSelector((state) => state.Register.step1);
  const dispatch = useDispatch() // ไว้อัพเดต state กลาง
  // CHECK E-MAIL
  const { t, lang } = useTranslations();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const [ personalizeList, setPersonalizeList ] = useState([]) 
  const [ pValue, setPValue] = useState({})
  const [dropdownNationality, setDropdownNationality] = useState([]);

  const getPersonalize = async (bookingId = null) => {
    try {
        const { data } = await SearchPersonalize({ params: { bookingId: bookingId } });
        if (data.resultCode === 20000) {
            const resData = data.body
            setPersonalizeList(resData)
        } 
    } catch (e) {
        console.log("ERR getPersonalize", e);
    }
  };
  useEffect(() => {
    getPersonalize()
    getDdlNationality()
  }, [lang])

  const getDdlNationality = async () => {
    try {
      const { data } = await SearchDdlNationality({});
      if (data.resultCode === 20000) {
        setDropdownNationality(data.body);
      }
    } catch (e) {
      console.log("ERR getDdlNationality", e);
    }
  };

  const postCreateMember = async (payload) => {
    try {
      const { data } = await CreateMember({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body
        onSubmitStep2(resData.memberId)
        setTimeout(() => {
          if (payload.googleUid || payload.facebookUid) {
            const { googleUid, facebookUid, email } = payload;
            const _payload = { email, googleUid, facebookUid };
            postLoginWithSocial(_payload);
          } else if (payload.lineUid) {
            getSearchLineIdLogin(lineId)
          } else {
            navigate("/check-email", { state: { email: payload.email } });
          }
        }, 800)
      } else if (data.resultCode === 40301) {
        sweetAlert.AlertInfo({
          errorCode: data.resultCode,
          title: " An account with this email already exists",
          subtitle: "Please try a different email address",
        });
      } else {
        sweetAlert.AlertError({
          errorCode: data?.resultCode,
          title: data?.resultCode,
          subtitle: data?.resultDescription,
        });
      }
    } catch (error) {
      const { data } = error.response;
      // sweetAlert.AlertError({
      //   errorCode: data?.resultCode,
      //   subtitle: data?.resultDescription,
      // });
    }
  };

  const postLoginWithSocial = async (payload) => {
    try {
      const { data } = await AuthLoginWithSocial({ data: payload });
      if (data.resultCode === 20000) {
        const userToken = data.body.userToken;
        helper.Auth.flowLOGIN(userToken, lineId);
      } else {
        sweetAlert.AlertError({
          errorCode: data?.resultCode,
          title: data?.resultCode,
          subtitle: data?.resultDescription,
        });
      }
    } catch (error) {
      const { data } = error.response;
      // sweetAlert.AlertError({
      //   errorCode: data?.resultCode,
      //   subtitle: data?.resultDescription,
      // });
    }
  };

  const postPersonalize = async (payload) => {
    try {
      const { data } = await CreateAnsPersonalize({ data: payload });
      if (data.resultCode === 20000) {
      
      }
    } catch (error) {
      const { data } = error.response;
      // sweetAlert.AlertError({
      //   errorCode: data?.resultCode,
      //   subtitle: data?.resultDescription,
      // });
    }
  };

  const getSearchLineIdLogin = async (_lineId) => {
    try {
      localStorage.removeItem("token")
      const { data } = await SearchLineId({ lineId: _lineId });
      if (data.resultCode === 20000) {
        const resData = data.body
        const userToken = resData.token
        // localStorage.setItem("token", resData.token)
        // getSearchMemberById(resData.id)
        helper.Auth.flowLOGIN(userToken, _lineId, 'redirect', navigate);
      }
    } catch (e) {
      console.log("ERR ", e);
    }
  };

  const getSearchMemberById = async (memberId = null) => {
    try {
      const { data } = await SearchMemberById({ memberId: memberId });
      if (data.resultCode === 20000) {
        // setProfile(data.body);
        const tmpObj = {
          ...data.body, isLoading: true
        }
        dispatch(setProfile(tmpObj)); // set เข้า state กลาง
      } 
    } catch (e) {
      console.log("ERR getSearchMemberById", e);
    }
  };

  function onSubmitStep2(memberId) {
     const payload2 = {
      memberId: memberId,
      answerList: convertAnswerList(personalizeList, pValue)
    }
    console.log('payload2 >>', payload2)
    postPersonalize(payload2)
  }

  function onSave() {
    const payload1 = _.cloneDeep(step1)
    // console.log('payload1 >>', payload1)
    let _nationalityId = mapNationalityId(dropdownNationality, payload1.nationalityId)
    // console.log('_nationalityId >>', _nationalityId)
    const payload = {
      ...payload1,
      nationalityId: _nationalityId
    }
    // console.log('payload >>', payload)
    postCreateMember(payload)
   
  }

  useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }, []);

  function mapNationalityId(arr = [], name = "") {
    console.log("arr >>", arr)
    const find =  arr.find(item => item.name == name)
    return find.id ? find.id : null
  }

  // ถ้าไม่มีข้อมูล Step 1 ให้ดีดกลับไป
  if (!step1.firstname) {
    navigate('/register')
  }

  return (
    <>
      <WebContainer bgGray footer={
        <FooterRegister
          currentStep={2} 
          onBack={() => {
            console.log('onBack >>')
            navigate(-1)
          }}
          onNext={() => {
            console.log('onNext Submit >>')
            onSave()
          }}
        />
      }>
        <TopBanner />
        <GridMain className="form-space">
          <GridRap>
            <div className="form-header-space">
              <HeaderRegister 
                step={2}
                title={t.register.banner_t0} 
                subTitle={<>
                  {t.register.banner_t1_1}
                  <br className="md:hidden" />
                  <span className="hidden md:inline">&nbsp;</span>
                  {t.register.banner_t1_2}
                </>}
              />
              <div className="form-content-space px-[24px] md:px-[75px] pt-[28px] pb-[31px] md:pt-[48px] md:pb-[48px] bg-white rounded-b-[4px] md:rounded-b-[12px] border-[1px] md:border-[0px] border-Neutral-A300">
                <div className="flex justify-center">
                  <img
                    className="max-w-[61px] mb-[12px]"
                    src={SinginLogo}
                    alt=""
                  />
                </div>
                <div className="flex flex-col justify-center items-center mb-[16px] md:mb-[32px]">
                  <p className="text-Personal MinervaModern">
                    Your Interests
                  </p>
                  <div className="divider-glod"></div>
                  <p className="text-[12px] md:text-[20px] font-medium text-[#393939]">{t.register.yourHobbies}</p>
                </div>
                
                <div className="flex flex-col gap-[8px] md:gap-[16px] ">
                  {
                    personalizeList.map(item => {
                      return (
                        <>
                          <div className="flex gap-[10px] items-center ">
                            <CheckSingle
                              value={objToValue(pValue, item.id)} 
                              onChange={(v) => {
                                let tmpObj = _.cloneDeep(pValue)
                                tmpObj[item.id] = v
                                setPValue(tmpObj)
                              }}
                            />
                            <p className="text-[12px] md:text-[16px] text-[#393939]">
                              {item.name}
                            </p>
                          </div>
                        </>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
    </>
  );
}


function objToValue(objValue = {}, id) {
  if (objValue[id]) {
      return true
  }
  return false
}

function convertAnswerList(list = [], objValue = {}) {
  let ans = []
  for (let l1 of list) {
    const tmp = { questionId: l1.id, selected: objToValue(objValue, l1.id)  }
    ans.push(tmp)
  }
  return ans
}