import React from "react";
import useTranslations from "../../i18n/useTranslations";
import { useNavigate } from "react-router-dom";
import WebContainer from "../../components/Layouts/WebContainer";
import SettingTemplate from "./SettingTemplate";

export default function TermsCondition() {
  const { t, lang } = useTranslations();
  const navigate = useNavigate();

  return (
    <WebContainer bgGray>
      <SettingTemplate
        isOpen={true}
        onAccept={() => {
          navigate(-1);
        }}
        onClose={() => {
          navigate(-1);
        }}
        title="Cookies Policy"
      >
        <>
        <div className="pt-[24px]">
          {
              lang === 'en' ? <ContentEN/>
            : lang === 'th' ? <ContentTH/>
            : lang === 'cn' ? <ContentCN/>
            : <></>
          }
         </div>

        </>
      </SettingTemplate>
    </WebContainer>
  );
}

const ContentEN = () => {
  return (
    <div className="b-policy">
      <p>
        This Cookies Policy explains what Cookies are and how they are
        being used by reservation.healthlandspa.com. You could read
        this Cookies Policy so you can understand our practice
        regarding the collection, use or disclosure of cookies and the
        choices available to you for the use of cookies.
      </p>

      <h2>What Are Cookies?</h2>
      <p>
        Cookies are small pieces of data that are stored on your
        computer or other devices when you are visiting. Cookies are
        then sent back to the originating website on each subsequent
        visit, or to another website that recognizes those cookies.
        They are used in order to make website work, or more
        efficiently, as well as to provide information to the owner of
        the website.
      </p>
      <p>
        Session Cookies are temporary cookies that are used during the
        course of your visit to the website, and they expire when you
        close the browser.
      </p>
      <p>
        Persistent Cookies are cookies that remain on your computer
        until they expire or are deleted. It used to remember your
        preferences within a website and remain on your computer or
        mobile device even after you close your browser. They ensure a
        consistent and efficient experience for you while visiting the
        website or services.
      </p>
      <p>First-party Cookies are set by the site you are visiting.</p>
      <p>
        Third-party Cookies are set by a third party site separate
        from the site you are visiting, such as those who serve
        content or provide advertising or analytics services on the
        website.
      </p>
      <p>
        Similar technologies are technologies that store information
        in your browser or device utilizing local shared objects or
        local storage such as flash cookies, HTML 5, and other web
        application software methods.
      </p>

      <h2>How Do We Use Cookies?</h2>
      <p>
        We will collect user activities within our website through
        cookies or similar technologies. We use cookies for the
        following purposes:
      </p>

      <p>To provide a secure login process for all users</p>
      <p>
        To record the use of our website and your personal settings
      </p>
      <p>
        To analyze your behavior on our website for satisfaction
        optimization purposes
      </p>
      <p>
        To improve the efficiency of accessing and using our services
      </p>
      <p>
        To store your preferences and provide customized services to
        you
      </p>

      <h2>Type of Cookies We Use</h2>
      <p>We use the following types of cookies on our website:</p>

      <p>
        <b>Strictly Necessary Cookies</b>
      </p>
      <p>
        These cookies are essential to provide you with services
        available through the website and to enable you to use some of
        its features. They help to authenticate users and prevent
        fraudulent use of user accounts. Without these cookies, the
        services that you have asked for cannot be provided, and we
        use these cookies to provide you with those services.
      </p>

      <p>
        <b>Functional Cookies</b>
      </p>
      <p>
        These cookies allow us to remember choices you make when you
        use the website, such as remembering your login details or
        language preference. The purpose of these cookies is to
        provide you with a more personal experience and to avoid you
        having to re-enter your preferences every time you use the
        website. These cookies are Persistent Cookies because they
        remain on your devices for us to use during a next visit to
        our website. You can delete these cookies via your browser.
      </p>

      <p>
        <b>Analytical/Performance Cookies</b>
      </p>
      <p>
        These cookies allow us to recognize and count the number of
        users of our websites and understand how such users navigate
        through our website. This helps to improve how our websites
        work, for example, by ensuring that users can find what they
        are looking for easily.
      </p>

      <p>
        <b>Advertising Cookies</b>
      </p>
      <p>
        These cookies are used to deliver ads relevant to you. They
        also limit the number of times that you see an ad and help us
        measure the effectiveness of our marketing campaigns. We may
        also use the information obtained via these cookies to serve
        you with advertising that may be of interest to you based on
        your past online behaviour. These cookies are Persistent
        Cookies. We may share this information with third parties,
        including our business partners.
      </p>

      <h2>Your Choices Regarding Cookies</h2>
      <p>
        If you prefer to avoid the use of cookies on our website, you
        can change your browser's settings to exercise choices you
        have for certain cookies. If you disable or delete certain
        cookies in your browser settings, you may experience some
        inconvenience in your use of our website or some features may
        not function properly.
      </p>
      <p>
        To learn more about the use of cookies and to exercise choice
        regarding such cookies, please visit your browser’s official
        web pages. You may also visit{" "}
        <a href="https://www.allaboutcookies.org">
          www.allaboutcookies.org
        </a>{" "}
        for details on how to reject or delete cookies and for further
        information.
      </p>

      <h2>Changes to This Cookies Policy</h2>
      <p>
        We may change this Cookies Policy from time to time. Any
        changes of this Cookies Policy, we encourage you to frequently
        check on our website.
      </p>
      <p>
        This Cookies Policy was last updated and effective on 23rd
        September 2023
      </p>

      <h2>Contact Information</h2>
      <p>
        If you have any questions about this Cookies Policy, you may
        contact us by using the following details:
      </p>

      <p>
        <b>Data Controller</b>
      </p>
      <p>Health Land 2004 Company Limited</p>
      <p>
        288/1 Praditmanutham Road, Phlapphla, Wang Thonglang, Bangkok,
        10310
      </p>
      <p>piranut.l@healthland.co.th</p>

      <p>
        <b>Data Protection Officer</b>
      </p>
      <p>Name: Health Land Team</p>
      <p>
        288/1 Praditmanutham Road, Phlapphla, Wang Thonglang, Bangkok,
        10310
      </p>
      <p>piranut.l@healthland.co.th</p>
    </div>
  )
}

const ContentTH = () => {
  return (
  <div className="b-policy">
    <p>นโยบายคุกกี้ฉบับนี้ได้อธิบายความหมายและวิธีการใช้คุกกี้ของเว็บไซต์ reservation.healthlandspa.com โปรดศึกษานโยบายคุกกี้ฉบับนี้เพื่อให้คุณสามารถเข้าใจแนวปฏิบัติของเราเกี่ยวกับการเก็บรวบรวม ใช้ หรือการเปิดเผยคุกกี้ รวมถึงทางเลือกในการใช้คุกกี้ของเรา</p>

    <h2>คุกกี้คืออะไร ?</h2>
    <p>คุกกี้ คือ ไฟล์ข้อมูลขนาดเล็กที่ฝังอยู่ในคอมพิวเตอร์หรืออุปกรณ์ของคุณเมื่อคุณเยี่ยมชมเว็บไซต์ หลังจากนั้นคุกกี้จะถูกส่งกลับไปยังเว็บไซต์ต้นทางในแต่ละครั้งของการเข้าเยี่ยมชมในครั้งถัดไปหรือส่งไปยังเว็บไซต์อื่นที่จดจำคุกกี้นั้นได้ คุกกี้เหล่านี้ถูกใช้เพื่อทำให้เว็บไซต์สามารถทำงานได้หรือทำงานได้อย่างมีประสิทธิภาพ รวมถึงการให้ข้อมูลแก่เจ้าของเว็บไซต์</p>
    <p>คุกกี้แบบชั่วคราว (Session Cookies) คือ คุกกี้ที่มีระยะเวลาชั่วคราวจะถูกใช้ในระหว่างที่คุณเยี่ยมชมเว็บไซต์และจะสิ้นสุดลงเมื่อคุณปิดบราวเซอร์</p>
    <p>คุกกี้แบบถาวร (Persistent Cookies) คือ คุกกี้ที่มีระยะเวลาอยู่ตลอดจนกว่าหมดอายุหรือถูกลบ ใช้จดจำการตั้งค่าของคุณภายในเว็บไซต์ และจะยังคงอยู่ในคอมพิวเตอร์หรือบนมือถือของคุณแม้ว่าคุณจะปิดเว็บไซต์นั้นแล้ว คุกกี้ดังกล่าวนี้จะช่วยเรื่องความสอดคล้องของข้อมูลและช่วยเพิ่มประสิทธิภาพการใช้งานในขณะที่คุณเยี่ยมชมเว็บไซต์หรือบริการ</p>
    <p>คุกกี้ของบุคคลที่หนึ่ง คือ คุกกี้ที่ถูกกำหนดโดยเว็บไซต์ที่คุณกำลังเยี่ยมชม</p>
    <p>คุกกี้ของบุคคลที่สาม คือ คุกกี้ถูกกำหนดโดยเว็บไซต์ของบุคคลที่สามแยกต่างหากจากไซต์ที่คุณกำลังเยี่ยมชม เช่น ผู้ให้บริการข้อมูล โฆษณา หรือการวิเคราะห์บนเว็บไซต์</p>
    <p>เทคโนโลยีที่คล้ายคลึงกัน  คือ เทคโนโลยีที่จัดเก็บข้อมูลในบราวเซอร์หรืออุปกรณ์ของคุณเพื่อแบ่งปันข้อมูลหรือจัดเก็บข้อมูล เช่น แฟลชคุกกี้ HTML 5 และวิธีการอื่น ๆ ของซอฟต์แวร์แอปพลิเคชันบนเว็บไซต์</p>

    <h2>เราใช้คุกกี้อย่างไร ?</h2>
    <p>เราจะจัดเก็บข้อมูลการเข้าเยี่ยมชมเว็บไซต์ของเราผ่านคุกกี้หรือเทคโนโลยีที่คล้ายคลึงกัน โดยเราจะใช้เพื่อวัตถุประสงค์ดังต่อไปนี้:</p>

    <p>เพื่อให้การเข้าบัญชีผู้ใช้งานบนเว็บไซต์ของเราเป็นไปอย่างต่อเนื่องและปลอดภัย</p>
    <p>เพื่อบันทึกข้อมูลการใช้งานและการตั้งค่าของคุณบนเว็บไซต์ของเรา</p>
    <p>เพื่อวิเคราะห์พฤติกรรมการใช้งานของคุณบนเว็บไซต์ของเรา</p>
    <p>เพื่อพัฒนาประสิทธิภาพในการใช้งานและการเข้าถึงบริการของเรา</p>
    <p>เพื่อเก็บรวบรวมความสนใจ และเพิ่มความสามารถในการตอบสนองความต้องการในการใช้งานของคุณมากขึ้น</p>

    <h2>ประเภทคุกกี้ที่เราใช้</h2>
    <p>ประเภทคุกกี้ที่เราใช้บนเว็บไซต์ มีดังต่อไปนี้</p>

    <p><b>คุกกี้ที่มีความจำเป็นอย่างยิ่ง (Strictly Necessary Cookies)</b></p>
    <p>คุกกี้เหล่านี้มีความสำคัญต่อการให้บริการบนเว็บไซต์แก่คุณและเพื่อให้คุณสามารถใช้คุณลักษณะบางอย่างได้ คุกกี้เหล่านี้ช่วยในการยืนยันตัวบุคคลของผู้ใช้งานและช่วยป้องกันการปลอมแปลงบัญชีผู้ใช้งาน หากไม่มีคุกกี้เหล่านี้เราอาจไม่สามารถให้บริการแก่คุณได้ เราใช้คุกกี้ดังกล่าวนี้เพื่อให้บริการแก่คุณ</p>

    <p><b>คุกกี้เพื่อการทำงานของเว็บไซต์ (Functional Cookies)</b></p>
    <p>คุกกี้เหล่านี้ช่วยให้เราจดจำสิ่งที่คุณเลือกเมื่อคุณใช้งานเว็บไซต์ เช่น การจดจำรายละเอียดการเข้าสู่ระบบหรือการตั้งค่าภาษาของคุณ วัตถุประสงค์ของคุกกี้เหล่านี้มีเพื่อให้คุณได้รับประสบการณ์ที่เป็นส่วนตัวมากขึ้นและเพื่อหลีกเลี่ยงไม่ให้คุณต้องใส่ข้อมูลของคุณใหม่ทุกครั้งเมื่อคุณใช้งานเว็บไซต์ คุกกี้ดังกล่าวนี้เป็นคุกกี้แบบถาวรเนื่องจากยังคงอยู่ในอุปกรณ์ของคุณเพื่อให้เราใช้ในการเยี่ยมชมเว็บไซต์ครั้งต่อไปของคุณ คุณสามารถลบคุกกี้เหล่านี้ผ่านบราวเซอร์ของคุณได้</p>

    <p><b>คุกกี้เพื่อการวิเคราะห์/เพื่อประสิทธิภาพ (Analytical/Performance Cookies)</b></p>
    <p>คุกกี้เหล่านี้ช่วยให้เราจดจำและนับจำนวนผู้ใช้งานบนไซต์ของเรา รวมถึงทำให้เราเข้าใจถึงวิธีการที่ผู้ใช้งานใช้งานบนเว็บไซต์ของเรา สิ่งเหล่านี้จะช่วยปรับปรุงวิธีการทำงานของเว็บไซต์เรา เช่น เพื่อให้มั่นใจว่าผู้ใช้งานสามารถค้นหาสิ่งที่ต้องการได้ง่ายขึ้น</p>

    <p><b>คุกกี้เพื่อการโฆษณา (Advertising Cookies)</b></p>
    <p>คุกกี้เหล่านี้ใช้เพื่อแสดงโฆษณาที่เกี่ยวข้องกับคุณ คุกกี้เหล่านี้จะจำกัดจำนวนครั้งที่คุณเห็นโฆษณาและช่วยให้เราประเมินประสิทธิภาพด้านการตลาด นอกจากนี้เราอาจใช้ข้อมูลที่ได้รับจากคุกกี้เหล่านี้เพื่อเสนอโฆษณาที่คุณอาจสนใจโดยขึ้นอยู่กับพฤติกรรมการใช้งานออนไลน์ของคุณที่ผ่านมา คุกกี้ดังกล่าวนี้เป็นคุกกี้แบบถาวร เราอาจแบ่งปันข้อมูลนี้กับบุคคลที่สามรวมถึงพันธมิตรทางธุรกิจของเรา</p>

    <h2>ทางเลือกเกี่ยวกับคุกกี้</h2>
    <p>ในกรณีที่คุณไม่ประสงค์ให้มีการใช้งานคุกกี้บนเว็บไซต์ของเรา คุณสามารถเปลี่ยนแปลงได้ด้วยการตั้งค่าบนบราวเซอร์ของคุณเพื่อเลือกการใช้งานที่เหมาะสมกับคุณ หากคุณปิดการใช้งานหรือลบคุกกี้บางอย่างในการตั้งค่าบราวเซอร์ของคุณ ทั้งนี้ การใช้งานบนเว็บไซต์หรือคุณลักษณะบางอย่างอาจทำงานได้ไม่ตรงตามวัตถุประสงค์</p>
    <p>หากคุณต้องการศึกษาเพิ่มเติมเกี่ยวกับการใช้คุกกี้และการตั้งค่าคุกกี้ดังกล่าว คุณสามารถเข้าไปที่หน้าเว็บไซต์ทางการบราวเซอร์ของคุณได้ คุณสามารถดูรายละเอียดเกี่ยวกับวิธีการปฏิเสธหรือการลบคุกกี้ ตลอดจนข้อมูลเพิ่มเติมทั่วไปเกี่ยวกับคุกกี้ได้ที่ <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a></p>

    <h2>การแก้ไขเปลี่ยนแปลงนโยบายคุกกี้</h2>
    <p>เราอาจแก้ไขเปลี่ยนแปลงนโยบายคุกกี้ฉบับนี้เป็นครั้งคราว โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา</p>
    <p>นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 23 กันยายน 2566</p>

    <h2>รายละเอียดการติดต่อ</h2>
    <p>หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายคุกกี้ฉบับนี้ คุณสามารถติดต่อเราได้ ดังนี้</p>

    <p><b>ผู้ควบคุมข้อมูลส่วนบุคคล</b></p>
    <p>บริษัท เฮลท์ แลนด์ 2004 จำกัด</p>
    <p>
    288/1 ถนน ประดิษฐ์มนูธรรม พลับพลา วังทองหลาง กรุงเทพมหานคร 10310
    </p>
    <p>อีเมล piranut.l@healthland.co.th</p>

    <p><b>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</b></p>
    <p>ชื่อ ทีม Health Land</p>
    <p>
    สถานที่ติดต่อ 288/1 ถนน ประดิษฐ์มนูธรรม พลับพลา วังทองหลาง กรุงเทพมหานคร 10310
    </p>
    <p>อีเมล piranut.l@healthland.co.th</p>
  </div>
  )
}

const ContentCN = () => {
  return (
    <div className="b-policy">
      <p>
        This Cookies Policy explains what Cookies are and how they are
        being used by reservation.healthlandspa.com. You could read
        this Cookies Policy so you can understand our practice
        regarding the collection, use or disclosure of cookies and the
        choices available to you for the use of cookies.
      </p>

      <h2>What Are Cookies?</h2>
      <p>
        Cookies are small pieces of data that are stored on your
        computer or other devices when you are visiting. Cookies are
        then sent back to the originating website on each subsequent
        visit, or to another website that recognizes those cookies.
        They are used in order to make website work, or more
        efficiently, as well as to provide information to the owner of
        the website.
      </p>
      <p>
        Session Cookies are temporary cookies that are used during the
        course of your visit to the website, and they expire when you
        close the browser.
      </p>
      <p>
        Persistent Cookies are cookies that remain on your computer
        until they expire or are deleted. It used to remember your
        preferences within a website and remain on your computer or
        mobile device even after you close your browser. They ensure a
        consistent and efficient experience for you while visiting the
        website or services.
      </p>
      <p>First-party Cookies are set by the site you are visiting.</p>
      <p>
        Third-party Cookies are set by a third party site separate
        from the site you are visiting, such as those who serve
        content or provide advertising or analytics services on the
        website.
      </p>
      <p>
        Similar technologies are technologies that store information
        in your browser or device utilizing local shared objects or
        local storage such as flash cookies, HTML 5, and other web
        application software methods.
      </p>

      <h2>How Do We Use Cookies?</h2>
      <p>
        We will collect user activities within our website through
        cookies or similar technologies. We use cookies for the
        following purposes:
      </p>

      <p>To provide a secure login process for all users</p>
      <p>
        To record the use of our website and your personal settings
      </p>
      <p>
        To analyze your behavior on our website for satisfaction
        optimization purposes
      </p>
      <p>
        To improve the efficiency of accessing and using our services
      </p>
      <p>
        To store your preferences and provide customized services to
        you
      </p>

      <h2>Type of Cookies We Use</h2>
      <p>We use the following types of cookies on our website:</p>

      <p>
        <b>Strictly Necessary Cookies</b>
      </p>
      <p>
        These cookies are essential to provide you with services
        available through the website and to enable you to use some of
        its features. They help to authenticate users and prevent
        fraudulent use of user accounts. Without these cookies, the
        services that you have asked for cannot be provided, and we
        use these cookies to provide you with those services.
      </p>

      <p>
        <b>Functional Cookies</b>
      </p>
      <p>
        These cookies allow us to remember choices you make when you
        use the website, such as remembering your login details or
        language preference. The purpose of these cookies is to
        provide you with a more personal experience and to avoid you
        having to re-enter your preferences every time you use the
        website. These cookies are Persistent Cookies because they
        remain on your devices for us to use during a next visit to
        our website. You can delete these cookies via your browser.
      </p>

      <p>
        <b>Analytical/Performance Cookies</b>
      </p>
      <p>
        These cookies allow us to recognize and count the number of
        users of our websites and understand how such users navigate
        through our website. This helps to improve how our websites
        work, for example, by ensuring that users can find what they
        are looking for easily.
      </p>

      <p>
        <b>Advertising Cookies</b>
      </p>
      <p>
        These cookies are used to deliver ads relevant to you. They
        also limit the number of times that you see an ad and help us
        measure the effectiveness of our marketing campaigns. We may
        also use the information obtained via these cookies to serve
        you with advertising that may be of interest to you based on
        your past online behaviour. These cookies are Persistent
        Cookies. We may share this information with third parties,
        including our business partners.
      </p>

      <h2>Your Choices Regarding Cookies</h2>
      <p>
        If you prefer to avoid the use of cookies on our website, you
        can change your browser's settings to exercise choices you
        have for certain cookies. If you disable or delete certain
        cookies in your browser settings, you may experience some
        inconvenience in your use of our website or some features may
        not function properly.
      </p>
      <p>
        To learn more about the use of cookies and to exercise choice
        regarding such cookies, please visit your browser’s official
        web pages. You may also visit{" "}
        <a href="https://www.allaboutcookies.org">
          www.allaboutcookies.org
        </a>{" "}
        for details on how to reject or delete cookies and for further
        information.
      </p>

      <h2>Changes to This Cookies Policy</h2>
      <p>
        We may change this Cookies Policy from time to time. Any
        changes of this Cookies Policy, we encourage you to frequently
        check on our website.
      </p>
      <p>
        This Cookies Policy was last updated and effective on 23rd
        September 2023
      </p>

      <h2>Contact Information</h2>
      <p>
        If you have any questions about this Cookies Policy, you may
        contact us by using the following details:
      </p>

      <p>
        <b>Data Controller</b>
      </p>
      <p>Health Land 2004 Company Limited</p>
      <p>
        288/1 Praditmanutham Road, Phlapphla, Wang Thonglang, Bangkok,
        10310
      </p>
      <p>piranut.l@healthland.co.th</p>

      <p>
        <b>Data Protection Officer</b>
      </p>
      <p>Name: Health Land Team</p>
      <p>
        288/1 Praditmanutham Road, Phlapphla, Wang Thonglang, Bangkok,
        10310
      </p>
      <p>piranut.l@healthland.co.th</p>
    </div>
  )
}
