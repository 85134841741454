import React, { useEffect } from "react";
import { SendEmailVerify } from "../../services/Member";
import { AuthLoginWithSocial } from "../../services/Auth";
import helper from "../../services/helper";
import sweetAlert from "../../services/sweetAlert";
import useTranslations from "../../i18n/useTranslations";
import LoginWithGoogle from "../../components/Auth/LoginWithGoogle";
import LoginWithFacebook from "../../components/Auth/LoginWithFacebook";
import { useNavigate, useParams } from "react-router-dom";


export default function LoginSocial() {
  const { lang, supportedLangs, setLang } = useTranslations();
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { redirect } = useParams();
  const lineId = localStorage.getItem('lineId') ?? null

  const postLoginWithSocial = async (payload, profile) => {
    try {
      const { data } = await AuthLoginWithSocial({ data: payload });
      if (data.resultCode === 20000) {
        const userToken = data.body.userToken;
        helper.Auth.flowLOGIN(userToken, lineId, redirect);
      } else if (data.resultCode === 40401) {
        const qProfile = helper.encodeJWT(profile);
        console.log("qProfile >>", qProfile);
        if (`${window.location.pathname}` === '/register') {
          window.location.href = "/register?profile=" + qProfile
        } else {
          navigate("/register?profile=" + qProfile);
        }
      } else if (data.resultCode === 40401) {
        sweetAlert.AlertInfo({
          errorCode: "errorCode",
          title: "Incorrect Email or Password",
          subtitle: "Please try again",
        });
      } else {
        sweetAlert.AlertError({
          errorCode: data?.resultCode,
          title: data?.resultCode,
          subtitle: data?.resultDescription,
        });
      }
    } catch (error) {
      console.log("error postLoginWithSocial >>", error);
      // sweetAlert.AlertError({
      //   errorCode: data?.resultCode,
      //   subtitle: data?.resultDescription,
      // });
    }
  };

  return (
    <>
      <LoginWithGoogle
        onLoginGoogle={(uid, profile, result) => {
          console.log("onLoginGoogle token >>", uid);
          console.log("onLoginGoogle profile >>", profile);
          console.log("onLoginGoogle result >>", result);
          const payload = { googleUid: uid, email: profile.email };
          postLoginWithSocial(payload, profile);
        }}
      />
      <LoginWithFacebook
        onLoginFacebook={(uid, profile, result) => {
          console.log("onLoginWithFacebook token >>", uid);
          console.log("onLoginWithFacebook profile >>", profile);
          console.log("onLoginWithFacebook result >>", result);
          const payload = { facebookUid: uid, email: profile.email };
          postLoginWithSocial(payload, profile);
        }}
      />
    </>
  );
}
