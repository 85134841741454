import React, { useEffect, useState } from 'react'

export default function RatingStar({ value = 0, onChange }) {
  const maxStar = 5
  return (
    <>
      <div className="flex gap-[8px] md:gap-[16px]">

        {
          [...Array(maxStar)].map((item, i) => {
            if (i < value) {
              return <StarFull key={i} onClick={() => onChange(i + 1)}/>
            }
            return <StarEmpty key={i} onClick={() => onChange(i + 1)}/>
          })
        }
       
      </div>
    </>
  )
}

const StarFull = ({ onClick }) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <div className="hidden md:block">
        <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 2.7004L21.8035 11.8452L22.0468 12.4301L22.6782 12.4807L32.5508 13.2722L25.0289 19.7155L24.5479 20.1275L24.6948 20.7437L26.9929 30.3776L18.5406 25.215L18 24.8848L17.4594 25.215L9.00712 30.3776L11.3052 20.7437L11.4521 20.1275L10.9711 19.7155L3.44922 13.2722L13.3218 12.4807L13.9532 12.4301L14.1965 11.8452L18 2.7004Z" fill="#DDB05B" stroke="#DDB05B" stroke-width="2.07407"/>
        </svg>
      </div>
      <div className="block md:hidden">
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.6111 1.48948L14.3235 8.01082L14.4408 8.2928L14.7452 8.31721L21.7855 8.88163L16.4215 13.4765L16.1896 13.6751L16.2605 13.9722L17.8993 20.8424L11.8717 17.1608L11.6111 17.0016L11.3505 17.1608L5.32298 20.8424L6.96176 13.9722L7.03262 13.6751L6.80069 13.4765L1.43669 8.88163L8.47703 8.31721L8.78145 8.2928L8.89874 8.01082L11.6111 1.48948Z" fill="#DDB05B" stroke="#DDB05B"/>
        </svg>
      </div>
    </div>
  )
}

const StarEmpty = ({ onClick }) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <div className="hidden md:block">
        <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 1.30198L22.2994 11.639L22.4167 11.921L22.7211 11.9454L33.8808 12.84L25.3783 20.1233L25.1463 20.322L25.2172 20.6191L27.8149 31.509L18.2606 25.6733L18 25.5141L17.7394 25.6733L8.18515 31.509L10.7828 20.6191L10.8537 20.322L10.6217 20.1233L2.11924 12.84L13.2789 11.9454L13.5833 11.921L13.7006 11.639L18 1.30198Z" stroke="#DDB05B"/>
        </svg>
      </div>
      <div className="block md:hidden">
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.5 1.48948L14.2124 8.01082L14.3297 8.2928L14.6341 8.31721L21.6744 8.88163L16.3104 13.4765L16.0785 13.6751L16.1493 13.9722L17.7881 20.8424L11.7606 17.1608L11.5 17.0016L11.2394 17.1608L5.21186 20.8424L6.85065 13.9722L6.92151 13.6751L6.68957 13.4765L1.32558 8.88163L8.36592 8.31721L8.67034 8.2928L8.78762 8.01082L11.5 1.48948Z" stroke="#DDB05B"/>
        </svg>
      </div>
    </div>
  )
}