import React, { useState, useEffect } from "react";
import AvatarProfile from "../../assets/images/Avatar-Profiles.svg";
import ArrowRight from "../../assets/images/arrow-right.svg";
import "../../assets/styles/Profile.css";
import { Link, useNavigate } from "react-router-dom";
import { SearchMemberById } from "../../services/Member";
import helper from "../../services/helper";
import CardPoint from "./CardPoint";
import PopupPointBenefit from "../../components/Popup/PopupPointBenefit";
import useTranslations from "../../i18n/useTranslations";
import { useDispatch, useSelector } from 'react-redux';

export default function ProfileBox({ showDesktop }) {
  const profile = useSelector((state) => state.profile) // ไว้ดึง state
  const { t } = useTranslations();
  // const [profile, setProfile] = useState({});
  const [modalPoints, setModalPoint] = useState(false);

  const navigate = useNavigate();

  function getFullName(firstname,lastname) {
    if(firstname || lastname) {
      return firstname + " " + lastname
    }
    return ""
  }

  // const getSearchMemberById = async (memberId = null) => {
  //   try {
  //     const { data } = await SearchMemberById({ memberId: memberId });
  //     if (data.resultCode === 20000) {
  //       setProfile(data.body);
  //     } else if (data.resultCode === 40401) {
  //       navigate("/login");
  //     }
  //   } catch (e) {
  //     console.log("ERR getSearchMemberById", e);
  //   }
  // };

  // useEffect(() => {
  //   const data = helper.Auth.getTokenData();
  //   getSearchMemberById(data.memberId);
  // }, []);


  let twStyle = `profile-head ` 
  if (showDesktop) {
    twStyle += `show-desktop` 
  }

  return (
    <>

      <PopupPointBenefit open={modalPoints} onChange={(e)=> setModalPoint(e) } />
      <div className={twStyle}>
        {/* รูป */}
        <div className="flex flex-col gap-[16px]">
          <div className="flex justify-center ">
            <img
              className="imgProfile"
              src={profile.imagePicture ? profile.imagePicture : AvatarProfile}
              alt=""
            />
          </div>
          <div className="flex flex-col gap-[8px]">
            {/* ชื่อ  */}
            <p className="text-center text-[30px] md:text-[32px] leading-[1em] MinervaModern">
              {getFullName(profile.firstname, profile.lastname)}
            </p>
            {/* ปุ่ม */}
            <div className="w-full flex justify-center">
              <Link to={"/profile-view"} className="w-fit cursor-pointer">
                <div className="itens-center justify-center flex gap-[8px] text-Primary-A200 text-[12px]">
                  <p className="HelveticaNeue text-Neutral-A800 text-[14px] md:text-[20px]">
                    {t.profilebox.viewProfile}
                  </p>
                  <img src={ArrowRight} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* card point */}
        <div className="w-full flex justify-center">
          <CardPoint currentPoint={profile.currentPoint} onClick={() => setModalPoint(true)} />
        </div>
      </div>
    </>
    
  );
}