import { get, post } from "./Api";

export function CheckBookingPayment({ params, data }) {
  return post({ url: `/api/v1/healthland/payment/checkBookingPayment`, params, data });
}

// Credit Card

export function CreateCharge({ params, data }) {
  return post({ url: `/api/v1/healthland/payment/card/createCharge`, params, data });
}

export function UpdateKbankCheckOut({ params, data }) {
  return post({ url: `/kbankcheckout`, params, data });
}

export function GetPayloadRescheduleBooking(bookingId) {
  return get({ url: `/api/v1/healthland/booking/getPayloadRescheduleBooking/${bookingId}` });
}

// QR CODE
export function CreateOrder({ params, data }) {
  return post({ url: `/api/v1/healthland/payment/qr/createOrder`, params, data });
}

export function CancelOrder({ params, data }) {
  return post({ url: `/api/v1/healthland/payment/qr/cancelOrder`, params, data });
}

export function UpdateQRKbankCheckOut({ params, data }) {
  return post({ url: `qrkbankcheckout`, params, data });
}