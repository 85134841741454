import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import helper from "../../services/helper";

export default function AutoLogin() {
  const lineId = localStorage.getItem('lineId') ?? null
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  useEffect(() => {
    const userToken = searchParams.get('token');
    helper.Auth.flowLOGIN(userToken, lineId, null, navigate);
    window.localStorage.removeItem('step1')
    window.localStorage.removeItem('step2')
    window.localStorage.removeItem('otherData')
  }, [])


  return <></>;
}
