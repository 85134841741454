
import React, { useState, useEffect } from 'react'
import useTranslations from '../../../i18n/useTranslations';

export default function ButtonFilter({ onClick }) {
  const { t } = useTranslations()
  return (
    <>
      <div className=" flex flex-row gap-[8px] justify-end items-center cursor-pointer" onClick={() => onClick()}>
        <FilterIcon/>
        <h5 className="text-[14px] md:text-[20px] font-medium text-[#493B43]">{t.history.Filter}</h5>
      </div>
    </>
  )
} 

const FilterIcon = () => {
  return (
    <>
      <div className="hidden md:block">
        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 2.6C1.5 2.03995 1.5 1.75992 1.60899 1.54601C1.70487 1.35785 1.85785 1.20487 2.04601 1.10899C2.25992 1 2.53995 1 3.1 1H19.9C20.4601 1 20.7401 1 20.954 1.10899C21.1422 1.20487 21.2951 1.35785 21.391 1.54601C21.5 1.75992 21.5 2.03995 21.5 2.6V3.26939C21.5 3.53819 21.5 3.67259 21.4672 3.79756C21.438 3.90831 21.3901 4.01323 21.3255 4.10776C21.2526 4.21443 21.151 4.30245 20.9479 4.4785L14.5521 10.0215C14.349 10.1975 14.2474 10.2856 14.1745 10.3922C14.1099 10.4868 14.062 10.5917 14.0328 10.7024C14 10.8274 14 10.9618 14 11.2306V16.4584C14 16.6539 14 16.7517 13.9685 16.8363C13.9406 16.911 13.8953 16.9779 13.8363 17.0315C13.7695 17.0922 13.6787 17.1285 13.4971 17.2012L10.0971 18.5612C9.72957 18.7082 9.5458 18.7817 9.39827 18.751C9.26927 18.7242 9.15606 18.6476 9.08325 18.5377C9 18.4122 9 18.2142 9 17.8184V11.2306C9 10.9618 9 10.8274 8.96715 10.7024C8.93805 10.5917 8.89014 10.4868 8.82551 10.3922C8.75258 10.2856 8.65102 10.1975 8.44789 10.0215L2.05211 4.4785C1.84898 4.30245 1.74742 4.21443 1.67449 4.10776C1.60986 4.01323 1.56195 3.90831 1.53285 3.79756C1.5 3.67259 1.5 3.53819 1.5 3.26939V2.6Z" stroke="#493B43" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div className="block md:hidden">
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 2.58121C1.5 2.20275 1.5 2.01352 1.57365 1.86897C1.63844 1.74182 1.74182 1.63844 1.86897 1.57365C2.01352 1.5 2.20275 1.5 2.58121 1.5H13.9339C14.3124 1.5 14.5016 1.5 14.6461 1.57365C14.7733 1.63844 14.8767 1.74182 14.9415 1.86897C15.0151 2.01352 15.0151 2.20275 15.0151 2.58121V3.03356C15.0151 3.2152 15.0151 3.30602 14.9929 3.39047C14.9733 3.46531 14.9409 3.53621 14.8972 3.60009C14.8479 3.67217 14.7793 3.73165 14.642 3.85061L10.32 7.59633C10.1828 7.7153 10.1141 7.77478 10.0649 7.84686C10.0212 7.91074 9.98881 7.98164 9.96914 8.05648C9.94695 8.14093 9.94695 8.23175 9.94695 8.41339V11.9461C9.94695 12.0782 9.94695 12.1443 9.92563 12.2014C9.9068 12.2519 9.87617 12.2972 9.8363 12.3334C9.79117 12.3744 9.72982 12.3989 9.60712 12.448L7.30955 13.3671C7.06118 13.4664 6.93699 13.5161 6.8373 13.4954C6.75013 13.4772 6.67362 13.4254 6.62443 13.3512C6.56817 13.2664 6.56817 13.1326 6.56817 12.8651V8.41339C6.56817 8.23175 6.56817 8.14093 6.54597 8.05648C6.5263 7.98164 6.49393 7.91074 6.45026 7.84686C6.40097 7.77478 6.33234 7.7153 6.19508 7.59633L1.87309 3.85061C1.73583 3.73165 1.6672 3.67217 1.61791 3.60009C1.57424 3.53621 1.54187 3.46531 1.5222 3.39047C1.5 3.30602 1.5 3.2152 1.5 3.03356V2.58121Z" stroke="#493B43" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </>
  )
}

