import React from "react";
import useTranslations from "../../../i18n/useTranslations";
import SignUpLogo from "../../../assets/images/sign-up-logo.png";
import SignUpLogoMobile from "../../../assets/images/singin-logo-mobile.png";

export default function HeaderRegister({ step = 0, title, subTitle }) {
  const { t } = useTranslations();
  let style0 = "absolute top-0 left-0 mx-auto w-full h-full flex flex-col items-center justify-center"
  if (step) {
    style0 += " pb-[16px] md:pb-[12px]"
  }
  return (
      <div className="relative">
        <img
          className="min-h-[124px] hidden md:block h-auto  w-full md:h-auto md:max-h-auto mx-auto"
          src={SignUpLogo}
          alt="bg-Banner"
        />
        <img
          className="max-h-[120px] w-full md:hidden "
          src={SignUpLogoMobile}
          alt="bg-Banner"
        />
        <div className={style0}>
          <p className="cp-title head mb-[8px]">
            {title}
          </p>
          <div className="divider-gold"></div>
          <p className="text-center text-[12px] md:text-[16px] text-white ">

           {subTitle}
          </p>
        </div>
        {
          !!step && (
            <div className="r-step">
              <p className="text-center text-[20px] text-[#000000] MinervaModern">{step}/2</p>
            </div>
          )
        }
        
      </div>
    )
  }
