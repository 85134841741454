import React from "react";

export default function GridRap({ className, children, isMobileFull, isSmallSize, isMediumSize }) {
  if (isMobileFull) {
    return (
      <>
        <main className={`--gird-rap max-w-screen-lg w-full md:w-[100%] mx-auto ${className}`}>
          {children}
        </main>
      </>
    );
  }
  else if (isSmallSize) {
    return (
      <>
        <main className={`--gird-rap max-w-screen-small w-full md:w-[100%] mx-auto ${className}`}>
          {children}
        </main>
      </>
    );
  }
  else if (isMediumSize) {
    return (
      <>
        <main className={`--gird-rap max-w-screen-medium w-full md:w-[100%] mx-auto ${className}`}>
          {children}
        </main>
      </>
    );
  }

  return (
    <>
        <main className={`--gird-rap max-w-screen-lg w-full md:w-[100%] mx-auto ${className}`}>
          {children}
        </main>
    </>
  );
}
