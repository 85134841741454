import React, { useEffect, useState } from 'react'
import useTranslations from '../../i18n/useTranslations';
import { useNavigate } from 'react-router';
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import GridRap from '../../components/Layouts/GridRap';
import BookingItem from '../../components/general/BookingItem';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import { SearchPointsBurn, SearchPointsEarn } from "../../services/MemberShip";
import moment from 'moment';
import MemberCard from './MemberShipParts/MemberCard';
import DatePickerMonthYear from '../../components/general/DatePickerMonthYear';
import _ from "lodash"
import EmptyPoint from './MemberShipParts/EmptyPoint';
import HistoryItem from './MemberShipParts/HistoryItem';
import ProfileBox from '../Profile/ProfileBox';
import TitleBack from '../../components/general/TitleBack';

const defFilter = { time: null, status: null, location: null }
export default function HistoryPage() {
  const { t } = useTranslations();
  const [isOpen, setIsOpen] = React.useState(false)

  const [selectType, setSelectType] = React.useState("burn")
  const [burnList, setBurnList] = React.useState([])
  const [earnList, setEarnList] = React.useState([])

  const [yearMonth, setYearMonth] = useState(moment());


  const getBurnList = async (payload) => {
    try {
      const { data } = await SearchPointsBurn({ params: payload });
      if (data.resultCode === 20000) {
        const resData = data.body
        setBurnList(resData);
      }
    } catch (e) {
      console.log(`🦄: getBurnList -> e`, e);
    }
  };

  const getEarnList = async (payload) => {
    try {
      const { data } = await SearchPointsEarn({ params: payload });
      if (data.resultCode === 20000) {
        const resData = data.body
        setEarnList(resData);
      }
    } catch (e) {
      console.log(`🦄: getEarnList -> e`, e);
    }
  };

  useEffect(() => {
    // getMyBookingList(107)
    let _yearMonth = null
    if (yearMonth) {
      _yearMonth = _.cloneDeep(yearMonth.format('YYYY-MM'))
    }

    let payload = { yearMonth: _yearMonth }
    if (selectType === "earn") {
      getEarnList(payload)
    } else {
      getBurnList(payload)
    }
  }, [selectType, yearMonth])

  return (
    <>
      <WebContainer wfull={true} bgGraphics={true}>
        <GridMain className="">
          <GridRap>
            <div className="content-full box-content-space">
              <div className="profile-container ">
                <ProfileBox showDesktop/>
                <div className="booking-control">
                  <div className="bgk-container bg-white h-[100%]">
                    <TitleBack title={t.membership.Membership}/>
                    <div className="flex flex-col gap-[16px] md:gap-[32px]">
                      <>
                        <div className="block md:hidden">
                          <MemberCard/>
                        </div>
                        <div>
                          <div className="mb-[24px]">
                            <TabPointType select={selectType} onSelect={(v) => setSelectType(v)}/>
                          </div>
                          <div className="mb-[32px] md:mb-[56px]">
                            <DatePickerMonthYear
                              name="date"
                              placeholder="Month/Year"
                              inputFormat="MM/YYYY"
                              // minDate={moment().add(1, "day")}
                              // maxDate={moment().add(6, "month")}
                              value={yearMonth}
                              onChange={(value) => {
                                setYearMonth(value)
                              }}
                            />
                          </div>
                          <div>
                            
                            <div className="flex flex-col gap-[32px] md:gap-[16px]">
                              {
                                selectType === "burn"
                                ? burnList.length === 0 ? <EmptyPoint text={t.membership.notFoundBurn}/> : burnList.map(item => {
                                    return (
                                      <HistoryItem
                                        type="burn"
                                        point={item.point}
                                        bookingNo={item.bookingNo}
                                        expireDate={item.expire}
                                        discount={item.discount}
                                        createDate={item.createdOn}
                                      />
                                    )
                                  })
                                : earnList.length === 0 ? <EmptyPoint text={t.membership.notFoundEarn}/> : earnList.map(item => {
                                    return (
                                      <HistoryItem
                                        type="earn"
                                        point={item.point}
                                        bookingNo={item.bookingNo}
                                        expireDate={item.expire}
                                        createDate={item.createdOn}
                                      />
                                    )
                                })
                              }
                              
                            </div>
                                
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
    </>
  )
}

const TabPointType = ({ select = "burn", onSelect }) => {
  const { t } = useTranslations();

  const burnTabStyle = select === "burn" ? `active` : ''
  const earnTabStyle = select === "earn" ? `active` : ''
  function handleSelect(_select) {
    if (select !== _select) {
      onSelect(_select)
    }
  }
  return (
    <div className="flex border-[1px] border-Neutral-A300  rounded-[40px]">
      <div className={`tab-point ${burnTabStyle} py-[8px] rounded-[40px] w-[50%] text-center`} onClick={() => handleSelect('burn')} >
        <p className="text-[14px] md:text-[16px] ">{t.membership.pointsUsed}</p>
      </div>
      <div className={`tab-point ${earnTabStyle} py-[8px] rounded-[40px] w-[50%] text-center`} onClick={() => handleSelect('earn')} >
        <p className="text-[14px] md:text-[16px] ">{t.membership.pointEarned}</p>
      </div>
    </div>
  )
}
