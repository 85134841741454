// fonts
// EN
// import "./assets/fonts/en/HelveticaNeue.ttc";
import "./assets/fonts/en/helveticaneue-regular-webfont.ttf";
import "./assets/fonts/en/helveticaneue-medium-webfont.ttf";
import "./assets/fonts/en/helveticaneue_bold-webfont.ttf";
import "./assets/fonts/en/MINERVAMODERNRegular.otf";
import "./assets/fonts/en/Kanit-Light.ttf";

// TH
import "./assets/fonts/th/Priyati-Regular.otf";


import "./index.css";
import "./modal.css";
import "./header.css";
import "./font.css";
import "./muiCustom.css";
import "./ddCustom.css";
import "./sweet-alert.css";
import "./succes-page.css";
import "./general.css";
import "./assets/styles/styles.css";
import "./assets/styles/booking.css";
import "./assets/styles/booking-footer.css";
import "./assets/styles/paper.css";
import "./payment.css";
import "./policy.css";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AppNotLine from "./AppNotLine";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./services/Store";
import { LiffProvider } from "react-liff";
import AppConfig from "./utils/config";
import TagManager from "react-gtm-module";

const CONFIG = AppConfig.config()
// import helper from "./functions/helper";
// import { Logger } from "./services/Logger";

function fnHandleLiff(liff) {
  console.log("error", liff.error);
}

const search = window.location.search;
const path = window.location.pathname;
const liffId = CONFIG.LIFF_ID;
let isLoginBrowser = true;
const RouteNotAuth = [ "/otp" ];
const root = ReactDOM.createRoot(document.getElementById("root"));


const tagManagerArgs = {
  gtmId: CONFIG.GOOGLE_TAG,
};

TagManager.initialize(tagManagerArgs);

if (search.includes('line-') || path.includes('line-') ) {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <LiffProvider liffId={liffId} withLoginOnExternalBrowser={isLoginBrowser} callback={fnHandleLiff}>
          <App />
        </LiffProvider>
      </BrowserRouter>
    </Provider>
  );
} else {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <AppNotLine />
      </BrowserRouter>
    </Provider>
  );
}
