import React from "react";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Autocomplete, TextField } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function CustomAutoComplete({
  name,
  control,
  rules,
  options,
  onChange,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          className="autocomplete-hl"
          disablePortal
          options={options}
          getOptionLabel={(option) => {
            // console.log('option >>', option)
            return option?.name ?? option;
          }}
          placeholder=""
          onChange={onChange}
          popupIcon={<ExpandMoreRoundedIcon/>}
          renderInput={(params) => {
            // console.log("params >>", params);
            // console.log("params field >>", params);
            return (
              <TextField
                {...params}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ""}
              />
            );
          }}
        />
      )}
    />
  );
}

export default CustomAutoComplete;
