import IconPoint from "../../assets/images/icon-point.svg";
import bgCardPoint from "../../assets/images/BG-Card-Point.png";
import time from "../../assets/images/svg/time.svg";
import guest from "../../assets/images/svg/guest.svg";
import treatment from "../../assets/images/svg/treatment.svg";
import location from "../../assets/images/svg/location.svg";
import { Link } from "react-router-dom";


import React from 'react';
import styled from 'styled-components';
import moment from "moment";
import useTranslations from "../../i18n/useTranslations";

const StyledBookingItem = styled.div`
  /* display: flex; */
  /* width: 100%; */
  .p-upcom-detail {
    display: grid;
    grid-template-columns: 35% 65%;
    width: 100%;
  }
  .p-upcom-detail > .head {
    display: flex;
    flex-direction: column;
    border-radius: 10px 0px 0px 10px;
    background: linear-gradient(0deg, #6C646A -44.95%, #B2B2B2 100%);
    padding: 25px 12px;
    justify-content: center;
    text-align: center;
    width: auto;
    min-width: 110px;
    @media (min-width: 768px) {
      /* min-width: 188px; */
      padding: 25px 20px;
    }
  }

  .p-upcom-detail.on > .head {
    background: linear-gradient(180deg, #E8D5BD -8.72%, #C1A078 43.58%);
  }

  .p-upcom-detail > .head > h3 {
    color:#FFF;
    font-family: 'HelveticaNeue';
    text-align: center;
    font-weight: bold !important;
    font-size: 34px;
    font-weight: 400;
    line-height: 1em;
    margin-bottom: 8px;
    @media (min-width: 768px) {
      font-size: 34px;
    }
  }
  .p-upcom-detail.sm > .head > h3 {
    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  .p-upcom-detail > .head > h4 {
    color:#FFF;
    font-size: 14px;
    line-height: 1em;
    margin-bottom: 8px;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
  .p-upcom-detail.sm > .head > h4 {
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .p-upcom-detail > .head > h5 {
    color:#FFF;
    font-size: 12px;
    line-height: 1em;
    font-weight: 500;
    @media (min-width: 768px) {
      font-size: 14px;
    }
    
  }

  .p-upcom-detail.sm > .head > h5 {
    @media (min-width: 768px) {
      font-size: 14px;
    }
  }



  .p-upcom-detail > .tail {
    flex: 1;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid #DEE2E6;
    border-left: 0px solid #DEE2E6;
    background: #FFF;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 768px) {
      padding: 16px 32px;
    }
  }

  .p-upcom-detail > .tail > .box{
   /* margin: auto; */
   max-width: 180px;
    @media (min-width: 768px) {
      /* margin: auto; */
      max-width: 100%;
    }
  }

  .p-upcom-detail.sm > .tail {
    padding: 16px;
  }
  
  .p-upcom-detail.sm > .tail > .box > div {
   
    @media (min-width: 768px) {
      flex-direction: column;
      gap: 4px;
    }
  }

  .p-upcom-detail > .tail > .box > p {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .p-upcom-detail > .tail > .box > p.title {
    color: #343A40;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    @media (min-width: 768px) {
      margin-bottom: 8px;
      font-size: 16px;
    }
  }

  .p-upcom-detail.sm > .tail > .box > p.title {
    @media (min-width: 768px) {
      font-size: 16px;
      margin-bottom: 4px;
    }
    
  }

  .p-upcom-detail > .tail p.content {
    color: #6C757D;
    font-size: 12px;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .p-upcom-detail.sm > .tail p.content {
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .p-upcom-detail > .tail > .box > p[datatype*="time"]::before{
    content: "";
    background: url(${time}) center center no-repeat;
    background-size: 100%;
    width: 12px;
    height: 12px;
    @media (min-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }


  p.b-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    @media (min-width: 768px) {
      gap: 8px;
    }
  }
  p.b-icon::before{
    content: "";
    width: 12px;
    height: 12px;
    background: pink;
    background-size: 100%;
    @media (min-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }

  
  p.b-icon[datatype*="guest"]::before{
      background: url(${guest}) center center no-repeat;
      background-size: 100%;
  }
  p.b-icon[datatype*="treatment"]::before{
      background: url(${treatment}) center center no-repeat;
      background-size: 100%;
  }

  h5.b-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    @media (min-width: 768px) {
      gap: 8px;
    }
  }

  h5.b-icon::before{
    content: "";
    width: 8px;
    height: 12px;
    @media (min-width: 768px) {
      width: 10px;
      height: 14px;
    }
  }

  h5.b-icon[datatype*="location"]::before{
    background: url(${location}) center center no-repeat;
    background-size: 100%;
  }
  .btn-bk-item {
    border-radius: 50px;
    cursor: pointer;
    padding: 4px 36px;
    
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 28px;
      padding: 4px 32px;
    }

    &.more-btn {
      border: 1px solid #6C757D;
      background: #FFF;
      color: #6C757D;
    }
    &.check-btn {
      border: 1px solid #0D962E;
      background: #FFF;
      color: #0D962E;
    }
    &.cancel-btn {
      border: 1px solid #A30000;
      background: #FFF;
      color: #A30000;
    }
  }
`;


const BookingItem = ({ 
    styleList = "off",
    date = "1997-01-01",
    location = "",
    guest = 0, 
    treatment = 0, 
    startTime = "",
    bookingId = 0,
    onClick,
    type = "more",
    link = `/booking-detail`,
    size = ""
}) => {
  const { t } = useTranslations();
  const [ day, month , year ] = moment(date, "YYYY-MM-DD").format("D-MMM-YYYY").split("-")
  return (
    <StyledBookingItem onClick={onClick}>
      <div className={`p-upcom-detail ${size} ${styleList}`}>
        <div className="head">
          <h3 className="">{day}</h3>
          <h4 className="">{month} {year}</h4>
          <h5 className="b-icon" datatype="location">{location}</h5>
        </div>
        <div className="tail">
          <div className="box">
            <p className="title" datatype="time">{t.bookingItem.startAt} {startTime}</p>
            {/* <div className="flex"> */}
            <div className="flex flex-row gap-[12px] md:gap-[16px] mb-[12px] items-start">
                <p className="b-icon content " datatype="guest">{guest} {t.bookingItem.guests}</p>
                <p className="b-icon content " datatype="treatment">{treatment} {t.bookingItem.treatments}</p>
            </div>
          {/* </div> */}
          {
            (type == "more" || type == "0") && (
              <Link to={`${link}/${bookingId}`} className="btn-bk-item more-btn"> {t.bookingItem.moreDetails}</Link>
            )
          }
          {
            type == "1" && (
              <Link to={`${link}/${bookingId}`} className="btn-bk-item check-btn">{t.bookingItem.checkedIn}</Link>
            )
          }
          {
            (type == "2" || type == "4") && (
              <Link to={`${link}/${bookingId}`} className="btn-bk-item cancel-btn">{t.bookingItem.cancelled}</Link>
            )
          }
          </div>
        </div>
      </div>
    </StyledBookingItem>
  );
};

export default BookingItem;