import IconPoint from "../../assets/images/icon-point.svg";
import bgCardPoint from "../../assets/images/BG-Card-Point.png";
import bgCardPointDesktop from "../../assets/images/bg-card-point-desktop.png";


import React from 'react';
import styled from 'styled-components';
import useTranslations from "../../i18n/useTranslations";

const StyledCardPoint = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  background: url(${bgCardPoint}) center center no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  padding: 32px 16px;
  flex-direction: row;
  @media (min-width: 1024px) {
    height: 118px;
    padding: 4px 0px;
    /* gap: 16px; */
    flex-direction: column;
    background: url(${bgCardPointDesktop}) center center no-repeat;
  }


  .p-card-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  .p-card-detail > .label{
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 6px;
  }

  img.icon-point {
    width: 30px;
    @media (min-width: 1024px) {
      width: 40px;
    }
  }
  .p-card-detail > .label > h3 {
    font-size: 36px;
    background: linear-gradient(103deg, #E8D5BD 20.74%, #C1A078 85.15%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 0.8em;
    @media (min-width: 1024px) {
      font-size: 36px;
    }
  }

  .p-card-detail > .label > h5 {
    font-size: 20px;
    background: linear-gradient(103deg, #E8D5BD 20.74%, #C1A078 85.15%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 100%;
    line-height: 0.8em;
    @media (min-width: 1024px) {
      font-size: 24px;
    }
  }

  .p-card-btn {
    padding: 8px 20px;
    border-radius: 32px;
    background: #E1C596;
    line-height: 1em;
  }
  .p-card-btn > p {
    color: #493B43;
    font-size: 12px;
    font-weight: 500;
    line-height: 140%;
    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

`;


const CardPoint = ({ currentPoint = 0, onClick }) => {
  const { t } = useTranslations();
  return (
    <StyledCardPoint >
      <div className="p-card-detail">
        <img className="icon-point" src={IconPoint} alt="icon-point" />
        <div className="label">
          <h3 className="MinervaModern">{currentPoint > 0 ? currentPoint : 0}</h3>
          <h5 className="MinervaModern">{t.profilebox.point}</h5>
        </div>
      </div>
      <button className="p-card-btn" onClick={onClick}>
        <p>{t.profilebox.pointBenefits}</p>
      </button>
    </StyledCardPoint>
  );
};

export default CardPoint;