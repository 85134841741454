import React from "react";
import GridRap from "../../../components/Layouts/GridRap";
import { useDispatch, useSelector } from "react-redux";
import IconUser from "../../../assets/images/icon-user.svg";
import IconCalendar from "../../../assets/images/icon-calendar.png";
import IconMarker from "../../../assets/images/icon-marker.png";
import { useEffect } from "react";
import { getTotalDuration } from "../../../features/BookingSlice";
import GridMain from "../../../components/Layouts/GridMain";
import moment from "moment";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import ButtonPrimaryOutline from "../../../components/button/ButtonPrimaryOutline";
import useTranslations from "../../../i18n/useTranslations";

export default function FooterBookingPaymentPolicy({ onClose , onSave, isNext = true }) {
  const { t } = useTranslations();
  const dispatch = useDispatch()
  const guestData = useSelector(state => state.booking.guestData)

  const handleBack = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleSelect = () => {
    if (onSave) {
      onSave()
    }
  }

  return (
    <footer className="summary">
      <div className="box-2">
        <GridMain>
          <GridRap isSmallSize>
            <div className="flex justify-center gap-[16px] md:gap-[24px]">
              <ButtonPrimaryOutline 
                size="mini"
                addClass=""
                onClick={handleBack}
              >
                {t.onlinePaymentPolicy.btnBack}
              </ButtonPrimaryOutline>
              <ButtonPrimary 
                size="mini"
                addClass=""
                onClick={handleSelect} 
              >
                {t.onlinePaymentPolicy.btnAccept}
              </ButtonPrimary>
            </div>
          </GridRap>
        </GridMain>
      </div>
    </footer>
  )

  
}