import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function CheckSingle({
  label = '',
  value = false,
  name = '',
  onChange,
  error = false,
  disabled = false
}) {

  let isDisabled = "bg-white cursor-pointer"
  if (disabled) {
    isDisabled = "bg-[#E9ECEF] no-event"
  }
  return (
    <div className="">
      <div 
        className={`relative rounded-[4px] border-[1px] border-Neutral-A300 w-[16px] h-[16px] md:w-[24px] md:h-[24px] cursor-pointer ${isDisabled}`}
        onClick={() => {
          if(onChange) {
            if(disabled === false) {
              onChange(!value)
            }
          }
        }}
      >
      <div className="w-full h-full mark-check flex justify-center items-center">
        {
          value && (
            <svg width="14px" height="14px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 4.5L6.75 12.75L3 9" stroke="#E1C596" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          )
        }
      </div>
     </div>
    </div>
  );
}
