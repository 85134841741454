import * as React from "react";
import ButtonBack from "../button/ButtonBack";
import { useNavigate } from "react-router-dom";

export default function TitleBack({ title = "" }){
  const navigate = useNavigate();
  return (
    <div className="relative">
      <ButtonBack className="absolute-y-center" onClick={() => navigate("/profile")}/>
      <h5 className="cp-title">{title}</h5>
    </div>
  );
}
