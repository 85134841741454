import React, { useEffect, useState } from "react";
import WebContainer from "../../components/Layouts/WebContainer";
import GridMain from "../../components/Layouts/GridMain";
import GridRap from "../../components/Layouts/GridRap";
import { useNavigate } from "react-router-dom";
import { SearchMyBooking } from "../../services/Booking";

export default function BookingMain() {
  const [ loading, setLoading] = useState(true)
  const [ bookingId, setBookingId ] = useState(null)

  const getMyBooking = async (payload) => {
    try {
      const { data } = await SearchMyBooking({ params: payload });
      console.log('getMyBooking data >>', data)
      if (data.resultCode === 20000) {
        const resData = data.body
        const _bookingId = resData.id
        const payload = { bookingId: _bookingId }
        setBookingId(_bookingId)
        
        // ถ้าเจอแปลว่า มีการจองแต่ออกก่อน
        window.location.href = '/booking-step4'
      } else {
        // ถ้าไม่เจอแปลว่า ไม่การจองค้างอยู่
        window.location.href = '/booking-step1'
      }
    } catch (e) {
      console.log(`🦄: getMyBooking -> e`, e);
      window.location.href = '/booking-step1'
    }
  };

  useEffect(() => {
    console.log('useEffect BookingMain !!')
    getMyBooking()
  }, [] )

  if (loading) {
    return <div className="bg-[#FFF]"></div>
  }

  return (
    <>
      <WebContainer>
        <GridMain>
          <GridRap>BookingMain</GridRap>
        </GridMain>
      </WebContainer>
    </>
  );
}
