import React from "react";
import GridMain from "../../../components/Layouts/GridMain";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import useTranslations from "../../../i18n/useTranslations";
import GridRap from "../../../components/Layouts/GridRap";
import ButtonPrimaryOutline from "../../../components/button/ButtonPrimaryOutline";
import { Link } from "react-router-dom";

export default function FooterRegister({ currentStep = 0, onBack, onNext, labelBack = "Back", labelNext = "Next" }) {
  const { t } = useTranslations();

  if (currentStep === 0) {
    return (
      <div className="footer-normal-container r-step1">
        <div className="control">
          <ButtonPrimaryOutline
            type="button"
            addClass="w-full md:max-w-[180px] hidden md:block"
            onClick={() => {
              onBack();
            }}
          >
            {t.register.btnBack}
          </ButtonPrimaryOutline>

          <ButtonPrimary
            type="submit"
            onClick={() => {
              onNext();
            }}
            addClass="w-full md:max-w-[180px]"
          >
            {t.register.btnNext}
          </ButtonPrimary>
        </div>
        <div className="block md:hidden text-center flex justify-center gap-[4px] text-[12px]">
          <p>{t.register.AlreadyHaveAccount}</p>
          <Link className="text-[#DDB05B]" to={"/login"}>
            {t.register.aSignIn}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="footer-normal-container">
      <ButtonPrimaryOutline
        type="button"
        onClick={() => {
          onBack();
        }}
      >
        {t.register.btnBack}
      </ButtonPrimaryOutline>

      <ButtonPrimary
        type="submit"
        onClick={() => {
          onNext();
        }}
      >
        {t.register.btnNext}
      </ButtonPrimary>
    </div>
  );
}
