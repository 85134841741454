import React, { useEffect, useState } from "react";
import { CreateCharge } from "../../services/Payment";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import ModalPay from "../BookingNew/ModalPay";
import AppConfig from "../../utils/config";
const CONFIG = AppConfig.config()

function PaymentAliPay({ 
    bookingId = null, 
    amount = 1, 
    description = "", 
    referenceOrder = "",
    onSuccess = () => { console.log('onSuccess!') }
  }) {

  const navigate = useNavigate()
  const [ open, setOpen ] = useState(true);

  const handleClosePopup = (e) => {
    console.log('handleClosePopup e >>', e)
  }

  const handleSubmitPopup = (e) => {
    const payload = {
      bookingId: bookingId,
      description: description,
      amount: amount,
      paymentMethods: "alipay",
      referenceOrder: referenceOrder,
      mode: "token",
      mid: CONFIG.PAYMENT_GETEWAY.DATA_APIMID_ARIPAY
    };

    console.log('handleSubmitPopup payload >>', payload)
    postCreateCharge(payload)
  }

  const postCreateCharge = async (payload) => {
    try {
      const { data } = await CreateCharge({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body;
        console.log('resData >>', resData)
        if (resData.redirect) {
          // navigate(resData.redirect)
          window.location.href = resData.redirect
        }
      } 
    } catch (e) {
        console.log(`🦄: postCreateCharge -> e`, e);
        console.log('err', e)
    }
  };

  const payloadCard = {
    bookingId: bookingId,
    description: description,
    amount: amount,
    paymentMethods: "alipay",
    referenceOrder: referenceOrder,
  };

 
  return <>
    <ModalPay 
      open={open}
      amount={amount}
      onClose={()=> {
        console.log("onClose!!")
        setOpen(false)
      }} 
      onSave={()=> {
        console.log("onSave!!")
        handleSubmitPopup()
      }} 
    />
  </>
}

export default PaymentAliPay;