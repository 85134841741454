import React from "react";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import useTranslations from '../../../i18n/useTranslations';

export default function HistoryDetaillFooter({ 
  onClose , onSave, 
}) {
  const { t } = useTranslations();

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleSave = () => {
    if (onSave) {
      onSave()
    }
  }

  return (
    <>
      <footer className="normal">
        <div className="box-2">
          <div className="flex justify-center gap-[16px] md:gap-[24px]">
            <ButtonPrimary 
              addClass="w-full md:max-w-[180px]"
              onClick={handleSave}
            >
              {t.bookingDetail.bookAgain}
            </ButtonPrimary>
          </div>
        </div>
      </footer>
    </>
  );
}