import React from "react";
import TextCompleted from "../../assets/images/text-completed.png";
import CompletedIcon from "../../assets/images/Check-Mark.png";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import useTranslations from "../../i18n/useTranslations";
import WebContainer from "../../components/Layouts/WebContainer";
import SuccessPage from "../../components/Layouts/SuccessContent";

export default function Completed() {
  const lineId = localStorage.getItem('lineId') ?? null
  const navigate = useNavigate();
  const { t } = useTranslations();
  return (
    <WebContainer bgGraphics={true} contentCenter={true}>
      <SuccessPage>
        <div className="flex flex-col text-center justify-center items-center h-full ">
          <img src={CompletedIcon} alt="" />
          <h5 className="cp-title">Registration Completed</h5>
          <p className="text-[14px] md:text-[20px] leading-[140%] mt-[8px] md:mt-[16px] w-full mb-[32px]">
              {t.register.completeEmail1}
              <br className="block md:hidden"/> {t.register.completeEmail2}
          </p>
          <div className="w-full flex justify-center text-white">
            {/* <button className="btn-continue">Continue</button> */}
            <ButtonPrimary addClass="z-20" onClick={() => { navigate('/booking') } }>{t.register.completeBookingNow}</ButtonPrimary>
          </div>
        </div>
      </SuccessPage>
    </WebContainer>
  )

}
