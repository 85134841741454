import React, { useState, useEffect } from 'react'
import Label from '../../components/general/Label'
import { useForm } from 'react-hook-form';
import useTranslations from '../../i18n/useTranslations';
import IconTel from "../../assets/images/icon-tel.png";
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import { SearchBookingDetails } from "../../services/Booking";
import { AuthVerifyToken } from "../../services/Auth";
import helper from '../../services/helper';
import moment from 'moment';
import { useNavigate, useParams } from "react-router-dom";
import GridRap from '../../components/Layouts/GridRap';
import BookingDetailTemplate from '../MyBooking/BookingDetailParts/BookingDetailTemplate';
import HistoryDetaillFooter from './HistoryDetailParts/HistoryDetaillFooter';
import { setBookingMain, setOtherData, setStep1, setStep2 } from "../../features/BookingMainSlice";
import { useDispatch, useSelector } from "react-redux";
import { SearchDdlBranch } from "../../services/Dropdown";

const { toNumberWithCommas } = helper.FN
export default function HistoryDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const { bookingId } = useParams();

  const [ loading, setLoading ] = useState(true)
  const [ bookingDetail, setBookingDetail ] = useState({})
  const [ bookingList, setBookingList ] = useState([])

  const { register, formState: { errors }, handleSubmit, setValue, control, watch } = useForm({
  });
  const { t } = useTranslations();

  const getBookingDetails = async (payload) => {
    try {
      const { data } = await SearchBookingDetails({ params: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        setBookingDetail(resData)
        setBookingList(resData.bookingList)
        // setValue('branch', Branch.id)
      }
    } catch (e) {
      console.log(`🦄: getBookingDetails -> e`, e);
    }
  };


  useEffect(() => {
    console.log('useEffect Booking Detail !')
    const payload = { bookingId: bookingId }
    getBookingDetails(payload)
    getDdlBranch()
  }, [])


  const getDdlBranch = async () => {
    try {
      const { data } = await SearchDdlBranch();
      if (data.resultCode === 20000) {
        setBranchDD(data.body)
        // setValue('branch', Branch.id)
      }
    } catch (e) {
      console.log(`🦄: getDdlNationality -> e`, e);
    }
  };

  function setBranchDD(value) {
    const tmpObj = { ddBranch: value }
    dispatch(setOtherData(tmpObj));
  }

  function fnSetStep1(value) {
    dispatch(setStep1(value));
  }

  function setGuestData(value) {
    const tmpObj = { guestData: value };
    dispatch(setStep2(tmpObj));
  }

  function gotoBooking() {
    console.log('bookingDetail >>', bookingDetail)
    const step1 = {
      branch: Number(bookingDetail.branchId),
      guestCount: bookingList.length,
      date: bookingDetail.bookingDate,
    };

    const step2 = bookingList.map(item => {
      let tmpValue = {}
      const subTreatmentList = item?.subTreatment ?? []
      if (Array.isArray(subTreatmentList)) {
        for (let st of subTreatmentList) {
          let pid = st.programId
          let sid = st.treatmentId
          let stid = st.id
          let tmpKey = `${pid}|${sid}|${stid}`
          tmpValue[tmpKey] = true
        }
      }
      console.log('tmpValue >>', tmpValue)
      return {
        name: item.guestName,
        number: item.guestNumber,
        program: [],
        values: tmpValue,
        totalDuration: 0,
        totalPrice: 0,
      }
    })

    fnSetStep1(step1)
    setGuestData(step2)

    setTimeout(() => {
      navigate('/booking-step3')
    }, 500)
  }

  function convertStatus(bookingStatus, paymentStatus) {
    // navigate('/booking')
    if (bookingStatus == 1) {
      return "Checked"
    }
    return "Cancel"
  }


  return (
    <>
      <WebContainer bgGraphics={true}  
      footer={
        <HistoryDetaillFooter onSave={() => gotoBooking()}/>
      }>
        <GridMain className="">
          <GridRap isSmallSize>
            <div className="content-full box-content-space has-footer">
              <BookingDetailTemplate
                title="Booking Details"
                bookingDate={bookingDetail.bookingDate}
                bookingNo={bookingDetail.bookingNo}
                bookingDetail={bookingDetail}
                bookingList={bookingList}
                status={convertStatus(bookingDetail.bookingStatus, bookingDetail.paymentStatus)}
              />
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
      {/* <BookingDetaillFooter onClose={() => gotoMybooking()} onSave={() => gotoCheckIn()}/> */}
    </>
  )
}