import React, { useState } from 'react'
import Label from '../../components/general/Label'
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@mui/material'
import { useForm } from 'react-hook-form';
import useTranslations from '../../i18n/useTranslations';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconContact from '../../assets/images/icon-contact.png'
import { useSelector } from 'react-redux';
import moment from 'moment';
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import StepBanner from './StepBanner';
import { useEffect } from 'react';

import { SearchMyBooking } from "../../services/Booking";
import { SearchBookingDetails, UpdateContactInfo, UpdateCancelReserveSlotBooking } from "../../services/Booking";
import helper from '../../services/helper';
import FooterNormal from './FooterNormal';
import { useNavigate } from "react-router-dom";
import ModalBase from '../../components/Modal/ModalBase';
import GridRap from '../../components/Layouts/GridRap';
import Label2 from '../../components/general/Label2';

import IconUser from "../../assets/images/svg/guest-one.svg";
import IconCalendar from "../../assets/images/svg/calendar-one.svg";
import IconTime from "../../assets/images/svg/time-one.svg";
import IconMarker from "../../assets/images/svg/marker-one.svg";
import ButtonPrimaryOutline from '../../components/button/ButtonPrimaryOutline';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import ImageLabel from '../../components/general/ImageLabel';
import ModalReSlot from './ModalReSlot';

function Row(props) {
  const { t } = useTranslations();
  const { row, i } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="tb-box">
        <div className="head-title">
          <div className="flex gap-[8px] items-center">
            {
              open 
              ? <div className="sw-fn off" onClick={() => setOpen(!open)}/> 
              : <div className="sw-fn on" onClick={() => setOpen(!open)}/>
            }
                          {/* {`${t.bookingStep2.guest}`.replace('$number', (1))} */}
            <ImageLabel dataType="user" size="mini">{`${t.bookingStep4.guest}`.replace('$number', (i + 1))}</ImageLabel>
          </div>
          <div className="flex gap-[24px]">
            <p className="text-[12px] md:text-[16px] font-medium">{row.maxPeriod} min</p>
            <p className="text-[12px] md:text-[16px] font-medium">{row.price ? helper.FN.toNumberWithCommas(row.price) : 0 } THB</p>
          </div>
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="flex flex-col gap-[8px]">
          <div className="sp4-table" >
            <h5>{t.bookingStep4.name} : {row.guestName}</h5>
            {
              row?.subTreatment.map((childRow, i) => {
                return (
                  <>
                    <div className="detail">
                      <h6>{childRow.name}</h6>
                      <div>
                        <p>{childRow.period} {t.bookingStep4.min}</p>
                        <p>{childRow.price ? helper.FN.toNumberWithCommas(childRow.price) : 0 } {t.bookingStep4.thb}</p>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
          </div>
        </Collapse>
      </div>
    </>
  );
}

export default function BookingStep4() {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const profile = useSelector((state) => state.profile);

  const [ dropdownBranch, setDropdownBranch ] = useState([])
  const [ bookingDetail, setBookingDetail ] = useState({})
  const [ bookingList, setBookingList ] = useState([])
  const [ bookingId, setBookingId ] = useState(null)

  const [ contactName, setContactName ] = useState("")
  const [ contactTel, setContactTel ] = useState("")
  const [ contactEmail, setContactEmail ] = useState("")
  const [ isSubmit, setIsSubmit ] = useState(false)

  // POPUP STATE 
  const [ isShowPopupBack, setIsShowPopupBack ] = useState(false)

  const getBookingDetails = async (payload) => {
    try {
      const { data } = await SearchBookingDetails({ params: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        setBookingDetail(resData)
        console.log('resData >>', resData)
        console.log('profile >>', profile)


        const _contactName = resData?.contactName ?? profile.firstname + " " + profile.lastname
        const _contactTel = resData?.contactTel ?? profile.mobileNumber
        const _contactEmail = resData?.contactEmail ?? profile.email

        setContactName(_contactName)
        setContactTel(_contactTel)
        setContactEmail(_contactEmail)


        setBookingList(resData.bookingList)
        // setValue('branch', Branch.id)
      }
    } catch (e) {
      console.log(`🦄: getDdlNationality -> e`, e);
    }
  };

  const postUpdateContactInfo = async (payload) => {
    try {
      const { data } = await UpdateContactInfo({ data: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        gotoStep5()
      }
    } catch (e) {
      console.log(`🦄: postUpdateContactInfo -> e`, e);
    }
  };
  const postUpdateCancelReserveSlotBooking = async (payload) => {
    try {
      const { data } = await UpdateCancelReserveSlotBooking({ data: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        navigate('/booking-step3')
      }
    } catch (e) {
      console.log(`🦄: postUpdateCancelReserveSlotBooking -> e`, e);
    }
  };

  const getMyBooking = async (payload) => {
    try {
      const { data } = await SearchMyBooking({ params: payload });
      if (data.resultCode === 20000) {
        const resData = data.body
        const _bookingId = resData.id
        const payload = { bookingId: _bookingId }
        getBookingDetails(payload)
        setBookingId(_bookingId)
      } else {
        window.location.href = '/booking'
      }
    } catch (e) {
      console.log(`🦄: getMyBooking -> e`, e);
      window.location.href = '/booking'
    }
  };

  useEffect(() => {
    if (profile.id) {
      console.log('useEffect step 4 !')
      getMyBooking()
    }
  }, [profile] )

  

  function gotoStep3() {
    const payload = { bookingId: bookingId }
    postUpdateCancelReserveSlotBooking(payload)
    
  }

  function gotoStep5() {
    navigate('/booking-step5')
  }

  const handleBack = () => {
    // alert('handleBack !')
    setIsShowPopupBack(true)
  }
  const handleSave = () => {
    // alert('handleSave !')
    // gotoStep5()
    // alert(validateField())
    setIsSubmit(true)
    if (validateField()) {
      const payload = {
        bookingId: bookingId,
        contactName: contactName,
        contactTel: contactTel,
        contactEmail: contactEmail,
      }
      postUpdateContactInfo(payload)
    }
  }

  useEffect(() => { window.scrollTo({ top: 0 }) }, []); // newpage-scoll-top
  
  const validateField = () => {
    return Boolean(contactName && contactTel && contactTel.length > 8 && contactEmail)
  }

  return (
    <>
      <ModalReSlot 
        open={isShowPopupBack} 
        onSave={() => {
          gotoStep3()
        }}
        onClose={() => setIsShowPopupBack(false)}
      />
      <WebContainer footer={
        <FooterNormal step={4} bookingList={bookingList} onClose={handleBack} onSave={handleSave} isNext={!false}/>
      }>
        <GridMain className="bk-container ribbonx bk-step4">
          <GridRap isSmallSize>
          <div className="flex flex-col items-center">
            <StepBanner current={3}/>
              <div className="w-full mt-[24px] md:mt-[36px]">
                <h5 className="cp-title mb-[24px] md:mb-[16px]">Booking Summary</h5>
                {/* {JSON.stringify(booking.guestData)} */}
                <div className="flex flex-col gap-[24px]">
                <div className="flex flex-col gap-16px]">
                    <div className="flex justify-between items-center ">
                      <ImageLabel dataType="contact">{t.bookingStep4.contactInfo}</ImageLabel>
                      <p className="text-[10px] md:text-[14px]">{t.bookingStep4.yourContact}</p>
                      </div>
                      <div className="sp4-form">
                        <div className="sp4-row">
                          <Label thin>{t.bookingStep4.name}:</Label>
                          <TextField
                            required
                            fullWidth
                            placeholder='Name'
                            error={isSubmit && !contactName}
                            // helperText={'errors?.contactName?.message'}
                            value={contactName}
                            onChange={(e)=> setContactName(e.target.value)}
                            inputProps={{ 
                              maxLength: 150,
                              onChange: helper.FNFORM.handleCharOnly,
                            }}
                          />
                        </div>
                        <div className="group">
                          <div className="sp4-row">
                            <Label thin>{t.bookingStep4.tel}:</Label>
                            <TextField
                              required
                              fullWidth
                              placeholder='Tel'
                              error={isSubmit && !(contactTel && contactTel.length > 8)}
                              // helperText={'errors?.contactName?.message'}
                              value={contactTel}
                              onChange={(e)=> setContactTel(e.target.value)}
                              inputProps={{
                                minLength: 8,
                                maxLength: 15,
                                onChange: helper.FNFORM.handleNumberOnly,
                              }}
                            />
                          </div>
                          <div className="sp4-row">
                            <Label thin>{t.bookingStep4.email}:</Label>
                            <TextField
                              required
                              fullWidth
                              placeholder='Email'
                              error={isSubmit && !contactEmail}
                              // helperText={'errors?.contactName?.message'}
                              value={contactEmail}
                              onChange={(e)=> setContactEmail(e.target.value)}
                              inputProps={{ 
                                maxLength: 150,
                                onChange: helper.FNFORM.handleCharOnly,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sp4-detail">
                        <ImageLabel dataType="date">{t.bookingStep4.bookingDetails}</ImageLabel>
                        <div className="sp4-con">
                          <InfoText2 img={IconUser} title={`${t.bookingStep4.guestNumber}`.replace('$number', bookingDetail?.bookingList?.length)} value={""} noColon={true}/>
                          <InfoText2 img={IconCalendar} title={t.bookingStep4.date} value={moment(bookingDetail?.bookingDate).format('DD MMM YYYY')}/>
                          <InfoText2 img={IconTime} title={t.bookingStep4.time} value={bookingDetail.bookingStart ? helper.TIME.toShortTime(bookingDetail.bookingStart) : bookingDetail.bookingStart}/>
                          <InfoText2 img={IconMarker} title={t.bookingStep4.location} value={bookingDetail?.location}/>
                        </div>
                      </div>
                      <div className="flex flex-col gap-[16px] py-[16px] px-[16px] md:px-[0px]">
                          {
                            bookingList.map((row, i) => {
                              console.log('row >>', row)
                              
                              let maxPeriod = 0
                              let price = 0
                              for (let r of row.subTreatment) {
                                console.log('r >>', r)
                                if (r.period >= maxPeriod) {
                                  maxPeriod = r.period
                                }
                                price += r.price
                              }

                              row.maxPeriod = maxPeriod
                              row.price = price
                              return (
                                <Row key={row.name} row={row} i={i} />
                              )}
                            )
                          }

                      </div>
                  </div>
                </div>
              </div>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
    </>
  );
}


const InfoText2 = ({ img , title, value = "-" , className = "", styleList = null, noColon = false}) => {
  let pClassName = ""
  let spClassName = ""
  if (styleList == 0) {
    pClassName = "md:text-right md:w-[200px]"
    spClassName = "md:ml-[40px]"
  }
  return (
      <div className={`flex gap-[4px] items-center ${className}`}>
        { img ? <img className="w-auto h-[16px]" src={img} alt="img-info" /> : <></> }
        <p className={`text-[12px] md:text-[16px] font-medium ${pClassName}`}>{title}{ noColon ? '' : ':' } <span className={`ml-[2px] text-Neutral-A800 font-normal ${spClassName}`}>{value}</span></p>
      </div>
  )
}