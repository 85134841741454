import { createSlice } from '@reduxjs/toolkit'

const defaultData = {
  id: 0,
  isLoading: false,
}

export const ProfileSlice = createSlice({
  name: 'Profile',
  initialState: defaultData,
  reducers: {
    setProfile: (state, action) => {

      for (const [key, value] of Object.entries(action.payload)) {
        // console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetProfile: () => defaultData
  },
})

// Action creators are generated for each case reducer function
export const {
  setProfile, resetProfile
} = ProfileSlice.actions

export default ProfileSlice.reducer