
import React, { useState, useEffect } from "react";
import useTranslations from "../../../i18n/useTranslations";
import ModalBase2 from "../../../components/Modal/ModalBase2";
import ButtonPrimaryOutline from "../../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import { SearchDdlBranch } from "../../../services/Dropdown";
import Label from "../../../components/general/Label";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import _ from "lodash"
import DatePickerMonthYear from "../../../components/general/DatePickerMonthYear";
import Radio from '@mui/material/Radio';
import moment from "moment";

const defFilter = { time: moment(), status: null, location: null }
export default function ModalFilter({ open = false, onClose, onChange, value = {} }) {
  const { t } = useTranslations();
  const [tmpFilter, setTmpfilter] = useState(defFilter);
  const [filter, setFilter] = useState(defFilter);
  const [dropdownBranch, setDropdownBranch] = useState([]);

  const getDdlBranch = async () => {
    try {
      const { data } = await SearchDdlBranch();
      if (data.resultCode === 20000) {
        setDropdownBranch(data.body);
        // setValue('branch', Branch.id)
      }
    } catch (e) {
      console.log(`🦄: getDdlBranch -> e`, e);
    }
  };

  useEffect(() => {
    setFilter(_.cloneDeep(value));
    setTmpfilter(_.cloneDeep(value));
  }, [open]);

  useEffect(() => {
    getDdlBranch();
  }, []);

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleClear = () => {
    if (onChange) {
      // const _tmp = _.cloneDeep(tmpFilter)
      const _tmp = _.cloneDeep(defFilter)
      setFilter(_tmp)
    }
  }
  const handleSave = () => {
    if (onChange) {
      const _tmp = _.cloneDeep(filter)
      onChange(_tmp)
    }
  }

  const handleChange = (event) => {
    let _filter = _.cloneDeep(filter)
    _filter.status = event.target.value
    setFilter(_filter)
  };
  return (
    <>
      <ModalBase2
        open={open}
        onClose={()=>{}}
        size="size1 notpad"
        header={
          <>
            <div className="flex justify-between items-center w-full py-[16px] md:pt-[24px] md:pb-[16px] px-[24px] border-b-[#DEE2E6] border-b-[1px] md:border-b-[2px]">
              <div className="flex gap-[8px] items-center">
                <FilterIcon/>
                <h4 className="text-[16px] md:text-[20px] font-medium text-[#493B43] leading-[0.8em]">{t.historyFilter.historyFilter}</h4>
              </div>
              <CloseIcon onClick={handleClose}/>
            </div>
          </>
        }
        content={
          <div className="flex gap-[24px] md:gap-[16px] flex-col justify-center py-[12px] px-[24px]">
            {/* {JSON.stringify(filter)} */}
            <div>
              <Label bold color2 size2>{t.historyFilter.time}</Label>
              <div className="w-full input-booking">
                <DatePickerMonthYear
                  name="date"
                  placeholder="Month/Year"
                  inputFormat="MM/YYYY"
                  // minDate={moment().add(1, "day")}
                  // maxDate={moment().add(6, "month")}
                  value={filter.time}
                  onChange={(value) => {
                    let _filter = _.cloneDeep(filter)
                    _filter.time = value
                    setFilter(_filter)
                  }}
                />
              </div>
            </div>
            <div>
              <Label bold color2 size2>{t.historyFilter.status}</Label>
              <div className="w-full flex gap-[4px]">
                <div className="flex gap-[0px] items-center">
                  <Radio
                    checked={filter.status === '1'}
                    onChange={handleChange}
                    value="1"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                  <p className="text-[14px] md:text-[16px]">{t.historyFilter.checkedIn}</p>
                </div>
                <div className="flex gap-[0px] items-center">
                  <Radio
                    checked={filter.status === '2'}
                    onChange={handleChange}
                    value="2"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'B' }}
                  />
                  <p className="text-[14px] md:text-[16px]">{t.historyFilter.cancelled}</p>
                </div>
              
              
              </div>
            </div>
            <div>
              <Label bold color2 size2>{t.historyFilter.location}</Label>
              <div className="w-full input-booking">
                <Select
                  className="selection-hl"
                  displayEmpty
                  fullWidth
                  IconComponent={ExpandMoreRoundedIcon}
                  renderValue={(selected) => {
                    if (!selected || selected.length === 0) {
                      return <span className="txt-gray">{t.historyFilter.locationPlaceholder}</span>;
                    }
                    return dropdownBranch.find((item) => item.id === selected)
                      ?.name;
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  value={filter.location}
                  onChange={(e) => {
                    let _filter = _.cloneDeep(filter)
                    _filter.location = e.target.value
                    setFilter(_filter)
                  }}
                  // MenuProps={MenuSelectProps}
                >
                  {Array.isArray(dropdownBranch) &&
                    dropdownBranch.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        <em className="flex items-center">{item.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
          </div>
        }
        footer={
          <>
            <div className="w-full flex flex-col md:flex-row-reverse gap-[16px] justify-center items-center pt-[12px] pb-[18px] md:pb-[24px] px-[24px]">
              <ButtonPrimary 
                addClass="w-full md:max-w-[180px]"
                onClick={handleSave}
              >
                {t.historyFilter.btnApplyfilter}
              </ButtonPrimary>
              <ButtonPrimaryOutline 
                addClass="w-full md:max-w-[180px]"
                onClick={handleClear}
              >
                {t.historyFilter.btnReset}
              </ButtonPrimaryOutline>
            </div>
          </>
        }
      />
    </>
  );
};


const FilterIcon = () => {
  return (
    <>
      <div className="hidden md:flex">
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 2.35151C1.5 1.87844 1.5 1.6419 1.59264 1.46121C1.67414 1.30227 1.80417 1.17305 1.96411 1.09207C2.14593 1 2.38396 1 2.86 1H17.14C17.616 1 17.8541 1 18.0359 1.09207C18.1958 1.17305 18.3259 1.30227 18.4074 1.46121C18.5 1.6419 18.5 1.87844 18.5 2.35151V2.91695C18.5 3.144 18.5 3.25752 18.4721 3.36309C18.4473 3.45663 18.4066 3.54526 18.3517 3.62511C18.2897 3.71521 18.2034 3.78957 18.0307 3.93827L12.5943 8.62042C12.4216 8.76912 12.3353 8.84347 12.2733 8.93358C12.2184 9.01342 12.1777 9.10205 12.1529 9.1956C12.125 9.30116 12.125 9.41469 12.125 9.64174V14.0576C12.125 14.2228 12.125 14.3054 12.0982 14.3768C12.0745 14.4399 12.036 14.4965 11.9858 14.5417C11.9291 14.593 11.8519 14.6237 11.6975 14.685L8.80755 15.8338C8.49513 15.958 8.33893 16.0201 8.21353 15.9942C8.10388 15.9716 8.00765 15.9068 7.94576 15.814C7.875 15.708 7.875 15.5408 7.875 15.2064V9.64174C7.875 9.41469 7.875 9.30116 7.84708 9.1956C7.82234 9.10205 7.78162 9.01342 7.72668 8.93358C7.66469 8.84347 7.57836 8.76912 7.40571 8.62042L1.96929 3.93827C1.79664 3.78957 1.71031 3.71521 1.64832 3.62511C1.59338 3.54526 1.55266 3.45663 1.52792 3.36309C1.5 3.25752 1.5 3.144 1.5 2.91695V2.35151Z" stroke="#493B43" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div className="flex md:hidden">
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 2.08121C1 1.70275 1 1.51352 1.07365 1.36897C1.13844 1.24182 1.24182 1.13844 1.36897 1.07365C1.51352 1 1.70275 1 2.08121 1H13.4339C13.8124 1 14.0016 1 14.1461 1.07365C14.2733 1.13844 14.3767 1.24182 14.4415 1.36897C14.5151 1.51352 14.5151 1.70275 14.5151 2.08121V2.53356C14.5151 2.7152 14.5151 2.80602 14.4929 2.89047C14.4733 2.96531 14.4409 3.03621 14.3972 3.10009C14.3479 3.17217 14.2793 3.23165 14.142 3.35061L9.82004 7.09633C9.68278 7.2153 9.61414 7.27478 9.56486 7.34686C9.52119 7.41074 9.48881 7.48164 9.46914 7.55648C9.44695 7.64093 9.44695 7.73175 9.44695 7.91339V11.4461C9.44695 11.5782 9.44695 11.6443 9.42563 11.7014C9.4068 11.7519 9.37617 11.7972 9.3363 11.8334C9.29117 11.8744 9.22982 11.8989 9.10712 11.948L6.80955 12.8671C6.56118 12.9664 6.43699 13.0161 6.3373 12.9954C6.25013 12.9772 6.17362 12.9254 6.12443 12.8512C6.06817 12.7664 6.06817 12.6326 6.06817 12.3651V7.91339C6.06817 7.73175 6.06817 7.64093 6.04597 7.55648C6.0263 7.48164 5.99393 7.41074 5.95026 7.34686C5.90097 7.27478 5.83234 7.2153 5.69508 7.09633L1.37309 3.35061C1.23583 3.23165 1.1672 3.17217 1.11791 3.10009C1.07424 3.03621 1.04187 2.96531 1.0222 2.89047C1 2.80602 1 2.7152 1 2.53356V2.08121Z" stroke="#493B43" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </>
  )
}
const CloseIcon = ({ onClick }) => {
  return (
    <>
      <div className="hidden md:flex cursor-pointer" onClick={ () => onClick() }>
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.5 1.5L1.5 15.5M1.5 1.5L15.5 15.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </div>
      <div className="flex md:hidden cursor-pointer" onClick={ () => onClick() }>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1L1 13M1 1L13 13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </>
  )
}