import React, { useState } from "react";
import TopLogo from "../../../components/Layouts/TopLogo";
import IconPiont from "../../../assets/images/Icon-Piont.svg";
import IconPoint from "../../../assets/images/icon-points.png";
import IconPointGray from "../../../assets/images/icon-point-gray.png";
import "../../../assets/styles/Membership.css";
import Tab from "../../../components/general/Tab";
import dayjs from "dayjs";
import useTranslations from "../../../i18n/useTranslations";
import MyProfiles from "../../../components/general/MyProfile";
import { useDispatch, useSelector } from 'react-redux';
import AvatarProfile from "../../../assets/images/Avatar-Profiles.svg";
import PopupPointBenefit from "../../../components/Popup/PopupPointBenefit";


export default function MemberCard({ data = {} }) {
  const [modalPoints, setModalPoint] = useState(false);
  const profile = useSelector((state) => state.profile)
  const { t } = useTranslations();

  return (
    <>
      <div className="card-membership">
        <div className="c-mp-container">
          <div className="flex items-center gap-[12px] md:gap-[24px]">
            <div>
              <img
                className="imgProfile-bor w-full max-w-[41px] h-[41px] md:max-w-[91px] md:h-[91px] object-cover"
                src={profile.imagePicture ? profile.imagePicture : AvatarProfile}
                alt=""
              />
            </div>
            <div className=" flex-1 flex flex-col gap-[8px]">
              <p className="text-[#E1C596] text-[10px] font-mediumx">{t.membership.HealthlandMemberCard}</p>
              <p className="text-[#FFFFFF] text-[20px] MinervaModern leading-[0.8em]">{profile.firstname} {profile.lastname}</p>
            </div>
          </div>
          <div className="--footer flex items-end justify-between">
            <div className="flex gap-[8px] items-center">
              <div>
                <img className="w-full max-w-[30px]" src={IconPiont} alt="" />
              </div>
              <div className="flex gap-[8px] items-end">
                <p className="text-[32px] leading-[0.8em] MinervaModern num-point">{profile.currentPoint}</p>
                <p className="text-[20px] leading-[0.8em] MinervaModern unit-point">{t.membership.Points}</p>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <button className="member-ship-point-benefits-btn" onClick={() => setModalPoint(true)}>
                <p className="">{t.membership.PointBenefits}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <PopupPointBenefit open={modalPoints} onChange={(e)=> setModalPoint(e) } />
    </>
  )
}