import React, { useState, useEffect } from "react";
import TopLogo from "../../components/Layouts/TopLogo";
import AvatarProfiles from "../../assets/images/Avatar-Profile.svg";
import AvatarProfile from "../../assets/images/Avatar-Profiles.svg";
import "../../assets/styles/Profile.css";
import EditProfile from "../../assets/images/Edit-profile.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { SearchLineId, SearchMemberById } from "../../services/Member";
import helper from "../../services/helper";
import moment from "moment";
import netl from "../../assets/images/bg-base/hl-net-left.png";
import netr from "../../assets/images/bg-base/hl-net-right.png";
import WebContainer from "../../components/Layouts/WebContainer";
import GridMain from "../../components/Layouts/GridMain";
import ProfileBox from "./ProfileBox";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import useTranslations from "../../i18n/useTranslations";

export default function ViewProfile() {
  const { t, lang } = useTranslations();
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const [height, setHeight] = useState(0);

  const OPTION_GENDER = helper.OPTIONS.GENDERLANG(lang);

  const getSearchMemberById = async (memberId = null) => {
    try {
      const { data } = await SearchMemberById({ memberId: memberId });
      if (data.resultCode === 20000) {
        setProfile(data.body);
      } else if (data.resultCode === 40401) {
        helper.Auth.Logout("/login");
      }
    } catch (e) {
      console.log("ERR getSearchMemberById", e);
    }
  };

  useEffect(() => {
    setHeight(window.screen.height);
    const data = helper.Auth.getTokenData();
    getSearchMemberById(data.memberId);
  }, []);

  console.log(profile);

  const ButtonTemplate = ({ label = "" }) => {
    return (
      <ButtonPrimaryOutline
        size="mini"
        addClass="flex w-full  w-[164px] md:w-[145px] gap-[8px] justify-center items-center"
        onClick={() => {
          navigate("/profile-edit");
        }}
      >
        {label}
        <img className="w-auto h-[12px] md:h-[14px]" src={EditProfile} alt="pen-icon" />
      </ButtonPrimaryOutline>
    )
  }
  return (
    <WebContainer bgGraphics={true} contentCenter={false} wfull={true}>
      <GridMain>
        <div className="content-full box-content-space">
          <div className="md:flex gap-x-[48px] justify-center">
            <div className="flex justify-center flex-col items-center md:w-[180px]">
              <h5 className="cp-title">Profile</h5>
              <div className="flex justify-center pt-[28px] pb-[29px] md:pb-[48px]">
                <img
                  className="imgProfile"
                  src={
                    profile.imagePicture ? profile.imagePicture : AvatarProfile
                  }
                  alt=""
                />
              </div>
              <div className="hidden md:flex justify-center">
                <ButtonTemplate label={t.profileView.btnEditProfile} />
              </div>
            </div>
            <div className="bg-profile-right w-full max-w-[435px] md: md:w-[435px]">
              <div className="record-view">
                <InfoTextTemplate title={t.profileView.name} value={profile.firstname} />
                <InfoTextTemplate title={t.profileView.surname} value={profile.lastname} />
              </div>

              <div className="record-view">
                <InfoTextTemplate title={t.profileView.birthdate} value={moment(profile.birthDate).format("DD/MM/YYYY")} />
                <InfoTextTemplate title={t.profileView.mobile} value={profile.mobileNumber} />
              </div>
              {/* email */}
              <div className="">
                <InfoTextTemplate title={t.profileView.email} value={profile.email} />
              </div>

              <div className="record-view">
                <InfoTextTemplate title={t.profileView.nationality} value={profile.nationality?.name} />
                <InfoTextTemplate 
                  title={t.profileView.gender}
                  value={OPTION_GENDER.find((item) => item.value === profile.genderId)?.label} 
                  styleValue={{ fontWeight: lang === 'cn' ? 'bold': 500 }}
                />
              </div>
              {/* button edit profile */}
              <div className="flex md:hidden justify-center">
                <ButtonTemplate label={t.profileView.btnEditProfile} />
              </div>
            </div>
          </div>
        </div>
      </GridMain>
    </WebContainer>
  );
}


const InfoTextTemplate = ({ title, value, styleValue = {} }) => {
  const styleDetailText = { color: "#343A40", fontWeight: 500, ...styleValue };
  return (
    <div className="flex flex-col gap-[4px]">
      <p className="text-[#6D6E71] text-[12px] md:text-[16px]">
        {title}
      </p>
      <p className="text-[14px] md:text-[16px] font-medium" style={styleDetailText}
      >
        {value}
      </p>
    </div>
  )
}