import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import GridMain from '../Layouts/GridMain';

export default function BasicTabs({ value = null, tabs = [], handleChange, keyName = 'name' }) {
  return (
    <>
      <div className="--tabmobile hidden md:block">
        <Tabs
          className="hl-tab"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          orientation="vertical"
        >
          {tabs?.map(tab => (
            <Tab label={tab[keyName]} />
          ))}
        </Tabs>
      </div>
      <div className="--tabdesktop md:hidden">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          orientation="horizontal"
        >
          {tabs?.map(tab => (
            <Tab label={tab[keyName]} />
          ))}
        </Tabs>
      </div>
    </>
  );
}