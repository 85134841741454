import GridMain from "../../components/Layouts/GridMain";
import GridRap from "../../components/Layouts/GridRap";
import WebContainer from "../../components/Layouts/WebContainer";
import FooterNormal from "../BookingNew/FooterNormal";
import credit from "../../assets/images/booking/payment/credit.svg"
import qrCode from "../../assets/images/booking/payment/qr-code.svg"
import alipay from "../../assets/images/booking/payment/alipay.svg"
import weChat from "../../assets/images/booking/payment/we-chat.svg"

import { CheckBookingPayment } from "../../services/Payment";
import { SearchBookingDetails, SearchBookingExpire, UpdateCancelReserveSlotPayment } from "../../services/Booking";
import Payment from "./Payment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import PaymentQR from "./PaymentQR";
import moment from "moment";
import PaymentCreditCard from "./PaymentCreditCard";
import PaymentAliPay from "./PaymentAliPay";
import ModalReSlot from "../BookingNew/ModalReSlot";
import { UpdateCancelReserveSlotBooking } from "../../services/Booking";
import ModalTimeOutReSlot from "../BookingNew/ModalTimeOutReSlot";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import CountdownTimer from "../BookingNew/CountdownTimer";
import useTranslations from "../../i18n/useTranslations";
import ModalPay from "../BookingNew/ModalPay";
import helper from "../../services/helper";

const PaymentWechat = PaymentQR

const listCheckoutDef = [
  { id: "1", type: "credit", img: credit, text: "Credit / Debit Card", dataPaymentMethods: "card", dataAmount: 1 },
  { id: "2", type: "qrCode", img: qrCode, text: "Thai QR Payment", dataPaymentMethods: "qr", dataAmount: 1 },
  { id: "3", type: "alipay", img: alipay, text: "Alipay", dataPaymentMethods: "card", dataAmount: 1 },
  { id: "4", type: "weChat", img: weChat, text: "Wechat", dataPaymentMethods: "card", dataAmount: 1 },
];

const paymentDataDef = {
  loading: false,
  amount: 1,
  bookingNo: "#08-20231113-0001",
  location: "Sathorn",
  contactName: "Surasit Yerpui",
  contactEmail: "pukpui15s@gmail.com"
}

function PaymentDev() {
  const { t } = useTranslations();
  const { bookingId } = useParams();

  const navigate = useNavigate()
  const [ listCheckout, setListCheckout ] = useState([])

  const [ paymentData, setPaymentData ] = useState(paymentDataDef)
  const [ bookingDetail, setBookingDetail ] = useState({})

  const [ selectPaymentType, setSelectPaymentType ] = useState(null)
  const [ submitType, setSubmitType ] = useState("");

  // POPUP STATE 
  const [ isShowPopupBack, setIsShowPopupBack ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ bookingExpire, setBookingExpire ] = useState(null)
  const [ paymentExpire, setPaymentExpire ] = useState(null)

  const handleBack = () => {
    setIsShowPopupBack(true)
  };

  function handleSave() {
    setSubmitType("");
    setTimeout(() => { setSubmitType(selectPaymentType); }, 150);
  }

  function onPaymentSuccess() {
   alert("onPaymentSuccess!")
  }
  
  function onPaymentError() {
   alert("onPaymentError!")
  }

  const getBookingExpire = async (payload) => {
    try {
      const { data } = await SearchBookingExpire({ params: payload });
      if (data.resultCode === 20000) {
        // setDropdownBranch(data.body);
        const resData = data.body
        setBookingExpire(resData.bookingExpire)
        setPaymentExpire(resData.paymentExpire)
      }
    } catch (e) {
      console.log(`🦄: getBookingExpire -> e`, e);
    }
  };

  const postCheckBookingPayment = async (payload) => {
    try {
      const { data } = await CheckBookingPayment({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body

        if (resData.redirect) {
          // alert('redirect')
          navigate(resData.redirect)
        } else {
          setBookingDetail(resData)
          const tmpPaymentData = {
            loading: true,
            amount: resData.netPrice,
            bookingNo: resData.bookingNo,
            location: resData.location,
            contactName: resData.contactName,
            contactEmail: resData.contactEmail
          }
          setPaymentData(tmpPaymentData)
  
          let updateData = listCheckoutDef.map(item => {
            item.dataAmount = resData.netPrice
            // item.dataOrderId = "order_" + resData.bookingNo
            return item
          })
          setListCheckout(updateData)
        }
      }
    } catch (e) {
      console.log(`🦄: postCheckBookingPayment -> e`, e);
    }
  };

  function GenReferenceOrder(bookingNo = "", method = "") {
    if ([ 'wechat', 'alipay' ].includes(method)) {
      const timeBase62 = helper.FN.encodeBase62(moment().format("HHmmss"))
      const formatRefOrder = bookingNo + timeBase62
      return formatRefOrder.replace('#',"H").replace('-',"A").replace('-',"A")
    }
    return bookingNo + moment().format("YYYYMMDDTHH:mm:sss")
  }

  const postUpdateCancelReserveSlotBooking = async (payload) => {
    try {
      const { data } = await UpdateCancelReserveSlotBooking({ data: payload });
      if (data.resultCode === 20000 || true) {
        // setDropdownBranch(data.body);
        const resData = data.body
        navigate('/booking-step3')
      }
    } catch (e) {
      console.log(`🦄: postUpdateCancelReserveSlotBooking -> e`, e);
    }
  };

  const postCancelReserveSlotPayment = async (payload, ans) => {
    try {
      const { data } = await UpdateCancelReserveSlotPayment({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body
      }
      if (ans === "Y") {
        navigate("/booking-step3")
        // alert("YES")
      } else {
        window.localStorage.removeItem('step1')
        window.localStorage.removeItem('step2')
        window.localStorage.removeItem('otherData')
        // alert("NO")
        setTimeout(() => {
          window.location.href = "/booking"
          navigate("/booking")
        }, 800)
      }
    } catch (e) {
      console.log(`🦄: getBookingExpire -> e`, e);
    }
  };

  function alertPaymentTimeout(ans = "N") {
    // alert('Payment Time-out')
    const payload = { bookingId: bookingId }
    postCancelReserveSlotPayment(payload, ans)
  }

  function handleOnExpire () {
    let elem = document.getElementsByClassName("payment-container")
    console.log('elem >>', elem)
    if (elem.length) {
      elem[0].style.display = 'none'
    }
      setIsOpen(true)
  }

  function gotoStep3() {
    const payload = { bookingId: bookingId }
    postCancelReserveSlotPayment(payload, "Y")
  }

  const onLoad = () =>{
    const payload = { bookingId: bookingId }
    postCheckBookingPayment(payload)
    getBookingExpire(payload)
  }



  useEffect(() => {
    onLoad()
  }, [])


  function KbankPopup () {
    const item = listCheckout.find((item) => item.type === submitType)
    switch(submitType) {
      case "credit":
        return  (
          <PaymentCreditCard
            bookingId={bookingId}
            amount={paymentData.amount}
            description="HealthLand Product"
            referenceOrder={GenReferenceOrder(paymentData.bookingNo)}
            onSuccess={() => onLoad()}
          />
        )
      case "qrCode":
        return (
          <PaymentQR
            bookingId={bookingId}
            amount={paymentData.amount}
            description="HealthLand Product"
            referenceOrder={GenReferenceOrder(paymentData.bookingNo)}
            onSuccess={() => onLoad()}
          />
        )
      case "alipay":
        return (
          <PaymentAliPay
            bookingId={bookingId}
            amount={paymentData.amount}
            description="HealthLand Product"
            referenceOrder={GenReferenceOrder(paymentData.bookingNo, 'alipay')}
            onSuccess={() => onLoad()}
          />
        )
      case "weChat":
        return (
          <PaymentWechat
            bookingId={bookingId}
            amount={paymentData.amount}
            sourceType={"wechat"}
            description="HealthLand Product"
            referenceOrder={GenReferenceOrder(paymentData.bookingNo, 'wechat')}
            onSuccess={() => onLoad()}
          />
        )
      default:
        return <></>
    }
  }

  useEffect(() => { window.scrollTo({ top: 0 }) }, []); // newpage-scoll-top

  
  return (
    <>
      <ModalTimeOutReSlot 
        open={isOpen} 
        onClose={()=> {
          console.log("onClose!!")
          alertPaymentTimeout("Y")
        }} 
        onSave={()=> {
          console.log("onSave!!")
          alertPaymentTimeout("N")
          // setIsShowPopupBack(false)
        }} 
      />
      <ModalReSlot 
        open={isShowPopupBack} 
        onSave={() => {
          gotoStep3()
        }}
        onClose={() => setIsShowPopupBack(false)}
      />


      <WebContainer
        footer={
        <FooterNormal
          step={"payment"}
          // bookingList={bookingList}
          onClose={handleBack}
          onSave={handleSave}
        />
        }
      >
        {/* { bookingId } */}
        <GridMain className="bk-container ribbonx bk-step4">
          <GridRap isSmallSize>
            <div className="flex flex-col items-center">
              <div class="flex flex-col items-center justify-center mb-[24px] md:mb-[32px]">
                <h5 class="cp-title mb-[8px] md:mb-[16px]">Payment Method</h5>
                <p class="text-[12px] md:text-[16px]">{t.bookingPayment.title}</p>
              </div>
              <div className="count-down-box">
                <CountdownTimer expireDateTime={paymentExpire} onExpire={handleOnExpire}/>
                <p>{t.bookingPayment.subTitle}</p>
              </div>
              <div className="w-full">
                {/* {selectPaymentType} */}
                <div className="payment-list">
                  {
                    listCheckout.map((item) => {
                      return (
                        <div key={item.id} className={`payment-item ${item.type === selectPaymentType ? 'active' : ''}` } onClick={() => {
                          setSelectPaymentType(item.type)
                        }}>
                          <img src={item.img} alt={item.type}/>
                          <p>{item.text}</p>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
            {/* <ButtonPrimary onClick={()=> handleOnExpire()}>OPEN</ButtonPrimary> */}
            {/* { moment(paymentExpire).toLocaleString() } */}
            { 
              KbankPopup() // เปิด popup KBANK
            } 
          </GridRap>
        </GridMain>
      </WebContainer>
      
    </>
  );
}

export default PaymentDev;
