import React, { useEffect, useState } from "react";
import { CreateCharge } from "../../services/Payment";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AppConfig from "../../utils/config";
const CONFIG = AppConfig.config()


function PaymentCreditCard({ 
    bookingId = null, 
    amount = 1, 
    description = "", 
    referenceOrder = "",
    onSuccess = () => { console.log('onSuccess!') }
  }) {

  const navigate = useNavigate()

  const handleClosePopup = (e, payloadCard = {}) => {
    console.log('handleClosePopup e >>', e)
  }

  const handleSubmitPopup = (e, payloadCard = {}) => {
    e.preventDefault();
    console.log('handleSubmitPopup e >>', e)
    
    const submitter = document.querySelector("#kbankcheckout input[name='token']");
    console.log("handleSubmitPopup submitter >>", submitter)

    const payload = {
      ...payloadCard,
      token: submitter.value,
    };

    console.log('handleSubmitPopup payload >>', payload)
    postCreateCharge(payload)
  }

  const postCreateCharge = async (payload) => {
    try {
      const { data } = await CreateCharge({ data: payload });
      if (data.resultCode === 20000) {
        const resData = data.body;
        console.log('resData >>', resData)
        if (resData.redirect) {
          // alert(resData.redirect)
          // navigate(resData.redirect)
          window.location.href = resData.redirect
        }
      } 
    } catch (e) {
        console.log(`🦄: postCreateCharge -> e`, e);
        console.log('err', e)
    }
  };

  const getMeasurement = async (payloadCard = {}) => {

    try {
      console.log("getMeasurement onload >> ", window.KPayment);

      try {
        var eletoken = document.getElementsByName("token");
        for (var i = 0; i < eletoken.length; i++) {
          eletoken[i].remove();
        }
      } catch (e) {
        console.log(e);
      }

      // setData เข้า Popup
      window.KPayment.setMid(CONFIG.PAYMENT_GETEWAY.DATA_APIMID_CARD);
      window.KPayment.setAmount(payloadCard.amount);
      window.KPayment.setPaymentMethods("card");

      setTimeout(() => {
        window.KPayment.show(); // สั่งเปิด Popup ขึ้นมา
        window.KPayment.onClose((e) => handleClosePopup(e, payloadCard)); // เรียกตอนออก Popup
        document.getElementById("kbankcheckout").onsubmit = (e) => handleSubmitPopup(e, payloadCard); // เรียกตอนSubmit Popup

      }, 100)

    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    const payloadCard = {
      bookingId: bookingId,
      description: description,
      amount: amount,
      paymentMethods: "card",
      referenceOrder: referenceOrder,
      mode: "token",
      mid: CONFIG.PAYMENT_GETEWAY.DATA_APIMID_CARD
    };
    getMeasurement(payloadCard)
  }, []);

  return <div></div>
  // return <div>PaymentCreditCard</div>;
}

export default PaymentCreditCard;