import { get, post } from "./Api";

export function SearchLineId({ params, lineId }) {
  return get({ url: `/api/v1/healthland/searchLineId/${lineId}`, params });
}

export function SearchMemberById({ params, memberId }) {
  return get({
    url: `/api/v1/healthland/searchMemberById/${memberId}`,
    params,
  });
}

export function SearchEmail({ params }) {
  return get({ url: `/api/v1/healthland/member/searchEmail`, params });
}

export function CreateMember({ data }) {
  return post({ url: `/api/v1/healthland/member/createMember`, data });
}

export function UpdateMember({ data }) {
  return post({ url: `/api/v1/healthland/member/updateMember`, data });
}

export function ForgetPassword({ data }) {
  return post({ url: `/api/v1/healthland/member/forgetPassword`, data });
}

export function UpdatePassword({ data }) {
  return post({ url: `/api/v1/healthland/member/updatePassword`, data });
}

export function SendEmailVerify({ params, memberId }) {
  return get({
    url: `/api/v1/healthland/member/sendMailVerify/${memberId}`,
    params,
  });
}
