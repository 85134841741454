import React from "react";
import useTranslations from "../../../i18n/useTranslations";
import MyProfiles from "../../../components/general/MyProfile";
import moment from "moment";

export default function HistoryItem({ type = "", point = 0, bookingNo = "", expireDate, createDate, discount = null }) {
  const { t } = useTranslations();


  let pointStyle =  {
    earn: { color: "#0D962E", prefix: "+" },
    burn: { color: "#A30000", prefix: "-" }
  }

  // เขียนแบบนี้จะไม่บัค แต่ยาวหน่อย
  const pointClassBase = `text-[20px] md:text-[20px] text-[$color] font-medium`
  let pointClass = {
    earn: pointClassBase.replace('$color', pointStyle.earn.color),
    burn: pointClassBase.replace('$color', pointStyle.burn.color),
  }


  const textExpire =  expireDate ? `${t.membership.pointExpired}: ${moment(expireDate).format("DD MMM YY")}` : ""
  return (
    <>
    {/* {JSON.stringify(typeStyle)} */}
      <div className="flex flex-col items-center py-[20px] px-[16px] gap-[16px] justify-center border-Neutral-A300 border-[2px] rounded-[12px] ">
        <div className="flex items-center justify-between w-full">
          <p className="text-[20px] md:text-[20px] font-medium" style={{ color: pointStyle[type].color }}>{pointStyle[type].prefix} {point ?? 0} {t.membership.Points}</p>
          {
            type === "earn"
            ? <p className="text-[12px] md:text-[20px] text-Neutral-A800 font-medium">{textExpire}</p>
            : <p className="text-[12px] md:text-[20px] text-Neutral-A800 font-medium"> {t.membership.Discount} {discount ?? 0} THB</p>
          }
        </div>
        <div className="flex items-center justify-between w-full">
          <p className="text-[14px] md:text-[16px] text-Neutral-A800 ">Booking ID {bookingNo}</p>
          <p className="text-[14px] md:text-[16px] text-Neutral-A700 ">{ createDate ? moment(createDate).format("DD MMM YY h.mm a") : "" }</p>
        </div>
      </div>
    </>
  );
}


