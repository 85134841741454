import * as React from "react";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import ModalBase from "../Modal/ModalBase";
import useTranslations from "../../i18n/useTranslations";

export default function PopupPointBenefit({ open = false, onChange }){
  const { t } = useTranslations();
  return (
    <>
      <ModalBase
        open={open}
        onClose={() => onChange(false)}
        size="size1"
        header={
          <>
            <h5 className="cp-title">
              {t.settingPage.pointsBenefits.title}
            </h5>
          </>
        }
        content={
          <>
            <div className="flex flex-col gap-[16px] md:gap-[24px]">
              <p className="text-[#000000] text-[14px] md:text-[16px] HelveticaNeue">
                {t.settingPage.pointsBenefits.desc0}
              </p>
              <p className="text-[#000000] text-[14px] md:text-[16px] HelveticaNeue">
                {t.settingPage.pointsBenefits.desc1}
              </p>
              <p className="text-[#000000] text-[14px] md:text-[16px] HelveticaNeue">
                {t.settingPage.pointsBenefits.desc2}
              </p>
              <p className="text-[#000000] text-[14px] md:text-[16px] HelveticaNeue">
                {t.settingPage.pointsBenefits.desc3}
              </p>
              <p className="text-[#000000] text-[14px] md:text-[16px] HelveticaNeue">
                {t.settingPage.pointsBenefits.desc4}
              </p>
              <p className="text-[#000000] text-[14px] md:text-[16px] HelveticaNeue">
                {t.settingPage.pointsBenefits.desc5}
              </p>
            </div>
          </>
        }
        footer={
          <>
            <div className="w-full flex justify-center items-center gap-[16px] ">
              <ButtonPrimary
                addClass="w-full md:max-w-[180px]"
                onClick={() => onChange(false)}
              >
                Done
              </ButtonPrimary>
            </div>
          </>
        }
      />
    </>
  );
};