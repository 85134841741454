import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const otherData = localStorage.getItem('otherData') !== null ? JSON.parse(localStorage.getItem('otherData')) : {
  ddBranch: []
}

const step1 = localStorage.getItem('step1') !== null ? JSON.parse(localStorage.getItem('step1')) : {
  branch: null,
  guestCount: null,
  date: null
}

const step2 = localStorage.getItem('step2') !== null ? JSON.parse(localStorage.getItem('step2')) : {
  guestData: [],
}

const defaultData = {
  id: 0,
  otherData: otherData,
  step1: step1,
  step2: step2,
  popupTreatment: {
    isOpen: false,
    guestIndex: null
  },
  isLoading: false,
};

export const BookingMainSlice = createSlice({
  name: "BookingMain",
  initialState: defaultData,
  reducers: {
    setBookingMain: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    setOtherData: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state.otherData[key] = value;
      }
      localStorage.setItem("otherData", JSON.stringify(state.otherData))
    },
    setStep1: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state.step1[key] = value;
      }

      localStorage.setItem("step1", JSON.stringify(state.step1))
    },
    setStep2: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state.step2[key] = value;
      }
      console.log('state.step2.guestData >>', state.step2.guestData)
      if (state.step2?.guestData) {
        state.step1.guestCount = state.step2.guestData.length
      }
      localStorage.setItem("step2", JSON.stringify(state.step2))
    },
    setPopupTreatment: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state.popupTreatment[key] = value;
      }
    },
    resetBookingMain: () => defaultData,
  },
});

// Action creators are generated for each case reducer function
export const { setBookingMain, resetBookingMain, setOtherData, setStep1, setStep2, setPopupTreatment } = BookingMainSlice.actions;

export default BookingMainSlice.reducer;
