import React, { useState } from "react";
import Label from "../../components/general/Label";
import { Button, TextField, Tooltip } from "@mui/material";
import { useForm } from "react-hook-form";
import useTranslations from "../../i18n/useTranslations";
import IconUserWhite from "../../assets/images/svg/user-one-white.svg";
import IconCirclePlus from "../../assets/images/icon-circle-plus.png";
import IconCirclePlusGray from "../../assets/images/icon-circle-plus-gray.png";
import IconUserAdd from "../../assets/images/icon-user-add.png";
import IconUser from "../../assets/images/icon-user.svg";
import IconCalendar from "../../assets/images/icon-calendar.png";
import IconDeleteCircle from "../../assets/images/icon-delete-circle.png";
import IconDeleteCircleWhite from "../../assets/images/svg/delete-circle-white.svg";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setStep1, setStep2, setPopupTreatment } from "../../features/BookingMainSlice";
import { SearchProgramTreatment } from "../../services/Booking";
import { setProgramTreament } from "../../features/ProgramTreamentSlice";
import TopLogo from "../../components/Layouts/TopLogo";
import GridMain from "../../components/Layouts/GridMain";
import GridRap from "../../components/Layouts/GridRap";
import Steppers from "../../components/Stepper/Steppers";
import WebContainer from "../../components/Layouts/WebContainer";
import StepBanner from "./StepBanner";
import PopupTreatments from "./PopupTreatments/SelectTreatments";
import helper from "../../services/helper";
import FooterSummary from "./FooterSummary";
import { useNavigate } from "react-router-dom";
import PopupBookingCondition from "./PopupBookingCondition/PopupBookingCondition";
import ModalBase from "../../components/Modal/ModalBase";
import ButtonPrimaryOutline from "../../components/button/ButtonPrimaryOutline";
import ButtonPrimary from "../../components/button/ButtonPrimary";


const programTreamentList = helper.Booking.mockProgramTreament

export default function BookingStep2(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const step1 = useSelector((state) => state.bookingMain.step1);
  const step2 = useSelector((state) => state.bookingMain.step2);
  const popupTreatment = useSelector((state) => state.bookingMain.popupTreatment);
  const booking = useSelector((state) => state.booking);
  const programTreament = useSelector((state) => state.programTreament.data);
  // const programTreament = programTreamentList

  const { t } = useTranslations();

  // State กลาง
  const { branch, guestCount, date } = step1
  const { isOpen, guestIndex } = popupTreatment
  const { guestData } = step2

  // State 
  const [ isShowAH, setIsshowAH ] = useState(false)
  const [ isShowPopupBack, setIsShowPopupBack ] = useState(false)

  const getProgramTreatment = async (branchId = 0) => {
    try {
      const { data } = await SearchProgramTreatment({
        params: { branchId: branchId },
      });
      if (data.resultCode === 20000) {
        console.log("data.body >>", data.body);
        setProgramTreament(data.body);
        const tmpObj = {
          data: data.body,
          isLoading: true,
        };
        dispatch(setProgramTreament(tmpObj)); // set เข้า state กลาง
      }
    } catch (e) {
      console.log("ERR getProgramTreatment", e);
    }
  };

  function setGuestIndex(value) {
    const tmpObj = { isOpen: true, guestIndex: value };
    dispatch(setPopupTreatment(tmpObj));
  }

  const onAddTreatments = (i) => {
    setGuestIndex(i)
  };

  const onDeleteTreatments = (gIndex,_programId, _treatmentId, _subTreatmentId) => {
    let keyMap = `${_programId}|${_treatmentId}|${_subTreatmentId}`
    console.log('keyMap >>', keyMap)
    console.log('gIndex >>', gIndex)
    console.log('guestData >>', guestData)
    let _guestData = _.cloneDeep(guestData)

    delete _guestData[gIndex]?.values[keyMap]
    console.log('_guestData[gIndex] >>', _guestData[gIndex])
    setGuestData(_guestData)
  };

  function setGuestDataValues(_guestIndex, values = {}) {
    const _guestData = _.cloneDeep(guestData)
    _guestData[_guestIndex].values = values

    console.log('_guestData >>', _guestData) // values เข้า guest แต่ละคน
    const tmpObj = { guestData: _guestData };
    console.log('tmpObj >>', tmpObj)
    dispatch(setStep2(tmpObj));
  }


  function setGuestData(value) {
    const tmpObj = { guestData: value };
    dispatch(setStep2(tmpObj));
  }

  function genGuestRaw(running = 1) {
    const tmp = {
      // name: "Guest " + running,
      name: "",
      number: running,
      program: [],
      values: {},
      totalDuration: 0,
      totalPrice: 0,
    };
    return tmp
  }

  function defaultGuest(count = 0) {
    console.log("count >>", count);
    let ans = [];
    for (let n = 0; n < count; n++) {
      const running = Number(n) + 1;
      const tmp = genGuestRaw(running)
      ans.push(tmp);
    }
    return ans;
  }

  function onAddGuest() {
    let _guestData = _.cloneDeep(guestData)
    const running = guestData.length + 1
    const tmp = genGuestRaw(running)

    _guestData.push(tmp)
    setGuestData(_guestData)
  }

  function onUpdateGuest(uIndex, key, value) {
    console.log(' >> onUpdateGuest ', uIndex, key, value)
    let _guestData = _.cloneDeep(guestData)

    _guestData[uIndex][key] = value

    console.log('update guestData >>', _guestData)
    setGuestData(_guestData)
  }

  function onDeleteGuest(rIndex = 0) {
    console.log('rIndex >>', rIndex)
    let _guestData = _.cloneDeep(guestData)
    _guestData = _guestData.filter((item, i ) => i != rIndex)
    console.log('rIndex _guestData >>', _guestData)
    setGuestData(_guestData)
  }

  function onOpenPopup(value) {
    const tmpObj = { isOpen: value };
    dispatch(setPopupTreatment(tmpObj));
  }

  useEffect(() => {
    // ค่าเริ่มต้น
    // console.log('useEffect ค่าเริ่มต้น !')
    // const _guestData = defaultGuest(step1.guestCount || 1);
    // console.log("🚀 ~ _guestData:", _guestData);
    // setGuestData(_guestData)
  // }, [step1]);
  }, []);

  // useEffect(() => {
  //   getProgramTreatment(branch);
  // }, [branch]);

  function gotoStep3() {
    navigate('/booking-step3')
  }

  function gotoStep1() {
    navigate('/booking-step1')
  }

  function onSave() {
    // alert('onSave')
    setIsshowAH(true)
  }
  
  function onClose() {
    // alert('onClose')
    // navigate(-1)
    gotoStep1()
    // setIsShowPopupBack(true)
  }

  function calIsNext(_guestData = []) {
    for (let gd of _guestData) {
      if (Object.keys(gd.values).length === 0) {
        return true
      }
    }
    return false
  }

  

  if (isShowAH) {
    return <PopupBookingCondition onClose={() => setIsshowAH(false)} onSave={gotoStep3}/>
  }
  let spaceFooter = "bk-container bk-step2 ribbonx "
  if (isOpen) {
    spaceFooter += "space-popup"
  }

  

  return (
    <>
      <WebContainer footer={
        isOpen ? <></> : <FooterSummary  onClose={onClose} onSave={onSave} isNextStep={calIsNext(guestData)}/>
      }>
        {/* STEP1 : {JSON.stringify(step1)}<br/> */}
        {/* STEP2 : {JSON.stringify(step2)} */}
        {/* popupTreatment : {JSON.stringify(popupTreatment)} */}
        {/* programTreament : {JSON.stringify(programTreament)} */}
        {/* <button onClick={() => onOpenPopup(true)}>OPEN</button> */}
        <GridMain className={spaceFooter}>
          <GridRap isSmallSize={!isOpen}>
            <PopupTreatments selectIndex={0}  
              component={
                <>
                  <div className="flex flex-col items-center">
                    <StepBanner />
                    <div className="w-full mt-[24px] md:mt-[36px]">
                      <h5 className="cp-title mb-[24px] md:mb-[16px]">Add Treatments</h5>
                      {/* {JSON.stringify(booking.guestData)} */}
                      <div className="flex flex-col gap-[24px]">
                        {
                          guestData.map((item, i) => {
                            console.log('item 0>>', item)
                            const { maxPeriod, price } = helper.Booking.getMaxPeriodAndPrice(programTreament, item.values)
                            return (
                              <div className="card" key={i}>
                                <div className="card-header">
                                  <div className="card-header-icon">
                                    <img src={IconUserWhite} alt="" />
                                  </div>
                                  <div className="flex-1 text-[16px] md:text-[20px] font-bold leading-[0.8em]">{`${t.bookingStep2.guest}`.replace('$number', (i + 1))} </div>
                                  {i > 0 && (
                                    <button
                                      className="flex items-center gap-[.5rem]"
                                      onClick={() => onDeleteGuest(i)}
                                    >
                                      <img
                                        src={IconDeleteCircleWhite}
                                        alt=""
                                        className="w-[12px] h-[13px]"
                                      />
                                      <span className="text-[12px] md:text-[16px] font-bold">{t.bookingStep2.remove}</span>
                                    </button>
                                  )}
                                </div>
                                <div className="card-body flex flex-col">
                                  <div className="px-[12px] py-[20px] pb-[16px] flex flex-col gap-[18px] md:gap-[16px]">
                                    <div className="card-row">
                                      <Label size2 bold color2 >{t.bookingStep2.guestName}</Label>
                                      <TextField
                                        required
                                        fullWidth
                                        placeholder={t.bookingStep2.placeName}
                                        inputProps={{ 
                                          maxLength: 150,
                                          onChange: helper.FNFORM.handleCharOnly,
                                        }}
                                        value={item.name}
                                        onChange={(e)=> {
                                          console.log('e i >>', i)
                                          console.log('e v >>', e.target.value)
                                          onUpdateGuest(i, 'name', e.target.value)
                                        }}
                                      />
                                    </div>
                                    <div className="card-row">
                                      <Label size2 bold color2>{t.bookingStep2.selectProgram}</Label>
                                      {/* {JSON.stringify(programTreament)} */}
                                      <div className="flex flex-col gap-[12px] mb-[12px] mt-[8px]">
                                        {
                                          Object.keys(item.values).map((value, j) => {
                                            const [programId, treatmentId, subTreatmentId] = `${value}`.split('|')
                                            // const p = programTreament[programId][treatmentId][subTreatmentId]
                                            const _treatmentList = programTreament.find(item => item.programId == programId)?.treatment ?? []
                                            const _subTreatmentList = _treatmentList.find(item => item.treatmentId == treatmentId)?.subTreatment ?? []
                                            const subTreatment = _subTreatmentList.find(item => item.subTreatmentId == subTreatmentId) ?? {}
                                            const p = subTreatment
                                            // const _treatment = _treatment.find(item => item.treatmentId === treatmentId)
                                            console.log("🚀 programTreament", programTreament)
                                            console.log("🚀 _treatmentList", _treatmentList)
                                            console.log("🚀 _subTreatmentList", _subTreatmentList)
                                            console.log("🚀 subTreatment", subTreatment)
                                            console.log("🚀 ~ file: BookingStep2.js:209 ~ Object.keys ~ programId, treatmentId, subTreatmentId:", programId, treatmentId, subTreatmentId)
                                            
                                            return (
                                              <div className="item-treatment">
                                                <button className="t-icon" onClick={() => onDeleteTreatments(i,programId, treatmentId, subTreatmentId)}/>
                                                <div className="it-name">
                                                  {/* {JSON.stringify(p)} */}
                                                  <p>{ p.subTreatmentName ? p.subTreatmentName: "-"}</p>
                                                  <p>{ p.subTreatmentPrice ? helper.FN.toNumberWithCommas(p.subTreatmentPrice) : 0} THB</p>
                                                </div>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                      {/* {JSON.stringify(item.values)} */}
                                      {/* {Object.keys(item.values).length} */}
                                      {
                                        Object.keys(item.values).length >= 2 ? <></>
                                        : (
                                          <button className="btn-add-treatment"
                                            onClick={() => onAddTreatments(i)}
                                            disabled={item?.program.length === 2}
                                          >
                                            <div className="add-icon"/>
                                            <p className="text-[14px] md:text-[16px] font-medium text-[#DDB05B]">
                                              {t.bookingStep2.btnAddTreatments}
                                            </p>
                                          </button>
                                        )
                                      }
                                    </div>
                                  </div>
                                  {/* <div className="card-line"></div> */}
                                  <div className="card-bottom">
                                    <p className="text-[14px] md:text-[16px] mr-[10%] md:mr-[95px] font-medium text-Neutral-A800 leading-[1em]">{t.bookingStep2.duration}: {maxPeriod? `${maxPeriod} min`: '-' } </p>
                                    <p className="text-[14px] md:text-[16px] font-medium text-Neutral-A800 leading-[1em]">{t.bookingStep2.price}: {price ? `${helper.FN.toNumberWithCommas(price)} THB`: '-' } </p>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                        {
                          guestData.length < 5
                          ? (
                            <div className="flex items-center justify-center ">
                              <button
                                className="btn-add-guest"
                                onClick={onAddGuest}
                                disabled={guestData.length === 5}
                              >
                                <p className="text-[14px] md:text-[16px] font-medium md:font-normal text-[#6C757D] md:text-[#493B43]">{t.bookingStep2.btnAddMoreGuest}</p>
                              </button>
                            </div>
                          )
                          : <></>
                        }
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </GridRap>
        </GridMain>
      </WebContainer>
    </>
  );
}
