export default function ButtonSocial({ imgLink, label, onClick }) {
  return (
    <button
      type="button"
      className="btn-social w-fit md:w-fit py-[0px] md:py-[10px] md:px-[42px] flex justify-center gap-[15px] border-[0px] md:border-[1px] rounded-[8px] border-Gray-300 cursor-pointer"
      onClick={() => onClick()}
    >
      <img src={imgLink} alt="" />
      {/* <p className="hidden md:block text-[20px] text-Neutral-A900">{label}</p> */}
    </button>
  );
}
