import React from "react";
import { Controller } from "react-hook-form";
// import { MobileDatePicker } from "@mui/lab";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import OpenPass from "../../assets/images/icon-input/calendar-mini.svg";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import moment from "moment/moment";


const ToolbarComponent = () => {
  const aaa = ()=> <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="16" height="16" rx="8" fill="#DDB05B"/>
  </svg>
  
  return (
    <div className="w-full flex py-[12px] px-[12px] justify-between items-center border-[#DEE2E6] border-b-[1px]">
      <p className="text-[#493B43] font-medium text-[16px]">Select your date</p>
      <div className="flex gap-[8px]">
        <div className="flex gap-[8px] items-center">
          <div className="w-[10px] h-[10px] bg-[#DDB05B] border-[1px] border-[#DDB05B] rounded-[50%]"/>
          <p className="text-[#000000] text-[12px]">Selected</p>
        </div>
        <div className="flex gap-[8px] items-center">
          <div className="w-[10px] h-[10px] border-[1px] border-[#DDB05B] rounded-[50%]"/>
          <p className="text-[#000000] text-[12px]">Available</p>
        </div>
      </div>
  </div>
  )
}

function DatePickerNormal({
  error,
  helperText,
  maxDate,
  minDate,
  value,
  onChange,
  shouldDisableDate,
  placeholder = "DD/MM/YYYY",
  customClass = "",
  onMonthChange = null,
  onOpen = null
}) {
  console.log('shouldDisableDate >>', shouldDisableDate)
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        ToolbarComponent={ToolbarComponent}
        DialogProps={{
          className: customClass
        }}
        // components={{
        //   ActionBar: () => <div></div>
        // }}
          className={`w-full py-[10px] hl-datepicker ${customClass}` }
          inputFormat="DD/MM/YYYY"
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          dayOfWeekFormatter={(day) => moment().day(day).format('ddd')}
          onChange={(newValue) => {
            console.log('newValue >>', newValue)
            onChange(newValue)
          }}
          onOpen={()=> {
            if (onOpen) {
              onOpen(value)
            }
          }}
          onMonthChange={(month) => {
            if (onMonthChange) {
              const strMonth = moment(new Date(month).toISOString()).format('YYYY-MM')
              // console.log('onMonthChange month >>', moment(new Date(month).toISOString()).format('YYYY-MM'))
              onMonthChange(strMonth)
            }
          }}
          shouldDisableDate={(d) => {
            if (shouldDisableDate?.length === 0 && customClass === "hl-date-step3") {
              return true
            }
            if (shouldDisableDate?.length > 0) {
              const dates = shouldDisableDate.map((s) => {
                return moment(new Date(s)).format("DDMMYYYY");
              });
              if (!dates.includes(moment(new Date(d)).format("DDMMYYYY"))) {
                return true;
              }
            }
            return false;
          }}
          
          renderInput={(params) => (
            <TextField
              className="py-[10px]"
              {...params}
              error={!!error}
              helperText={helperText}
              placeholder={placeholder}
              InputProps={{
                disableunderline: true,
                endAdornment: (
                  <InputAdornment className="px-[10px]" position="end">
                    <IconButton edge="end">
                      <img
                        className="w-[12px] md:w-[18px]"
                        src={OpenPass}
                        alt=""
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
    </LocalizationProvider>
  );
}

export default DatePickerNormal;
