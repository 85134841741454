import React, { useEffect, useState } from "react";
import TopLogo from "../../components/Layouts/TopLogo";
import TopBanner from "../../components/Layouts/TopBanner";

import SinginLogo from "../../assets/images/logo-lotus.png";
import { TextField, InputLabel, Autocomplete } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Th from "../../assets/images/icon-Thailand.svg";

import FormHelperText from "@mui/material/FormHelperText";
import { useForm, Controller } from "react-hook-form";
import DatePickerField from "../../components/general/DatePickerField";

import { SearchLineId, CreateMember, SearchEmail } from "../../services/Member";
import { SearchDdlNationality } from "../../services/Dropdown";
import RadioGroupField from "../../components/general/RadioGroupField";
import { useNavigate, useLocation } from "react-router-dom";
import helper from "../../services/helper";
import TextFieldPassword from "../../components/general/TextFieldPassword";
import Label from "../../components/general/Label";
import CheckSingle from "../../components/general/CheckSingle";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import useTranslations from "../../i18n/useTranslations";
import TermPopup from "./TermPopup";
import GridMain from "../../components/Layouts/GridMain";
import GridRap from "../../components/Layouts/GridRap";
import "react-international-phone/style.css";
import { Link, useSearchParams } from "react-router-dom";
import sweetAlert from "../../services/sweetAlert";
import { AuthLoginWithSocial } from "../../services/Auth";
import moment from "moment";
import FooterRegister from "./RegisterParts/FooterRegister";
import HeaderRegister from "./RegisterParts/HeaderRegister";
import WebContainer from './../../components/Layouts/WebContainer';
import { useDispatch, useSelector } from "react-redux";
import { setStep1 } from "../../features/RegisterSlice";
import CustomSelect from "../../components/general/CustomSelect";
import PagePolicy from "./RegisterParts/PagePolicy";
import LoginWithGoogle from "../../components/Auth/LoginWithGoogle";
import LoginWithFacebook from "../../components/Auth/LoginWithFacebook";
import LoginSocial from "../../components/Auth/LoginSocial";
import CustomAutoComplete from "../../components/general/CustomAutoComplete";

const { gotoPage } = helper.Route;
const { removeUndefined, objectToQueryString } = helper.FN;



export default function Register({ value }) {
  const step1 = useSelector((state) => state.Register.step1);
  const { t, lang } = useTranslations();
  const dispatch = useDispatch();
  const lineId = localStorage.getItem('lineId') ?? null
  const [isOpen1, setIsOpen1] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [disEmail, setDisEmail] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const location = useLocation();
  const [ searchParams ] = useSearchParams();

  const OPTION_GENDER = helper.OPTIONS.GENDERLANG(lang);

  const { register, formState: { errors }, handleSubmit, getValues, setValue, control, watch, setError,clearErrors } = useForm({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      firstname: "",
      lastname: "",
      mobileNumber: "",
      email: "",
      confirmEmail: "",
      mobileCodeId: 1,
      nationalityId: null,
      birthdate: null,
      genderId: 1,
      password: "",
      rePassword: "",
      googleUid: "",
      facebookUid: "",
    },
  });

  const [acceptPrivacyStatus, setAcceptPrivacyStatus] = useState(false);
  const [dropdownNationality, setDropdownNationality] = useState([]);

  // CHECK E-MAIL
  const [emailExists, setEmailExists] = useState(false);


  const navigate = useNavigate();

  const getDdlNationality = async () => {
    try {
      const { data } = await SearchDdlNationality({});
      if (data.resultCode === 20000) {
        setDropdownNationality(data.body);

        // DEFAULT THAILAND
        const DD = data?.body ?? [];
        const natId = DD.find((item) =>
          `${item.name}`.toUpperCase().includes("THAI")
        );
        if (natId) {
          setValue("nationalityId", natId.name);
        }
      }
    } catch (e) {
      console.log("ERR getDdlNationality", e);
    }
  };


  const onCheckInput = async ({ idcard, email }) => {
    const payload = removeUndefined({ idcard, email });
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await SearchEmail({ params: payload });
        if (data.resultCode === 40301) {
          resolve(true);
        }
        resolve(false);
      } catch (error) {
        resolve(false);
      }
    });
  };

  const onSubmit = (data) => {
    let payload = {
      ...data,
      genderId: data?.genderId ?? '1',
      lineUid: lineId,
      acceptPrivacyStatus: acceptPrivacyStatus,
    };
    delete payload.rePassword;
    console.log('payload  onSubmit>>', payload)
    if (acceptPrivacyStatus && !emailExists) {
      dispatch(setStep1(payload));
      navigate('/register-step2')
    }
  };



  useEffect(() => {
    // getDdlMobileCode();
    getDdlNationality();

    // Pre Field Register
    const queryProfile = searchParams.get('profile');
    console.log('queryProfile >>', queryProfile)
    if (queryProfile) {
      let updateData = {}
      const profile = helper.decodeJWT(queryProfile);
      console.log("profile >>", profile);
      updateData.email = profile?.email ?? ""
      updateData.googleUid = profile?.googleUid ?? ""

      const checkEmail = async (mail) => {
        const value = await onCheckInput({
          email: mail,
        });
        setEmailExists(value);
        setDisEmail(true);
      };
      checkEmail(profile?.email ?? "");

      // ตัดคำ
      const displayName = profile?.displayName ?? "";
      const displayNames = `${profile.displayName}`.split(" ");
      if (!step1.firstname || !step1.lastname) {
        if (displayNames.length === 2) {
          const [firstname, lastname] = displayNames;
          updateData.firstname = firstname ?? ""
          updateData.lastname = lastname ?? ""
        } else {
          updateData.firstname = displayName
        }
      }
      const payload = { ...step1, ...updateData }
      dispatch(setStep1(payload));
    }
  }, []);

  useEffect(() => {
    console.log('step1 >> ', step1)
     
    setValue('firstname', step1.firstname)
    setValue('lastname', step1.lastname)
    setValue('mobileNumber', step1.mobileNumber)
    setValue('email', step1.email)
    setValue('confirmEmail', step1.email)
    setValue('nationalityId', step1.nationalityId)
    setValue('birthdate', step1.birthdate)
    setValue('genderId', step1.genderId)
    setValue('password', step1.password)
    setValue('rePassword', step1.password)

    setValue('googleUid', step1.googleUid)
    setValue('facebookUid', step1.facebookUid)

    setAcceptPrivacyStatus(step1.acceptPrivacyStatus)
    
  }, [step1])

  const bindValueState = () => {
    const tmpState = {
      firstname: getValues('firstname'),
      lastname: getValues('lastname'),
      mobileNumber: getValues('mobileNumber'),
      email: getValues('email'),
      confirmEmail: getValues('confirmEmail'),
      mobileCodeId: getValues('firstname'),
      nationalityId: getValues('nationalityId'),
      birthdate: getValues('birthdate'),
      genderId: getValues('genderId'),
      password: getValues('password'),
      rePassword: getValues('rePassword'),
      googleUid: getValues('googleUid'),
      facebookUid: getValues('facebookUid'),
    }
    console.log('bindValueState >>', tmpState)
    dispatch(setStep1(tmpState))
  }

  return <>
    {/* {JSON.stringify(step1)} */}
    

    <WebContainer bgGray footer={
      isOpen1 === false
      ? (
      <FooterRegister
        currentStep={currentStep} 
        onBack={() => {
          console.log('onBack >>')

        }}
        onNext={() => {
          console.log('onNext Submit >>')
          setIsSubmit(true)
          document.getElementById("r-submit").click()
          // navigate('/register-step2', { state: { payloadStep1: "abcd" }})
        }}
      />
      )
      : <></>
    }>
      <PagePolicy 
        isOpen={isOpen1}
        onAccept={()=> {
          setIsOpen1(false);
          setAcceptPrivacyStatus(true);
        }}
        onClose={()=> setIsOpen1(false)}
        component={
          <>
            <TopBanner />
            <GridMain className="form-space">
              <GridRap>
              <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div className="form-header-space">
                  <HeaderRegister 
                    step={1}
                    title={t.register.banner_t0} 
                    subTitle={<>
                      {t.register.banner_t1_1}
                      <br className="md:hidden" />
                      <span className="hidden md:inline">&nbsp;</span>
                      {t.register.banner_t1_2}
                    </>}
                  />
                  <div className="form-content-space">
                    <div className="flex justify-center">
                      <img
                        className="max-w-[61px] mb-[12px]"
                        src={SinginLogo}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col justify-center items-center mb-[24px]">
                      <p className="text-Personal MinervaModern">
                        Personal Information
                      </p>
                      <div className="divider-glod"></div>
                    </div>

                    <div className="flex flex-col gap-[24px] md:gap-[16px]">
                      {/* name */}
                      <div className="grid grid-cols-2 gap-[24px] md:gap-[24px]">
                        <div className="">
                          <Label>{t.register.form_firstname}</Label>
                          <TextField
                            required
                            fullWidth
                            placeholder={t.register.form_firstname_placeholder}
                            error={!!errors?.firstname}
                            helperText={errors?.firstname?.message}
                            {...register("firstname", {
                              required: t.register.form_firstname_validate_0,
                            })}
                            inputProps={{ 
                              maxLength: 150,
                              onChange: helper.FNFORM.handleCharOnly,
                            }}
                          />
                        </div>
                        <div className="">
                          <Label>{t.register.form_lastname}</Label>
                          <TextField
                            required
                            fullWidth
                            placeholder={t.register.form_lastname_placeholder}
                            error={!!errors?.lastname}
                            helperText={errors?.lastname?.message}
                            {...register("lastname", {
                              required: t.register.form_lastname_validate_0,
                            })}
                            inputProps={{ 
                              maxLength: 150,
                              onChange: helper.FNFORM.handleCharOnly,
                            }}
                          />
                        </div>
                      </div>
                      {/* mobile */}
                      <div>
                        <Label>{t.register.form_mobile_number}</Label>
                        <div className="flex gap-[12px] md:gap-[12px]">
                          <TextField
                            required
                            variant="outlined"
                            color="primary"
                            placeholder={t.register.form_mobile_number_placeholder}
                            type="text"
                            id="mobileNumber"
                            name="mobileNumber"
                            fullWidth
                            error={!!errors?.mobileNumber}
                            helperText={errors?.mobileNumber?.message}
                            {...register("mobileNumber", {
                              required: t.register.form_mobile_number_validate_0,
                              minLength: {
                                value: 8,
                                message: t.register.form_mobile_number_validate_1,
                              },
                              maxLength: {
                                value: 15,
                                message: t.register.form_mobile_number_validate_1,
                              },
                            })}
                            inputProps={{ 
                              maxLength: 15,
                              onChange: helper.FNFORM.handleNumberOnly,
                            }}
                          />  
                        </div>
                      </div>

                      <div className="grid md:grid-cols-2 gap-[24px] md:gap-[24px]">
                        <div className="w-full">
                          <Label>{t.register.form_email}</Label>
                          <TextField
                            required
                            fullWidth
                            placeholder={t.register.form_email_placeholder}
                            error={!!errors?.email || emailExists}
                            helperText={errors?.email?.message}
                            disabled={disEmail}
                            {...register("email", {
                              required: {
                                value: true,
                                message: t.register.form_email_validate_0,
                              },
                              pattern: {
                                value:
                                  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                                message: t.register.form_email_validate_1,
                              },
                            })}
                            inputProps={{
                              onBlur: async () => {
                                setEmailExists(false);
                                if (!errors?.email) {
                                  const value = await onCheckInput({
                                    email: watch("email"),
                                  });
                                  setEmailExists(value);
                                }
                              },
                              onChange: helper.FNFORM.handleCharOnly,
                              maxLength: 150,
                            }}
                          />
                          {!errors?.email && emailExists && (
                            <FormHelperText error={true} className="!ml-0">
                              {t.register.form_email_validate_2}
                            </FormHelperText>
                          )}
                        </div>
                        <div className="w-full">
                          <Label>{t.register.form_confirm_email}</Label>
                          <TextField
                            required
                            fullWidth
                            placeholder={t.register.form_confirm_email_placeholder}
                            error={!!errors?.confirmEmail}
                            helperText={errors?.confirmEmail?.message}
                            disabled={disEmail}
                            
                            {
                              ...register("confirmEmail", {
                                required: {
                                  value: true,
                                  message: t.register.form_confirm_email_validate_0,
                                },
                                pattern: {
                                  value:
                                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                                  message: t.register.form_confirm_email_validate_1,
                                },
                                validate: (value) => {
                                  console.log('validate 0!!', value)
                                  console.log('validate 1!!', watch("email"))
                                  console.log('validate 2!!', value === watch("email"))
                                  return (
                                    value === watch("email") ||
                                    t.register.form_confirm_email_validate_3
                                  );
                                },
                              })
                            }
                            inputProps={{
                              maxLength: 150,
                              onChange: helper.FNFORM.handleCharOnly,
                            }}
                          />
                        </div>
                      </div>

                      <div className="grid md:grid-cols-2 gap-[24px] md:gap-[24px]">
                        <div>
                            <Label>{t.register.form_nationality}</Label>
                            <div className="w-full">
                              {/* {watch('nationalityId')} */}
                              <div className="">
                                <CustomAutoComplete
                                  name="nationalityId"
                                  control={control}
                                  rules={{
                                    required: t.register.form_nationality_validate_0,
                                  }}
                                  options={dropdownNationality}
                                  onChange={(event, value) => {
                                    // console.log('value >>', value)
                                    setValue('nationalityId', value?.name ?? null);
                                  }}
                                />
                                
                                {/* {JSON.stringify(dropdownNationality)} */}
                                {/* <CustomSelect
                                  name="nationalityId"
                                  control={control}
                                  rules={{
                                    required: t.register.form_nationality_validate_0,
                                  }}
                                  options={dropdownNationality}
                                  error={false}
                                /> */}
                              </div>
                            </div>
                        </div>
                        <div>
                          <Label>{t.register.form_birthdate}</Label>
                          <DatePickerField
                            name="birthdate"
                            placeholder={t.register.form_birthdate_placeholder}
                            maxDate={moment()}
                            control={control}
                            rules={{ required: true }}
                            error={!!errors.birthdate}
                            helperText={
                              errors.birthdate
                                ? t.register.form_birthdate_validate_0
                                : ""
                            }
                          />
                        </div>
                      </div>

                      <div>
                        <Label>{t.register.form_gender}</Label>
                        <RadioGroupField
                          name="genderId"
                          control={control}
                          options={OPTION_GENDER}
                        />
                      </div>
                      <div>
                        <div className="grid md:grid-cols-2 gap-[24px] md:gap-[24px]">
                          <div>
                            <Label>{t.register.form_password}</Label>
                            <TextFieldPassword
                              required
                              fullWidth
                              placeholder={t.register.form_password_placeholder}
                              error={!!errors?.password}
                              helperText={errors?.password?.message}
                              {...register("password", {
                                required: t.register.form_password_validate_0,
                                minLength: {
                                  value: 6,
                                  message: t.register.form_password_validate_1,
                                },
                              })}
                              inputProps={{ 
                                maxLength: 20,
                                onChange: helper.FNFORM.handleCharOnly,
                              }}
                            />
                            {!errors?.password ? (
                              <p className="text-[12px] md:hidden mt-[0px] md:mt-[6px] text-Neutral-A600">
                                {t.register.form_password_info}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div>
                            <Label>{t.register.form_re_password}</Label>
                            <TextFieldPassword
                              required
                              fullWidth
                              placeholder={t.register.form_re_password}
                              error={!!errors?.rePassword}
                              helperText={errors?.rePassword?.message}
                              // {...register("rePassword", {required: "กรุณากรอกยืนยันรหัสผ่าน"})}
                              {...register("rePassword", {
                                required: t.register.form_password_validate_0,
                                validate: (value) => {
                                  return (
                                    value === watch("password") ||
                                    t.register.form_re_password_validate_2
                                  );
                                },
                                minLength: {
                                  value: 6,
                                  message: t.register.form_password_validate_1,
                                },
                              })}
                              inputProps={{ 
                                maxLength: 20,
                                onChange: helper.FNFORM.handleCharOnly,
                              }}
                            />
                            {!errors?.rePassword ? (
                              <p className="text-[12px] md:hidden mt-[0px] md:mt-[6px] text-Neutral-A600">
                                {t.register.form_re_password_info}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="mb-[24px] md:mb-[16px] hidden md:block">
                            {
                              !errors?.rePassword ? (
                              <p className="text-[14px] w-full md:mt-[6px] text-Neutral-A600">
                                * {t.register.form_password_info}
                              </p>
                              ):<></>
                            }
                          
                            {
                              !errors?.rePassword ? (
                              <p className="text-[14px] w-full md:mt-[6px] text-Neutral-A600">
                                ** {t.register.form_re_password_info}
                              </p>
                              ):<></>
                            }
                        </div>
                      </div>
                      
                      <div>
                        
                        {!acceptPrivacyStatus && isSubmit ? (
                          <FormHelperText className="!mb-[3px]" error={true}>
                            {t.register.form_re_accept_privacy_status_validate_0}
                          </FormHelperText>
                        ) : (
                          <></>
                        )}

                        <div className="flex gap-[10px] text-[12px] text-[#8F8F8F] ">
                          <CheckSingle
                            value={acceptPrivacyStatus}
                            onChange={(v) => setAcceptPrivacyStatus(v)}
                          />
                          <p className="text-[12px] md:text-[16px] text-Neutral-A600 mt-[-4px]">
                            {t.register.form_terms_and_privacy_0}&nbsp;
                            <span
                              className="text-[12px] md:text-[16px] text-[#DDB05B] underline cursor-pointer"
                              onClick={() => {
                                bindValueState()  
                                setIsOpen1(true)
                              }}
                            >
                              {t.register.form_terms_and_privacy_1}
                            </span>
                          </p>
                        </div>
                        {
                          !lineId && (
                            <>
                              <div className="flex flex-col gap-[24px] mt-[24px]">
                                <div className="relative w-full flex items-center justify-between">
                                  <div className="absolute divider w-full z-[1]"></div>
                                  <div className="text-Neutral-A400 px-[8px] bg-white mx-auto text-center z-[2] bg-white" >
                                    <p className="text-[12px] md:text-[14px]">{t.register.orSocial}</p>
                                  </div>
                                </div>
                                <div className="flex justify-center items-center gap-[36px] md:gap-[12px]">
                                  <LoginSocial/>
                                </div>
                              </div>
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <button id="r-submit" type="submit" className="hidden">Submit</button>
              </form>
              </GridRap>
            </GridMain>
          </>
        }
      />
      
    </WebContainer>
    

    
    {/* <TermPopup
      open={isOpen1}
      onClose={() => {
        setIsOpen1(false);
      }}
      onAccept={() => {
        setIsOpen1(false);
        setAcceptPrivacyStatus(true);
      }}
    /> */}
  </>;
}

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { label: 'The Good, the Bad and the Ugly', year: 1966 },
  { label: 'Fight Club', year: 1999 },
  {
    label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { label: 'Forrest Gump', year: 1994 },
  { label: 'Inception', year: 2010 },
  {
    label: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: 'Goodfellas', year: 1990 },
  { label: 'The Matrix', year: 1999 },
  { label: 'Seven Samurai', year: 1954 },
  {
    label: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { label: 'City of God', year: 2002 },
  { label: 'Se7en', year: 1995 },
  { label: 'The Silence of the Lambs', year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: 'Life Is Beautiful', year: 1997 },
  { label: 'The Usual Suspects', year: 1995 },
  { label: 'Léon: The Professional', year: 1994 },
  { label: 'Spirited Away', year: 2001 },
  { label: 'Saving Private Ryan', year: 1998 },
  { label: 'Once Upon a Time in the West', year: 1968 },
  { label: 'American History X', year: 1998 },
  { label: 'Interstellar', year: 2014 },
  { label: 'Casablanca', year: 1942 },
  { label: 'City Lights', year: 1931 },
  { label: 'Psycho', year: 1960 },
  { label: 'The Green Mile', year: 1999 },
  { label: 'The Intouchables', year: 2011 },
  { label: 'Modern Times', year: 1936 },
  { label: 'Raiders of the Lost Ark', year: 1981 },
  { label: 'Rear Window', year: 1954 },
  { label: 'The Pianist', year: 2002 },
  { label: 'The Departed', year: 2006 },
  { label: 'Terminator 2: Judgment Day', year: 1991 },
  { label: 'Back to the Future', year: 1985 },
  { label: 'Whiplash', year: 2014 },
  { label: 'Gladiator', year: 2000 },
  { label: 'Memento', year: 2000 },
  { label: 'The Prestige', year: 2006 },
  { label: 'The Lion King', year: 1994 },
  { label: 'Apocalypse Now', year: 1979 },
  { label: 'Alien', year: 1979 },
  { label: 'Sunset Boulevard', year: 1950 },
  {
    label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { label: 'The Great Dictator', year: 1940 },
  { label: 'Cinema Paradiso', year: 1988 },
  { label: 'The Lives of Others', year: 2006 },
  { label: 'Grave of the Fireflies', year: 1988 },
  { label: 'Paths of Glory', year: 1957 },
  { label: 'Django Unchained', year: 2012 },
  { label: 'The Shining', year: 1980 },
  { label: 'WALL·E', year: 2008 },
  { label: 'American Beauty', year: 1999 },
  { label: 'The Dark Knight Rises', year: 2012 },
  { label: 'Princess Mononoke', year: 1997 },
  { label: 'Aliens', year: 1986 },
  { label: 'Oldboy', year: 2003 },
  { label: 'Once Upon a Time in America', year: 1984 },
  { label: 'Witness for the Prosecution', year: 1957 },
  { label: 'Das Boot', year: 1981 },
  { label: 'Citizen Kane', year: 1941 },
  { label: 'North by Northwest', year: 1959 },
  { label: 'Vertigo', year: 1958 },
  {
    label: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  { label: 'Reservoir Dogs', year: 1992 },
  { label: 'Braveheart', year: 1995 },
  { label: 'M', year: 1931 },
  { label: 'Requiem for a Dream', year: 2000 },
  { label: 'Amélie', year: 2001 },
  { label: 'A Clockwork Orange', year: 1971 },
  { label: 'Like Stars on Earth', year: 2007 },
  { label: 'Taxi Driver', year: 1976 },
  { label: 'Lawrence of Arabia', year: 1962 },
  { label: 'Double Indemnity', year: 1944 },
  {
    label: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  { label: 'Amadeus', year: 1984 },
  { label: 'To Kill a Mockingbird', year: 1962 },
  { label: 'Toy Story 3', year: 2010 },
  { label: 'Logan', year: 2017 },
  { label: 'Full Metal Jacket', year: 1987 },
  { label: 'Dangal', year: 2016 },
  { label: 'The Sting', year: 1973 },
  { label: '2001: A Space Odyssey', year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: 'Toy Story', year: 1995 },
  { label: 'Bicycle Thieves', year: 1948 },
  { label: 'The Kid', year: 1921 },
  { label: 'Inglourious Basterds', year: 2009 },
  { label: 'Snatch', year: 2000 },
  { label: '3 Idiots', year: 2009 },
  { label: 'Monty Python and the Holy Grail', year: 1975 },
];