import React, { useState, useEffect } from 'react'
import useTranslations from '../../i18n/useTranslations';
import WebContainer from '../../components/Layouts/WebContainer';
import GridMain from '../../components/Layouts/GridMain';
import GridRap from '../../components/Layouts/GridRap';
import helper from '../../services/helper';
import { Link } from 'react-router-dom';
import ListTileProfile from '../../components/ListItme/ListTileProfile';

export default function Settings() {  
  const { t } = useTranslations();
  
  return (
    <>
      <WebContainer wfull={true} bgGraphics={true}>
        <GridMain className="">
          <GridRap className="max-w-[400px]">
            <div className="content-full box-content-space">
              
              <div className="border-[1px] md:border-[2px] rounded-[8px] md:rounded-[8px] border-Neutral-A300">
                <div className="py-[24px] md:py-[24px]">
                  <h5 className="cp-title mb-[32px] md:mb-[24px]">
                    {t.settings.Settings}
                  </h5>
                  <div className="max-w-[257px] mx-auto w-full flex flex-col gap-[24px] px-[12px] md:px-[0px] mb-[12px]">
                    <Link to={"/profile-edit"}>
                      <ListTileProfile
                        type="booking"
                        title={t.settings.EditProfile}
                        isSelected={false}
                      />
                    </Link>
                    <Link to={"/privacy-policy"}>
                      <ListTileProfile
                        type="membership"
                        title={t.settings.PrivacyPolicy}
                        isSelected={false}
                      />
                    </Link>
                    <Link to={"/term-condition"}>
                      <ListTileProfile
                        type="history"
                        title={t.settings.TermsAndConditions}
                        isSelected={false}
                      />
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </GridRap>
        </GridMain>
      </WebContainer>
    </>
  )
}