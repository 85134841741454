import { configureStore } from "@reduxjs/toolkit";
import LoadingReducer from "../features/LoadingSlice";
import ProfileReducer from "../features/ProfileSlice";
import i18nReducer from "../i18n/i18nSlice";
import BookingReducer from "../features/BookingSlice";
import BookingMainReducer from "../features/BookingMainSlice";
import ProgramTreamentReducer from "../features/ProgramTreamentSlice";
import RegisterReducer from "../features/RegisterSlice";

export default configureStore({
  reducer: {
    loading: LoadingReducer,
    profile: ProfileReducer,
    i18n: i18nReducer,
    booking: BookingReducer,
    bookingMain: BookingMainReducer,
    programTreament: ProgramTreamentReducer,
    Register: RegisterReducer,
  },
});
