import React from "react";
import GridMain from "./GridMain";
import netl from "../../assets/images/bg-base/hl-net-left.png";
import netr from "../../assets/images/bg-base/hl-net-right.png";

function SuccessContent({ children }) {
  return (
    <>
      <GridMain className="h-full flex flex-col text-center justify-center items-center content-full" isMobileFull>
        <div className="py-[65px]">{children}</div>
      </GridMain>
    </>
  );
}

export default SuccessContent;
