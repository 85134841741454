import React, { useEffect, useState } from "react";
import useTranslations from "../../i18n/useTranslations";
import En from "../../assets/images/icon-united-kingdom.svg";
import Th from "../../assets/images/icon-Thailand.svg";
import Cn from "../../assets/images/icon-china.svg";
import LangDropdown from "../general/LangDropdown";

export default function Translate(props) {
  const { t } = useTranslations();
  const { lang, supportedLangs, setLang } = useTranslations();
  let FLAG_IMG = En;
  const FLAG_OPTION = { en: En, th: Th, cn: Cn };
  if (FLAG_OPTION[lang]) {
    FLAG_IMG = FLAG_OPTION[lang];
  }

  const ARRsupportedLangs = Object.entries(supportedLangs);
  const dropdownLang = ARRsupportedLangs.map(([id, name]) => ({ id, name:`${id}`.toUpperCase() , image: FLAG_OPTION[id] }));
  console.log('dropdownLang >>', dropdownLang)
  console.log('window.location.pathname >>', window.location.pathname)
  const blackList = ['/booking-step2','/booking-step3']
  return (
    <>
    {/* {JSON.stringify(dropdownLang)} */}
      {
        blackList.includes(window.location.pathname) && false
        ? <></>
        : (
          <LangDropdown
            options={dropdownLang}
            value={lang}
            onChange={(v) => {
              console.log("onChange!", v);
              setLang(v);
            }}
          />
        )
      }
    </>
  );
}
