import { Button } from '@mui/material';
import React, { Component } from 'react';
import { render } from 'react-dom';
import Cropper from 'react-easy-crop';
import ButtonPrimary from '../../components/button/ButtonPrimary';

export default class ExampleComponent extends Component {
  canvas = {}

  state = {
    image: 'https://www.gravatar.com/avatar/3d721f4c46282afc254f3ea0cd05df30?s=170&d=identicon&r=PG',
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1 / 1,
    croppedAreaPixels: {}
  }

  drawImage = () => {
    const { croppedAreaPixels } = this.state;
    // console.log('this.image >>', this.image)
    this.ctx.drawImage(this.image, croppedAreaPixels.x, croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height, 0, 0, croppedAreaPixels.width, croppedAreaPixels.height);
   
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log('croppedAreaPixels >>', croppedAreaPixels)
    this.setState({
      croppedAreaPixels
    }, () =>{
      this.drawImage();
    })
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  onSave = () => {
    const base64ImageData = this.canvas.toDataURL('image/jpeg');
    console.log(base64ImageData);
    this.props.onChange(base64ImageData)

  }

  render() {
    const { image, croppedAreaPixels, crop, zoom, aspect } = this.state;
    return (
      <div className="popup-crop">
        <div className="bg-crop"/>
        <img 
          ref={imageRef => this.image = imageRef}
          id="source"
          style={{display: 'none'}}
          src={this.props.srcImg} />
        <canvas
          ref={canvas => {
            this.canvas = canvas;
            canvas && (this.ctx = canvas.getContext('2d'))
          }}
          style={{display: 'none'}}
          width={croppedAreaPixels.width}
          height={croppedAreaPixels.height}
        />
        <Cropper
          image={this.props.srcImg}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={this.onCropChange}
          onCropComplete={this.onCropComplete}
          onZoomChange={this.onZoomChange}
        />
        <ButtonPrimary addClass="img-crop" onClick={this.onSave}>Crop</ButtonPrimary>
      </div>
    )
  }
}