import React, { useState, useEffect } from "react";
import Modal from "react-modal";

const defaultHeader = <>Header</>

export default function ModalBase2({ mini = false, open = false, onClose, header = defaultHeader, content, footer, size = '' }) {

  useEffect(() => {
    console.log('useEffect! ModalBase')
    if (open) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }
    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [open])

  const customStylesModalPoint = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      height: "auto",
    },
  };

  let contentStyle = ""
  if (mini) {
    contentStyle = ""
  }
  
  return (
    <>
      {open ? (
        <div>
          <Modal
            className={`modal-base ${size}`}
            isOpen={customStylesModalPoint}
            onRequestClose={() => onClose(false)}
            // style={customStylesModalPoint}
          >
            <div className="w-full flex flex-col justify-center items-center ">
              {header}
            </div>

            <div className="">
              <div className={contentStyle}>
                {content}
              </div>
            </div>

            <div className="">
              {footer}
            </div>
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
