export default function ButtonPrimary(props) {
  const { addClass = "", children, size } = props;
  let sizeStyle = "text-[16px] md:text-[16px]";
  if (size === 'mini') {
    sizeStyle = "text-[16px] md:text-[16px]"
  }
  return (
    <button
      className={`btn-primary bg-[#493B43] order-[1px] border-[#493B43] rounded-[100px] leading-[41px] h-[41px] ${sizeStyle} min-w-[135px] md:min-w-[180px] text-white ${addClass}`}
      {...props}
    >
      <>{children}</>
    </button>
  );
}
